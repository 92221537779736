<template>
  <div>
    <v-switch
      v-model="localValue"
      outlined
      :label="inputName"
      color="primary"
    />
  </div>
</template>

<script>
  export default {
    name: "IseqBooleanInput",
    props: {
      value: {
        required: false,
        default: undefined
      },
      inputName: {
        type: String,
        required: false,
        default: ""
      },
    },
    computed: {
      localValue: {
        get() {
          return this.value;
        },
        set(newValue) {
          this.$emit('input', newValue);
        }
      }
    }
  }
</script>