<template>
  <div>
    <treeselect
      v-model="localValue"
      :multiple="true"
      :options="allowedExtensionsFilter(filesTree.children, input.constraints.extensions)"
      :clearable="true"
      :searchable="true"
      :flatten-search-results="false"
      :close-on-select="true"
      :disable-branch-nodes="true"
      placeholder="Please select files"
      search-nested
    >
      <label
        slot="option-label"
        slot-scope="{node}"
      >
        <template v-if="node.raw.runDate">
          <v-tooltip right>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >
                {{ node.label }}
              </span>
            </template>

            <span>{{ node.raw.runDate }}</span>
          </v-tooltip>
        </template>
        <template v-else>
          {{ node.label }}
        </template>
      </label>

      <div
        slot="value-label"
        slot-scope="{ node }"
      >
        {{ fileNodeLabel(node.raw.id, node.raw.label) }}
      </div>

      <div
        v-if="allowedExtensionsFilter(filesTree.children, input.constraints.extensions).length"
        slot="before-list"
        class="before-list-element"
      >
        <v-iseq-btn
          v-if="!areAllValuesSelected"
          type="secondary"
          class="before-list-element__select-all-btn"
          @click="selectAll()"
        >
          Select All
        </v-iseq-btn>

        <v-iseq-btn
          v-if="isAnyValueSelected"
          type="secondary"
          class="before-list-element__select-all-btn"
          @click="deselectAll()"
        >
          Deselect All
        </v-iseq-btn>
      </div>
    </treeselect>
  </div>
</template>

<script>
  import Treeselect from "@riophae/vue-treeselect";
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import IseqButton from "@/components/ui/IseqButton.vue";

  export default {
    name: "IseqArrayFileInput",
    components: {
      Treeselect,
      "v-iseq-btn": IseqButton
    },
    props: {
      value: {
        required: false,
        default: undefined
      },
      input: {
        type: Object,
        required: true
      },
      filesTree: {
        type: Object,
        required: false,
        default: () => {}
      },
      sampleUuid: {
        type: String,
        required: false,
        default: undefined
      },
      areAllValuesSelected: {
        type: Boolean,
        required: true
      },
      isAnyValueSelected: {
        type: Boolean,
        required: true
      },
      allowedExtensionsFilter: {
        type: Function,
        required: true
      }
    },
    computed: {
      localValue: {
        get() {
          return this.value;
        },
        set(newValue) {
          if (this.input.constraints.maxFiles && newValue && newValue.length > this.input.constraints.maxFiles) {
            newValue = newValue.slice(1);
          }
          this.$emit('input', newValue);
        }
      }
    },
    methods: {
      fileNodeLabel(id, label) {
        const fileSampleUuid = id.split('/')[1];
        if (this.sampleUuid === fileSampleUuid && label) {
          return label;
        } else {
          const sample = this.content.find(node => node.uuid === fileSampleUuid);
          return id.split('/')[id.split('/').length - 1] + ' (' + sample.name + ')';
        }
      },
      selectAll() {
        let values = this.value || [];

        function addAllLeaves(node) {
          if (node.children) {
            for (let child of node.children) {
              addAllLeaves(child);
            }
          } else if (!values.includes(node.id)) {
            values.push(node.id);
          }
        }

        for (let node of this.allowedExtensionsFilter(this.filesTree.children, this.input.constraints.extensions)) {
          addAllLeaves(node);
        }

        this.$emit('input', values);
      },
      deselectAll() {
        const values = this.value || [];
        const filteredValues = values.filter(node => node.split('/')[1] !== this.sampleUuid);
        this.$emit('input', filteredValues);
      }
    }
  }
</script>

<style lang="scss" scoped>
.before-list-element {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  margin-bottom: 4px;
  margin-left: 5px;

  &__select-all-btn {
    width: 100px;
    height: 20px;
    margin: 0 4px 0 4px;
  }
}
</style>