<template>
  <div class="wrapper">
    <iseq-credential-views-frame>
      <div
        v-if="form==='login'"
        class="wrapper__input-fields"
      >
        <div class="wrapper__input-fields__title">
          <span class="wrapper__input-fields__title__text">
            Sign in to your account
          </span>
        </div>

        <div class="wrapper__input-fields__field-name">
          <span class="wrapper__input-fields__field-name__text">
            Email
          </span>
        </div>

        <v-text-field
          v-model="username"
          type="email"
          filled
          hide-details
          class="iseq-text-field wrapper__input-fields__field"
          @keyup.enter="signIn"
        />

        <div
          v-if="flags.isUsernameEmpty"
          class="wrapper__input-fields__error"
        >
          <span class="wrapper__input-fields__error__text">Username cannot be empty</span>
        </div>

        <div class="wrapper__input-fields__field-name">
          <span class="wrapper__input-fields__field-name__text">
            Password
          </span>
        </div>

        <v-text-field
          v-model="password"
          type="password"
          filled
          hide-details
          class="iseq-text-field wrapper__input-fields__field"
          @keyup.enter="signIn"
        />

        <div
          v-if="flags.isNotAuthorized"
          class="wrapper__input-fields__error"
        >
          <span class="wrapper__input-fields__error__text">Email and password don't match</span>
        </div>


        <div
          v-if="flags.attemptsExceeded"
          class="wrapper__input-fields__error"
        >
          <span class="wrapper__input-fields__error__text">Attempt limit exceeded. Try again later.</span>
        </div>

        <div
          class="wrapper__input-fields__forgotten-password"
          @click="retrievePassword()"
        >
          <span class="wrapper__input-fields__forgotten-password__text">
            Forgot your password?
          </span>
        </div>

        <div class="wrapper__button-field">
          <v-iseq-btn
            :disabled="signingInInProgress"
            class="wrapper__button-field__button"
            @click="signIn"
          >
            {{ signingInInProgress ? `Signing in...` : `Sign in` }}
          </v-iseq-btn>
        </div>

        <span class="wrapper__text">
          <router-link to="/sign-up">
            Don't have an account? <span class="link">Sign up</span>
          </router-link>
        </span>
      </div>

      <div
        v-if="form==='2FALoginConfirmation'"
        class="wrapper__input-fields"
      >
        <div class="wrapper__input-fields__title">
          <span class="wrapper__input-fields__title__text">
            Confirm sign in
          </span>
        </div>

        <div class="wrapper__input-fields__field-name">
          <span class="wrapper__input-fields__field-name__text">
            SMS code
          </span>
        </div>

        <v-text-field
          v-model="code"
          filled
          hide-details
          class="iseq-text-field wrapper__input-fields__field"
          @keyup.enter="confirm2FACode()"
        />

        <div
          v-if="flags.invalidCode"
          class="wrapper__input-fields__error"
        >
          <span class="wrapper__input-fields__error__text">Code invalid or expired. Try again.</span>
        </div>

        <div
          v-if="flags.attemptsExceeded"
          class="wrapper__input-fields__error"
        >
          <span class="wrapper__input-fields__error__text">Attempt limit exceeded. Try again later.</span>
        </div>

        <div class="wrapper__button-field">
          <v-iseq-btn
            :disabled="signingInInProgress"
            class="wrapper__button-field__button"
            @click="confirm2FACode()"
          >
            {{ signingInInProgress ? `Signing in...` : `Sign in` }}
          </v-iseq-btn>
        </div>
      </div>
    </iseq-credential-views-frame>
  </div>
</template>

<script>
  import {AmplifyEventBus} from 'aws-amplify-vue';
  import {Auth} from 'aws-amplify';
  import store from '@/store';
  import {isValidEmail} from "@/plugins/utils";
  import IseqCredentialViewsFrame from "@/views/Login/components/IseqCredentialViewsFrame";
  import IseqButton from "@/components/ui/IseqButton.vue";

  async function getJWT() {
    const session = await Auth.currentSession();
    const jwt = session.idToken.jwtToken;
    store.commit('security/updateToken', jwt);
  }

  export default {
    name: "Login",
    components: {
      IseqCredentialViewsFrame,
      "v-iseq-btn": IseqButton
    },
    data: function () {
      return {
        user: undefined,
        form: "login",
        username: "",
        password: "",
        code: "",
        flags: {
          isNotAuthorized: false,
          isUsernameEmpty: false,
          invalidCode: false,
          attemptsExceeded: false
        },
        signUpEmailPredefined: false,
        signingInInProgress: false
      }
    },
    created() {
      if (this.$route.query.email && isValidEmail(this.$route.query.email)) {
        this.$router.push({name: 'signup', params: {email: this.$route.query.email}});
      }
    },
    mounted() {
      Auth.currentAuthenticatedUser().catch(err => console.log(err));

      AmplifyEventBus.$on("authState", eventInfo => {
        if (eventInfo === "signedIn") {
          getJWT();
          this.$router.push({path: "/"});
        }
      });
    },
    methods: {
      async signIn() {
        if (this.validateUsername(this.username)) {
          this.flags = {...this.flags, isNotAuthorized: false, attemptsExceeded: false};
          try {
            this.signingInInProgress = true;
            this.user = await Auth.signIn(this.username, this.password);
            this.signingInInProgress = false;
            if (this.user.challengeName === "NOMFA" || this.user.challengeName === undefined) {
              await this.$router.push(this.$route.query.redirect ? this.$route.query.redirect : "/")
            } else {
              this.form = "2FALoginConfirmation";
            }
          } catch (error) {
            this.signingInInProgress = false;
            if (error.code === "UserNotConfirmedException") {
              await this.$router.push({
                name: "signup",
                params: {
                  signUpConfirm: true,
                  username: this.username,
                  password: this.password
                }
              });
            } else if (error.code === "NotAuthorizedException") {
              if (error.message.includes("attempts exceeded")) {
                this.flags.attemptsExceeded = true;
              } else {
                this.flags.isNotAuthorized = true;
              }
            } else if (error.code === "InvalidParameterException") {
              this.flags.isNotAuthorized = true;
            } else {
              console.log(error)
            }
          }
        }
      },
      async confirm2FACode() {
        try {
          this.flags = {...this.flags, invalidCode: false, attemptsExceeded: false};
          this.signingInInProgress = true;
          await Auth.confirmSignIn(this.user, this.code, "SMS_MFA");
          this.signingInInProgress = false;
          await this.$router.push("/");
        } catch (error) {
          this.signingInInProgress = false;
          if (error.code === "CodeMismatchException") {
            this.flags.invalidCode = true;
          } else if (error.code === "LimitExceededException") {
            this.flags.attemptsExceeded = true
          } else {
            console.log(error);
          }
        }
      },
      validateUsername(username) {
        this.flags = {...this.flags, isUsernameEmpty: false, attemptsExceeded: false};
        if (username.length === 0) {
          this.flags.isUsernameEmpty = true;
          return false
        }
        return true
      },
      retrievePassword() {
        this.$router.push({name: 'forgot-password', params: {username: this.username}});
      }
    }
  }
</script>
<style lang="scss"
       scoped>

@import '@/scss/CredentialViewsStyles.scss';
</style>