<template>
  <div>
    <v-textarea
      v-model="localValue"
      outlined
      hide-details
      :label="inputName"
    />

    <template v-if="value && maxLength && value.length >= maxLength">
      <span class="warning-text">
        <v-icon
          color="warning"
          class="mr-0 mb-3 mt-1"
        >
          mdi-alert
        </v-icon>

        This input is limited to {{ maxLength }} characters
      </span>
    </template>
  </div>
</template>

<script>
  export default {
    name: "IseqTextAreaInput",
    props: {
      value: {
        required: false,
        default: undefined
      },
      inputName: {
        type: String,
        required: false,
        default: ""
      },
      maxLength: {
        type: Number,
        required: false,
        default: undefined
      }
    },
    computed: {
      localValue: {
        get() {
          return this.value;
        },
        set(newValue) {
          this.$emit('input', newValue);
        }
      }
    }
  }
</script>