const filenameWithMetaRegexp = /.*[a-zA-Z0-9\-_]\/(?<filenameWithMeta>[_\-0-9a-zA-Z]+\.[_\-0-9a-zA-Z]([\-_0-9a-zA-Z.]*[0-9a-zA-Z](\?.*|)|))$/;

export function validateUrl(url) {
  const urlRegex = /^https?:\/\/[^\s\/$.?#].[^\s]*$/;
  return url ? !!url.match(urlRegex) : false;
}

export function validateFtp(ftp) {
  const ftpRegex = /^ftp:\/\/[^\s\/$.?#].[^\s]*$/;
  return ftp ? !!ftp.match(ftpRegex) : false;
}

export function getFilenameFromUrl(url) {
  if (url.match(filenameWithMetaRegexp)) {
    const filenameWithMeta = url.match(filenameWithMetaRegexp).groups.filenameWithMeta;
    const filenameRegexp = /(?<filename>[\-_0-9a-zA-Z.\-]*)\?*.*/;
    return filenameWithMeta.match(filenameRegexp).groups.filename;
  } else {
    return `from address: ${url}`;
  }
}

export function getFileExtensionFromUrl(url) {

  // This function doesn't return exact extension part if file name includes dots

  if (url) {
    if (url.match(filenameWithMetaRegexp)) {
      const filenameWithMeta = url.match(filenameWithMetaRegexp).groups.filenameWithMeta;
      const fileExtensionRegexp = /.*?(?<extension>\.[\-_0-9a-zA-Z.]*)\?*.*/;
      return filenameWithMeta.match(fileExtensionRegexp).groups.extension;
    } else {
      return "";
    }
  } else {
    return undefined;
  }
}

export function getFileExtensionFromFilename(filename) {

  // This function doesn't return exact extension part if file name includes dots

  const fileExtensionRegexp = /.*?(?<extension>\..*)/;
  if (filename && filename.match(fileExtensionRegexp)) {
    return filename.match(fileExtensionRegexp).groups.extension;
  } else {
    return "\"unknown\"";
  }
}

export function recognizeExtension(extension, knownExtensions) {
  const extensionsLongSorted = knownExtensions.split(", ").sort((a, b) => {
    return (b.match(/\./g) || []).length - (a.match(/\./g) || []).length;
  });

  if (extension) {
    for (const element of extensionsLongSorted) {
      if (extension.endsWith(element)) {
        return element;
      }
    }
  }

  return extension;
}