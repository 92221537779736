<template>
  <v-iseq-card
    title="Workflows"
    class="iseq-workflow-list"
  >
    <template #title-row>
      <div
        v-if="showUnreleased"
      >
        <v-text-field
          v-model="searchPhrase"
          label="Search"
          clearable
          hide-details
          clear-icon="mdi-close"
        >
          <v-icon
            slot="prepend"
            color="primary"
          >
            mdi-clipboard-text-search-outline
          </v-icon>
        </v-text-field>
      </div>
    </template>

    <v-iseq-banner
      v-if="isAdmin && wdlsWithoutPrice.length > 0"
      type="warning"
      :value="[`There ${wdlsWithoutPrice.length === 1 ? `is 1 workflow` : `are ${wdlsWithoutPrice.length} workflows` } without price that will not appear on this list unless their price is set through the`, '']"
      :link="{text: 'admin panel.', address: `${adminPanelAddress}/workflows`}"
      center-content
    />

    <v-iseq-banner
      v-if="notParsedWorkflows.length > 0 && isAdmin"
      type="warning"
      :value="[`There are workflows that did not pass validation. For more info please check the`, '']"
      :link="{text: 'admin panel.', address: `${adminPanelAddress}/workflows`}"
      center-content
    />

    <v-data-table
      :headers="tableColumns"
      :items="filteredWorkflows"
      :footer-props="{
        'items-per-page-options': itemsPerPageOptions
      }"
      @click:row="goToWorkflow"
    >
      <template #[`item.price`]="{ item }">
        {{ `${item.price ? item.price : 0} credits` }}
      </template>
    </v-data-table>

    <v-iseq-btn
      v-if="isAdmin"
      large
      post-icon="mdi-folder-sync"
      @click.prevent="changeUnreleasedVisibility"
    >
      {{ `${showUnreleased ? "Hide unreleased workflows" : "Show unreleased Workflows"}` }}
    </v-iseq-btn>
  </v-iseq-card>
</template>

<script>

  import {mapState} from 'vuex';
  import IseqCard from "@/components/ui/IseqCard.vue";
  import IseqButton from "@/components/ui/IseqButton.vue";
  import IseqBanner from "@/components/ui/IseqBanner.vue";

  export default {
    name: "WorkflowList",
    components:{
      "v-iseq-card": IseqCard,
      "v-iseq-btn": IseqButton,
      "v-iseq-banner": IseqBanner
    },
    data: function () {
      return {
        searchPhrase: "",
        itemsPerPageOptions: [10, 20, 30, -1],
        showUnreleased: false,
        tableColumns: [
          {text: 'Workflow name', value: 'name'},
          {text: 'Type', value: 'tag'},
          {text: 'Version', value: 'version'},
          {text: 'Price', value: 'price'}
        ]
      }
    },
    computed: {
      ...mapState('workflow', ['workflows', 'notParsedWorkflows', 'wdlsWithoutPrice']),
      ...mapState('organization', ['organizations', 'organization']),
      ...mapState('user', ['isAdmin']),
      filteredWorkflows() {
        if (!this.searchPhrase) {
          return this.workflows.filter(workflow => workflow.name);
        } else {
          let keywords = this.searchPhrase.split(' ');

          return this.workflows.filter(workflow => {
            for (let keyword of keywords) {
              if (!workflow.name || !workflow.name.toLowerCase().includes(keyword.toLowerCase())) {
                return false;
              }
            }
            return true;
          });
        }
      },
      adminPanelAddress() {
        return process.env.VUE_APP_ADMIN_PANEL_ADDRESS;
      }
    },
    watch: {
      organization: {
        deep: true,
        handler() {
          this.$store.dispatch('workflow/getProdWorkflows');
        }
      },
      organizations: {
        deep: true,
        handler() {
          if (this.isAdmin) {
            this.$store.dispatch('workflow/getWdlsWithoutPrice');
            this.$store.dispatch('workflow/getNotParsedProdWorkflows');
          }
        }
      }
    },
    created: function () {
      this.$store.dispatch('workflow/getProdWorkflows');
      if (this.isAdmin) {
        this.$store.dispatch('workflow/getWdlsWithoutPrice');
        this.$store.dispatch('workflow/getNotParsedProdWorkflows');
      }
    },
    methods: {
      goToWorkflow(destination) {
        this.$router.push({path: `/workflows/${destination.workflowId}`});
      },
      newAnalysis() {
        this.$router.push({path: '/analyses/new'});
      },
      changeUnreleasedVisibility() {
        this.showUnreleased = !this.showUnreleased;
        if (this.showUnreleased) {
          this.$store.dispatch('workflow/getDevWorkflows');
          if (this.isAdmin) {
            this.$store.dispatch('workflow/getNotParsedDevWorkflows');
          }
        }
        else {
          this.$store.dispatch('workflow/getProdWorkflows');
          if (this.isAdmin) {
            this.$store.dispatch('workflow/getNotParsedProdWorkflows');
          }
        }
      }
    },
  }
</script>
<style lang="scss"
       scoped>
.iseq-workflow-list {

}
</style>