import store from '@/store';

export default {
  namespaced: true,
  state: {
    id: 0,
    items: [],
    missingConnectionSnackbarId: undefined
  },
  mutations: {
    addItem: (state, payload) => {
      state.id++;

      state.items.push({
        id: state.id,
        message: payload.message,
        icon: payload.icon,
        type: payload.type,
      });
      if (payload.callback){
        payload.callback(state.id);
      }
      if (payload.timeout) {
        store.commit("snackbarQueue/removeItem", {id: state.id, timeout: payload.timeout});
      }
    },
    removeItem: (state, payload) => {
      const itemToDelete = state.items.find(item => item.id === payload.id);
      let timeout = payload.force ? 0 : (payload.timeout || 3500);

      if (itemToDelete.type !== 'error' || payload.force) {
        setTimeout(() => {
          state.items.splice(state.items.findIndex(item => item.id === payload.id), 1);
        }, timeout)
      }
    },
    setMissingConnectionSnackbarId: (state, payload) => {
      state.missingConnectionSnackbarId = payload;
    },
    cleanAllData(state) {
      state.id = 0;
      state.items = [];
    },
  },
  actions: {
    addMessage({state, commit}, payload) {
      if (payload.message && (payload.type !== "error" || (payload.type === "error" && !state.items.some(item => item.message === payload.message)))) {
        return new Promise((resolve) => {
          commit('addItem', {
            message: payload.message,
            callback: resolve,
            type: payload.type
          });
        });
      } else {
        return null;
      }
    },
    addConfirmationMessage({commit}, payload) {
      const type = {
        fileUpload: {icon: "mdi-cloud-upload", message: "Your upload has started. You can see the progress on the dashboard"},
        analysisStarted: {icon: "mdi-chart-arc", message: "Your analysis has started. You can see the progress on the dashboard"},
        joinRequestSent: {icon: "mdi-account-arrow-right", message: "Join request has been sent"},
        internetAccessRetrieved: {icon: "mdi-wifi", message: "Your internet connection was restored"},
        userNameChanged: {icon: "mdi-account", message: "Name has been changed"},
        invitationSentSuccessfully: {icon: "mdi-account-plus", message: "Invitation has been sent successfully"},
        bugReported: {icon: "mdi-account", message: "Bug has been reported"}
      };

      commit('addItem', {
        message: type[payload.type].message,
        icon: type[payload.type].icon,
        type: "success",
        timeout: payload.timeout || 5000
      });
    },
    addMissingConnectionSnackbar({state, commit, dispatch}) {
      if (state.missingConnectionSnackbarId) {
        commit("removeItem", {force: true, id: state.missingConnectionSnackbarId});
      }
      dispatch('addMessage', {
        message: "No Internet connection. Trying to reconnect…",
        type: "warning"
      }).then( response => {
        commit("setMissingConnectionSnackbarId", response);
      });
    },
    removeMissingConnectionSnackbar({state, commit}) {
      if (state.missingConnectionSnackbarId) {
        commit("removeItem", {force: true, id: state.missingConnectionSnackbarId});
      }
      commit("setMissingConnectionSnackbarId", undefined);
    }
  },
};
