import request from '@/customjs/request.js'
import {generatePagingData} from "@/plugins/utils";
import store from "@/store";

export default {

  namespaced: true,

  state: {
    workflowTableOptions: generatePagingData({}),
    workflow: {},
    workflows: [],
    workflowsGrouped: [],
    workflowInputExtensions: "",
    workflowInputExtensionsLong: "",
    notParsedWorkflows: [],
    inputs: {},
    wdlsWithoutPrice: []
  },

  mutations: {
    setWorkflowTableOptions (state, payload) {
      state.workflowTableOptions = payload;
    },
    clearWorkflowTableOptions(state) {
      state.workflowTableOptions = generatePagingData({});
    },
    setWorkflowInputExtensionsLong: (state, payload) => {
      state.workflowInputExtensionsLong = payload.sort().join(', ');
    },
    setNotParsedWorkflows(state, payload) {
      state.notParsedWorkflows = payload;
    },
    setWdlsWithoutPrice(state, payload) {
      state.wdlsWithoutPrice = payload;
    },
    setWorkflows(state, data) {

      // Setting plain workflows list
      state.workflows = data;

      // Dividing list by tags

      const tags = [
        "Clinical WES/WGS",
        "Consumer WES/WGS",
        "Research",
        "Utilities",
      ];

      data.map(workflow => workflow.tag = workflow.tag ? workflow.tag : "Undefined");
      data.sort(function (a, b) {
        if (tags.indexOf(a.tag)+1 && tags.indexOf(b.tag)+1){
          if (tags.indexOf(a.tag) === tags.indexOf(b.tag)) return 0;
          else if (tags.indexOf(a.tag) < tags.indexOf(b.tag)) return -1;
          else return 1
        }
        else if (tags.indexOf(a.tag)+1){
          return 1
        }
        else if (tags.indexOf(b.tag)+1){
          return -1
        }
        else {
          if (a.tag === b.tag) return 0;
          else if (a.tag < b.tag) return 1;
          else return -1
        }
      });

      let groupedData = [];

      if (data.length) {
        groupedData.push({divider: true});
        groupedData.push({header: data[0].tag});
        groupedData.push({divider: true});
        groupedData.push(data[0]);

        for (let i = 1; i < data.length; i++) {
          if (data[i].tag !== groupedData[groupedData.length - 1].tag) {
            groupedData.push({divider: true});
            groupedData.push({header: data[i].tag});
            groupedData.push({divider: true});
            groupedData.push(data[i])
          } else {
            groupedData.push(data[i])
          }
        }
      }

      // Setting tagged workflows list
      state.workflowsGrouped = groupedData;
    },

    setWorkflow(state, workflow) {
      // Adding computed fields to workflow

      // Parsing inputs into Array
      workflow.inputs = Array.from(Object.entries(workflow.inputs), ([id, inputObject]) => {
        inputObject.id = id;
        return inputObject;
      })

      // Parsing groups into Array, adding subinputs by their IDs and merging together with the rest of inputs
      if (workflow.groupInputs) {
        const groupInputs = Array.from(Object.entries(workflow.groupInputs), ([id, groupObject]) => {
          groupObject.id = id;
          groupObject.inputs = workflow.inputs.filter(node => groupObject.inputs.includes(node.id));
          return groupObject;
        })
        workflow.inputs = [...workflow.inputs, ...groupInputs];
      }

      // Adding data about control input to the controlled inputs in order to avoid repeating it every time it's needed
      workflow.inputs.map(input => {
        if (input.constraints && input.constraints.dependentOn) {
          const controlInput = workflow.inputs.find(potentialControlInput => potentialControlInput.id === input.constraints.dependentOn);
          input.controlInput = {
            name: controlInput.name,
            id: controlInput.id
          }
        }
        return input;
      })

      // Sorting workflow inputs
      workflow.inputs.sort(function (a, b) {
        if (a.index < b.index) return -1;
        if (a.index > b.index) return 1;
        return 0;
      });

      state.workflow = workflow;
    },

    setInputExtensions(state, data) {
      const newExtensions = [];
      for (let extend of data) {
        let index = extend.lastIndexOf('.');
        const newExtend = extend.slice(index);
        let isInArray = false;
        newExtensions.map(extend => {
          if(extend === newExtend) {
            isInArray = true
          }
        });
        if(!isInArray) {
          newExtensions.push(newExtend);
        }
      }
      state.workflowInputExtensions = newExtensions.sort().join(', ');
    },

    cleanWorkflow(state) {
      state.workflow = {};
    },

    cleanAllData(state) {
      state.workflows = [];
      state.workflowsGrouped = [];
      state.workflowInputExtensions = "";
      state.inputs = {};
      state.workflow = {};
      state.wdlsWithoutPrice = [];
    }
  },

  actions: {

    getProdWorkflows({commit}) {
      const organizationUuid = store.getters["organization/currentOrganizationUuid"];

      request.request({
        endpoint: `wdl/prod?organizationUuid=${organizationUuid}`,
        callback: function (data) {
          commit("setWorkflows", data);
        }
      })
    },

    getDevWorkflows({commit}) {
      request.request({
        endpoint: "wdl/dev",
        service: "API_WDL",
        callback: function (data) {
          commit("setWorkflows", data);
        }
      })
    },

    getWorkflow({commit}, payload) {
      request.request({
        endpoint: `wdl/${payload}`,
        service: "API_DX",
        callback: function (data) {
          commit("setWorkflow", data);
        }
      })
    },

    getWorkflowFromURL({commit}, payload) {
      return new Promise((resolve, reject) => {
        request.request({
          endpoint: `wdl/parse?${payload.variant ? "variant=" + payload.variant + '&' : ''}hidden=true&validate=true&url=${payload.url}`,
          service: "API_WDL",
          callback: function (data) {
            commit("setWorkflow", data);
            resolve("Success");
          },
          errorCallback: function (error) {
            reject(error);
          }
        })
      })
    },

    getNotParsedProdWorkflows({commit}) {
      request.request({
        endpoint: "wdl/not-parsed/prod",
        service: "API_WDL",
        callback: function (data) {
          commit("setNotParsedWorkflows", data);
        }
      })
    },

    getNotParsedDevWorkflows({commit}) {
      request.request({
        endpoint: "wdl/not-parsed/dev",
        service: "API_WDL",
        callback: function (data) {
          commit("setNotParsedWorkflows", data);
        }
      })
    },

    getInputExtensions({commit}) {
      request.request({
        endpoint: "wdl/extensions",
        service: "API_WDL",
        callback: function (data) {
          commit("setInputExtensions", data);
          commit("setWorkflowInputExtensionsLong", data);
        }
      })
    },

    getWdlsWithoutPrice({commit}) {
      request.request({
        endpoint: "wdl/without-price",
        callback: function (data) {
          commit("setWdlsWithoutPrice", data.wdlsWithoutPrice);
        }
      })
    }
  },
  getters: {
    workflowTableOptions: (state) => {
      return state.workflowTableOptions;
    }
  }
}
