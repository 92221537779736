<template>
  <div class="loading-spinner">
    <v-progress-circular
      :size="size"
      :width="width"
      :color="color"
      indeterminate
    />

    <span class="loading-spinner__text">
      {{ text }}
    </span>
  </div>
</template>

<script>
  export default {
    name: "IseqLoadingSpinner",
    props: {
      size: {
        type: String,
        default: "50"
      },
      width: {
        type: String,
        default: "5"
      },
      color: {
        type: String,
        default: "primary"
      },
      text: {
        type: String,
        default: "Loading..."
      }
    },
  }
</script>

<style lang="scss"
       scoped>
    .loading-spinner{
        display: flex;
        flex-direction: column;
        align-items: center;

        &__text {
            padding-top: 10px;
            color: var(--font-color);
            font-size: 20px;
        }
    }
</style>