import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import Amplify, * as AmplifyModules from "aws-amplify"
import { AmplifyPlugin } from "aws-amplify-vue"
import awsmobile from '@/plugins/aws-exports'
import { VueReCaptcha } from "vue-recaptcha-v3";
import Rollbar from 'rollbar';
import VueSegmentAnalytics from 'vue-segment-analytics';

import './plugins/axios.js'
import './plugins/vuejs-logger.js'
import './plugins/vue-cookies.js'

Vue.config.productionTip = false
Amplify.configure(awsmobile)

Vue.prototype.$rollbar = new Rollbar({
  accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.NODE_ENV,
});

Vue.config.errorHandler = (err, vm, info) => {
  if(process.env.NODE_ENV !== 'development') {
    vm.$rollbar.error(err);
  }
  throw err;
}


Vue.use(AmplifyPlugin, AmplifyModules, VueReCaptcha, {siteKey: '6LcWsM0ZAAAAAGwMDHaBTZ57uFb9yceglrk9QOtS'})

// Segment.com initialization
Vue.use(VueSegmentAnalytics, {
  id: process.env.VUE_APP_SEGMENT_API_KEY,
  router // Optional
});

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
