import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

const intelliseqLightThemeColors = {
  primary: "#0079E1",
  secondary: "#7f94ae",
  tertiary: "#BBCBD8",
  dark: "#00285B",
  light: "#ECF0F5",
  light_2: "#F5F6FA",
  text: "#03265a",
  textinverted: "#7084a0",
  inactive: "#bbcbd8",
  active: "#0079E1",
  infolighten: "#eaf4fd",
  accent: colors.orange.darken1,
  error: "#EB4A61",
  danger: "#EB4A61",
  info: colors.cyan.darken1,
  request: "#0079E1",
  success: "#3BC380",
  warning: colors.orange.darken1,
  warning_light: "#FBC438",
  borderinactive: "#9E9E9E",
  bordertext: "#646464",
  navbar_bg: "#00285B",
  topbar_bg: "#0079E1"
}

const themes = {
  intelliseq: {
    light: intelliseqLightThemeColors,
  },
  tgenome: {
    light: {
      ...intelliseqLightThemeColors,
      primary: '#e20074',
      active: '#002e56',
      navbar_bg: "#e20074",
      inactive: "#ffb8fb",
      topbar_bg: "#ff5da8",
      request: "#002e56",
      error: "#8e001e"
    }
  }
}

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: process.env.VUE_APP_MODE === 'tgenome' ? themes.tgenome.light : themes.intelliseq.light
    }
  },
  icons: {
    iconfont: 'md',
  },
});
