<template>
  <div class="iseq-project-details">
    <v-iseq-card
      :title="`Project: ${project.name}`"
      class="iseq-project-details__base-info"
    >
      <template #title-row>
        <div class="iseq-project-details__base-info__title-row">
          <v-iseq-btn
            v-if="permissionRequired('projectEdit')"
            type="secondary"
            @click="updateProject"
          >
            Edit
          </v-iseq-btn>

          <v-iseq-btn
            v-if="permissionRequired('projectDelete')"
            type="red"
            ml
            @click="openDeleteDialog()"
          >
            Delete project
          </v-iseq-btn>
        </div>
      </template>

      <v-divider :inset="false" />

      <div
        v-if="project.description"
        class="iseq-project-details__base-info__description"
      >
        {{ project.description }}
      </div>

      <v-iseqfield :values="descriptionFields" />
    </v-iseq-card>

    <v-iseq-card
      v-if="permissionRequired('sampleAdd') || permissionRequired('analysisAdd') || permissionRequired('projectUserInvite')"
      no-title
      no-bottom-margin
      class="iseq-project-details__actions"
    >
      <div class="iseq-project-details__actions__buttons">
        <v-iseq-btn
          v-if="permissionRequired('sampleAdd')"
          width="wide"
          large
          mr
          @click="newSample"
        >
          New sample
        </v-iseq-btn>

        <v-iseq-btn
          v-if="permissionRequired('analysisAdd')"
          width="wide"
          large
          mr
          @click="newAnalysis"
        >
          New analysis
        </v-iseq-btn>

        <v-iseq-btn
          v-if="permissionRequired('projectUserInvite')"
          width="wide"
          large
          mr
          @click="openInviteMemberDialog()"
        >
          Invite new member
        </v-iseq-btn>
      </div>
    </v-iseq-card>

    <v-iseq-card
      title="Project details"
      tabs-only
      class="iseq-project-details__relationship"
    >
      <template #title-row>
        <div class="iseq-project-details__relationship__wrapper">
          <v-tabs
            v-model="tab"
            height="64px"
            fixed-tabs
            color="primary"
          >
            <v-tab
              v-if="permissionRequired('sampleView')"
              class="iseq-project-details__relationship__wrapper__tab"
              href="#samples"
            >
              <v-icon class="mr-4 title">
                mdi-folder
              </v-icon>
              Samples
            </v-tab>

            <v-tab
              v-if="permissionRequired('analysisView')"
              class="iseq-project-details__relationship__wrapper__tab"
              href="#analyses"
            >
              <v-icon class="mr-4 title">
                mdi-folder
              </v-icon>
              Analyses
            </v-tab>

            <v-tab
              class="iseq-project-details__relationship__wrapper__tab"
              href="#members"
            >
              <v-icon class="mr-4 title">
                mdi-account-multiple
              </v-icon>
              Members
            </v-tab>

            <v-tab
              v-if="permissionRequired('projectUserAdd')"
              class="iseq-project-details__relationship__wrapper__tab"
              href="#requests"
            >
              <v-icon class="mr-4 title">
                mdi-account-multiple
              </v-icon>
              Join requests
              <v-badge
                v-if="joinRequests.length"
                :content="joinRequests.length"
                color="danger"
                offset-x="5"
                offset-y="4"
              />
            </v-tab>

            <v-tab
              v-if="permissionRequired('projectUserInvite')"
              class="iseq-project-details__relationship__wrapper__tab"
              href="#sentInvitations"
            >
              <v-icon class="mr-4 title">
                mdi-account-plus
              </v-icon>
              Invitations
            </v-tab>
          </v-tabs>
        </div>
      </template>
    </v-iseq-card>


    <v-iseq-card class="iseq-project-details__table">
      <v-tabs-items
        touchless
        :value="tab"
      >
        <v-tab-item value="samples">
          <v-data-table
            :headers="samplesHeaders"
            :items="sampleContent"
            :loading="loading"
            :footer-props="{
              'items-per-page-options': itemsPerPageOptions
            }"
            :server-items-length="sampleTotalElements"
            :options.sync="samplesTableOptions"
          >
            <template #[`item.name`]="{ item }">
              <router-link :to="`/samples/${item.uuid}`">
                <div class="iseq-project-details__table__field">
                  {{ item.name }}
                </div>
              </router-link>
            </template>

            <template #[`item.status`]="{ item }">
              <router-link :to="`/samples/${item.uuid}`">
                <div class="iseq-project-details__table__field">
                  {{ item.status }}
                </div>
              </router-link>
            </template>

            <template #[`item.created`]="{ item }">
              <router-link :to="`/samples/${item.uuid}`">
                <div class="iseq-project-details__table__field">
                  {{ item.created }}
                </div>
              </router-link>
            </template>
          </v-data-table>
        </v-tab-item>

        <v-tab-item value="analyses">
          <v-data-table
            :headers="analysisHeaders"
            :footer-props="{
              'items-per-page-options': itemsPerPageOptions
            }"
            :items="analysesContent"
            :server-items-length="analysesTotalElements"
            :options.sync="optionsAnalyses"
          >
            <template #[`item.name`]="{ item }">
              <router-link :to="`/analyses/${item.uuid}`">
                <div class="iseq-project-details__table__field">
                  {{ item.name }}
                </div>
              </router-link>
            </template>

            <template #[`item.status`]="{ item }">
              <router-link :to="`/analyses/${item.uuid}`">
                <div class="iseq-project-details__table__field">
                  {{ item.status }}
                </div>
              </router-link>
            </template>

            <template #[`item.started`]="{ item }">
              <router-link :to="`/analyses/${item.uuid}`">
                <div class="iseq-project-details__table__field">
                  {{ item.started }}
                </div>
              </router-link>
            </template>

            <template #[`item.finished`]="{ item }">
              <router-link :to="`/analyses/${item.uuid}`">
                <div class="iseq-project-details__table__field">
                  {{ item.finished }}
                </div>
              </router-link>
            </template>

            <template #[`item.chargedCost`]="{ item }">
              <router-link :to="`/analyses/${item.uuid}`">
                <div class="iseq-project-details__table__field">
                  {{ item.chargedCost }} credits
                </div>
              </router-link>
            </template>
          </v-data-table>
        </v-tab-item>

        <v-tab-item value="members">
          <v-data-table
            :headers="membersHeaders"
            :items="members"
          >
            <template #[`item.actions`]="{ item }">
              <v-icon
                v-if="availablePositions(roleReadable(item.role)).length"
                small
                class="iseq-project-details__table__icon"
                @click="updateRole(item)"
              >
                mdi-pencil
              </v-icon>

              <v-tooltip
                top
                :disabled="!isLastAdmin"
              >
                <template #activator="{ on }">
                  <span v-on="on">
                    <v-icon
                      v-if="permissionRequired('projectUserDelete')"
                      small
                      :disabled="isLastAdmin"
                      class="iseq-project-details__table__icon"
                      @click="removeMember(item)"
                    >
                      mdi-account-off
                    </v-icon>
                  </span>
                </template>
                <span>Projects must contain at least one project admin member.</span>
              </v-tooltip>
            </template>

            <template #[`item.role`]="{ item }">
              {{ roleReadable(item.role) }}
            </template>
          </v-data-table>
        </v-tab-item>

        <v-tab-item value="requests">
          <v-data-table
            :headers="requestsHeaders"
            :items="joinRequests"
          >
            <template #[`item.actions`]="{ item }">
              <div class="iseq-project-details__table__actions">
                <v-iseq-btn
                  type="red"
                  large
                  @click="rejectRequest(item)"
                >
                  Reject
                </v-iseq-btn>

                <v-iseq-btn
                  large
                  @click="openAcceptJoinRequestDialog(item)"
                >
                  Accept
                </v-iseq-btn>
              </div>
            </template>
          </v-data-table>
        </v-tab-item>

        <v-tab-item value="sentInvitations">
          <v-data-table
            :headers="invitationsHeader"
            :items="pendingInvitations"
          >
            <template #[`item.expirationDate`]="{item}">
              <v-tooltip top>
                <template #activator="{ on }">
                  <span v-on="on">
                    {{ calculateDifference(item.expirationDate) }}
                  </span>
                </template>

                {{ item.expirationDate }}
              </v-tooltip>
            </template>

            <template #[`item.role`]="{item}">
              {{ roleReadable(item.role) }}
            </template>

            <template #[`item.status`]="{item}">
              {{ item.status[0] + item.status.toLowerCase().slice(1) }}
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-iseq-card>

    <iseq-confirmation-modal
      v-if="acceptJoinRequestDialog"
      header="Accept join request"
      confirm-button-type="primary"
      confirm-button-text="Accept request"
      reject-button-type="secondary"
      reject-button-text="Close"
      @actionRejected="closeAcceptJoinRequestDialog()"
      @actionConfirmed="acceptRequest()"
    >
      <p>
        Add user <strong>{{ newMember.name ? newMember.name : newMember.email }}</strong> to the project as:
      </p>

      <v-select
        v-model="newMemberRole"
        item-text="name"
        item-value="value"
        :items="availablePositions()"
      />
    </iseq-confirmation-modal>

    <iseq-confirmation-modal
      v-if="updateMemberDialog"
      header="Update role"
      confirm-button-type="primary"
      confirm-button-text="Save changes"
      reject-button-type="secondary"
      reject-button-text="Close"
      @actionRejected="closeUpdateMemberDialog"
      @actionConfirmed="updateMemberRole(member)"
    >
      <p>
        Change user's role for:<br>
        <strong>{{ member.email }}</strong>
      </p>

      <v-select
        v-model="member.newRole"
        item-text="name"
        item-value="value"
        :items="availablePositions()"
      />
    </iseq-confirmation-modal>

    <iseq-confirmation-modal
      v-if="updateProjectDialog"
      header="Update project data"
      confirm-button-type="primary"
      confirm-button-text="Save changes"
      reject-button-type="secondary"
      reject-button-text="Close"
      :confirm-button-disabled="!isNameFormValid"
      @actionRejected="closeProjectDialog"
      @actionConfirmed="saveChanges"
    >
      <v-form
        ref="nameForm"
        v-model="isNameFormValid"
      >
        <v-text-field
          v-model="projectUpdateForm.formData.name"
          class="text-field"
          outlined
          :rules="nameRules"
          counter="50"
          label="Name"
          @change="validateName"
          @keyup="validateName"
        />
      </v-form>

      <div>
        <v-textarea
          v-model="projectUpdateForm.formData.description"
          class="iseq-text-field iseq-text-field--active"
          label="Description"
          auto-grow
        />
      </div>
    </iseq-confirmation-modal>

    <iseq-confirmation-modal
      v-if="removeMemberDialog"
      header="Remove member"
      confirm-button-type="danger"
      confirm-button-text="Remove"
      reject-button-type="secondary"
      reject-button-text="Close"
      @actionRejected="removeMemberDialog = false"
      @actionConfirmed="removeMemberConfirmed()"
    >
      <span>
        Are you sure that you want to remove member with email:<br>
        <strong>{{ member.email }}</strong>
      </span>
    </iseq-confirmation-modal>

    <iseq-confirmation-modal
      v-if="deleteDialog"
      header="Delete project"
      confirm-button-type="primary"
      confirm-button-text="Delete"
      reject-button-type="secondary"
      reject-button-text="Close"
      :confirm-button-disabled="runningAnalyses.length > 0"
      @actionRejected="deleteDialog = false"
      @actionConfirmed="deleteProject()"
    >
      <template v-if="runningAnalyses.length === 0">
        <span>
          In order to delete this project enter its name (<strong>{{ project.name }}</strong>) below and click
          "Delete" button.<br>
          <br>
          (Please, keep in mind that all samples and analyses belonging to this project will also be deleted. This operation cannot be undone!)
        </span>

        <div>
          <v-text-field
            v-model="deleteDialogEnteredName"
            label="Project's name"
            hide-details
          />
        </div>

        <span
          v-if="deletedNotification"
          class="error-text"
        >
          {{ deletedNotification }}
        </span>
      </template>

      <template v-else>
        <span>
          Following analysis must be completed or terminated in order to delete project  {{ project.name }}:
        </span>

        <div
          v-for="analysis in runningAnalyses"
          :key="analysis.uuid"
        >
          <span>
            <router-link :to="`/analyses/${analysis.uuid}`">
              - <span class="link"> {{ analysis.name }}</span>
            </router-link><br>
          </span>
        </div>
      </template>
    </iseq-confirmation-modal>

    <v-invite-member-dialog
      v-if="inviteMemberDialog"
      invitation-type="project"
      @closed="inviteMemberDialog=false"
    />
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import IseqField from "@/components/ui/IseqField.vue";
  import InviteMemberModal from '@/components/modals/InviteMemberModal.component.vue';
  import VueCookies from 'vue-cookies';
  import {availablePositionInProject, permissionCheck} from "@/plugins/permissions";
  import {dateToRelativeTime, pagingDataParse, roleToHR} from "@/plugins/utils";
  import IseqConfirmationModal from "@/components/modals/IseqConfirmationModal.vue";
  import IseqCard from "@/components/ui/IseqCard.vue";
  import IseqButton from "@/components/ui/IseqButton.vue";
  import {segmentTrack} from "@/helpers/analytics";

  export default {
    name: "ProjectDetails",
    components: {
      IseqConfirmationModal,
      'v-iseqfield': IseqField,
      'v-invite-member-dialog': InviteMemberModal,
      "v-iseq-card": IseqCard,
      "v-iseq-btn": IseqButton
    },
    beforeRouteLeave(to, from, next) {
      this.$store.commit('sample/clearSamplesTableOptions');
      this.$store.commit('sample/resetContent');
      this.$store.commit('analysis/resetContent');
      if (this.markedToDeletion) {
        this.$store.commit('project/cleanProjectData');
      }
      if (!to.name.includes('project')) {
        this.$store.commit('project/clearProjectsTableOptions');
        next();
      } else {
        next();
      }
    },
    data: function () {
      return {
        itemsPerPageOptions: [10, 20, 30, -1],
        itemsPerPageMembers: [10, 20, 30, -1],
        optionsSample: {},
        optionsAnalyses: {},
        loading: false,
        deleteDialog: false,
        deleteDialogEnteredName: "",
        deletedNotification: "",
        markedToDeletion: false,
        updateMemberDialog: false,
        updateProjectDialog: false,
        acceptJoinRequestDialog: false,
        newMember: {},
        newMemberRole: "PROJECT_VIEWER",
        removeMemberDialog: false,
        inviteMemberDialog: false,
        tabs: ["Added", "Modified", "Tags", "Status"],
        tab: "Members",
        member: {
          uuid: '',
          email: '',
          newRole: '',
          role: ''
        },
        projectUpdateForm: {
          uuid: '',
          formData: {
            name: '',
            description: ''
          }
        },
        isNameFormValid: false,
        nameRules: [
          v => (!!v && v.length > 0) || "Name cannot be empty",
          v => v.length <= 50 || "Name cannot be longer than 50 characters",
          v => (!this.headerProjects.some(element => element.name === v) || v === this.project.name) || "Project named " + v + " already exists in this organization"
        ],
        membersHeaders: [
          {text: 'Name', value: 'name'},
          {text: 'Email', value: 'email'},
          {text: 'Role', value: 'role'},
          {text: 'Actions', value: 'actions', sortable: false,}
        ],
        samplesHeaders: [
          {text: 'Name', value: 'name'},
          {text: 'Status', value: 'status'},
          {text: 'Created', value: 'created'}
        ],
        requestsHeaders: [
          {text: 'Name', value: 'userName'},
          {text: 'Email', value: 'userEmail'},
          {text: 'Actions', value: 'actions', sortable: false,}
        ],
        analysisHeaders: [
          {text: 'Name', value: 'name'},
          {text: 'Status:', value: 'status'},
          {text: 'Started', value: 'started'},
          {text: 'Finished', value: 'finished'},
          {text: 'Cost', value: 'chargedCost'}
        ],
        invitationsHeader: [
          {text: 'Email', value: 'recipientEmail'},
          {text: 'Expires in', value: 'expirationDate'},
          {text: 'Role', value: 'role'},
          {text: 'Issued by', value: 'senderEmail'},
          {text: 'Status', value: 'status'},
        ],
      }
    },
    computed: {
      ...mapState('project', ['project', 'members', 'joinRequests', 'runningAnalyses', 'headerProjects', 'pendingInvitations']),
      ...mapState('sample', {sampleContent: 'content', sampleTotalElements: 'totalElements'}),
      ...mapState('user', ['user']),
      ...mapState('analysis', {analysesContent: 'content', analysesTotalElements: 'totalElements'}),
      ...mapState('organization', ['organization']),
      samplesTableOptions: {
        get() {
          if (this.project.uuid) {
            return this.$store.getters['sample/samplesTableOptions'];
          } else {
            return undefined;
          }
        },
        set(newValue) {
          this.$store.commit('sample/setSamplesTableOptions', newValue);
          if (this.project.uuid) {
            this.$store.dispatch('sample/getSampleList');
          }
        }
      },
      descriptionFields() {
        if (!this.project) return [];
        else return [
          {
            icon: "mdi-account-multiple",
            name: "Organization: ",
            value: this.project.organizationName,
            route: "/organizations/" + this.project.organizationUuid
          },
          {
            icon: "mdi-account-arrow-left",
            name: "Your position in project: ",
            value: this.roleReadable(this.project.role)
          }
        ]
      },
      isLastAdmin() {
        return this.members.filter(member => member.role.toLowerCase().includes("admin")).length === 1;
      },
    },
    watch: {
      optionsAnalyses: {
        handler() {
          this.loading = true;

          this.$store.dispatch("analysis/getAnalysisByProject", {
            data: pagingDataParse({
              sortDesc: this.optionsAnalyses.sortDesc,
              sortBy: this.optionsAnalyses.sortBy,
              itemsPerPage: this.optionsAnalyses.itemsPerPage,
              totalElements: this.analysesTotalElements,
              page: this.optionsAnalyses.page
            }),
            projectUuid: this.uuid
          });
          this.loading = false;
        },
        deep: true,
      },
      organization(newOrganization) {
        if (newOrganization.uuid !== this.project.organizationUuid) {
          this.$router.push('/projects');
        }
      },
      project() {
        if (this.$route.query.tab === "requests" && permissionCheck("projectUserAdd")) {
          this.tab = "requests";
        } else if (this.permissionRequired('sampleView')) {
          this.tab = "samples";
        } else if (this.permissionRequired('analysisView')) {
          this.tab = "analyses";
        } else {
          this.tab = "members";
        }
      }
    },
    created() {
      this.uuid = this.$route.params.uuid;
      VueCookies.set('project', this.uuid);
      this.$store.dispatch('project/getProject', {uuid: this.uuid, getAllData: true});
    },
    methods: {
      openInviteMemberDialog() {
        segmentTrack({event: "Invite New Member Clicked"});
        this.inviteMemberDialog = true;
      },
      openDeleteDialog() {
        segmentTrack({event: "Delete Project Clicked"});
        this.deleteDialog = true;
      },
      calculateDifference(date) {
        let relativeTime = dateToRelativeTime(date);
        return relativeTime.slice(0, relativeTime.lastIndexOf(' '));
      },
      acceptRequest() {
        this.acceptJoinRequestDialog = false;
        this.$store.dispatch('project/acceptRequest', {
          invitationUuid: this.newMember.uuid,
          userUuid: this.newMember.userUuid,
          role: this.newMemberRole
        });
        this.joinRequests.splice(this.joinRequests.indexOf(this.newMember), 1);
      },
      rejectRequest(item) {
        this.$store.dispatch('project/rejectRequest', {requestUuid: item.uuid});
        this.joinRequests.splice(this.joinRequests.indexOf(item), 1);
      },
      openAcceptJoinRequestDialog(item) {
        this.acceptJoinRequestDialog = true;
        this.newMember = item;
      },
      closeAcceptJoinRequestDialog() {
        this.acceptJoinRequestDialog = false;
        this.newMemberRole = "PROJECT_VIEWER";
      },
      permissionRequired(action) {
        return permissionCheck(action)
      },
      roleReadable(name) {
        return roleToHR(name);
      },
      availablePositions(userRole) {
        return availablePositionInProject(userRole);
      },
      newSample() {
        segmentTrack({event: "New Sample Clicked"});
        this.$router.push('/samples/new');
      },
      newAnalysis() {
        segmentTrack({event: "New Analysis Clicked"});
        this.$router.push({path: `/analyses/new`});
      },
      updateRole(data) {
        const newRole = data.role.replace(' ', '_').toUpperCase();
        this.member = {
          uuid: data.uuid,
          email: data.email,
          role: data.role,
          newRole: newRole
        };
        this.updateMemberDialog = true;
      },
      updateMemberRole() {
        const data = {
          role: this.member.newRole,
          userUuid: this.member.uuid
        };
        this.$store.dispatch('project/updateMemberRole', {
          data: data,
          projectUuid: this.project.uuid
        });
        this.updateMemberDialog = false;
      },
      closeUpdateMemberDialog() {
        this.updateMemberDialog = false;

        this.member = {
          uuid: '',
          email: '',
          newRole: '',
          role: ''
        }
      },
      removeMember(data) {
        this.member = {
          uuid: data.uuid,
          email: data.email,
        };
        this.removeMemberDialog = true;
      },
      removeMemberConfirmed() {
        this.removeMemberDialog = false;
        this.$store.dispatch('project/removeMember', {
          uuid: this.member.uuid,
          projectUuid: this.project.uuid
        });
      },
      closeProjectDialog() {
        this.updateProjectDialog = false;
      },
      updateProject() {
        this.projectUpdateForm = {
          uuid: this.project.uuid,
          formData: {
            name: this.project.name,
            description: this.project.description
          }
        }
        this.updateProjectDialog = true;
      },
      saveChanges() {
        this.updateProjectDialog = false;
        this.$store.dispatch('project/updateProject', this.projectUpdateForm);
      },
      checkDirection(direction) {
        switch (direction) {
        case true:
          return 'DESC';
        case false:
          return 'ASC';
        default:
          return ''
        }
      },
      deleteProject() {
        if (this.deleteDialogEnteredName === this.project.name) {
          this.deleteDialog = false;
          this.$store.dispatch('project/deleteProject', {
            uuid: this.uuid,
            organizationUuid: this.project.organizationUuid
          });
          this.markedToDeletion = true;
        } else {
          this.deletedNotification = "Please, check your project’s name again."
        }
      },
      validateName() {
        return this.$refs.nameForm.validate();
      }
    },
  }
</script>

<style lang="scss"
       scoped>

.error-text {
  margin-left: 30px;
  color: var(--danger-color);
}

.iseq-project-details {
  padding: 0;
  margin: 0;

  &__base-info,
  &__actions,
  &__relationship,
  &__table {
    &__wrapper {
      display: flex;

      .iseq-text-field {
        margin: 4px;
      }

      .iseq-select {
        margin: 4px;
        min-width: calc(33% - 4px);
        max-width: calc(33% - 4px);
      }

      .iseq-btn {
        min-width: 155px;
        margin: 4px 4px 0 auto;
      }

      &--direction-column {
        flex-direction: column;
      }
    }

    &__icon {
      margin-left: 20px;
    }
  }

  &__base-info {
    &__title-row {
      width: min-content;
      display: flex;
      justify-content: flex-end;
    }

    &__description {
      padding: 20px 0px 15px;
      margin-left: 2px;
      font-size: 14px;
      color: var(--font-inverted-color);
    }
  }

  &__relationship {
    &__wrapper {
      &__tab {
        max-width: 200px;
        padding: 0 24px;
      }
    }
  }

  &__table {
    &__field {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  &__settings {
    &__inputs {
      margin: 10px 20px 0px;
      display: flex;
      flex-direction: column;
    }

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 15px;
      margin-right: 20px;
      padding-bottom: 15px;

      .iseq-btn {
        margin-left: 10px;
      }
    }
  }
}

@media screen and (max-width: 1270px) {
  .iseq-project-details__relationship {
    flex-wrap: wrap;

    &__wrapper {
      &__tab {
        padding: 0 24px;
      }
    }
  }
}
</style>