<template>
  <div class="iseq-faq-field">
    <router-link
      :to="{name: 'FAQ', query: {section: sectionName}}"
      class="iseq-faq-field__header"
    >
      <div
        class="iseq-faq-field__header__wrapper"
        @click="scrollHere()"
      >
        <span class="iseq-faq-field__header__wrapper__title">
          {{ title }}
        </span>
      </div>
    </router-link>

    <v-divider />

    <div class="iseq-faq-field__description">
      <span class="iseq-faq-field__description__text">
        <slot />
      </span>
    </div>
  </div>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    name: "FAQField",
    props: ['title', 'sectionName'],
    computed: {
      ...mapState('organization', ['organization']),
      topMargin() {
        return this.organization.accountStatus === "TRIAL" ? 130 : 90;
      }
    },
    methods: {
      scrollHere() {
        this.$vuetify.goTo(document.getElementById(this.sectionName), {offset: this.topMargin});
      }
    }
  }
</script>

<style lang="scss"
       scoped>
    .iseq-faq-field {
        border-style: solid;
        border-radius: 4px;
        border-width: 1px;
        border-color: var(--tertiary-color);
        overflow: hidden;
        margin-top: 15px;


        &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background-color: var(--light);
            padding: 10px 15px;

            &__wrapper {
                width: 100%;
                height: 100%;

                &__title {
                    color: var(--font-color);
                    font-size: 1.4em;
                    padding: 5px;
                }
            }

            &__icon {
                color: var(--primary-color) !important;
            }
        }

        &__description {
            background-color: var(--light-2);
            padding: 20px;

            &__text {
                font-size: 1.3em;
                line-height: 1.6em;
            }
        }
    }
</style>