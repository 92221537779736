import request from "@/customjs/request";

export async function parsePhenotypesInputs(inputs) {
  let promises = [];
  let HPOTerms = [];
  let errors = [];
  let foundGenes =[];

  if (inputs.phenotypes_description) {
    promises.push(parsePhenotypesDescription(inputs.phenotypes_description, inputs.HPOInputsIds.phenotypes_description));
  }
  if (inputs.hpo_terms) {
    promises.push(parseHPOTerms(inputs.hpo_terms, inputs.HPOInputsIds.hpo_terms));
  }

  await Promise.allSettled(promises).then((results) => {
    results.forEach((result) => {
      result.status === 'fulfilled' ? HPOTerms = [...HPOTerms, ...result.value] : errors = [...errors, {reason: result.reason.reason, id: result.reason.inputID}];
    })
  })

  await getGenesForPhenotypes(HPOTerms)
    .then(result => {
      foundGenes = result;
    })
    .catch(e => {
      errors.push({reason: 'Error occurred during parsing phenotypes to genes', id: undefined});
    })

  return new Promise((resolve, reject) => {
    if (errors.length) {
      reject(errors);
    }
    else {
      resolve(foundGenes);
    }
  })
}

async function parsePhenotypesDescription(phenotypesDescription, inputID) {
  return new Promise((resolve, reject) => {
    request.request({
      endpoint: "parse-text",
      service: "API_EXPLORARE",
      method: "post",
      withCredentials: false,
      headers: {'Access-Control-Allow-Origin': '*'},
      data: {query: phenotypesDescription},
      callback: function (data) {
        if (data.hpoTerms) {
          resolve(data.hpoTerms);
        } else {
          reject({reason: `No phenotypes have been detected in description of patient phenotypes`, inputID: inputID});
        }
      },
      errorCallback: function (data) {
        reject({reason: `Error occurred during parsing of description of patient phenotypes`, inputID: inputID});
      }
    });
  });
}

async function parseHPOTerms(terms, inputID) {
  let preparsedTerms = terms.split(',').map(element => element.trim());
  let promises = [];
  let HPOTerms = [];
  let errors = [];

  for (let term of preparsedTerms) {
    promises.push(new Promise((resolve, reject) => {
      request.request({
        endpoint: `get-phenotype-by-id?id=${encodeURIComponent(term)}`,
        service: "API_EXPLORARE",
        withCredentials: false,
        headers: {'Access-Control-Allow-Origin': '*'},
        callback: function (data) {
          resolve({
            data: data,
            phenotypeId: term
          });
        },
        errorCallback: function (data) {
          reject({
            data: data,
            phenotypeId: term
          });
        }
      });
    }));
  }

  await Promise.allSettled(promises).then((results) => {
    results.forEach((result) => {
      if (result.status === 'fulfilled') {
        if (result.value.data.phenotype) {
          HPOTerms.push(result.value.data.phenotype);
        } else {
          errors.push(result.value.phenotypeId);
        }
      } else {
        errors.push(result.reason.phenotypeId)
      }
    });
  });

  return new Promise((resolve, reject) => {
    errors.length > 0 ? reject({reason: `Following terms has not been recognized: ${errors.join(', ')}`, inputID: inputID}) : resolve(HPOTerms);
  });
}

async function getGenesForPhenotypes(phenotypes) {
  return new Promise((resolve, reject) => {
    request.request({
      endpoint: `get-genes`,
      method: 'post',
      withCredentials: false,
      headers: {'Access-Control-Allow-Origin': '*'},
      data: {
        hpoTerms: phenotypes.map(element => element.id),
        threshold: 0.25
      },
      service: "API_EXPLORARE",
      callback: function (data) {
        data = data.map(node => {
          return {name: node.name, score: node.score, type: "Phenotypes"}
        });
        resolve(data);
      },
      errorCallback: function (data) {
        reject(data);
      }
    });
  });
}

export async function getGenesForDiseases(diseases, inputID) {
  return new Promise((resolve, reject) => {
    request.request({
      endpoint: "get-genes-by-diseases-list",
      service: "API_EXPLORARE",
      method: "post",
      withCredentials: false,
      headers: {'Access-Control-Allow-Origin': '*'},
      data: diseases,
      callback: function (data) {
        data = data.map( node => {
          return {name: node.name, score: node.score, type: "Diseases"};
        })
        resolve(data);
      },
      errorCallback: function () {
        reject({reason: `Error occurred during parsing of provided patient diseases`, inputID: inputID});
      }
    });
  });
}

export async function getGenesFromPanels(genePanels, inputID) {
  return new Promise((resolve, reject) => {
    request.request({
      endpoint: `get-panels-genes?panels=${genePanels.join(',').replace(/_/g, '%20')}`,
      withCredentials: false,
      headers: {'Access-Control-Allow-Origin': '*'},
      service: "API_EXPLORARE",
      callback: function (data) {
        data = data.map(node => {
          return {name: node.name, score: node.score, type: "Gene panels"}
        })
        resolve(data);
      },
      errorCallback: function () {
        reject({reason: `Error while collecting genes from gene panels`, inputID: inputID});
      }
    });
  });
}

export async function validateUserGenes(genes, inputID) {
  return new Promise((resolve, reject) => {
    request.request({
      endpoint: "validate-genes",
      method: "post",
      withCredentials: false,
      headers: {'Access-Control-Allow-Origin': '*'},
      data: genes.split(',').map(element => element.trim()),
      service: "API_EXPLORARE",
      callback: function (data) {
        if (data.invalid && data.invalid.length !== 0) {
          let errorMessage = `Gene${data.invalid.length>1 ? 's' : ''} ${data.invalid.join(', ')} ${data.invalid.length>1 ? 'have' : 'has'} not been recognized`
          reject({reason: errorMessage, inputID: inputID})
        } else {
          data = data.corrected.map(node => {
            return {name: node.name, score: node.score, type: "Genes names"}
          });
          resolve(data);
        }
      },
      errorCallback: function (data) {
        reject(data);
      }
    })
  });
}

export async function getDiseasesSuggestion(query) {
  return new Promise((resolve, reject) => {
    request.request({
      endpoint: `disease-autocomplete?firstLetters=${query}&resultsCount=30`,
      withCredentials: false,
      headers: {'Access-Control-Allow-Origin': '*'},
      service: "API_EXPLORARE",
      callback: function (data) {
        resolve(data);
      },
      errorCallback: function (data) {
        reject(data);
      }
    });
  });
}

export async function parseDiseasesIDToNames(diseasesIDs) {
  return new Promise((resolve, reject) => {
    request.request({
      endpoint: `get-disease-names-by-ids?ids=${diseasesIDs.join(',')}`,
      withCredentials: false,
      headers: {'Access-Control-Allow-Origin': '*'},
      service: "API_EXPLORARE",
      callback: function (data) {
        if (data.invalid && data.invalid.length>0) {
          reject(data.invalid);
        } else {
          resolve(data.valid);
        }
      },
      errorCallback: function (data) {
        reject(data);
      }
    });
  });
}