import Vue from 'vue'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

// set default config
VueCookies.config('7d')

//VueCookies.config('30d', '', '', 'false', 'None')  // default: expireTimes = 1d, path = '/', domain = '', secure = '', sameSite = ''

// set global cookie
VueCookies.set('theme','default');
VueCookies.set('hover-time','1s');
