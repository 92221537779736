<template>
  <v-dialog
    v-model="active"
    persistent
    max-width="600px"
  >
    <div class="iseq-invite-member-modal">
      <div class="iseq-invite-member-modal__header">
        <span class="iseq-invite-member-modal__header__title">Invite new member to the {{ invitationType }}</span>
      </div>

      <div>
        Add member:
      </div>

      <div class="iseq-invite-member-modal__input-row">
        <v-form
          ref="emailForm"
          v-model="emailFormValid"
          lazy-validation
        >
          <v-combobox
            v-if="invitationType === 'project'"
            v-model="newMember"
            :rules="newMemberRules"
            :items="membersOutsideProject"
            item-text="email"
            :hide-details="validate() && !showErrors"
            label="New member's email address"
          />

          <v-text-field
            v-else
            v-model="newMember"
            :rules="newMemberRules"
            :hide-details="validate() && !showErrors"
            label="New member's email address"
          />
        </v-form>
      </div>

      <template v-if="!isUserAlreadyMember(newMember) && !isMemberInvited(newMember)">
        <template v-if="invitationType !== 'project' || !membersOutsideProject.includes(newMember)">
          <div class="iseq-invite-member-modal__input-description">
            In organization position of:
          </div>

          <div class="iseq-invite-member-modal__input-row">
            <v-select
              v-model="organizationPosition"
              item-text="name"
              item-value="value"
              :items="organizationInvitePositions()"
            />
          </div>

          <div class="iseq-invite-member-modal__description-row">
            {{ organizationDescriptions[organizationPosition] }}
          </div>
        </template>

        <template v-if="invitationType === 'project'">
          <div class="iseq-invite-member-modal__input-description">
            In project position of:
          </div>

          <div class="iseq-invite-member-modal__input-row">
            <v-select
              v-model="projectPosition"
              item-text="name"
              item-value="value"
              :items="availablePositionInProject()"
            />
          </div>

          <div class="iseq-invite-member-modal__description-row">
            {{ projectDescriptions[projectPosition] }}
          </div>
        </template>
      </template>

      <div class="iseq-invite-member-modal__buttons">
        <v-iseq-btn
          type="secondary"
          width="100px"
          @click="close()"
        >
          Close
        </v-iseq-btn>

        <v-iseq-btn
          ml
          width="100px"
          @click="inviteMember"
        >
          Add
        </v-iseq-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
  import {
    availablePositionInProject,
    organizationInvitePositions
  } from "../../plugins/permissions";
  import {mapState} from "vuex";
  import {isValidEmail} from "../../plugins/utils";
  import IseqButton from "@/components/ui/IseqButton.vue";

  export default {
    name: "InviteMemberModal",
    components: {
      "v-iseq-btn": IseqButton
    },
    props: {
      invitationType: {
        type: String,
        required: true
      },
    },
    data: function () {
      return {
        newMember: undefined,
        projectPosition: 'PROJECT_VIEWER',
        organizationPosition: 'ORGANIZATION_MEMBER',
        projectDescriptions: {
          PROJECT_ADMIN: "“Admin” is a user in charge of the project; can invite or remove users as well as accept pending join requests; can create samples and run analyses.",
          PROJECT_EDITOR: "“Editor” is a user who can perform basic actions within projects such as creating samples, running analyses, downloading results.",
          PROJECT_VIEWER: "”Viewer” is a user who can only see samples and results of the analyses in the project; cannot run analyses or create samples."
        },
        organizationDescriptions: {
          ORGANIZATION_OWNER: "“Owner” is a user who creates and owns the organization and projects; has access to all projects; manages payments and permissions in the organization and projects.",
          ORGANIZATION_MEMBER: "“Member” is a user who has access to the organization; sees the list of projects; can join the projects by sending join requests to the project’s admin.",
          ORGANIZATION_ADMIN: "“Admin” is a user who creates projects within an organization; has access to all the existing projects in the organization; manages (partly) permissions in the organization and projects."
        },
        newMemberRules: [
          v => !!v || "User email address cannot be empty.",
          v => !this.isMemberInvited(v) || `This user has already been invited into this ${this.invitationType}.`,
          v => typeof v === 'object' || this.validateEmail(v) || "This is not a correct email address.",
          v => typeof v === 'object' || !this.isUserAlreadyMember(v) || `This user is already member of the ${this.invitationType}.`
        ],
        emailFormValid: true,
        active: true,
        showErrors: false
      }
    },
    computed: {
      ...mapState('project', {project: 'project', projectMembers: 'members', projectPendingInvitations: 'pendingInvitations'}),
      ...mapState('organization', {organization: 'organization', organizationMembers: 'members'}),
      membersOutsideProject() {
        let outsideMembers = this.organizationMembers.filter(organizationMember => {
          return !this.projectMembers.find(projectMember => projectMember.uuid === organizationMember.uuid)
        });
        return outsideMembers.map(member => {
          member.isInvited = !!this.projectPendingInvitations.find(invitation => invitation.recipientEmail === member.email);
          return member
        });
      }
    },
    watch: {
      newMember(newValue) {
        if (!!newValue && typeof newValue === 'object') {
          this.organizationPosition = 'ORGANIZATION_MEMBER';
        }
      }
    },
    methods: {
      isMemberInvited(member) {
        if (this.invitationType === 'project') {
          if (!!member && typeof member === "object") {
            member = member.email;
          }
          return !!this.membersOutsideProject.find(item => item.email === member && item.isInvited)
        } else {
          //Endpoint with users already invited to organization will be added in the future
          return false;
        }
      },
      isUserAlreadyMember(member) {
        if (this.invitationType === 'project') {
          return !!this.projectMembers.find(item => item.email === member);
        } else {
          return !!this.organizationMembers.find(item => item.email === member);
        }
      },
      validateEmail(email) {
        return isValidEmail(email);
      },
      availablePositionInProject() {
        return availablePositionInProject();
      },
      organizationInvitePositions() {
        return organizationInvitePositions();
      },
      validate() {
        return this.$refs.emailForm ? this.$refs.emailForm.validate() : false;
      },
      inviteMember() {
        this.showErrors = true;

        // validation and change of v-model value in v-combobox is run only on @focusout
        // small timeout allows it to change this value before executing invite functions
        setTimeout(() => {
          if (this.validate()) {
            if (this.invitationType === 'project') {
              if (!!this.newMember && typeof this.newMember === 'object') {
                this.inviteMemberToProject();
              } else {
                this.inviteMemberToProjectAndOrganization()
              }
            } else {
              this.inviteMemberToOrganization();
            }
          }
        }, 100);
      },
      inviteMemberToProject() {
        if (this.newMember) {
          this.$store.dispatch('project/inviteMemberToProject', {
            role: this.projectPosition,
            email: this.newMember.email,
            projectUuid: this.project.uuid
          });
        }
        this.close();
      },
      inviteMemberToOrganization() {
        if (this.newMember) {
          this.$store.dispatch('organization/inviteMemberToOrganization', {
            email: this.newMember,
            position: this.organizationPosition,
            uuid: this.organization.uuid
          });
        }
        this.close();
      },
      inviteMemberToProjectAndOrganization() {
        if (this.newMember !== '') {
          this.$store.dispatch('project/inviteMemberToProjectAndOrganization', {
            email: this.newMember,
            projectRole: this.projectPosition,
            organizationRole: this.organizationPosition,
            projectUuid: this.project.uuid
          });
        }
        this.close();
      },
      close() {
        this.newMember = undefined;
        this.$emit('closed');
      }
    }
  }
</script>

<style lang="scss"
       scoped>

    .iseq-invite-member-modal {
        padding: 15px;
        display: flex;
        flex-direction: column;
        background-color: white;
        color: var(--font-color);

        &__header {
            margin-bottom: 10px;
            width: 100%;

            &__title {
                color: var(--font-color);
                font-weight: bold;
                font-size: 20px;
            }
        }

        &__input-description {
            margin-top: 10px;
        }

        &__input-row {
            margin-left: 20px;
            margin-top: -5px;
        }

        &__description-row {
            margin-left: 20px;
            margin-bottom: 10px;
            margin-top: -10px;
        }

        &__buttons {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
    }
</style>