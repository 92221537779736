<template>
  <div class="wrapper">
    <iseq-credential-views-frame>
      <template v-if="form==='signUp'">
        <div class="wrapper__input-fields">
          <div class="wrapper__input-fields__title">
            <span class="wrapper__input-fields__title__text">
              Create a new account
            </span>
          </div>

          <div class="wrapper__input-fields__field-name">
            <span class="wrapper__input-fields__field-name__text">
              Email
            </span>
          </div>

          <v-text-field
            v-model="username"
            type="email"
            filled
            hide-details
            :readonly="signUpEmailPredefined"
            class="iseq-text-field wrapper__input-fields__field"
            @keyup.enter="signUp"
          />

          <div
            v-if="flags.isUsernameEmpty"
            class="wrapper__input-fields__error"
          >
            <span class="wrapper__input-fields__error__text">Username cannot be empty.</span>
          </div>

          <div
            v-if="flags.isUsernameTaken"
            class="wrapper__input-fields__error"
          >
            <span class="wrapper__input-fields__error__text">This e-mail is already in use.</span>
          </div>

          <div
            v-if="flags.isUsernameInvalid"
            class="wrapper__input-fields__error"
          >
            <span class="wrapper__input-fields__error__text">This is not a valid e-mail address.</span>
          </div>

          <div class="wrapper__input-fields__field-name">
            <span class="wrapper__input-fields__field-name__text">
              Password

              <v-tooltip
                class="tooltip--right"
                right
              >
                <template #activator="{ on }">
                  <v-icon
                    class="tooltip__icon"
                    size="20px"
                    color="primary"
                    v-on="on"
                  >
                    mdi-help-circle
                  </v-icon>
                </template>

                <span>Password must:<br>
                  -be at least 12 characters long,<br>
                  -contain at least 1 digit,<br>
                  -contain at least 1 capital letter,<br>
                  -contain at least 1 small letter
                </span>
              </v-tooltip>
            </span>
          </div>

          <v-text-field
            v-model="password"
            type="password"
            filled
            hide-details
            class="iseq-text-field wrapper__input-fields__field"
            @keyup.enter="signUp"
            @focusout="validatePassword"
          />

          <div
            v-if="flags.isPasswordNotPermitted && passwordStructureError"
            class="wrapper__input-fields__error"
          >
            <span class="wrapper__input-fields__error__text">
              {{ passwordStructureError }}
            </span>
          </div>

          <div class="wrapper__input-fields__field-name">
            <span class="wrapper__input-fields__field-name__text">
              Confirm password
            </span>
          </div>

          <v-text-field
            v-model="confirmPassword"
            type="password"
            filled
            hide-details
            class="iseq-text-field wrapper__input-fields__field"
            @keyup.enter="signUp"
            @focusout="validateConfirmPassword(password, confirmPassword)"
          />

          <div
            v-if="flags.isPasswordsDifference"
            class="wrapper__input-fields__error"
          >
            <span class="wrapper__input-fields__error__text">Passwords don't match</span>
          </div>

          <div class="wrapper__button-field">
            <v-iseq-btn
              class="wrapper__button-field__button"
              @click="signUp"
            >
              Create new account
            </v-iseq-btn>
          </div>

          <span class="wrapper__text">
            <router-link to="/login">
              Have an account? <span class="link">Log in</span>
            </router-link>
          </span>
        </div>
      </template>

      <template v-if="form==='confirmSignUp'">
        <div class="wrapper__input-fields">
          <div class="wrapper__input-fields__title">
            <span class="wrapper__input-fields__title__text">
              Activate your account
            </span>
          </div>

          <div class="wrapper__input-fields__subtitle">
            <span class="wrapper__input-fields__subtitle__text">
              Please, check your e-mail to get the activation code.
            </span>
          </div>

          <div class="wrapper__input-fields__field-name">
            <span class="wrapper__input-fields__field-name__text">
              Activation code

              <v-tooltip
                class="tooltip--right"
                right
              >
                <template #activator="{ on }">
                  <v-icon
                    class="tooltip__icon"
                    size="20px"
                    color="primary"
                    v-on="on"
                  >
                    mdi-help-circle
                  </v-icon>
                </template>
                <span>You can activate your account right now or sign in later.</span>
              </v-tooltip>
            </span>
          </div>

          <v-text-field
            v-model="code"
            filled
            hide-details
            class="iseq-text-field wrapper__input-fields__field"
            @input="resetCodeFlags()"
            @keyup.enter="confirmSignUp"
          />

          <div
            v-if="flags.isCodeInvalid"
            class="wrapper__input-fields__error"
          >
            <span class="wrapper__input-fields__error__text">Code invalid or expired. Try again.</span>
          </div>

          <div
            v-if="flags.attemptsExceeded"
            class="wrapper__input-fields__error"
          >
            <span
              class="wrapper__input-fields__error__text"
            >Attempt limit exceeded. Try again later.</span>
          </div>

          <div
            v-if="flags.isCodeResent"
            class="wrapper__input-fields__success"
          >
            <span class="wrapper__input-fields__success__text">Code has been resent. Please, check your e-mail.</span>
          </div>

          <div class="wrapper__button-field">
            <v-iseq-btn
              class="wrapper__button-field__button"
              @click="confirmSignUp"
            >
              Activate
            </v-iseq-btn>
          </div>

          <span class="wrapper__text">
            <span
              class="action"
              @click="resendSignUp"
            > Click here to resend activation code.</span>
          </span>
        </div>
      </template>
    </iseq-credential-views-frame>
  </div>
</template>

<script>
  import {AmplifyEventBus} from 'aws-amplify-vue'
  import {Auth} from 'aws-amplify'
  import store from '@/store'
  import {isValidEmail} from "@/plugins/utils";
  import {
    passwordStructureError,
    validateConfirmPassword,
    validatePassword,
    validateUsername
  } from "@/helpers/credentialsHelper";
  import IseqCredentialViewsFrame from "@/views/Login/components/IseqCredentialViewsFrame";
  import IseqButton from "@/components/ui/IseqButton.vue";
  import {segmentTrack} from "@/helpers/analytics";

  async function getJWT() {
    const session = await Auth.currentSession();
    const jwt = session.idToken.jwtToken;
    store.commit('security/updateToken', jwt);
  }

  export default {
    name: "Signup",
    components: {
      IseqCredentialViewsFrame,
      "v-iseq-btn": IseqButton
    },
    data: function () {
      return {
        username: "",
        password: "",
        confirmPassword: "",
        code: "",
        flags: {
          isUsernameTaken: false,
          isUsernameInvalid: false,
          isPasswordNotPermitted: false,
          isCodeResent: false,
          isCodeInvalid: false,
          isPasswordsDifference: false,
          isUsernameEmpty: false,
          attemptsExceeded: false
        },
        signUpEmailPredefined: false,
        form: "signUp"
      }
    },
    computed: {
      passwordStructureError() {
        return passwordStructureError(this.password);
      }
    },
    mounted() {
      Auth.currentAuthenticatedUser().catch(err => console.log(err));

      AmplifyEventBus.$on("authState", eventInfo => {
        if (eventInfo === "signedIn") {
          getJWT();
          this.$router.push({path: "/"});
        }
      });
    },
    created() {
      if (this.$route.query.email && isValidEmail(this.$route.query.email)) {
        this.username = this.$route.query.email;
        this.signUpEmailPredefined = true;
      } else if (this.$route.params.email && isValidEmail(this.$route.params.email)) {
        this.username = this.$route.params.email;
        this.signUpEmailPredefined = true;
      } else if (this.$route.params.signUpConfirm) {
        this.form = 'confirmSignUp';
        this.username = this.$route.params.username;
        this.password = this.$route.params.password;
      }
    },
    methods: {
      async signUp() {
        if (this.validateUsername(this.username) &&
          this.validatePassword(this.password) &&
          this.validateConfirmPassword(this.password, this.confirmPassword)) {
          try {
            const {user} = await Auth.signUp({
              username: this.username,
              password: this.password
            });
            this.form = 'confirmSignUp'
          } catch (error) {
            if (error.code === "UsernameExistsException") {
              this.flags.isUsernameTaken = true;
            } else if (error.message === "Username should be an email.") {
              this.flags.isUsernameInvalid = true;
            } else if (error.code === "InvalidParameterException") {
              this.flags.isPasswordNotPermitted = true;
            } else if (error.code === "InvalidPasswordException") {
              this.flags.isPasswordNotPermitted = true;
            } else {
              console.log(error)
            }
          }
        }
      },
      async confirmSignUp() {
        this.flags = {...this.flags, CodeMismatchException: false, attemptsExceeded: false};
        try {
          await Auth.confirmSignUp(this.username, this.code);
          segmentTrack({event: "New Account Activated"});
          await this.signIn()
        } catch (error) {
          if (error.code === "CodeMismatchException") {
            this.flags.isCodeInvalid = true
          } else if (error.code === "LimitExceededException") {
            this.flags.attemptsExceeded = true
          } else {
            console.log(error)
          }
        }
      },
      async resendSignUp() {
        try {
          await Auth.resendSignUp(this.username);
          this.flags.isCodeResent = true;
        } catch (error) {
          if (error.code === "CodeMismatchException") {
            this.flags.isCodeInvalid = true
          } else {
            console.log(error)
          }
        }
      },
      async signIn() {
        try {
          await Auth.signIn(this.username, this.password);
          await this.$router.push("/");
        } catch (error) {
          console.log(error)
        }
      },
      resetCodeFlags() {
        this.flags = {...this.flags, isCodeInvalid: false, isCodeResent: false};
      },
      validateUsername(username) {
        const validation = validateUsername(username);
        this.flags = {...this.flags, ...validation.flags};
        return validation.result;
      },
      validatePassword(password) {
        const validation = validatePassword(password);
        this.flags = {...this.flags, ...validation.flags};
        return validation.result;
      },
      validateConfirmPassword(password, confirmPassword) {
        const validation = validateConfirmPassword(password, confirmPassword);
        this.flags = {...this.flags, ...validation.flags};
        return validation.result;
      }
    }
  }
</script>

<style scoped lang="scss">

@import "@/scss/CredentialViewsStyles.scss";
</style>