import Vue from 'vue'
import store from '@/store'
import {Auth} from 'aws-amplify'
import {handleForbiddenError} from "../helpers/errorHandlers";

async function getJWT() {
  const session = await Auth.currentSession()
  store.commit('security/updateToken', session.idToken.jwtToken);
}

export default {

  async request(params) {

    /* setting params */
    let snackbarMessage = params.snackbarMessage;
    let method = (params.method === undefined) ? "get" : params.method;
    let endpoint = (params.endpoint === undefined) ? "hello" : params.endpoint;
    let callback = (params.callback === undefined) ? function (data) {
    } : params.callback;
    let errorCallback = (params.errorCallback === undefined) ? function (error) {
      Vue.$log.error(error)
    } : params.errorCallback;
    let withCredentials = (params.withCredentials === undefined) ? true : params.withCredentials;
    let service = (params.service === undefined) ? "API_DX" : params.service;
    let reqData = (params.reqData === undefined) ? {} : params.reqData;
    let additionalData = (params.additionalData === undefined) ? {} : params.additionalData;
    let data = (params.data === undefined) ? Object.keys(reqData).map(function (key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(reqData[key])
    }).join('&') : params.data;

    // resolve runs the first function in .then

    let headers = (params.headers === undefined) ? {
      'Authorization': 'Bearer ' + store.state.security.accessToken,
      'Access-Control-Allow-Origin': '*'
    } : params.headers;

    let host;
    if (service === "API_DX") {
      host = process.env.VUE_APP_API_DX_URL;
    } else if (service === "API_WDL") {
      host = process.env.VUE_APP_API_WDL_URL;
    } else if (service === "API_FILES") {
      host = process.env.VUE_APP_API_FILES_URL;
    } else if (service === "API_EXPLORARE") {
      host = process.env.VUE_APP_API_EXPLORARE_URL;
    }

    Vue.$log.debug("Request to " + service + "/" + endpoint)

    if (store.getters["isAppOnline"]) {
      store.dispatch('snackbarQueue/addMessage', {message: snackbarMessage, type: "request"}).then(response => {
        let snackbarid = response;

        axios.interceptors.response.use(null, (error) => {
          if (error.config && error.response && error.response.status === 401) {
            getJWT();
            setTimeout(() => console.log("After 1s"), 1000)
          }
          return Promise.reject(error);
        });

        /* making request */
        return axios({
          method: method,
          url: host + '/' + endpoint,
          withCredentials: withCredentials,
          crossdomain: true,
          data: data,
          headers: headers,
        })
          .then(response => {
            if (snackbarid) {
              store.commit('snackbarQueue/removeItem', {id: snackbarid});
            }
            Vue.$log.debug("Succesfull response");
            callback(response.data);
          })
          .catch(error => {
            Vue.$log.debug("Error response");
            if (snackbarid) {
              store.commit('snackbarQueue/removeItem', {id: snackbarid, force: true});
            }
            errorCallback(error);
            if (error.response == null) {
              store.dispatch('snackbarQueue/addMessage', {
                message: "Request error " + endpoint + ": " + String(error),
                type: "error"
              })
            } else if (error.response.data && error.response.data.showToUser) {
              store.dispatch('snackbarQueue/addMessage', {
                message: error.response.data.message,
                type: "error"
              });
            } else {
              if (error.response.status === 401) {
                console.log(error.response);
                getJWT()
              } else if (error.response.status === 500) {
                const errorText = "Request error " + endpoint + ": " + JSON.stringify(error.message) + " " + JSON.stringify(error.response.data.message);
                console.log(errorText);
                if (!endpoint.includes("bug")) {
                  store.dispatch('user/reportBug', {content: `AUTOMATED ERROR 500 MESSAGE: \"${errorText}\" while using: METHOD: ${method}, ENDPOINT: ${endpoint}`, sendEmail: false, showNotification: false});
                }
                store.dispatch('snackbarQueue/addMessage', {
                  message: "Internal server error. Our engineers were informed about the incident. We will solve the problem as soon as possible.",
                  type: "error"
                })
              } else if (error.response.status === 403) {
                handleForbiddenError();
              } else {
                console.log(error.response);
                if (error.response.data.showToUser !== false) {
                  store.dispatch('snackbarQueue/addMessage', {
                    message: JSON.stringify(error.response.data.message),
                    type: "error"
                  })
                }
              }
            }
          })

      }, error => {
        Vue.$log.error("Unable to get snackbarqueue ID or some uncaught error within axios")
      })
    } else {
      Vue.$log.debug("Unable to send request. No internet connection.");
    }

  }
}
