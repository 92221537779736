<template>
  <div class="iseq-dashboard-analysis">
    <v-iseq-card
      title="Analyses"
      no-bottom-margin
      class="iseq-dashboard-analysis__card"
    >
      <v-iseq-banner
        v-if="!analysesContent.length"
        type="info-light"
        :value="['You don\'t have any analyses right now. You can create one or', '']"
        :link="{text: 'see our examples.', address: '/analyses/demo'}"
      />

      <v-data-table
        v-else
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions
        }"
        :headers="analysisColumns"
        :items="analysesContent"
        :server-items-length="analysesTotalElements"
        :options.sync="optionsAnalyses"
        class="iseq-dashboard-analysis__card__table"
        @click:row="goToAnalysis"
      />
    </v-iseq-card>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import {permissionCheck} from "@/plugins/permissions";
  import {generatePagingData, pagingDataParse} from "@/plugins/utils";
  import IseqCard from "@/components/ui/IseqCard.vue";
  import IseqBanner from "@/components/ui/IseqBanner.vue";
  import {segmentTrack} from "@/helpers/analytics";

  export default {
    name: 'DashboardHeader',
    components: {
      "v-iseq-card": IseqCard,
      "v-iseq-banner": IseqBanner
    },
    data: function () {
      return {
        eventItemsPerPage: 5,
        itemsPerPageOptions: [10, 20, 30, -1],
        optionsAnalyses: {sortDesc: [], sortBy: []},
        analysisColumns: [
          {text: 'Name', value: 'name'},
          {text: 'Status:', value: 'status'},
          {text: 'Started', value: 'started'},
          {text: 'Finished', value: 'finished'}
        ],
      }
    },
    computed: {
      ...mapState('analysis', ['analyses']),
      ...mapState('analysis', {analysesContent: 'content', analysesTotalElements: 'totalElements'}),
      ...mapState('project', ['project']),
      ...mapState('user', ['user']),
    },
    watch: {
      optionsAnalyses: {
        handler() {
          this.$store.dispatch("analysis/getAnalysisByProject", {data: this.pageData(), projectUuid: this.project.uuid});
        },
        deep: true,
      },
      project(newProject) {
        if (newProject.uuid) {
          this.$store.dispatch('analysis/getAnalysisByProject', {data: this.pageData(), projectUuid: newProject.uuid});
        }
      }
    },
    created() {
      if (this.project.uuid) {
        this.$store.dispatch('analysis/getAnalysisByProject', {
          data: generatePagingData({}),
          projectUuid: this.project.uuid
        });
      }
    },
    beforeDestroy() {
      this.$store.commit('analysis/resetContent')
    },
    methods: {
      permissionRequired(action) {
        return permissionCheck(action)
      },
      createAnalysis() {
        segmentTrack({event: "Create New Clicked"});
        this.$router.push({path: "/analyses/new"});
      },
      goToAnalysis(event) {
        this.$router.push({path: `/analyses/${event.uuid}`});
      },
      checkDirection(direction) {
        switch (direction) {
        case true:
          return 'DESC';
        case false:
          return 'ASC';
        default:
          return ''
        }
      },
      pageData() {
        return pagingDataParse({
          sortDesc: this.optionsAnalyses.sortDesc,
          sortBy: this.optionsAnalyses.sortBy,
          itemsPerPage: this.optionsAnalyses.itemsPerPage,
          totalElements: this.analysesTotalElements,
          page: this.optionsAnalyses.page
        });
      }
    },
  }
</script>
<style lang="scss"
       scoped>

.iseq-dashboard-analysis {
  &__card {
    &__table {
      padding: 0px 16px;
    }

    &__btn {
      display: block;
    }

    .iseq-btn {
      min-width: 155px;
      margin: 4px 4px 0 auto;

      &__label,
      &__icon {
        margin: 0 4px;
      }
    }
  }
}
</style>