<template>
  <v-dialog
    v-model="paymentDialog"
    max-width="600px"
  >
    <v-card class="iseq-payment-modal">
      <v-card-title class="iseq-payment-modal__title">
        <span>Payments details</span>
      </v-card-title>

      <div class="iseq-payment-modal__content">
        <v-stepper
          v-model="paymentSteps"
          class="stepper"
        >
          <v-stepper-header class="stepper__header">
            <v-stepper-step
              :complete="paymentSteps > 1"
              step="1"
              class="stepper__header__step"
            >
              Top up your account
            </v-stepper-step>

            <v-divider class="stepper__header__divider" />

            <v-stepper-step
              :complete="paymentSteps > 2"
              step="2"
              class="stepper__header__step"
            >
              Credit card
            </v-stepper-step>

            <v-divider class="stepper__header__divider" />

            <v-stepper-step
              :complete="paymentSteps > 3"
              step="3"
              class="stepper__header__step"
            >
              Summary
            </v-stepper-step>

            <v-divider class="stepper__header__divider" />

            <v-stepper-step
              :complete="paymentSteps > 4"
              step="4"
              class="stepper__header__step"
            >
              Status
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <iseq-top-up-credits />
            </v-stepper-content>

            <v-stepper-content step="2">
              <div v-if="currentCardData.expMonth === null">
                <iseq-adding-card />
              </div>
              <div v-else>
                <iseq-confirm-card-data />
              </div>
            </v-stepper-content>

            <v-stepper-content step="3">
              <iseq-summary :credits="credits" />
            </v-stepper-content>

            <v-stepper-content step="4">
              <iseq-status :status-payment="statusPayment" />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
  import iseqAddingCard   from './components/addingCard.component';
  import iseqTopUpCredits from './components/topUpCredits.component';
  import iseqSummary      from './components/summary.component';
  import iseqStatus       from './components/status.component';
  import { mapState }     from 'vuex';
  import IseqConfirmCardData from "@/components/payment/components/confirmCardData.vue";

  export default {
    name: 'IseqPaymentModal',
    components: {
      IseqConfirmCardData,
      iseqStatus,
      iseqSummary,
      iseqAddingCard,
      iseqTopUpCredits,
    },
    computed: {
      ...mapState('payment', [ 'paymentSteps', 'statusPayment', 'credits', 'currentCardData' ]),
      paymentDialog: {
        get() {
          return this.$store.getters[ 'payment/paymentDialog' ];
        },
        set(newValue) {
          this.$store.commit('payment/setPaymentDialog', newValue);
        },
      },
    },
    watch: {
      paymentDialog() {
        if (!this.paymentDialog) {
          this.$store.commit('payment/setClientSecret', '');
          this.$store.commit('payment/setIntelliseqTransactionToken', '');
          this.$store.commit('payment/setCredits', '100');
          this.$store.commit('payment/setPaymentSteps', 1);
          this.$store.commit('payment/setCurrentCardData', {expMonth: null, expYear: null, last4: "", brand: ""});
        }
      },
    },
    methods: {
      closeDialog() {
        this.$store.commit('payment/setClientSecret', '');
        this.$store.commit('payment/setCredits', '100');
        this.$store.commit('payment/setIntelliseqTransactionToken', '');
        this.$store.commit('payment/setPaymentSteps', 1);
        this.$store.commit('payment/setStatusPayment', {});
        this.$store.commit('payment/setPaymentDialog', false);
        this.$store.commit('payment/setCurrentCardData', {expMonth: null, expYear: null, last4: "", brand: ""});
      },
    },
  };
</script>

<style lang="scss"
       scoped>

.iseq-payment-modal {
  &__content {
    .stepper {
      box-shadow: none;

      &__header {
        box-shadow: none;

        &__divider {
          border-style: dashed;
        }
      }
    }
  }
}

</style>