import store from '@/store'
import router from '@/router'

const messages = {
  ACCESS_FORBIDDEN: "You don't have access to the requested data. If you think that's a mistake please contact your organization's admin.",
  RESOURCE_DELETED: "Resource you are trying to access has been deleted",
  SAMPLE_OUTDATED: "Sample you are trying to access has expired",
  FILENAME_COLLISION: "Conflict happened between file you were trying to upload and the same named file which is being uploaded by you or another user. Only the first one of them will be uploaded."
};

export function handleForbiddenError() {
  store.dispatch('snackbarQueue/addMessage', {
    message: messages.ACCESS_FORBIDDEN,
    type: "warning"
  });
  store.dispatch("organization/getHeaderOrganizations", {});
  router.push("/");
}

export function handleDeletedResourceError(payload) {
  store.dispatch('snackbarQueue/addMessage', {
    message: payload ? messages[payload] : messages.RESOURCE_DELETED,
    type: "warning"
  });
  store.dispatch("organization/getHeaderOrganizations", {});
  router.push("/");
}

export function filenameCollisionOnUploadError() {
  store.dispatch('snackbarQueue/addMessage', {
    message: messages.FILENAME_COLLISION,
    type: "warning"
  });
}