<template>
  <div class="iseq-dashboard-analysis-progress">
    <v-iseq-card class="iseq-dashboard-analysis-progress__card">
      <div class="iseq-dashboard-analysis-progress__card__close">
        <v-icon
          class="iseq-dashboard-analysis-progress__card__close__icon"
          color="dark"
          @click="toggleAnalysisTab"
        >
          {{ hidden ? 'mdi-chevron-down-circle' : 'mdi-chevron-up-circle-outline' }}
        </v-icon>
      </div>

      <v-tabs v-model="activeTab">
        <v-tab
          v-show="dashboardAnalysesProgress.userAnalyses.length"
          key="0"
          class="iseq-dashboard-analysis-progress__card__tab"
          @click="hidden=false"
        >
          My analyses
          <v-badge
            v-show="dashboardAnalysesProgress.userAnalyses.length"
            :content="dashboardAnalysesProgress.userAnalyses.length"
            offset-x="-5"
            offset-y="4"
          />
        </v-tab>

        <v-tab
          v-show="dashboardAnalysesProgress.otherAnalyses.length"
          key="1"
          class="iseq-dashboard-analysis-progress__card__tab"
          @click="hidden=false"
        >
          Others' analyses
          <v-badge
            v-show="dashboardAnalysesProgress.otherAnalyses.length"
            :content="dashboardAnalysesProgress.otherAnalyses.length"
            offset-x="-5"
            offset-y="4"
          />
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-show="!hidden"
        v-model="activeTab"
        touchless
      >
        <v-tab-item key="0">
          <template v-if="dashboardAnalysesProgress.userAnalyses.length">
            <v-iseq-progressbar-list
              :items="dashboardAnalysesProgress.userAnalyses"
              class="iseq-dashboard-analysis-progress__card__progressbar-list"
            />
          </template>
        </v-tab-item>

        <v-tab-item key="1">
          <template v-if="dashboardAnalysesProgress.otherAnalyses.length">
            <v-iseq-progressbar-list
              :items="dashboardAnalysesProgress.otherAnalyses"
              class="iseq-dashboard-analysis-progress__card__progressbar-list"
            />
          </template>
        </v-tab-item>
      </v-tabs-items>
    </v-iseq-card>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import analysisProgressbarList from './components/analysisProgressbarList.vue'
  import IseqCard from "@/components/ui/IseqCard.vue";

  export default {
    name: 'IseqDashboardAnalysisProgress',
    components: {
      "v-iseq-progressbar-list": analysisProgressbarList,
      "v-iseq-card": IseqCard
    },
    data() {
      return {
        activeTab: 0,
        hidden: false
      }
    },
    computed: {
      ...mapState('analysis', ['dashboardAnalysesProgress']),
      numberOfUserAnalyses() {
        return this.dashboardAnalysesProgress.userAnalyses.length;
      },
      numberOfOtherAnalyses() {
        return this.dashboardAnalysesProgress.otherAnalyses.length;
      }
    },
    watch: {
      numberOfUserAnalyses(newVal) {
        if (newVal && !this.numberOfOtherAnalyses) {
          this.activeTab = 1;
        }
      },
      numberOfOtherAnalyses(newVal) {
        if (newVal && !this.numberOfUserAnalyses) {
          this.activeTab = 1;
        }
      }
    },
    created() {
      if (!this.numberOfUserAnalyses) {
        this.activeTab = 1;
      }
    },
    methods: {
      toggleAnalysisTab() {
        this.hidden = !this.hidden;
      }
    },
  };
</script>

<style lang="scss"
       scoped>
.iseq-dashboard-analysis-progress {
    &__card {
        position: relative;

        &__close {
            position: absolute;
            right: 20px;
            top: 30px;
            z-index: 10;

            &__icon {
                cursor: pointer;
                margin-right: 10px;
            }
        }

        &__tab {
            padding: 0 32px;
        }

        &__progressbar-list {
            margin: 10px 20px;
        }
    }
}

.hide {
    display: none;
}
</style>