<template>
  <div class="iseq-top-bar">
    <span class="iseq-top-bar__text">
      <template v-if="trialDaysLeft>=0">
        You are currently using a trial version of the iFlow platform. Your access to the platform will expire within the next {{ trialDaysLeft }} {{ `${ trialDaysLeft === 1 ? 'day' : 'days' }` }}.
      </template>

      <template v-else>
        Your trial version of iFlow™ has expired.
      </template>

      <span v-if="permissionRequired('organizationCreditsAdd')">
        <span
          class="iseq-top-bar__text__link"
          @click="addCredits"
        >Top up</span> your account to upgrade to the regular version.
      </span>
    </span>
  </div>
</template>

<script>
  import {mapState} from "vuex";
  import {permissionCheck} from "@/plugins/permissions";

  export default {
    name: 'IseqTopBar',
    computed: {
      ...mapState('organization', ['organization']),
      trialDaysLeft() {
        return 30 - Math.floor((new Date() - new Date(this.organization.creationDate).getTime())/(1000*60*60*24));
      }
    },
    methods: {
      permissionRequired(action) {
        return permissionCheck(action)
      },
      addCredits() {
        this.$store.commit('payment/setPaymentDialog', true)
      }
    }
  };
</script>

<style lang="scss"
       scoped>
    .iseq-top-bar {
        background-color: var(--topbar-background-color);
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        &__text {
            color: white;
            font-size: 13px;

            &__link {
                text-decoration: underline;
                font-weight: 700;
                cursor: pointer;
            }
        }
    }
</style>