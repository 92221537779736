<template>
  <v-iseq-card
    title="Samples"
    class="iseq-sample-list"
  >
    <template #title-row>
      <div class="iseq-sample-list__header">
        <v-iseq-btn
          v-if="permissionRequired('sampleAdd')"
          plus-button
          ml
          @click.prevent="createSample"
        />
      </div>
    </template>

    <v-data-table
      :headers="tableColumns"
      :footer-props="{
        'items-per-page-options': itemsPerPageOptions
      }"
      :items="content"
      :loading="loading"
      :server-items-length="totalElements"
      :options.sync="samplesTableOptions"
    >
      <template #[`item.name`]="{ item }">
        <router-link :to="`/samples/${item.uuid}`">
          <div class="iseq-sample-list__field">
            {{ item.name }}
          </div>
        </router-link>
      </template>

      <template #[`item.created`]="{ item }">
        <router-link :to="`/samples/${item.uuid}`">
          <div class="iseq-sample-list__field">
            {{ item.created }}
          </div>
        </router-link>
      </template>

      <template #[`item.validUntil`]="{ item }">
        <router-link :to="`/samples/${item.uuid}`">
          <div class="iseq-sample-list__field">
            <v-tooltip
              top
              :disabled="calculateTimeDifference(item.validUntil).endsWith('ago')"
            >
              <template #activator="{ on }">
                <span v-on="on">

                  {{ item.validUntil }}
                </span>
              </template>

              Expires in {{ trimTimeDifference(calculateTimeDifference(item.validUntil)) }}
            </v-tooltip>
          </div>
        </router-link>
      </template>
    </v-data-table>
  </v-iseq-card>
</template>

<script>
  import {mapState} from 'vuex'
  import {permissionCheck} from "@/plugins/permissions";
  import {dateToRelativeTime} from "@/plugins/utils";
  import IseqCard from "@/components/ui/IseqCard.vue";
  import IseqButton from "@/components/ui/IseqButton.vue";
  import {segmentTrack} from "@/helpers/analytics";

  export default {
    name: 'SampleList',
    components: {
      "v-iseq-card": IseqCard,
      "v-iseq-btn": IseqButton
    },
    beforeRouteLeave(to, from, next) {
      if (!to.name.includes('sample')) {
        this.$store.commit('sample/clearSamplesTableOptions');
        this.$store.commit('sample/clearContent');
        next();
      } else {
        next();
      }
    },
    data: function () {
      return {
        itemsPerPageOptions: [10, 20, 30, -1],
        loading: false,
        preference: "",
        tableColumns: [
          {text: 'Sample name', value: 'name'},
          {text: 'Created time', value: 'created'},
          {text: 'Valid until', value: 'validUntil'},
        ]
      }
    },
    computed: {
      ...mapState('sample', ['content', 'totalElements']),
      ...mapState('project', ['project']),
      ...mapState('user', ['userCredits']),
      samplesTableOptions: {
        get() {
          return this.$store.getters['sample/samplesTableOptions'];
        },
        set(newValue) {
          this.$store.commit('sample/setSamplesTableOptions', newValue);
          this.$store.dispatch('sample/getSampleList');
        }
      }
    },
    watch: {
      project(newProject) {
        if (!newProject.uuid) {
          this.$router.push('/');
        } else {
          this.$store.commit('sample/clearSamplesTableOptions');
          this.$store.dispatch('sample/getSampleList');
        }
      }
    },
    methods: {
      permissionRequired(action) {
        return permissionCheck(action)
      },
      calculateTimeDifference(date) {
        return dateToRelativeTime(date);
      },
      trimTimeDifference(timeDifference) {
        return timeDifference.slice(0, timeDifference.lastIndexOf(' '));
      },
      createSample() {
        segmentTrack({event: "Create New Sample Clicked"});
        this.$router.push('/samples/new');
      }
    }
  }
</script>
<style lang="scss"
       scoped>
.iseq-sample-list {
  &__header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  &__field {
    height: 100%;
    display: flex;
    align-items: center;
  }
}
</style>