var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"iseq-dashboard-invitations"},[_c('v-iseq-card',{staticClass:"iseq-dashboard-invitations__card",attrs:{"title":"Invitations","no-bottom-margin":""},scopedSlots:_vm._u([{key:"post-title",fn:function(){return [(_vm.invitations.length)?_c('v-badge',{attrs:{"content":_vm.invitations.length,"color":"danger","offset-x":"-5","offset-y":"4"}}):_vm._e()]},proxy:true}])},[_c('v-data-table',{staticClass:"iseq-dashboard-invitations__card__table",attrs:{"headers":_vm.invitationColumns,"items":_vm.invitations},scopedSlots:_vm._u([{key:"item.objectName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"row-items"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-icon',{attrs:{"color":"text"}},[_vm._v(" "+_vm._s(item.objectType === "ORGANIZATION" ? "mdi-domain" : "mdi-folder-open")+" ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(item.objectType)+" ")]),_vm._v(" "+_vm._s(item.objectName)+" ")],1)]}},{key:"item.sendDate",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.calculateDifference(item.sendDate))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.sendDate)+" ")])]}},{key:"item.expirationDate",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.calculateDifference(item.expirationDate))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.expirationDate)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"iseq-dashboard-invitations__card__table__actions"},[_c('v-iseq-btn',{attrs:{"type":"red","mr":"","large":""},on:{"click":function($event){return _vm.openRejectDialog(item)}}},[_vm._v(" Reject ")]),_c('v-iseq-btn',{attrs:{"large":""},on:{"click":function($event){return _vm.acceptInvitation(item)}}},[_vm._v(" Accept ")])],1)]}}],null,true)})],1),(_vm.rejectDialog)?_c('iseq-confirmation-modal',{attrs:{"header":"Reject invitation","confirm-button-type":"danger","confirm-button-text":"Reject","reject-button-type":"secondary","reject-button-text":"Close"},on:{"actionRejected":function($event){_vm.rejectDialog = false},"actionConfirmed":function($event){return _vm.rejectInvitation(_vm.invitation)}}},[_c('p',[_vm._v(" Rejected invitation cannot be restored. In order to join this "+_vm._s(_vm.invitation.objectType.toLowerCase())+" in the future you will have to ask for new invitation."),_c('br'),_c('br'),_vm._v(" Are you sure that you want to reject it? ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }