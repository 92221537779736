<template>
  <v-dialog
    v-model="isOpen"
    :persistent="persistent"
    :max-width="width"
  >
    <div class="iseq-simple-message-modal">
      <div
        v-if="header"
        class="iseq-simple-message-modal__header"
      >
        <span class="iseq-simple-message-modal__header__title">{{ header }}</span>
      </div>

      <div class="iseq-simple-message-modal__message">
        {{ message }}
        <slot />
      </div>

      <template v-if="link">
        <router-link :to="link">
          <v-iseq-btn
            @click="close()"
          >
            {{ buttonText }}
          </v-iseq-btn>
        </router-link>
      </template>

      <template v-else>
        <v-iseq-btn
          @click="close()"
        >
          {{ buttonText }}
        </v-iseq-btn>
      </template>
    </div>
  </v-dialog>
</template>

<script>
  import IseqButton from "@/components/ui/IseqButton.vue";

  export default {
    name: "IseqSimpleMessageModal",
    components: {
      "v-iseq-btn": IseqButton
    },
    props: {
      header: {
        type: String,
        default: undefined
      },
      message: {
        type: String,
        default: ""
      },
      buttonText: {
        type: String,
        default: "close"
      },
      link: {
        type: String,
        default: undefined
      },
      persistent: {
        type: Boolean,
        default: true
      },
      width: {
        type: String,
        default: "600px"
      }
    },
    data: function () {
      return {
        isOpen: true
      }
    },
    methods: {
      close() {
        this.$emit('closed');
      }
    }
  }
</script>

<style lang="scss"
       scoped>

.iseq-simple-message-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: white;

  &__header {
    margin-bottom: 20px;

    &__title {
      color: var(--dark);
      font-weight: bold;
      font-size: 1.7em;
    }
  }

  &__message {
    width: 100%;
    align-items: flex-start;
    margin-bottom: 20px;
    color: var(--dark);
    font-size: 1.2em;
  }
}
</style>