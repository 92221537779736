<template>
  <v-btn
    :class="['iseq-btn', `iseq-btn--${type}`]"
    :style="cssProps"
    :outlined="type !== 'primary'"
    :fab="plusButton"
    :small="plusButton || small"
    v-bind="$attrs"
    depressed
    @click="clicked($event)"
  >
    <template v-if="plusButton">
      <v-icon>mdi-plus</v-icon>
    </template>

    <template v-else>
      <v-icon
        v-if="preIcon"
        class="pre-icon"
      >
        {{ preIcon }}
      </v-icon>

      <slot />

      <v-icon
        v-if="postIcon"
        class="post-icon"
      >
        {{ postIcon }}
      </v-icon>
    </template>
  </v-btn>
</template>

<script>
  export default {
    name: "IseqButton",
    props: {
      type: {
        type: String,
        default: "primary",
        validator(value) {
          return ['primary', 'secondary', 'warning', 'red', 'green', 'grey'].includes(value);
        }
      },
      width: {
        type: String,
        default: "min-content"
      },
      preIcon: {
        type: String,
        default: undefined
      },
      postIcon: {
        type: String,
        default: undefined
      },
      mr: {
        type: Boolean,
        default: false
      },
      ml: {
        type: Boolean,
        default: false
      },
      plusButton: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      buttonWidth() {
        if (this.plusButton) {
          return '40px';
        }

        switch (this.width) {
        case 'wide':
          return '155px';
        case 'medium':
          return '120px';
        default:
          return this.width;
        }
      },
      cssProps() {
        return {
          '--button-width': this.buttonWidth,
          '--margin-right': this.mr ? '8px' : '0',
          '--margin-left': this.ml ? '8px' : '0'
        }
      }
    },
    methods: {
      clicked(event) {
        this.$emit('click', event);
      }
    }
  }
</script>

<style lang="scss"
       scoped>

.v-btn {
  width: var(--button-width);
  margin-left: var(--margin-left);
  margin-right: var(--margin-right);
  font-weight: bold;
  letter-spacing: 0 !important;
  font-size: 12px !important;
  text-transform: none !important;
}

.v-btn .v-btn__content {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.iseq-btn {
  &--primary {
    background-color: var(--active) !important;
    color: rgba(255, 255, 255, 1) !important;

    .v-btn__content {
      color: rgba(255, 255, 255, 1) !important;
    }
  }

  &--secondary {
    color: var(--active) !important;
    background-color: rgba(255, 255, 255, 1) !important;

    .v-btn__content {
      color: var(--active) !important;
    }
  }

  &--warning {
    color: var(--warning-color) !important;
    background-color: rgba(255, 255, 255, 1) !important;

    .v-btn__content {
      color: var(--warning-color) !important;
    }
  }

  &--red {
    color: var(--danger-color) !important;
    background-color: rgba(255, 255, 255, 1) !important;

    .v-btn__content {
      color: var(--danger-color) !important;
    }
  }

  &--green {
    color: var(--success-color) !important;
    background-color: rgba(255, 255, 255, 1) !important;

    .v-btn__content {
      color: var(--success-color) !important;
    }
  }

  &--grey {
    color: var(--font-inverted-color) !important;
    background-color: rgba(255, 255, 255, 1) !important;

    .v-btn__content {
      color: var(--font-inverted-color) !important;
    }
  }
}

.post-icon {
  margin-left: 8px;
}

.pre-icon {
  margin-right: 8px;
}
</style>