var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"iseq-organization-list"},[_c('v-iseq-card',{staticClass:"iseq-organization-list__content",attrs:{"title":"Organizations","no-bottom-margin":""}},[_c('v-data-table',{staticClass:"iseq-organization-list__content__table",attrs:{"headers":_vm.tableColumns,"items":_vm.content,"server-items-length":_vm.totalElements,"options":_vm.options,"footer-props":{
        'items-per-page-options': _vm.itemsPerPageOptions
      }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.goToOrganizationDetail},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{attrs:{"to":("/organizations/" + (item.uuid))}},[_c('div',{staticClass:"iseq-organization-list__content__field"},[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"item.role",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{attrs:{"to":("/organizations/" + (item.uuid))}},[_c('div',{staticClass:"iseq-organization-list__content__field"},[_vm._v(" "+_vm._s(_vm.roleReadable(item.role))+" ")])])]}},{key:"item.credits",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{attrs:{"to":("/organizations/" + (item.uuid))}},[_c('div',{staticClass:"iseq-organization-list__content__field"},[_vm._v(" "+_vm._s(item.credits)+" ")])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }