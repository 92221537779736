<template>
  <div>
    <v-tabs-items
      touchless
      :value="tab"
    >
      <v-tab-item value="ownProjects">
        <div class="iseq-project-list-tabs__header">
          <span class="iseq-project-list-tabs__header__title">
            My projects
          </span>

          <div>
            <v-iseq-btn
              v-if="permissionRequired('projectAdd')"
              plus-button
              ml
              @click.prevent="newProject"
            />
          </div>
        </div>

        <v-data-table
          :headers="joinedProjectsTableColumns"
          :items="userProjects"
          :server-items-length="userProjectsTotalElements"
          :options.sync="userProjectsTableOptions"
          :footer-props="{'items-per-page-options': itemsPerPageOptions}"
        >
          <template #[`item.name`]="{ item }">
            <router-link :to="`/projects/${item.uuid}`">
              <div class="iseq-project-list-tabs__field">
                {{ item.name }}
              </div>
            </router-link>
          </template>

          <template #[`item.created`]="{ item }">
            <router-link :to="`/projects/${item.uuid}`">
              <div class="iseq-project-list-tabs__field">
                {{ item.created }}
              </div>
            </router-link>
          </template>

          <template #[`item.role`]="{ item }">
            <router-link :to="`/projects/${item.uuid}`">
              <div class="iseq-project-list-tabs__field">
                {{ hrRole(item.role) }}
              </div>
            </router-link>
          </template>
        </v-data-table>
      </v-tab-item>

      <v-tab-item value="otherProjects">
        <div class="iseq-project-list-tabs__header">
          <span class="iseq-project-list-tabs__header__title">
            Other projects
          </span>
        </div>

        <v-data-table
          :headers="joinableProjectsTableColumns"
          :items="joinableProjects"
          :server-items-length="joinableProjectsTotalElements"
          :options.sync="joinableProjectsTableOptions"
          :footer-props="{'items-per-page-options': itemsPerPageOptions}"
        >
          <template #[`item.uuid`]="{ item }">
            <v-iseq-btn
              v-if="!item.requestSent"
              small
              @click="joinProject(item)"
            >
              Join
            </v-iseq-btn>

            <v-iseq-btn
              v-else
              small
              disabled
            >
              Pending
            </v-iseq-btn>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
  import {mapState} from "vuex";
  import {permissionCheck} from "@/plugins/permissions";
  import {roleToHR} from "@/plugins/utils";
  import IseqButton from "@/components/ui/IseqButton.vue";
  import {segmentTrack} from "@/helpers/analytics";

  export default {
    name: "ProjectListTabs",
    components: {
      "v-iseq-btn": IseqButton
    },
    props: {
      tab: {
        type: String,
        required: true
      }
    },
    data: function () {
      return {
        options: {},
        itemsPerPageOptions: [10, 20, 30, -1],
        isAllProjects: true,
        joinedProjectsTableColumns: [
          {text: 'Name', value: 'name', width: '200px'},
          {text: 'Created at', value: 'created', width: '150px'},
          {text: 'User role', value: 'role', width: '120px'}
        ],
        joinableProjectsTableColumns: [
          {text: 'Name', value: 'name', width: '200px'},
          {text: 'Created at', value: 'created', width: '150px'},
          {text: 'Action', value: 'uuid', width: '120px'}
        ]
      }
    },
    computed: {
      ...mapState('project', ['userProjects', 'userProjectsTotalElements', 'joinableProjects', 'joinableProjectsTotalElements']),
      ...mapState('organization', ['organization']),
      userProjectsTableOptions: {
        get() {
          return this.$store.getters['project/userProjectsTableOptions'];
        },
        set(newValue) {
          this.$store.commit('project/setUserProjectsTableOptions', newValue);
          this.$store.dispatch('project/getUserProjects', {organizationUuid: this.organization.uuid});
        }
      },
      joinableProjectsTableOptions: {
        get() {
          return this.$store.getters['project/joinableProjectsTableOptions'];
        },
        set(newValue) {
          this.$store.commit('project/setJoinableProjectsTableOptions', newValue);
          this.$store.dispatch('project/getJoinableProjects');
        }
      }
    },
    watch: {
      organization(newOrganization) {
        this.$store.dispatch('project/getUserProjects', {organizationUuid: newOrganization.uuid});
        this.$store.dispatch('project/getJoinableProjects');
      },
    },
    methods: {
      permissionRequired(action) {
        return permissionCheck(action)
      },
      hrRole(role) {
        return roleToHR(role);
      },
      joinProject(project) {
        this.$store.dispatch('project/joinProject', {projectUuid: project.uuid});
        this.joinableProjects[this.joinableProjects.indexOf(project)].requestSent=true;
      },
      newProject() {
        segmentTrack({event: "Create New Project Clicked"});
        this.$router.push({path: `/projects/new`});
      },
    }
  }
</script>

<style lang="scss" scoped>
.iseq-project-list-tabs {
  &__header {
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    &__title {
      color: var(--font-color);
      font-weight: bold;
      font-size: 20px;
    }
  }

  &__divider {
    padding-bottom: 15px;
  }

  &__field {
    height: 100%;
    display: flex;
    align-items: center;
  }
}
</style>