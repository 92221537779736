import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import marked from 'marked';
import DOMPurify from 'dompurify'

export function getDocumentTitle(subpageTitle, totalPromptsNumber) {
  if (totalPromptsNumber) {
    return `(${totalPromptsNumber}) ${process.env.VUE_APP_PAGES_TITLE} - ${subpageTitle}`;
  } else {
    return `${process.env.VUE_APP_PAGES_TITLE} - ${subpageTitle}`;
  }
}

export function pagingDataParse(data) {

  // In case of no data specified the default paging settings will be returned
  if(!data) {
    return generatePagingData({});
  }

  const direction = data.sortDesc[0] ? 'desc' : 'asc';
  const sortBy = data.sortBy[0] ? data.sortBy[0] : '';
  const itemsPerPage = data.itemsPerPage !== -1 ? data.itemsPerPage : data.totalElements;

  return `page=${data.page - 1}&size=${itemsPerPage}&sort=${sortBy},${direction}`
}

export function generatePagingData(payload) {
  if (payload.raw) {
    return {
      page: 1,
      itemsPerPage: payload.pageSize ? payload.pageSize : 10,
      sortBy: [],
      sortDesc: []
    }
  } else {
    return `page=0&size=${payload.pageSize ? payload.pageSize : 10}`;
  }
}

export function timeZoned(date) {
  if ((new Date(date)).toString() !== "Invalid Date" && date !== null) {
    const timeZonedDate = new Date(new Date(date).getTime() - (new Date().getTimezoneOffset()) * 60 * 1000);
    const timeZonedString = timeZonedDate.toLocaleString('en-US', {hourCycle: "h23"});
    const gmt = new Date().toLocaleTimeString('en-us', {timeZoneName: 'short'}).split(' ')[2];
    return `${timeZonedString} ${gmt}`
  } else {
    return date
  }
}

export function roleToHR(name) {
  if (name) {
    const separator = name.includes(' ') ? ' ' : '_';
    return name[name.indexOf(separator) + 1].toUpperCase() + name.slice(name.indexOf(separator) + 2).toLowerCase()
  } else {
    return name;
  }
}

export function dateToRelativeTime(date) {
  dayjs.extend(relativeTime);
  const diff = dayjs(date).fromNow(true); // See: https://day.js.org/docs/en/display/from-now

  if (dayjs() < dayjs(date)) {
    return `${diff} left`;
  } else {
    return `${diff} ago`;
  }
}

function calculateSize (size) {
  const names = ["B", "KB", "MB", "GB"];
  let i = 0;
  let subValue = "00";
  while (size > 999 && i < 3) {
    subValue = (parseInt(((size / 10) % 100).toString(), 10)).toString();
    i++;
    size = parseInt((size / 1000).toString(), 10)
  }
  if (subValue.length < 2) subValue = "0" + subValue;
  return size + '.' + subValue + names[i];
}

function sortFiles (files) {
  files.sort(function (a, b){
    if (a.runDate) {
      if (b.runDate) {
        return new Date(a.runDate) > new Date(b.runDate) ? -1 : 1;
      }
      else return 1
    }
    else if (b.runDate) {
      return 1;
    }
    else {
      if (a.label.includes('report')) {
        if (b.label.includes('report')) {
          if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
          else if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
          else return 0;
        } else return -1;
      }

      if (b.label.includes('report')) return 1;

      if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
      else if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
      else return 0;
    }
  });
  return files;
}

export function parseFiles(files) {
  // Sorting files and subdirectories in directory
  files = sortFiles(files);

  files = files.map( file => {
    // Translating filesize to human-readable value
    file.size = calculateSize(file.size);

    // Translating date to users timezone and format (if given)
    if (file.runDate) {
      file.runDate = timeZoned(file.runDate);
    }

    // Recursive parse if node is a directory
    if (file.children) {
      file.children = parseFiles(file.children);
    }
    return file;
  });
  return files;
}

export function isValidEmail(email) {
  //RFC 5322 Official Standard General Email Regex
  const emailRegex = /^(?:[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/gm;
  return emailRegex.test(email);
}

export function markdownCompile(data) {
  return data ? DOMPurify.sanitize(marked(data.replace(/\\n/g, "\n"))) : data;
}
