import request    from '@/customjs/request.js';
import VueCookies from 'vue-cookies';
import store      from '@/store';

export default {
  namespaced: true,
  state: {
    files: [],
    myUploadingFiles: [],
    othersUploadingFiles: [],
  },
  mutations: {
    setMyUploadingFiles: (state, payload) => {
      let changeProgress = false;
      state.myUploadingFiles.map(file => {
        if (file.uuid === payload.uuid) {
          const index = state.myUploadingFiles.indexOf(file);
          state.myUploadingFiles[ index ].progress = payload.progress;
          if(payload.error !== '') {
            state.myUploadingFiles[ index ].error = payload.error;
          }
          if(payload.status !== 'INCOMPLETE') {
            state.myUploadingFiles[ index ].status = payload.status;
          }
          changeProgress = true;
        }
      });
      if (!changeProgress) {
        state.myUploadingFiles.push(payload);
      }
    },
    setOthersUploadingFiles: (state, payload) => {
      let changeProgress = false;
      state.othersUploadingFiles.map(file => {
        if (file.uuid === payload.uuid) {
          const index = state.othersUploadingFiles.indexOf(file);
          state.othersUploadingFiles[ index ].progress = payload.progress;
          changeProgress = true;
        }
      });
      if (!changeProgress) {
        state.othersUploadingFiles.push(payload);
      }
    },
    resetFiles: (state) => {
      state.files = [];
      state.myUploadingFiles = [];
      state.othersUploadingFiles = [];
    },
    setActiveTab: (state, payload) => {
      state.activeTab = payload
    },
    setIsActiveTab: (state, payload) => {
      state.isActiveTab = payload
    },
    removeFileFromMyList: (state, payload) => {
      state.myUploadingFiles = state.myUploadingFiles.filter(el => el.uuid !== payload);

    },
    removeFileFromOthersList: (state, payload) => {
      state.othersUploadingFiles = state.othersUploadingFiles.filter(el => el.uuid !== payload);
    },
    cleanAllData: (state) => {
      state.files=[];
      state.myUploadingFiles=[];
      state.othersUploadingFiles=[];
    }
  },
  actions: {
    receiveFileData({commit, dispatch, rootState}, payload) {
      const userEmail = rootState.user.user;
      if(userEmail && payload) {
        if(payload.userEmail === userEmail.email) {
          commit('setMyUploadingFiles', payload)
        } else {
          commit('setOthersUploadingFiles', payload)
        }
      }
    },
    cancelUpload({commit, dispatch}, payload) {
      request.request({
        service: "API_FILES",
        snackbarMessage: payload.failed ? undefined : "Deleting uploading files",
        endpoint: `files/${payload.id}`,
        method: "delete",
        callback: function () {
          commit('removeFileFromMyList', payload.id)
          commit('removeFileFromOthersList', payload.id)
        }
      })
    },
    getIncompleteFiles({dispatch}, payload) {
      request.request({
        service: "API_FILES",
        endpoint: `projects/${payload.projectUuid}/files/incomplete`,
        method: "get",
        callback: function (data) {
          for(let file of data) {
            dispatch('receiveFileData', file);
          }
        }
      })
    },
    resendFileHttp({dispatch}, payload){
      request.request({
        service: "API_FILES",
        endpoint: `samples/${payload.sampleUuid}/file/http`,
        method: "put",
        data: {
          fileUuid: payload.uuid,
        },
        callback: function (data) {
          for(let file of data) {
            dispatch('receiveFileData', file);
          }
        }
      })
    },
    resendFileFtp({dispatch}, payload){
      request.request({
        service: "API_FILES",
        endpoint: `samples/${payload.sampleUuid}/file/ftp`,
        method: "put",
        data: {
          fileUuid: payload.uuid,
        },
        callback: function (data) {
          for(let file of data) {
            dispatch('receiveFileData', file);
          }
        }
      })
    },
    resendFileDisk({dispatch}, payload){
      request.request({
        service: "API_FILES",
        endpoint: `samples/${payload.info.sampleUuid}/file/disk`,
        method: "put",
        data: payload.file,
        callback: function (data) {
          for(let file of data) {
            dispatch('receiveFileData', file);
          }
        }
      })
    },
  },
}
