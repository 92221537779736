import Vue from 'vue';
import Vuex from 'vuex';
import analysis from './modules/analysis.js';
import sample from './modules/sample.js';
import security from './modules/security.js';
import user from './modules/user.js';
import project from './modules/project.js';
import organization from './modules/organization.js';
import snackbarQueue from './modules/snackbarQueue.js';
import event from './modules/event.js';
import workflow from './modules/workflow.js';
import invitation from './modules/invitation';
import notification from './modules/notification';
import fileUploads from './modules/fileUploads';
import payment from './modules/payment';
import store from '@/store';
import request from '@/customjs/request';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    connected: false,
    checkUploadFromDisk: undefined,
    isLowAccModalOpen: false,
    appOnlineStatus: true,
  },

  mutations: {
    setConnected: (state, payload) => {
      state.connected = payload;
    },
    setCheckUploadFromDisk: (state, payload) => {
      state.checkUploadFromDisk = payload;
    },
    setAppOnlineStatus: (state, payload)=> {
      state.appOnlineStatus = payload;
    }
  },

  actions: {
    cleanAllData({commit}) {
      store.commit('analysis/cleanAllData');
      store.commit('event/cleanAllData');
      store.commit('organization/cleanAllData');
      store.commit('project/cleanAllData');
      store.commit('sample/cleanAllData');
      store.commit('security/cleanAllData');
      store.commit('snackbarQueue/cleanAllData');
      store.commit('user/cleanAllData');
      store.commit('workflow/cleanAllData');
      store.commit('invitation/cleanAllData');
      store.commit('notification/cleanAllData');
      store.commit('fileUploads/cleanAllData')
    },

    setAppOnlineStatus({commit, dispatch}, payload) {
      if (payload) {
        store.dispatch("snackbarQueue/removeMissingConnectionSnackbar");
        store.dispatch("snackbarQueue/addConfirmationMessage", {type: "internetAccessRetrieved"});
      } else {
        store.dispatch("snackbarQueue/addMissingConnectionSnackbar");
      }
      commit("setAppOnlineStatus", payload);
    }
  },

  getters: {
    isLowAccModalOpen: (state) => {
      return state.isLowAccModalOpen;
    },
    isAppOnline: (state) => {
      return state.appOnlineStatus;
    }
  },

  modules: {
    payment,
    analysis,
    sample,
    security,
    user,
    project,
    organization,
    snackbarQueue,
    event,
    workflow,
    invitation,
    notification,
    fileUploads
  }
})
