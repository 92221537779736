<template>
  <div class="iseq-dashboard-invitations">
    <v-iseq-card
      title="Invitations"
      no-bottom-margin
      class="iseq-dashboard-invitations__card"
    >
      <template #post-title>
        <v-badge
          v-if="invitations.length"
          :content="invitations.length"
          color="danger"
          offset-x="-5"
          offset-y="4"
        />
      </template>

      <v-data-table
        :headers="invitationColumns"
        :items="invitations"
        class="iseq-dashboard-invitations__card__table"
      >
        <template #[`item.objectName`]="{ item }">
          <div class="row-items">
            <v-tooltip top>
              <template #activator="{ on }">
                <div v-on="on">
                  <v-icon color="text">
                    {{ item.objectType === "ORGANIZATION" ? "mdi-domain" : "mdi-folder-open" }}
                  </v-icon>
                </div>
              </template>

              {{ item.objectType }}
            </v-tooltip>

            {{ item.objectName }}
          </div>
        </template>

        <template #[`item.sendDate`]="{ item }">
          <v-tooltip top>
            <template #activator="{ on }">
              <span v-on="on">
                {{ calculateDifference(item.sendDate) }}
              </span>
            </template>

            {{ item.sendDate }}
          </v-tooltip>
        </template>

        <template #[`item.expirationDate`]="{ item }">
          <v-tooltip top>
            <template #activator="{ on }">
              <span v-on="on">
                {{ calculateDifference(item.expirationDate) }}
              </span>
            </template>

            {{ item.expirationDate }}
          </v-tooltip>
        </template>

        <template #[`item.actions`]="{ item }">
          <div class="iseq-dashboard-invitations__card__table__actions">
            <v-iseq-btn
              type="red"
              mr
              large
              @click="openRejectDialog(item)"
            >
              Reject
            </v-iseq-btn>

            <v-iseq-btn
              large
              @click="acceptInvitation(item)"
            >
              Accept
            </v-iseq-btn>
          </div>
        </template>
      </v-data-table>
    </v-iseq-card>

    <iseq-confirmation-modal
      v-if="rejectDialog"
      header="Reject invitation"
      confirm-button-type="danger"
      confirm-button-text="Reject"
      reject-button-type="secondary"
      reject-button-text="Close"
      @actionRejected="rejectDialog = false"
      @actionConfirmed="rejectInvitation(invitation)"
    >
      <p>
        Rejected invitation cannot be restored. In order to join this {{ invitation.objectType.toLowerCase() }} in the
        future you will have to ask for new invitation.<br>
        <br>
        Are you sure that you want to reject it?
      </p>
    </iseq-confirmation-modal>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import {dateToRelativeTime} from "../../../plugins/utils";
  import IseqConfirmationModal from "@/components/modals/IseqConfirmationModal.vue";
  import IseqCard from "@/components/ui/IseqCard.vue";
  import IseqButton from "@/components/ui/IseqButton.vue";

  export default {
    name: 'DashboardInvitations',
    components: {
      IseqConfirmationModal,
      "v-iseq-card": IseqCard,
      "v-iseq-btn": IseqButton
    },
    data: function () {
      return {
        invitationColumns: [
          {text: 'Invited to', value: 'objectName'},
          {text: "Sent by", value: "senderEmail"},
          {text: 'Received', value: 'sendDate'},
          {text: 'Valid until', value: 'expirationDate'},
          {text: 'Quick actions', value: 'actions', sortable: false}
        ],
        invitation: {objectType: ""},
        rejectDialog: false
      }
    },
    computed: {
      ...mapState('user', ['user']),
      ...mapState('invitation', ['invitations']),
    },
    methods: {
      acceptInvitation(item) {
        this.$store.dispatch('invitation/acceptInvitation', {uuid: item.uuid, objectType: item.objectType.toLowerCase()});
      },
      rejectInvitation(item) {
        this.rejectDialog = false;
        this.$store.dispatch('invitation/rejectInvitation', {uuid: item.uuid, redirect: false});
      },
      openRejectDialog(item) {
        this.rejectDialog = true;
        this.invitation = item;
      },
      route(item) {
        this.$router.push({path: item.route});
      },
      calculateDifference(date) {
        return dateToRelativeTime(date)
      }
    }
  }
</script>
<style lang="scss"
       scoped>

.iseq-dashboard-invitations {
  &__card {
    &__table {
      padding: 0px 16px;

      &__actions {
        display: flex;
        flex-direction: row;
      }
    }
  }

  .iseq-badge {
    &--red {
      color: var(--danger-color) !important;
    }
  }

  .row-items {
    display: flex;
  }
}
</style>