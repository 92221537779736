var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs-items',{attrs:{"touchless":"","value":_vm.tab}},[_c('v-tab-item',{attrs:{"value":"ownProjects"}},[_c('div',{staticClass:"iseq-project-list-tabs__header"},[_c('span',{staticClass:"iseq-project-list-tabs__header__title"},[_vm._v(" My projects ")]),_c('div',[(_vm.permissionRequired('projectAdd'))?_c('v-iseq-btn',{attrs:{"plus-button":"","ml":""},on:{"click":function($event){$event.preventDefault();return _vm.newProject($event)}}}):_vm._e()],1)]),_c('v-data-table',{attrs:{"headers":_vm.joinedProjectsTableColumns,"items":_vm.userProjects,"server-items-length":_vm.userProjectsTotalElements,"options":_vm.userProjectsTableOptions,"footer-props":{'items-per-page-options': _vm.itemsPerPageOptions}},on:{"update:options":function($event){_vm.userProjectsTableOptions=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/projects/" + (item.uuid))}},[_c('div',{staticClass:"iseq-project-list-tabs__field"},[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/projects/" + (item.uuid))}},[_c('div',{staticClass:"iseq-project-list-tabs__field"},[_vm._v(" "+_vm._s(item.created)+" ")])])]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/projects/" + (item.uuid))}},[_c('div',{staticClass:"iseq-project-list-tabs__field"},[_vm._v(" "+_vm._s(_vm.hrRole(item.role))+" ")])])]}}],null,true)})],1),_c('v-tab-item',{attrs:{"value":"otherProjects"}},[_c('div',{staticClass:"iseq-project-list-tabs__header"},[_c('span',{staticClass:"iseq-project-list-tabs__header__title"},[_vm._v(" Other projects ")])]),_c('v-data-table',{attrs:{"headers":_vm.joinableProjectsTableColumns,"items":_vm.joinableProjects,"server-items-length":_vm.joinableProjectsTotalElements,"options":_vm.joinableProjectsTableOptions,"footer-props":{'items-per-page-options': _vm.itemsPerPageOptions}},on:{"update:options":function($event){_vm.joinableProjectsTableOptions=$event}},scopedSlots:_vm._u([{key:"item.uuid",fn:function(ref){
var item = ref.item;
return [(!item.requestSent)?_c('v-iseq-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.joinProject(item)}}},[_vm._v(" Join ")]):_c('v-iseq-btn',{attrs:{"small":"","disabled":""}},[_vm._v(" Pending ")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }