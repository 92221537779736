var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-iseq-card',{staticClass:"iseq-sample-list",attrs:{"title":"Samples"},scopedSlots:_vm._u([{key:"title-row",fn:function(){return [_c('div',{staticClass:"iseq-sample-list__header"},[(_vm.permissionRequired('sampleAdd'))?_c('v-iseq-btn',{attrs:{"plus-button":"","ml":""},on:{"click":function($event){$event.preventDefault();return _vm.createSample($event)}}}):_vm._e()],1)]},proxy:true}])},[_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"footer-props":{
      'items-per-page-options': _vm.itemsPerPageOptions
    },"items":_vm.content,"loading":_vm.loading,"server-items-length":_vm.totalElements,"options":_vm.samplesTableOptions},on:{"update:options":function($event){_vm.samplesTableOptions=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":("/samples/" + (item.uuid))}},[_c('div',{staticClass:"iseq-sample-list__field"},[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"item.created",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":("/samples/" + (item.uuid))}},[_c('div',{staticClass:"iseq-sample-list__field"},[_vm._v(" "+_vm._s(item.created)+" ")])])]}},{key:"item.validUntil",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":("/samples/" + (item.uuid))}},[_c('div',{staticClass:"iseq-sample-list__field"},[_c('v-tooltip',{attrs:{"top":"","disabled":_vm.calculateTimeDifference(item.validUntil).endsWith('ago')},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(item.validUntil)+" ")])]}}],null,true)},[_vm._v(" Expires in "+_vm._s(_vm.trimTimeDifference(_vm.calculateTimeDifference(item.validUntil)))+" ")])],1)])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }