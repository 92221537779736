<template>
  <div class="iseq-analysis-list">
    <v-iseq-card
      title="Analyses"
      class="iseq-analysis-list__content"
    >
      <template #title-row>
        <div class="iseq-analysis-list__content__title-row">
          <v-iseq-btn
            v-if="permissionRequired('analysisAdd')"
            plus-button
            ml
            @click.prevent="createAnalysis"
          />

          <span class="iseq-analysis-list__content__title-row__link">
            <router-link :to="'/analyses/demo'">See example analyses</router-link>
          </span>
        </div>
      </template>

      <v-iseq-banner
        v-if="!content.length"
        type="info-light"
        value="You don't have any analyses right now"
      />

      <v-data-table
        v-else
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions
        }"
        :headers="analysisColumns"
        :items="content"
        :server-items-length="totalElements"
        :options.sync="analysisTableOptions"
        class="iseq-analysis-list__content__table"
      >
        <template #[`item.sampleName`]="{ item }">
          <router-link :to="`/analyses/${item.uuid}`">
            <div class="iseq-analysis-list__content__table__field">
              {{ item.sampleName }}
            </div>
          </router-link>
        </template>

        <template #[`item.name`]="{ item }">
          <router-link :to="`/analyses/${item.uuid}`">
            <div class="iseq-analysis-list__content__table__field">
              {{ item.name }}
            </div>
          </router-link>
        </template>

        <template #[`item.started`]="{ item }">
          <router-link :to="`/analyses/${item.uuid}`">
            <div class="iseq-analysis-list__content__table__field">
              {{ item.started }}
            </div>
          </router-link>
        </template>

        <template #[`item.finished`]="{ item }">
          <router-link :to="`/analyses/${item.uuid}`">
            <div class="iseq-analysis-list__content__table__field">
              {{ item.finished }}
            </div>
          </router-link>
        </template>

        <template #[`item.status`]="{ item }">
          <router-link :to="`/analyses/${item.uuid}`">
            <div class="iseq-analysis-list__content__table__field">
              {{ item.status }}
            </div>
          </router-link>
        </template>

        <template #[`item.chargedCost`]="{ item }">
          <router-link :to="`/analyses/${item.uuid}`">
            <div class="iseq-analysis-list__content__table__field">
              {{ item.chargedCost }} credits
            </div>
          </router-link>
        </template>
      </v-data-table>
    </v-iseq-card>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import {permissionCheck} from "@/plugins/permissions";
  import IseqCard from "@/components/ui/IseqCard.vue";
  import IseqButton from "@/components/ui/IseqButton.vue";
  import IseqBanner from "@/components/ui/IseqBanner.vue";
  import {segmentTrack} from "@/helpers/analytics";

  export default {
    name: "AnalysisList",
    components: {
      "v-iseq-card": IseqCard,
      "v-iseq-btn": IseqButton,
      "v-iseq-banner": IseqBanner
    },
    beforeRouteLeave(to, from, next) {
      if (!to.name.includes('analys')) {
        this.$store.commit('analysis/clearAnalysisTableOptions');
        this.$store.commit('analysis/resetContent');
        next();
      } else {
        next();
      }
    },
    data: function () {
      return {
        loading: false,
        itemsPerPageOptions: [10, 20, 30, -1],
        analysisColumns: [
          {text: 'Sample', value: 'sampleName'},
          {text: 'Name', value: 'name'},
          {text: 'Started', value: 'started'},
          {text: 'Finished', value: 'finished'},
          {text: 'Status', value: 'status'},
          {text: 'Cost', value: 'chargedCost', sortable: false},
        ]
      }
    },
    computed: {
      ...mapState('analysis', ['analyses', 'content', 'totalElements']),
      ...mapState('project', ['project']),
      analysisTableOptions: {
        get() {
          return this.$store.getters['analysis/analysisTableOptions'];
        },
        set(newValue) {
          this.$store.commit('analysis/setAnalysisTableOptions', newValue);
          this.$store.dispatch('analysis/getAnalysisList', {projectUuid: this.project.uuid});
        }
      }
    },
    watch: {
      project(newProject) {
        if (!newProject.uuid) {
          this.$router.push('/');
        } else {
          this.$store.commit('analysis/clearAnalysisTableOptions');
          this.$store.dispatch('analysis/getAnalysisList', {projectUuid: newProject.uuid});
        }
      }
    },
    created: function () {
      this.$store.dispatch('analysis/getAnalysisList', {projectUuid: this.project.uuid});
    },
    methods: {
      permissionRequired(action) {
        return permissionCheck(action)
      },
      createAnalysis() {
        segmentTrack({event: "Create New Analysis Clicked"});
        this.$router.push({path: `/analyses/new`});
      }
    }
  }
</script>

<style scoped
       lang="scss">

.iseq-analysis-list {
  &__content {
    &__title-row {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &__link {
        margin-left: 30px;
        cursor: pointer;
        color: var(--font-inverted-color);
        text-decoration: underline;
      }
    }

    &__table {
      padding: 16px;

      &__field {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>