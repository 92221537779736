<template>
  <div class="iseq-credential-views-frame">
    <v-card class="iseq-credential-views-frame__content">
      <div class="iseq-credential-views-frame__content__logo">
        <img
          alt="iFlow Logo"
          class="iseq-credential-views-frame__content__logo__image"
          :src="logoPath"
        >
      </div>

      <!-- View's content goes here -->
      <slot />
    </v-card>

    <div class="iseq-credential-views-frame__statement">
      By creating an account or signing in you accept <br>
      our
      <router-link to="/terms-of-use">
        <u>Terms of Use</u>
      </router-link>
      and
      <router-link to="/privacy-policy">
        <u>Privacy Policy</u>
      </router-link>
      .
    </div>
  </div>
</template>

<script>
  export default {
    name: "IseqCredentialViewsFrame",
    data: function () {
      return {
        logoPath: require(process.env.VUE_APP_LOGO_DARK_PATH),
      }
    }
  }
</script>

<style lang="scss"
       scoped>

.iseq-credential-views-frame {
  min-height: calc(100vh - 90px);
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;

  &__content {
    padding: 20px;
    width: 450px;
    margin-top: 100px;

    &__logo {
      margin-top: -170px;
      margin-bottom: 50px;
      padding: 20px;
      margin-left: calc(50% - 170px);
      width: 340px;

      &__image {
        width: 300px;
      }
    }
  }

  &__statement {
    text-align: center;
    padding: 20px;
    margin-bottom: 30px;
  }
}
</style>