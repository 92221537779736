<template>
  <div class="iseq-file-progressbar">
    <v-tooltip right>
      <template #activator="{ on }">
        <span
          class="iseq-file-progressbar__row-title"
          v-on="on"
        >
          <router-link :to="`/samples/${item.sampleUuid}`">{{ filename }}</router-link>
        </span>
      </template>

      <span>{{ filename }}</span>
    </v-tooltip>

    <div
      class="iseq-file-progressbar__progressbar"
    >
      <v-progress-linear
        rounded
        :value="progressbarValue"
        height="16"
        :color="progressbarColor"
      >
        <template #default="{}">
          <div class="iseq-file-progressbar__progressbar__description">
            <span class="iseq-file-progressbar__progressbar__description__text">{{ progressbarText }}</span>
          </div>
        </template>
      </v-progress-linear>
    </div>

    <div
      v-if="item.status !== 'INCOMPLETE'"
      class="iseq-file-progressbar__value"
    >
      <span
        v-if="checkError"
        class="iseq-file-progressbar__value__text"
      >
        {{ item.progress }}%
      </span>
    </div>

    <div
      v-if="showActions"
      class="iseq-file-progressbar__actions"
    >
      <div
        v-if="item.error !== '' && item.error !== 'Aborted' && !!item.error && item.type === 'DISK'"
        class="iseq-file-progressbar__actions__action"
        @click="openResendModal(item)"
      >
        <v-tooltip top>
          <template #activator="{ on }">
            <v-icon
              color="dark"
              v-on="on"
            >
              mdi-refresh
            </v-icon>
          </template>

          <span>Reupload {{ filename }}</span>
        </v-tooltip>
      </div>

      <div
        v-if="item.error === '' && item.progress < 100"
        class="iseq-file-progressbar__actions__action"
        @click="deleteUpload()"
      >
        <v-tooltip top>
          <template #activator="{ on }">
            <v-icon
              color="dark"
              v-on="on"
            >
              mdi-close
            </v-icon>
          </template>

          <span>Cancel upload of {{ filename }}</span>
        </v-tooltip>
      </div>
    </div>

    <v-iseq-confirmation-modal
      v-if="abortModal"
      width="400px"
      header="Upload abort"
      :message="`Are you sure, you want to abort uploading file: ${filename}`"
      reject-button-text="Cancel"
      reject-button-type="secondary"
      confirm-button-text="Abort"
      confirm-button-type="danger"
      @actionRejected="abortModal=false"
      @actionConfirmed="sendRequestToAbort"
    />

    <iseq-confirmation-modal
      v-if="resendModal"
      width="450px"
      confirm-button-type="primary"
      confirm-button-text="Resend"
      reject-button-type="secondary"
      reject-button-text="Cancel"
      @actionRejected="closeResendModal"
      @actionConfirmed="sendRequestToReupload"
    >
      <span>Please select file from disk to resend</span>

      <v-file-input
        outlined
        chips
        show-size
        hide-details
        label="Press to select files from your computer"
        @change="addFile"
      />
    </iseq-confirmation-modal>
  </div>
</template>

<script>
  import IseqConfirmationModal from "@/components/modals/IseqConfirmationModal.vue";

  export default {
    name: "FileProgressbar",
    components: {
      IseqConfirmationModal,
      'v-iseq-confirmation-modal': IseqConfirmationModal
    },
    props: {
      item: {
        type: Object,
        default: () => {}
      },
      showActions: {
        type: Boolean,
        default: false
      }
    },
    data: function () {
      return {
        resendModal: false,
        abortModal: false,
        fileFromDiskToResend: {
          info: {},
          file: {},
        }
      }
    },
    computed: {
      progressbarText() {
        if (this.item.completed || this.item.progress === 100) {
          return "Completed";
        } else if (!this.checkError) {
          return this.item.error;
        } else if (this.item.status !== 'IN_PROGRESS') {
          return this.item.status;
        } else {
          return ''
        }
      },
      progressbarValue() {
        if ((this.item.error && this.item.error.length) || this.item.completed || this.item.status !== 'IN_PROGRESS') {
          return 100;
        } else {
          return this.item.progress;
        }
      },
      progressbarColor() {
        if (this.item.status !== 'IN_PROGRESS' && this.item.status !== 'ABORTED') {
          return 'error';
        }
        if (this.item.completed || this.item.progress === 100) {
          return 'success';
        }
        else {
          return 'dark';
        }
      },
      checkError() {
        return (!this.item.error || !this.item.error.length) && (-1 < this.item.progress) && (this.item.progress < 101);
      },
      filename() {
        return this.item.filename.substring(this.item.filename.lastIndexOf('/') + 1);
      },
    },
    methods: {
      sendRequestToAbort() {
        this.$store.dispatch('fileUploads/cancelUpload', {id: this.item.uuid, failed: this.item.status === "INCOMPLETE"});
        this.abortModal = false;
      },
      openResendModal(item) {
        if (item.type === 'DISK') {
          this.resendModal = true;
          this.fileFromDiskToResend.info = item;
        } else if (item.type === 'FTP') {
          this.$store.dispatch('fileUploads/resendFileFtp', item);
        } else if (item.type === 'HTTP') {
          this.$store.dispatch('fileUploads/resendFileHttp', item);
        }
      },
      closeResendModal() {
        this.resendModal = false;
        this.fileFromDiskToResend = {
          info: {},
          file: {},
        }
      },
      sendRequestToReupload() {
        let formData = new FormData();
        for (let i = 0; i < Object.keys(this.fileFromDiskToResend.file).length; i++) {
          let key = Object.keys(this.fileFromDiskToResend.file)[i];
          formData.append('file' + i + 'uuid', this.fileFromDiskToResend.info.uuid);
          formData.append('file' + i + 'size', this.fileFromDiskToResend.file[key].size);
          formData.append('file'+ i + 'lastModified', this.fileFromDiskToResend.file[key].lastModified);
          formData.append('file' + i, this.fileFromDiskToResend.file[key]);
        }

        this.$store.dispatch('fileUploads/resendFileDisk', {info: this.fileFromDiskToResend.info, file: formData});
        this.resendModal = false;
        this.fileFromDiskToResend = {
          info: {},
          file: {},
        }
      },
      addFile(file) {
        if (file) {
          this.$set(this.fileFromDiskToResend.file, file.name.replace(/[^(\w|\-|\.)]+/gi, "_"), file);
        }
      },
      deleteUpload() {
        if (this.item.status === "INCOMPLETE") {
          this.sendRequestToAbort();
        } else {
          this.abortModal = true;
        }
      }
    }
  }
</script>

<style lang="scss"
       scoped>
    .iseq-file-progressbar {
        display: flex;
        flex-direction: row;
        width: 100%;

        &__row-title {
            text-align: right;
            width: 20%;
            margin-right: 15px;
            color: var(--dark);
            font-size: 1.2em;
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: context-menu;
        }
        &__progressbar {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: calc(79% - 55px);

            &__description{
                width: 100%;
                display: flex;
                justify-content: center;

                &__text {
                    color: var(--white);
                    white-space: nowrap;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                    font-size: 12px;
                    font-weight: bolder;
                }
            }
        }
        &__value {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 4px;

            &__text {
                color: var(--dark);
                font-size: 1.2em;
                font-weight: 500;
            }
        }
        &__actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 4px;

            &__action {
                cursor: pointer;
            }
        }
    }
</style>