<template>
  <div class="iseq-analysis-demo-details">
    <v-iseq-card
      :title="`Analysis: ${analysis.name}`"
      class="iseq-analysis-demo-details__base-info"
    >
      <template #title-row>
        <div class="status">
          <v-badge
            :content="analysis.status.name"
            :color="checkColorBadge(analysis.status.id)"
          />
        </div>
      </template>

      <div
        v-if="analysis.initiator"
        class="iseq-analysis-demo-details__base-info__dates-row"
      >
        <div class="">
          <v-icon class="icon">
            mdi-account
          </v-icon>
          <span class="thin">Initiator: </span>
          <span class="thick">Intelliseq</span>
        </div>

        <div class="">
          <v-icon class="icon">
            mdi-calendar-month
          </v-icon>
          <span class="thin">Started: </span>
          <span class="thick">{{ analysis.startedDate }}</span>
          <span class="thin"> - Finished: </span>
          <span class="thick">{{ analysis.finishedDate }}</span>
        </div>
      </div>

      <v-divider :inset="false" />

      <div
        v-if="analysis.description"
        class="iseq-analysis-demo-details__base-info__description"
      >
        {{ analysis.description }}
      </div>

      <v-iseq-field :values="descriptionFields" />
    </v-iseq-card>

    <v-iseq-card
      title="Results"
      class="iseq-analysis-demo-details__results"
    >
      <div
        v-if="filesLoadingSpinner"
        class="iseq-analysis-demo-details__results__spinner"
      >
        <v-iseq-loading-spinner text="Loading outputs..." />
      </div>

      <template v-if="analysis.name && analysisFiles.id">
        <v-tree-menu
          :tree="analysisFiles"
          type="analysisDemo"
          :sample-uuid="analysis.sample.uuid"
          :project-uuid="analysis.sample.project.uuid"
          :organization-uuid="analysis.sample.project.organizationUuid"
          :analysis-uuid="analysis.uuid"
          :deletable="false"
          :show-children="true"
        />
      </template>
    </v-iseq-card>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import TreeMenu from '../../components/ui/TreeMenu.vue'
  import IseqField from "../../components/ui/IseqField.vue";
  import analysis from "../../store/modules/analysis";
  import LoadingSpinner from "../../components/ui/LoadingSpinner.vue";
  import IseqCard from "@/components/ui/IseqCard.vue";

  export default {
    name: "AnalysisDemoDetails",
    components: {
      "v-tree-menu": TreeMenu,
      "v-iseq-field": IseqField,
      "v-iseq-loading-spinner": LoadingSpinner,
      "v-iseq-card": IseqCard
    },
    data: function () {
      return {
        filesLoadingSpinner: true
      }
    },
    computed: {
      ...mapState('analysis', ['analysis', 'analysisFiles']),
      descriptionFields() {
        return [
          {
            icon: "mdi-microscope",
            name: "Workflow: ",
            value: this.analysis.workflowName,
            route: "/workflows/" + this.analysis.workflowUuid
          },
          {
            icon: "mdi-folder-multiple",
            name: "Project: ",
            value: this.analysis.sample.project.name
          },
          {
            icon: "mdi-folder-open",
            name: "Sample: ",
            value: this.analysis.sample.name
          }
        ]
      }
    },
    watch: {
      analysisFiles(newFilesTree) {
        this.filesLoadingSpinner = !newFilesTree.id;
      },
    },
    created: function () {
      this.$store.commit('analysis/cleanAllData');
      this.$store.dispatch('analysis/getDemoAnalysis', this.$route.params.uuid);
    },
    methods: {
      checkColorBadge(status) {
        switch (status) {
        case 'SUCCEEDED':
          return 'success';
        case 'FAILED':
          return 'red';
        case 'RUNNING':
          return 'blue';
        case 'ABORTED':
          return 'grey';
        }
      },
    }
  }
</script>
<style lang="scss"
       scoped>

    .iseq-analysis-demo-details {
        &__base-info,
        &__results {
            .status {
              width: 100%;
              margin-top: 2px;
              margin-left: 15px;
            }

            &__dates-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .icon {
                    color: var(--primary-color);
                }

                .thick {
                    color: var(--font-color);
                    font-weight: bold;
                    font-size: 13px;
                }

                .thin {
                    font-size: 14px;
                    color: var(--font-inverted-color);
                }
            }

            &__description {
                padding: 20px 0px 15px;
                margin-left: 2px;
                font-size: 14px;
                color: var(--font-inverted-color);
            }
        }

        &__results {
            &__spinner {
                padding-bottom: 25px;
            }
        }
    }
</style>
