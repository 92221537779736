<template>
  <div class="iseq-top-up-credits">
    <div class="iseq-top-up-credits__content">
      <v-form
        id="amount-form"
        ref="form"
        v-model="valid"
        class="iseq-top-up-credits__content__form"
      >
        <span class="iseq-top-up-credits__content__form__label">Number of credits to top up:</span>

        <v-text-field
          v-model="credits"
          :rules="creditsRules"
          required
          solo
          label="credits"
          class="iseq-top-up-credits__content__form__input"
        />
      </v-form>
    </div>

    <div class="iseq-top-up-credits__summary">
      <span class="iseq-top-up-credits__summary__label">Total cost:</span>
      <span class="iseq-top-up-credits__summary__value">${{ credits }}</span>
    </div>

    <div class="iseq-top-up-credits__declaration">
      Depending on your country, some taxes might be added in the next step.
    </div>

    <v-iseq-btn
      :loading="loadingBtn"
      type="primary"
      class="iseq-top-up-credits__btn"
      @click="validate"
    >
      Next
    </v-iseq-btn>

    <v-iseq-btn
      type="secondary"
      class="iseq-top-up-credits__btn"
      @click="closeDialog"
    >
      Close
    </v-iseq-btn>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import IseqButton from "@/components/ui/IseqButton.vue";

  export default {
    name: 'IseqTopUpCredits',
    components: {
      'v-iseq-btn': IseqButton
    },
    data() {
      return {
        valid: false,
        creditsRules: [
          v => (!isNaN(parseInt(v))) || 'Invalid value',
          v => v > 0 || 'The minimum value is 1',
        ],
      };
    },
    computed: {
      ...mapState('payment', ['loadingBtn']),
      credits: {
        get() {
          return this.$store.getters['payment/credits'];
        },
        set(newValue) {
          this.$store.commit('payment/setCredits', newValue);
        },
      },
    },
    watch: {
      credits(newCredits) {
        const newValue = newCredits.replace('.', '');
        if (newValue.charAt(0) === '0') {
          this.credits = newValue.substring(1);
        }
        this.credits = newValue;
      },
    },
    methods: {
      validate() {
        this.$refs.form.validate();
        if (this.valid) {
          this.$store.dispatch('payment/createPaymentIntent', this.credits);
        }
      },
      closeDialog() {
        this.$store.commit('payment/setPaymentDialog', false);
        this.$store.commit('payment/setClientSecret', '');
        this.$store.commit('payment/setCredits', '100');
        this.$store.commit('payment/setIntelliseqTransactionToken', '');
        this.$store.commit('payment/setStatusPayment', {});
        this.$store.commit('payment/setPaymentSteps', 1);
      },
    },
  };
</script>

<style lang="scss">
.iseq-top-up-credits {
  &__content {
    &__form {
      .v-input {
        &__control {
          min-height: 32px !important;

          .v-input__slot {
            font-size: 14px;
            box-shadow: 0 1px 3px 0 #E6EBF1 !important;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss"
       scoped>

.iseq-top-up-credits {
  &__content {
    &__form {
      margin-top: 12px;
      display: flex;
      align-items: center;

      &__label {
        display: flex;
        justify-content: flex-end;
        width: 50%;
        height: 63px;
        font-size: 18px;
        margin-right: 16px;
      }

      &__input {
        display: flex;
        justify-content: flex-start;
        width: 50%;
        max-width: 100px;
      }
    }
  }

  &__summary {
    display: flex;
    font-size: 16px;

    &__label {
      display: flex;
      justify-content: flex-end;
      width: 50%;
      margin-right: 16px;
    }

    &__value {
      display: flex;
      justify-content: flex-start;
      width: 50%;
      width: 120px;
    }
  }

  &__declaration {
    margin: 24px 0 32px 12px;
    font-size: 12px;
  }

  &__btn {
    margin-top: 16px;
    width: 100%;
    font-size: 16px !important;
  }
}
</style>
