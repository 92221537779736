<template>
  <div>
    <template v-for="index in values.length">
      <template v-if="(index-1)%2 === 0">
        <div
          :key="index"
          class="iseq-field"
        >
          <div
            :class="{'iseq-field__item': true, 'iseq-field__item--linkable': values[index - 1].route}"
            @click="routeTo(values[index-1].route)"
          >
            <v-icon class="icon">
              {{ values[index - 1].icon }}
            </v-icon>
            <span class="name">{{ values[index - 1].name }}</span>
            <span class="value">{{ values[index - 1].value }}</span>
          </div>

          <template v-if="index < values.length">
            <div
              class="iseq-field__item"
              @click="routeTo(values[index].route)"
            >
              <v-icon class="icon">
                {{ values[index].icon }}
              </v-icon>
              <span class="name">{{ values[index].name }}</span>
              <span class="value">{{ values[index].value }}</span>
            </div>
          </template>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
  export default {
    name: "IseqField",
    props: ['values'],
    methods: {
      routeTo(route) {
        if (route) {
          this.$router.push({path: route});
        }
      }
    }
  }
</script>

<style lang="scss"
       scoped>
.iseq-field {
  display: flex;
  flex-direction: row;

  &__item {
    width: calc(50% - 6px);
    margin: 5px 3px 5px 3px;
    padding: 12px;
    border-radius: 5px;
    background: var(--light-2);
    font-size: 14px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--light);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--linkable {
      &:hover {
        cursor: pointer;
        background: #f0f1f5;
      }
    }

    .icon {
      font-size: 18px;
      color: var(--active);
      margin-right: 5px;
    }

    .name {
      color: var(--font-inverted-color);
    }

    .value {
      color: var(--font-color);

      font-weight: bold;
      margin-left: 10px;
    }
  }
}
</style>