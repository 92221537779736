import { render, staticRenderFns } from "./ProjectDetails.view.vue?vue&type=template&id=518d5636&scoped=true&"
import script from "./ProjectDetails.view.vue?vue&type=script&lang=js&"
export * from "./ProjectDetails.view.vue?vue&type=script&lang=js&"
import style0 from "./ProjectDetails.view.vue?vue&type=style&index=0&id=518d5636&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "518d5636",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBadge,VDataTable,VDivider,VForm,VIcon,VSelect,VTab,VTabItem,VTabs,VTabsItems,VTextField,VTextarea,VTooltip})
