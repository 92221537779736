<template>
  <div class="iseq-profile">
    <profile-settings />
  </div>
</template>

<script>
  import ProfileSettings from './components/Profle-settings.component.vue'

  export default {
    name: "Profile",
    components: {
      ProfileSettings,
    }
  }
</script>
<style lang="scss"
       scoped>
</style>