<template>
  <div class="iseq-profile-settings">
    <v-iseq-card
      title="Account settings"
      class="iseq-profile-settings__account-settings"
    >
      <div class="iseq-profile-settings__account-settings__user-details">
        <div class="iseq-profile-settings__account-settings__user-details__info">
          <div class="iseq-profile-settings__account-settings__user-details__info__row">
            <div class="iseq-profile-settings__account-settings__user-details__info__row__display-box">
              <v-text-field
                v-model="user.email"
                readonly
                class="iseq-text-field"
                label="Email"
              />
            </div>

            <v-iseq-btn
              large
              width="180px"
              post-icon="mdi-key-variant"
              @click="passwordChangeDialog = true"
            >
              Change password
            </v-iseq-btn>
          </div>

          <div class="iseq-profile-settings__account-settings__user-details__info__row">
            <div class="iseq-profile-settings__account-settings__user-details__info__row__display-box">
              <v-form v-model="isNameFormValid">
                <v-text-field
                  v-model="name"
                  outlined
                  :rules="nameRules"
                  counter="50"
                  class="name-input"
                  label="Name"
                />
              </v-form>
            </div>

            <v-iseq-btn
              large
              width="180px"
              post-icon="mdi-rename-box"
              :disabled="!isNameFormValid"
              @click="changeUserData"
            >
              Save changes
            </v-iseq-btn>
          </div>

          <div class="iseq-profile-settings__account-settings__user-details__info__row">
            <div class="iseq-profile-settings__account-settings__user-details__info__row__display-box">
              <span>
                <span class="thick">Protect your account with Two-Factor Authentication</span> <span
                  :class="preferredMFA === 'NOMFA' ? 'grey-text' : 'green-text'"
                >({{
                  `${preferredMFA === "NOMFA" ? "OFF" : "ON"}`
                }})</span> <br>
                Prevent unauthorized access to your account by requiring additional SMS code when signing in.
              </span>
            </div>

            <div class="button-text-block">
              <v-iseq-btn
                large
                width="180px"
                post-icon="mdi-key-variant"
                @click="authenticationModal = true"
              >
                {{ `${preferredMFA === "NOMFA" ? "Activate " : "Deactivate "}` }} 2FA
              </v-iseq-btn>

              <div
                v-if="isUserPhoneNumberDefined"
                class="button-text-block__text"
              >
                <span
                  class="button-text-block__text__text-style"
                  @click="forceChangePhone=true; authenticationModal=true"
                >
                  Change your phone number
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-iseq-card>

    <v-iseq-2fa
      v-if="authenticationModal"
      :force-change-phone-number="forceChangePhone"
      @closed="authenticationModalClosed()"
    />

    <iseq-confirmation-modal
      v-if="passwordChangeDialog"
      width="400px"
      header="Change password"
      confirm-button-type="primary"
      confirm-button-text="Change"
      reject-button-type="secondary"
      reject-button-text="Close"
      @actionRejected="passwordChangeDialog = false"
      @actionConfirmed="changePassword"
    >
      <div class="password-change-form">
        <span class="password-change-form__fieldname">
          Old password
        </span>

        <v-text-field
          v-model="oldPassword"
          outlined
          hide-details
          type="password"
          class="input"
          label="Old password"
          @focusin="previousPasswordError=false"
        />

        <div
          v-if="previousPasswordError"
          class="password-change-form__input-error"
        >
          <span class="password-change-form__input-error__text">Incorrect password</span>
        </div>

        <span class="password-change-form__fieldname">
          New password

          <v-tooltip
            class="tooltip--right"
            right
          >
            <template #activator="{ on }">
              <v-icon
                class="tooltip__icon"
                size="20px"
                color="primary"
                v-on="on"
              >
                mdi-help-circle
              </v-icon>
            </template>

            <span>Password must:<br>
              -be at least 12 characters long,<br>
              -contain at least 1 digit,<br>
              -contain at least 1 capital letter,<br>
              -contain at least 1 small letter
            </span>
          </v-tooltip>
        </span>

        <v-text-field
          v-model="newPassword"
          outlined
          hide-details
          type="password"
          class="input"
          label="New password"
          @focusout="passwordGiven = true"
        />

        <div
          v-if="passwordGiven && passwordStructureError"
          class="password-change-form__input-error"
        >
          <span class="password-change-form__input-error__text">{{ passwordStructureError }}</span>
        </div>

        <span class="password-change-form__fieldname">
          Confirm new password
        </span>

        <v-text-field
          v-model="confirmNewPassword"
          outlined
          hide-details
          type="password"
          class="input"
          label="Confirm new password"
          @focusout="validateConfirmPassword"
        />

        <div
          v-if="passwordDifference"
          class="password-change-form__input-error"
        >
          <span class="password-change-form__input-error__text">Passwords don't match</span>
        </div>
      </div>
    </iseq-confirmation-modal>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import {Auth} from 'aws-amplify'
  import twoFactorAuthenticationModal from "@/views/Profile/components/twoFactorAuthenticationModal";
  import {passwordStructureError} from "@/helpers/credentialsHelper";
  import IseqConfirmationModal from "@/components/modals/IseqConfirmationModal.vue";
  import IseqCard from "@/components/ui/IseqCard.vue";
  import IseqButton from "@/components/ui/IseqButton.vue";

  export default {
    name: 'ProfileSettings',
    components: {
      IseqConfirmationModal,
      "v-iseq-2fa": twoFactorAuthenticationModal,
      "v-iseq-card": IseqCard,
      "v-iseq-btn": IseqButton,
    },
    data: function () {
      return {
        valid: true,
        name: "",
        preferredMFA: undefined,
        attributes: undefined,
        passwordChangeDialog: false,
        authenticationModal: false,
        forceChangePhone: false,
        passwordGiven: false,
        passwordDifference: false,
        previousPasswordError: false,
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        passwordChanging: false,
        errList: [],
        nameRules: [v => (!!v && v.length <= 50) || 'Name cannot be longer than 50 characters'],
        isNameFormValid: true,
      }
    },
    computed: {
      ...mapState('user', ['user']),
      passwordStructureError() {
        return passwordStructureError(this.newPassword);
      },
      isUserPhoneNumberDefined() {
        if (this.attributes) {
          let phoneNumber = this.attributes.find(attribute => attribute.Name === "phone_number");
          return !!phoneNumber;
        } else {
          return false;
        }
      },
      username() {
        return this.user.name;
      }
    },
    watch: {
      username(newName) {
        this.name = newName;
      }
    },
    created() {
      this.getUser().then(response => {
        this.getPreferredMFA(response);
        this.getUserAttributes(response);
      });
      this.name = this.username;
    },
    methods: {
      changeUserData() {
        this.$store.dispatch('user/setData', this.name);
      },
      async changePassword() {
        const user = await Auth.currentAuthenticatedUser();

        if (this.passwordGiven && !this.passwordStructureError && this.validateConfirmPassword()) {
          try {
            await Auth.changePassword(user, this.oldPassword, this.newPassword);
            await this.$store.dispatch('snackbarQueue/addMessage', {
              message: "Password has been changed",
              type: "info"
            });
            this.oldPassword = '';
            this.newPassword = '';
            this.confirmNewPassword = '';
            this.passwordChangeDialog = false;
          } catch (e) {
            console.log(e)
            if (e.message.includes("previousPassword") || e.code === "NotAuthorizedException") {
              this.previousPasswordError = true;
            } else {
              await this.$store.dispatch('snackbarQueue/addMessage', {message: e.message, type: "error"})
            }
          }
        }
      },
      async getUser() {
        try {
          return await Auth.currentAuthenticatedUser();
        } catch (err) {
          throw new Error(err)
        }
      },
      async getPreferredMFA(user) {
        this.preferredMFA = await Auth.getPreferredMFA(user);
      },
      async getUserAttributes(user) {
        this.attributes = await Auth.userAttributes(user);
      },
      authenticationModalClosed() {
        this.authenticationModal = false;
        this.forceChangePhone = false;
        this.getUser().then(response => this.getPreferredMFA(response));
      },
      validateConfirmPassword() {
        this.passwordDifference = false;
        if (this.newPassword !== this.confirmNewPassword) {
          this.passwordDifference = true;
          return false
        } else return true
      }
    }
  }
</script>
<style lang="scss"
       scoped>
.password-change-form {
  &__fieldname {
    font-size: 1.3em;
    color: var(--font-color);
  }

  .input {
    margin-bottom: 10px;
  }

  &__input-error {
    margin-top: -5px;
    margin-bottom: 10px;

    &__text {
      color: var(--danger-color);
      font-size: 1.0em;
      font-weight: 700;
      white-space: pre-wrap;
    }
  }
}

.iseq-profile-settings {
  &__account-settings {
    &__user-details {
      margin-left: 20px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      &__info {
        display: flex;
        flex-direction: column;

        &__row {
          display: flex;
          width: 600px;
          margin-bottom: 25px;
          align-items: center;

          &__display-box {
            width: 400px;

            .thick {
              font-weight: bold;
            }

            .grey-text {
              color: var(--font-inverted-color);
            }

            .green-text {
              color: var(-success-color);
            }

            .name-input {
              margin-bottom: -30px;
            }
          }

          .iseq-btn {
            margin-left: 20px;
            height: 45px;
          }

          .button-text-block {
            display: flex;
            flex-direction: column;
            align-items: center;

            .iseq-btn {
              margin-top: 5px;
              margin-left: 32px;
              width: 180px;
              height: 45px;
            }

            &__text {
              margin-left: 32px;
              margin-top: 5px;

              &__text-style {
                text-decoration: underline;
                color: var(--font-inverted-color);
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
</style>