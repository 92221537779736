<template>
  <div class="iseq-organization-list">
    <v-iseq-card
      title="Organizations"
      no-bottom-margin
      class="iseq-organization-list__content"
    >
      <v-data-table
        :headers="tableColumns"
        :items="content"
        :server-items-length="totalElements"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions
        }"
        class="iseq-organization-list__content__table"
        @click:row="goToOrganizationDetail"
      >
        <template #[`item.name`]="{ item }">
          <router-link :to="`/organizations/${item.uuid}`">
            <div class="iseq-organization-list__content__field">
              {{ item.name }}
            </div>
          </router-link>
        </template>

        <template #[`item.role`]="{ item }">
          <router-link :to="`/organizations/${item.uuid}`">
            <div class="iseq-organization-list__content__field">
              {{ roleReadable(item.role) }}
            </div>
          </router-link>
        </template>

        <template #[`item.credits`]="{ item }">
          <router-link :to="`/organizations/${item.uuid}`">
            <div class="iseq-organization-list__content__field">
              {{ item.credits }}
            </div>
          </router-link>
        </template>
      </v-data-table>
    </v-iseq-card>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import {pagingDataParse, roleToHR} from "@/plugins/utils"
  import IseqCard from "@/components/ui/IseqCard.vue";

  export default {
    name: 'OrganizationList',
    components: {
      "v-iseq-card": IseqCard
    },
    data: function () {
      return {
        options: {},
        itemsPerPageOptions: [10, 20, 30, -1],
        tableColumns: [
          {text: 'Organization', align: 'start', value: 'name'},
          {text: 'Role', value: 'role'},
          {text: 'Credits', value: 'credits'}
        ]
      }
    },
    computed: {
      ...mapState('organization', ['organizations', 'content', 'totalElements']),
    },
    watch: {
      options: {
        handler() {
          this.$store.dispatch("organization/getOrganizationsList", {
            data: pagingDataParse({
              sortDesc: this.options.sortDesc,
              sortBy: this.options.sortBy,
              itemsPerPage: this.options.itemsPerPage,
              totalElements: this.totalElements,
              page: this.options.page
            })
          });
        },
        deep: true,
      },
    },
    created: function () {
      this.$store.dispatch('organization/getOrganizationsList', {});
    },
    methods: {
      roleReadable(name) {
        return roleToHR(name);
      },
      checkDirection(direction) {
        switch (direction) {
        case true:
          return 'DESC';
        case false:
          return 'ASC';
        default:
          return ''
        }
      },
      goToOrganizationDetail(event) {
        this.$router.push({path: `/organizations/${event.uuid}`});
      }
    }
  }
</script>

<style scoped
       lang="scss">

.iseq-organization-list {
  &__content {
    &__field {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}
</style>