var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"iseq-dashboard-notification"},[_c('v-iseq-card',{staticClass:"iseq-dashboard-notification__card",attrs:{"title":"Notifications","no-bottom-margin":""},scopedSlots:_vm._u([{key:"post-title",fn:function(){return [(_vm.unreadNotificationsLength())?_c('v-badge',{attrs:{"content":_vm.unreadNotificationsLength(),"color":"danger","offset-x":"0","offset-y":"-4"}}):_vm._e()]},proxy:true},{key:"title-row",fn:function(){return [(_vm.unreadNotificationsLength()!==0)?_c('span',{staticClass:"iseq-btn__label",on:{"click":function($event){$event.preventDefault();return _vm.readAllNotifications($event)}}},[_vm._v("Mark all as read")]):_vm._e(),(_vm.unreadNotificationsLength()===0 && _vm.notifications.length)?_c('span',{staticClass:"iseq-btn__label iseq-btn__label--delete",on:{"click":function($event){$event.preventDefault();return _vm.deleteAllNotification($event)}}},[_vm._v("Delete All")]):_vm._e()]},proxy:true}])},[(!_vm.notifications.length)?_c('v-iseq-banner',{attrs:{"type":"info-light","value":"You don't have any awaiting notifications"}}):_c('v-data-table',{staticClass:"iseq-dashboard-notification__card__table",attrs:{"items-per-page":5,"footer-props":{
        'items-per-page-options': _vm.itemsPerPageOptions
      },"headers":_vm.notificationColumns,"items":_vm.notifications,"custom-sort":_vm.customSort},on:{"click:row":_vm.readNotification},scopedSlots:_vm._u([{key:"item.customMessage",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"iseq-dashboard-notification__card__table__notification",class:{unread: !item.read}},[(item.target.type !== 'USER')?_c('span',[_c('span',{staticClass:"value"},[_vm._v(_vm._s(("" + (item.target.type[0]) + (item.target.type.toLowerCase().slice(1, item.target.type.length)))))]),(_vm.messageHasPhrase(item, 'has been deleted'))?_c('span',{staticClass:"value"},[_vm._v(_vm._s(item.target.name))]):_c('span',{staticClass:"value value--link",on:{"click":function($event){return _vm.goToTarget(item)}}},[_vm._v(_vm._s(item.target.name))])]):_vm._e(),_c('span',{staticClass:"value"},[_vm._v(_vm._s(item.message))]),(item.target.type === 'USER')?_c('span',[(_vm.messageHasPhrase(item, 'removed'))?_c('span',{staticClass:"value"},[_vm._v(_vm._s(item.target.name))]):_c('span',{staticClass:"value value--link",on:{"click":function($event){return _vm.goToTarget(item)}}},[_vm._v(_vm._s(item.target.name))])]):_vm._e(),(_vm.messageHasPhrase(item, 'will be deleted in'))?_c('span',[_c('span',{staticClass:"value value--link"},[_c('router-link',{attrs:{"to":"/FAQ?section=SampleStorage"}},[_vm._v("Read the FAQ")])],1),_c('span',{staticClass:"value"},[_vm._v("to find out how to extend its validity.")])]):_vm._e()])]}},{key:"item.creationDate",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.calculateDifference(item.creationDate))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.creationDate)+" ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }