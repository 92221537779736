<template>
  <div class="iseq-sample-create">
    <v-iseq-insufficient-credits
      type="sampleCreate"
      :price="price"
    />

    <v-iseq-card
      title="Create sample"
      class="iseq-sample-create__base-info"
    >
      <div class="iseq-sample-create__base-info__container">
        <v-form
          ref="nameForm"
          v-model="isNameFormValid"
          class="iseq-sample-create__base-info__container__text-field"
        >
          <v-text-field
            id="NewSampleName"
            v-model="name"
            outlined
            counter="50"
            placeholder="Please, provide unique sample name"
            label="Sample name"
            :rules="nameRules"
          />
        </v-form>


        <div class="iseq-sample-create__base-info__container__select">
          <v-text-field
            outlined
            readonly
            label="Validity"
            value="1 month"
            class=""
          />
        </div>
      </div>

      <div class="iseq-sample-create__base-info__container">
        <v-textarea
          v-model="description"
          outlined
          auto-grow
          hide-details
          name="input-3-1"
          label="Description"
          class="iseq-sample-create__base-info__container__text-area"
        />
      </div>
    </v-iseq-card>

    <v-iseq-card
      title="Properties"
      class="iseq-sample-create__properties"
    >
      <v-divider class="mb-8" />

      <v-iseq-properties
        :properties="properties"
        @addProperty="addProperty"
        @deleteProperty="deleteProperty"
      />
    </v-iseq-card>

    <v-iseq-card
      title="Upload Files"
      tabs-only
      class="iseq-sample-create__files-tabs"
    >
      <template #title-row>
        <div class="iseq-sample-create__files-tabs__container">
          <v-tabs
            v-model="tab"
            height="64px"
            color="primary"
          >
            <v-tab
              key="Drive"
              class="iseq-sample-create__files-tabs__container__tab"
            >
              <v-icon class="iseq-sample-create__files-tabs__container__tab__icon">
                mdi-account-multiple
              </v-icon>
              From your computer
              <v-badge
                v-if="uploadErrors.disk.length"
                content="!"
                color="danger"
                offset-x="-5"
                offset-y="4"
              />
            </v-tab>

            <v-tab
              key="Url"
              class="iseq-sample-create__files-tabs__container__tab"
            >
              <v-icon class="iseq-sample-create__files-tabs__container__tab__icon">
                mdi-folder
              </v-icon>
              From URL
              <v-badge
                v-if="uploadErrors.url.length"
                content="!"
                color="danger"
                offset-x="-5"
                offset-y="4"
              />
            </v-tab>

            <!--                    <v-tab key="Ftp"-->
            <!--                           class="iseq-sample-create__files-tabs__container__tab">-->

            <!--                        <v-icon class="iseq-sample-create__files-tabs__container__tab__icon">mdi-folder</v-icon>-->
            <!--                        From FTP-->
            <!--                        <v-badge v-if="uploadErrors.ftp.length"-->
            <!--                                 content="!"-->
            <!--                                 color="danger"-->
            <!--                                 offset-x="-5"-->
            <!--                                 offset-y="4"/>-->
            <!--                    </v-tab>-->
          </v-tabs>
        </div>
      </template>
    </v-iseq-card>

    <v-iseq-card
      no-title
      no-bottom-margin
      class="iseq-sample-create__files"
    >
      <div class="iseq-sample-create__files__files-upload">
        <v-iseq-files-upload
          :urls-to-upload.sync="urls"
          :files-to-upload.sync="files"
          :ftp.sync="ftp"
          :existing-sample="false"
          :upload-errors.sync="uploadErrors"
          :upload-tab="tab"
        />
      </div>

      <div class="iseq-sample-create__files__extensions">
        Allowed files extensions: {{ workflowInputExtensionsLong }}
      </div>

      <div class="iseq-sample-create__files__info">
        <v-icon
          color="primary"
          class="iseq-sample-create__files__info__icon"
        >
          mdi-alert-circle
        </v-icon>

        <div class="iseq-sample-create__files__info__text">
          Currently implemented workflows work only on files in GRCh38/hg38 coordinates (refer to vcf, vcf.gz, gvcf,
          gvcf.gz, bam, bed, bed.gz files).<br>
          To liftover bed/bed.gz files from GRCh37(hg19) to GRCh38(hg38) use
          <router-link to="/workflows/75ba8cd6-9ece-33d0-9e08-93f2055f751a">
            <u>"Liftover tool for .bed files [GRCh37(hg19) to GRCh38(hg38)]"</u>
          </router-link>
          workflow available in "Workflows" at the navigation menu on the left.
        </div>
      </div>
    </v-iseq-card>

    <v-iseq-card
      no-title
      no-bottom-margin
      class="iseq-sample-create__footer"
    >
      <template #title-row>
        <div class="iseq-sample-create__footer__button-row">
          <v-tooltip
            :disabled="!createSampleDisabledReasons.length"
            top
          >
            <template #activator="{on, attrs}">
              <div
                v-bind="attrs"
                v-on="on"
              >
                <v-iseq-btn
                  large
                  width="wide"
                  :disabled="!!createSampleDisabledReasons.length"
                  @click="createSample()"
                >
                  Create sample
                </v-iseq-btn>
              </div>
            </template>
            <span>{{ createSampleDisabledReasons }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-iseq-card>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import {permissionCheck} from "../../plugins/permissions";
  import IseqInsufficientCreditsCard from "../../components/ui/IseqInsufficientCreditsCard.vue";
  import Properties from "./components/Properties.component"
  import FilesUpload from "./components/FilesUpload.component"
  import IseqCard from "@/components/ui/IseqCard.vue";
  import IseqButton from "@/components/ui/IseqButton.vue";

  export default {
    name: "SampleCreate",
    components: {
      "v-iseq-insufficient-credits": IseqInsufficientCreditsCard,
      "v-iseq-properties": Properties,
      "v-iseq-files-upload": FilesUpload,
      "v-iseq-card": IseqCard,
      "v-iseq-btn": IseqButton
    },
    beforeRouteLeave(to, from, next) {
      if (!to.name.includes('sample')) {
        this.$store.commit('sample/clearSamplesTableOptions');
        next();
      } else {
        next();
      }
    },
    data: function () {
      return {
        price: 1,
        tab: 0,
        name: "",
        description: "",
        files: {},
        urls: {},
        properties: [],
        isNameFormValid: false,
        nameRules: [
          v => (!!v && v.length > 0) || "Name cannot be empty",
          v => v.length <= 50 || "Name cannot be longer than 50 characters",
          v => !this.samplesMinimal.some(element => element.name === v) || "Sample named " + v + " already exists in this project",
          v => v.match(/^[A-Za-z0-9_-]+$/g) || "Name can contain only letters, numbers, underscores and '-' characters"
        ],
        ftp: {
          filename: '',
          password: '',
          url: '',
          username: '',
        },
        uploadErrors: {
          disk: [],
          url: [],
          ftp: []
        }
      }
    },
    computed: {
      ...mapState('user', ['userCredits']),
      ...mapState('sample', ['sample', 'samplesMinimal']),
      ...mapState('organization', ['organization', 'organizations']),
      ...mapState('project', ['project']),
      ...mapState('workflow', ['workflowInputExtensionsLong']),
      areUploadErrorsEmpty() {
        let errors = 0;
        Object.keys(this.uploadErrors).map(type => errors += this.uploadErrors[type].length);
        return !errors;
      },
      numberOfFilesToUpload() {
        return Object.keys(this.files).length + Object.keys(this.urls).length;
      },
      createSampleDisabledReasons() {
        if (!this.isNameFormValid) {
          return "Sample name is invalid";
        } else if (!this.areUploadErrorsEmpty) {
          return "There are errors in files upload section";
        } else if (this.numberOfFilesToUpload === 0) {
          return "You have to upload at least 1 file to your sample";
        } else if (this.userCredits < this.price) {
          return "Your account balance is too low";
        } else {
          return "";
        }
      }
    },
    watch: {
      project(newProject) {
        if (!newProject.uuid) {
          this.$router.push('/');
        } else if (!permissionCheck('sampleAdd')) {
          this.$router.push('/samples');
        }
      },
      organization() {
        this.$store.dispatch('user/getCredits');
      },
    },
    created: function () {
      this.$store.dispatch('user/getCredits');
      this.$log.debug("vue.sample.created");
      this.$store.dispatch('sample/getSampleListMinimal');
      this.$store.dispatch('workflow/getInputExtensions');
    },
    methods: {
      deleteProperty(key) {
        const indexOfElement = this.properties.findIndex((element) => {
          if (element.key === key) {
            return element
          }
        });
        if (indexOfElement !== -1) {
          this.properties.splice(indexOfElement, 1);
        }
      },

      addProperty(payload) {
        this.deleteProperty(payload.key)
        this.properties.push({key: payload.key, value: payload.value});
      },

      createSample() {
        if (this.isNameFormValid) {
          const newSampleObject = {
            uuid: this.project.uuid,
            files: this.files,
            urls: this.urls,
            ftp: this.ftp,
            data: {
              description: this.description,
              name: this.name,
              properties: this.properties
            }
          };
          this.$store.dispatch("sample/createSample", newSampleObject);
        } else {
          this.$vuetify.goTo(document.getElementById("NewSampleName"), {offset: 300});
        }
      }
    },
  }
</script>
<style lang="scss"
       scoped>

.iseq-sample-create {
  &__base-info,
  &__properties,
  &__files,
  &__attachments,
  &__footer {
    &__container {
      margin: 0 16px;

      &__text-field {
        margin-right: 16px;
      }

      &__btn {
        margin: 4px 0;

        &__icon {
          margin: 0 8px;
        }
      }
    }
  }

  &__base-info {
    &__container {
      display: flex;

      &__select {
        width: 50%;
        margin-left: 16px;
      }

      &__text-field {
        width: 50%;
      }
    }
  }

  &__files-tabs {
    &__container {
      &__tab {
        padding: 0 26px;

        &__icon {
          margin-right: 16px;
        }
      }
    }
  }

  &__files {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;

    &__files-upload {
      margin: 10px;
    }

    &__extensions {
      margin-left: 43px;
      color: var(--font-inverted-color);
      margin-top: -4px;
    }

    &__info {
      margin: 30px 10px 10px 43px;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid var(--tertiary-color);
      background: var(--light-2);
      display: flex;
      flex-direction: row;

      &__icon {
        align-content: center;
        margin-right: 10px;
      }

      &__text {
        font-weight: bold;
      }
    }
  }

  &__footer {
    &__button-row {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__dialog__info {
    text-align: center;
  }
}
</style>