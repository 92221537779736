var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('div',{staticClass:"iseq-genes-verification-modal"},[_c('div',{staticClass:"iseq-genes-verification-modal__header"},[_c('span',{staticClass:"iseq-genes-verification-modal__header__title"},[_vm._v("Genes selected for the analysis")])]),_c('div',{staticClass:"iseq-genes-verification-modal__message"},[_c('p',[_vm._v("Below is the list of genes with phenotype match (score, %) generated from the Scope of analysis inputs.")]),_c('p',[_vm._v("To add more genes go back to the \"Create new analysis\" view by clicking \"Cancel\" button.")])]),_c('div',{staticClass:"iseq-genes-verification-modal__filter"},[_c('v-text-field',{staticClass:"iseq-genes-verification-modal__filter__text-field",attrs:{"outlined":"","hide-details":"","label":"Search","placeholder":"Provide gene name"},model:{value:(_vm.nameFilter),callback:function ($$v) {_vm.nameFilter=$$v},expression:"nameFilter"}}),_c('v-icon',{staticClass:"iseq-genes-verification-modal__filter__icon",attrs:{"color":"primary","size":"32px"}},[_vm._v(" mdi-magnify ")])],1),_c('div',{staticClass:"iseq-genes-verification-modal__genes-box"},[_c('v-data-table',{attrs:{"footer-props":{
          'items-per-page-options': _vm.itemsPerPageOptions
        },"items-per-page":100,"headers":_vm.headers,"items":_vm.filteredGenes,"show-select":"","fixed-header":"","height":"291px","checkbox-color":"primary","item-key":"name"},on:{"click:row":_vm.toggleGeneValue},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){return undefined}},{key:"header.score",fn:function(ref){return [_vm._v(" Score "),_c('v-tooltip',{attrs:{"top":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mb-1",attrs:{"color":"primary"}},on),[_vm._v(" mdi-help-circle ")])]}}],null,true)},[_c('span',[_vm._v(" Phenotype match (score) - percent of a maximum possible score that is attributed to a gene. For each HPO term and/or phenotype description provided by a user, the algorithm traverses the phenotype ontology tree searching for a match with each gene and assigning a score based on the distance between the original term and the term that matches the gene. For specific genes provided by a user, the phenotype match is assigned to be 75%. For specific diseases provided by a user, the phenotype match is assigned to be 50%. For genes from the gene panel, phenotype match is assigned to be 30%. ")])])]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('span',{class:['iseq-genes-verification-modal__genes-box__entry__text', _vm.selected.includes(item) ? '' : 'iseq-genes-verification-modal__genes-box__entry__text--excluded']},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.score",fn:function(ref){
        var item = ref.item;
return [_c('div',{class:[_vm.selected.includes(item) ? 'iseq-genes-verification-modal__genes-box__entry__score' : 'iseq-genes-verification-modal__genes-box__entry__score-excluded']},[_c('span',{staticClass:"score-text"},[_vm._v(_vm._s(parseFloat(item.score).toFixed(2))+"%")])])]}},{key:"item.type",fn:function(ref){
        var item = ref.item;
return [_c('span',{class:['iseq-genes-verification-modal__genes-box__entry__source', _vm.selected.includes(item) ? '' : 'iseq-genes-verification-modal__genes-box__entry__source--excluded']},[_vm._v(" "+_vm._s(item.type)+" ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('div',{staticClass:"iseq-genes-verification-modal__buttons"},[_c('v-iseq-btn',{attrs:{"type":"secondary"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Cancel ")]),_c('v-tooltip',{attrs:{"top":"","disabled":_vm.selected.length > 0},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-iseq-btn',{attrs:{"ml":"","disabled":_vm.selected.length === 0},on:{"click":_vm.accept}},[_vm._v(" Accept ")])],1)]}}])},[_c('span',[_vm._v("At least one gene from the list must be selected")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }