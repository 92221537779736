<template>
  <div class="analysis-progressbar-list">
    <div
      v-for="item in shortenedItemsList"
      :key="item.uuid"
    >
      <div class="analysis-progressbar-list__container">
        <v-tooltip right>
          <template #activator="{ on }">
            <span
              class="analysis-progressbar-list__container__title"
              v-on="on"
            >
              <router-link :to="`/analyses/${item.uuid}`">{{ item.name }}</router-link>
            </span>
          </template>

          <span>{{ item.name }}</span>
        </v-tooltip>

        <div class="progressbar">
          <v-progress-linear
            :rounded="true"
            :value="calculateProgressState(item.doneTask, item.totalTask)"
            height="16"
            :color="checkColorProgressBar(item.doneTask, item.totalTask)"
          >
            <template #default="{}">
              <strong class="progressbar"> Processing... ({{ item.doneTask }} /
                {{ item.totalTask }} tasks) </strong>
            </template>
          </v-progress-linear>
        </div>
      </div>
    </div>

    <div
      v-show="numberOfEntries < items.length"
      class="analysis-progressbar-list__footer"
    >
      <span
        class="analysis-progressbar-list__footer__text"
        @click="showMore()"
      >

        Show more analyses
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    name: "AnalysisProgressbarList",
    props: {
      items: {
        type: Array,
        default: () => []
      }
    },
    data: function() {
      return {
        numberOfEntries: 0,
        additionStep: 5
      }
    },
    computed: {
      shortenedItemsList() {
        return this.items.slice(0, this.numberOfEntries);
      }
    },
    created: function() {
      if (this.items.length < this.additionStep) {
        this.numberOfEntries = this.items.length;
      } else {
        this.numberOfEntries = this.additionStep;
      }
    },
    methods: {
      calculateProgressState(done, total) {
        return (done / total) * 100;
      },
      checkColorProgressBar(done, total) {
        return ((done / total) === 1) ? 'success' : 'dark';
      },
      showMore() {
        if (this.numberOfEntries + this.additionStep > this.items.length) {
          this.numberOfEntries = this.items.length;
        } else {
          this.numberOfEntries += this.additionStep;
        }
      }
    }
  }
</script>

<style lang="scss"
       scoped>
    .analysis-progressbar-list {
        &__container {
            display: flex;
            align-items: center;

            &__title {
                display: block;
                text-align: right;
                width: 30%;
                margin-right: 15px;
                color: var(--dark);
                font-weight: 500;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                cursor: pointer;
                font-size: 14px;
            }

            .progressbar {
                width: 80%;
                text-align: center;
                color: var(--white);
            }
        }

        &__footer {
            margin-top: 20px;
            display: flex;
            justify-content: center;

            &__text {
                color: var(--dark);
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
</style>