import { render, staticRenderFns } from "./Profle-settings.component.vue?vue&type=template&id=195dbc06&scoped=true&"
import script from "./Profle-settings.component.vue?vue&type=script&lang=js&"
export * from "./Profle-settings.component.vue?vue&type=script&lang=js&"
import style0 from "./Profle-settings.component.vue?vue&type=style&index=0&id=195dbc06&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "195dbc06",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VForm,VIcon,VTextField,VTooltip})
