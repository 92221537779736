<template>
  <v-app :style="cssProps">
    <div class="iseq-app">
      <template v-if="windowWidth < 920">
        <div class="iseq-app__small-display">
          <div class="iseq-app__small-display__logo">
            <img
              alt="Logo"
              :src="pathToLogoFile.dark"
            >
          </div>

          <span class="iseq-app__small-display__message">
            This app doesn’t support mobile devices yet.<br>
            Please, use a desktop browser to open the app.
          </span>
        </div>
      </template>

      <template v-else>
        <v-iseq-top-bar
          v-if="isTopBarVisible"
          class="iseq-app__topbar"
        />

        <div
          v-if="
            !$route.path.includes('/login') &&
              !$route.path.includes('/sign-up') &&
              !$route.path.includes('/forgot-password')
          "
          :class="[
            'iseq-app__nav-bar',
            isTopBarVisible ? 'iseq-app__nav-bar--with-topbar' : '',
          ]"
        >
          <div
            :class="[
              'iseq-app__nav-bar__logo',
              navBarUserInfo.email ? '' : 'iseq-app__nav-bar__logo--wide',
            ]"
          >
            <router-link :to="navBarUserInfo.email ? '/' : '/login'">
              <v-img
                alt="Logo"
                contain
                :src="pathToLogoFile.light"
                transition="scale-transition"
                height="45px"
              />
            </router-link>
          </div>

          <div
            v-if="navBarUserInfo.email"
            class="iseq-app__nav-bar__content shadow"
          >
            <v-iseq-top-toolbar />
          </div>
        </div>

        <iseq-payment-modal />

        <div class="iseq-app__main">
          <div
            v-if="navBarUserInfo.email !== ''"
            class="shadow iseq-app__main__left-menu"
          >
            <v-iseq-left-toolbar
              :top-margin="mainTopMargin"
              :footer-intersection="footerIntersection"
            />
          </div>

          <div
            class="iseq-app__main__content"
            :class="{ 'iseq-app__main__content--wide': !navBarUserInfo.email }"
          >
            <router-view :key="$route.fullPath" />
          </div>
        </div>

        <div class="iseq-app__footer">
          <v-iseq-footer-basic id="footer" />
        </div>
      </template>
    </div>

    <get-response-data-collector
      v-if="showGetResponseForm"
    />

    <v-iseq-snackbar-queue />
  </v-app>
</template>

<script>
  import IseqTopToolbar from "@/components/layout/IseqTopToolbar.vue";
  import IseqLeftToolbar from "@/components/layout/IseqLeftToolbar.vue";
  import GetResponseDataCollector from "@/components/layout/GetResponseDataCollector.component.vue";
  import IseqSnackbarQueue from "@/components/layout/IseqSnackbarQueue.vue";
  import IseqFooterBasic from "@/components/layout/FooterBasic.component.vue";
  import IseqPaymentModal from "@/components/payment/paymentModal.component";
  import IseqTopBar from "./components/layout/IseqTopBar.vue";
  import { mapGetters, mapState } from "vuex";
  import SockJS from "sockjs-client";
  import { Auth } from "aws-amplify";
  import Stomp from "webstomp-client";
  import store from "@/store";
  import { permissionCheck } from "./plugins/permissions";
  import { analyticsIdentify } from "./helpers/analytics";
  import {runFullStory} from "@/helpers/fullStoryHelper";

  export default {
    name: "App",
    components: {
      "v-iseq-top-bar": IseqTopBar,
      "v-iseq-top-toolbar": IseqTopToolbar,
      "v-iseq-left-toolbar": IseqLeftToolbar,
      "v-iseq-snackbar-queue": IseqSnackbarQueue,
      "v-iseq-footer-basic": IseqFooterBasic,
      IseqPaymentModal,
      GetResponseDataCollector,
    },
    data: function() {
      return {
        stompClient: null,
        socket: undefined,
        windowWidth: window.innerWidth,
        onLine: navigator.onLine,
        footerIntersection: 0,
        pathToLogoFile: {
          light: require(process.env.VUE_APP_LOGO_LIGHT_PATH),
          dark: require(process.env.VUE_APP_LOGO_DARK_PATH),
        },
      };
    },
    computed: {
      ...mapState("user", ["user", "navBarUserInfo"]),
      ...mapState("fileUploads", ["myUploadingFiles"]),
      ...mapState(["checkUploadFromDisk", "connected"]),
      ...mapState("project", ["project"]),
      ...mapState("organization", ["organization"]),
      ...mapGetters(["isAppOnline"]),
      cssProps() {
        return {
          "--active": this.$vuetify.theme.currentTheme.active,
          "--inactive": this.$vuetify.theme.currentTheme.inactive,
          "--dark": this.$vuetify.theme.currentTheme.dark,
          "--light": this.$vuetify.theme.currentTheme.light,
          "--light-2": this.$vuetify.theme.currentTheme.light_2,
          "--font-color": this.$vuetify.theme.currentTheme.text,
          "--font-inverted-color": this.$vuetify.theme.currentTheme.textinverted,
          "--primary-color": this.$vuetify.theme.currentTheme.primary,
          "--secondary-color": this.$vuetify.theme.currentTheme.secondary,
          "--tertiary-color": this.$vuetify.theme.currentTheme.tertiary,
          "--info-light-color": this.$vuetify.theme.currentTheme.infolighten,
          "--warning-light-color": this.$vuetify.theme.currentTheme.warning_light,
          "--warning-color": this.$vuetify.theme.currentTheme.warning,
          "--error-color": this.$vuetify.theme.currentTheme.error,
          "--danger-color": this.$vuetify.theme.currentTheme.danger,
          "--success-color": this.$vuetify.theme.currentTheme.success,
          "--border-inactive": this.$vuetify.theme.currentTheme.borderinactive,
          "--border-text": this.$vuetify.theme.currentTheme.bordertext,
          "--navbar-background-color": this.$vuetify.theme.currentTheme.navbar_bg,
          "--topbar-background-color": this.$vuetify.theme.currentTheme.topbar_bg,
          "--main-top-margin": this.mainTopMargin,
          "max-width": "1400px",
          margin: "0 auto",
        };
      },
      isTopBarVisible() {
        return (
          this.organization.uuid && this.organization.accountStatus === "TRIAL"
        );
      },
      mainTopMargin() {
        let px = 0;
        if (this.isTopBarVisible) {
          px += 40;
        }
        if (
          this.$route.name !== "login" &&
          this.$route.name !== "sign-up" &&
          this.$route.name !== "forgot-password"
        ) {
          px += 80;
        }
        return px.toString() + "px";
      },
      showGetResponseForm() {
        return !!(this.navBarUserInfo.email &&
          this.isProd &&
          this.user.userSettings &&
          this.user.userSettings.marketing &&
          this.user.userSettings.marketing.getResponseAccountStatus === 'NONE');
      },

      isProd() {
        return process.env.VUE_APP_MODE === "prod";
      }
    },
    watch: {
      onLine(newValue) {
        this.$store.dispatch("setAppOnlineStatus", newValue);
        if (newValue) {
          this.connectWS();
        }
      },
      user(currentUser, prevUser) {
        // Configure Rollbar People
        this.$rollbar.configure({
          payload: {
            person: {
              id: currentUser.uuid, // required
              email: currentUser.email,
            },
          },
        });

        // Set Drift identity
        if (window.drift && currentUser) {
          window.drift.identify(currentUser.uuid, {
            email: currentUser.email,
            organization_uuid: this.organization.uuid
          });
        }

        // Set FullStory identity
        if (window.FS && currentUser) {
          window.FS.identify(currentUser.uuid, {
            email: currentUser.email,
          });
        }

        if (currentUser.userSettings.marketing.getResponseAccountStatus === 'NONE') {
          if (this.$route.query.gr) {
            this.$store.dispatch("user/updateGetResponseData", "OPT_IN");
          }
        }
      },
      checkUploadFromDisk(value) {
        let disk = undefined;
        if (value === undefined) {
          window.onbeforeunload = function(event) {
            event.preventDefault();
          };
        } else {
          disk = this.myUploadingFiles.filter(
            element =>
              element.type === "DISK" &&
              element.status === "IN_PROGRESS" &&
              element.progress < 100
          );
          if (disk.length) {
            window.onbeforeunload = function() {
              return "";
            };
          } else {
            window.onbeforeunload = function(event) {
              event.preventDefault();
            };
          }
        }
      },
      project(newProject, prevProject) {
        if (
          newProject.uuid &&
          !this.connected &&
          newProject.uuid !== prevProject.uuid
        ) {
          this.connectWS();
        }

        analyticsIdentify({
          userUuid: this.user.uuid,
          userEmail: this.user.email,
          activeOrganization: this.organization.uuid,
          activeProject: newProject.uuid,
        });
      },
    },

    mounted() {
      //Must happen on nextTick as mounted hook for parent (App) doesn't guarantee mounting for children components (footer).
      this.$nextTick(() => {
        let percentsRange = [...Array(101).keys()];
        new IntersectionObserver(this.changeFooterIntersectionValue, {
          threshold: percentsRange.map(x => parseFloat(x) / 100),
        }).observe(document.getElementById("footer"));
      });
      window.addEventListener("online", this.updateOnlineStatus);
      window.addEventListener("offline", this.updateOnlineStatus);
      this.$nextTick(() => {
        window.addEventListener("resize", this.onResize);
      });
      runFullStory();
    },

    beforeDestroy() {
      window.removeEventListener("online", this.updateOnlineStatus);
      window.removeEventListener("offline", this.updateOnlineStatus);
      window.removeEventListener("resize", this.onResize);
      if (this.stompClient) {
        this.stompClient.disconnect();
        this.$store.commit("setConnected", false);
      }
    },
    methods: {
      changeFooterIntersectionValue(entries, observer) {
        this.footerIntersection = parseInt(entries[0].intersectionRatio * 90);
      },

      updateOnlineStatus(e) {
        const { type } = e;
        this.onLine = type === "online";

        if (type === "online") {
          this.$rollbar.info("Connection has been restored");
        }
      },

      updateNotifications() {
        this.$store.dispatch("notification/getNotifications");
        this.$store.dispatch("invitation/getInvitations");
      },

      onResize() {
        this.windowWidth = window.innerWidth;
      },

      permissionRequired(action) {
        return permissionCheck(action);
      },

      async connectWS() {
        this.socket = new SockJS(
          process.env.VUE_APP_API_FILES_URL + "/file-upload"
        );
        this.stompClient = Stomp.over(this.socket);
        const session = await Auth.currentSession();
        const accessToken = session.idToken.jwtToken;
        this.stompClient.debug = () => {};
        this.stompClient.connect(
          { "X-Authorization": "Bearer " + accessToken },
          frame => {
            this.$store.commit("setConnected", true);
            this.stompClient.subscribe(
              "/queue/file-upload-user" + this.project.uuid,
              tick => {
                let parse = JSON.parse(tick.body);
                store.dispatch("fileUploads/receiveFileData", parse);
                if (this.checkUploadFromDisk === 1)
                  this.$store.commit("setCheckUploadFromDisk", 2);
                else this.$store.commit("setCheckUploadFromDisk", 1);
              }
            );
          },
          error => {
            this.$store.commit("setConnected", false);
            this.$store.commit("setCheckUploadFromDisk", undefined);
            if (this.isAppOnline) {
              if (
                error.code !== 1006 &&
                this.permissionRequired("fileUploadView")
              ) {
                this.$store.dispatch("fileUploads/getIncompleteFiles", {
                  projectUuid: this.project.uuid,
                });
              }
              setTimeout(() => {
                this.connectWS();
              }, 1000)
            }
          }
        );
      },
    },
  };
</script>

<style lang="scss">
// set Muli as default font
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@400;700&display=swap");

$typoOptions: display-4 display-3 display-2 display-1 headline title subtitle-1
  subtitle-2 body-1 body-2 caption overline; // all md typography options provided by vuetify
%font-choice {
  font-family: "Muli", sans-serif !important;
}

@mixin md-typography {
  @each $typoOption in $typoOptions {
    .#{$typoOption} {
      @extend %font-choice;
    }
  }
}

body {
  background: #f7fafc;
}

a {
  color: inherit !important;
  text-decoration: inherit;
}

.v-card p {
  padding: inherit !important;
  margin: inherit !important;
}

.v-application {
  font-size: 12px;
  @extend %font-choice;
  @include md-typography;
}

.v-btn {
  font-weight: bold;
  letter-spacing: 0 !important;
  font-size: 12px !important;
  text-transform: none !important;
}

.v-btn .v-btn__content {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

// card
.v-card__title {
  color: var(--font-color);
}

//.v-input__slot {
//  background-color: var(--secondary-color) !important;
//  border: none !important;
//}

// table
.v-data-table td,
.v-data-table th {
  border-bottom: 1px solid white !important;
  border-top: 1px solid white !important;
  white-space: nowrap;
}

th.text-start {
  font-size: 1.4em !important;
}

.text-start,
.v-data-footer__select,
.v-data-footer__pagination,
.v-data-footer__icons-before,
.v-data-footer__icons-after,
.v-select__selection,
.v-input__icon i,
.v-list-item__title {
  color: var(--dark);
}

tbody tr {
  cursor: pointer;
}

tbody tr:nth-of-type(odd) {
  background-color: var(--light);
  border-radius: 25px;
}

.v-data-table td:first-child {
  border-top-left-radius: 5px;
}

.v-data-table td:last-child {
  border-top-right-radius: 5px;
}

.v-data-table td:first-child {
  border-bottom-left-radius: 5px;
}

.v-data-table td:last-child {
  border-bottom-right-radius: 5px;
}

.v-data-footer {
  margin-top: 48px;
}

.text-dotted-overflow {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.shadow {
  -webkit-box-shadow: 0 50px 40px -25px rgba(0, 40, 91, 0.1) !important;
  -moz-box-shadow: 0 50px 40px -25px rgba(0, 40, 91, 0.1) !important;
  box-shadow: 0 50px 40px -25px rgba(0, 40, 91, 0.1) !important;
}

.iseq-title {
  color: var(--dark) !important;
  font-size: 1.7em !important;
  font-weight: 500;
}

// vuetify tabs
.v-tabs .v-tab {
  color: var(--secondary-color) !important;
  font-weight: bold;
  font-size: 14px !important;
  text-transform: none !important;
}

.v-tabs .v-tab--active {
  color: var(--primary-color) !important;
}

///
/// v-subheader
///
.v-subheader {
  color: black !important;
  font-weight: bold;
  font-size: 1.25rem;
  background: #eee;
}

//
// v-btn
//
.iseq-btn {
  background-color: var(--active) !important;

  &--red {
    color: var(--danger-color) !important;
    background-color: rgba(255, 255, 255, 1) !important;

    .v-btn__content {
      color: var(--danger-color) !important;
    }
  }

  &--red-full {
    color: rgba(255, 255, 255, 1) !important;
    background-color: var(--danger-color) !important;
  }

  &--orange {
    color: var(--warning-color) !important;
    background-color: rgba(255, 255, 255, 1) !important;

    .v-btn__content {
      color: var(--warning-color) !important;
    }
  }

  &--green {
    color: var(--success-color) !important;
    background-color: rgba(255, 255, 255, 1) !important;

    .v-btn__content {
      color: var(--success-color) !important;
    }
  }

  &--grey {
    color: var(--font-inverted-color) !important;
    background-color: rgba(255, 255, 255, 1) !important;

    .v-btn__content {
      color: var(--font-inverted-color) !important;
    }
  }

  &--secondary {
    color: var(--active) !important;
    background-color: rgba(255, 255, 255, 1) !important;

    .v-btn__content {
      color: var(--active) !important;
    }
  }
}

//
// v-icon
//
.iseq-icon {
  color: var(--primary-color) !important;

  &--white {
    color: white !important;
  }

  &--red {
    color: var(--danger-color) !important;
  }
}

//
// v-badge
//
.iseq-badge {
  color: white;

  //background: var(--primary-color) !important;

  &--red {
    background: var(--danger-color) !important;
  }
}

//
// v-text-area
//
.iseq-text-area {
  background-color: var(--light);
  border: white solid 0px;
  border-radius: 5px;
  padding: 20px 5px 0px 10px;
}

//
// v-text-field
//
.iseq-text-field {
  background-color: var(--light);
  border: white solid 0px;
  border-radius: 5px;
  padding: 20px 5px 0px 10px;

  &--active {
    background-color: rgba(255, 255, 255, 1) !important;
    border: var(--primary-color) solid 1px !important;
  }
}

.iseq-text-field .v-input__control {
}

.iseq-text-field .v-input__slot {
}

.iseq-text-field .v-input__slot::before,
.iseq-text-field .v-input__slot::after {
  display: none !important;
}

.iseq-text-field .v-input__slot::after {
  border: red solid 1px;
}

// v-iseq-text-field label
.iseq-text-field .v-input__slot {
  margin-top: 0px;
  margin-bottom: 0px;
}

.iseq-text-field:not(.v-input--is-label-active)
  .v-input__slot
  label:not(.primary--text) {
  margin-top: 0px;
  margin-bottom: 0px;
}

.iseq-text-field .v-input__slot label:not(.v-label--active) {
  color: var(--secondary-color) !important;
}

// v-iseq-text-field input
.iseq-text-field input {
  color: var(--font-color) !important;
}

.iseq-text-field .v-text-field__details {
  display: none !important;
}

//tree-menu
.tree-menu i {
  color: var(--secondary-color) !important;
  margin-right: 10px !important;
}

.tree-menu .v-list {
  margin-bottom: 6px !important;
  padding-bottom: 0px !important;
}

.tree-menu div:hover {
  background-color: var(--tertiary-color) !important;
}

//treeselect
.vue-treeselect__control {
  background-color: var(--tertiary-color) !important;
  height: 56px;
  border: 0px solid red !important;
}

.vue-treeselect__placeholder {
  color: #5f6062 !important;
  font-size: 1.4em;
  padding-top: 12px;
}

// amplify
.amplify-holder .v-overlay__content {
  -webkit-box-shadow: 0 0 50px 0 rgba(0, 40, 91, 0.1) !important;
  -moz-box-shadow: 0 0 50px 0 rgba(0, 40, 91, 0.1) !important;
  box-shadow: 0 0 50px 0 rgba(0, 40, 91, 0.1) !important;
  border-radius: 10px;
}

.amplify-holder div {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  color: var(--dark);
}

.amplify-holder input {
  background-color: var(--tertiary-color);
  border-style: none !important;
  border-radius: 5px;
  padding-top: 14px;
  padding-bottom: 14px;
}

.amplify-holder button {
  border-radius: 5px;
  background-color: var(--active);
  font-weight: bold;
  font-size: 12px !important;
  text-transform: none !important;
}

.iseq-app {
  height: 100%;
  background-color: #f7fafc;

  &__small-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px 50px 0px 50px;
    height: 100%;

    &__logo {
      display: flex;
      justify-content: center;
      width: 250px;
      margin-bottom: 25px;
    }

    &__message {
      color: var(--font-color);
      font-size: 20px;
      text-align: center;
      display: inline-block;
    }
  }

  &__topbar {
    position: fixed;
    z-index: 3;
    width: 100%;
    max-width: 1400px;
  }

  &__nav-bar {
    position: fixed;
    z-index: 3;
    max-width: 1400px;
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    background-color: var(--navbar-background-color);

    &--with-topbar {
      top: 40px;
    }

    &__logo {
      width: 20%;
      justify-self: center;
      align-self: center;

      &--wide {
        width: 100%;
      }
    }

    &__content {
      width: 80%;
    }
  }

  &__main {
    min-height: calc(100vh - var(--main-top-margin) - 90px);
    display: flex;
    flex-direction: row;
    margin: var(--main-top-margin) 0 0 0;

    &__left-menu {
      background: var(--white);
      width: 20%;
      z-index: 2;
    }

    &__content {
      width: 80%;

      &--wide {
        width: 100%;
      }
    }

    &__footer {
      z-index: 3;
    }
  }
}
</style>
