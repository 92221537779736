import {isValidEmail} from "@/plugins/utils";

export function passwordStructureError(password) {
  let validationErrors = []; 
  let errorMessage = "This password doesn't meet requirements. It must:\n";

  if (password.length < 12) {
    validationErrors.push("   -be at least 12 characters long");
  }

  if (!/\d/.test(password)){
    validationErrors.push("   -contain at least 1 digit");
  }

  if (!/[A-Z]/.test(password)){
    validationErrors.push("   -contain at least 1 capital letter");
  }

  if (!/[a-z]/.test(password)){
    validationErrors.push("   -contain at least 1 small letter");
  }

  return validationErrors.length > 0 ? errorMessage.concat(validationErrors.join(',\n')) : false;
}

export function validateUsername(username) {
  let flags = {
    isUsernameTaken: false,
    isUsernameInvalid: false,
    isUsernameEmpty: false
  }

  if (username.length === 0) {
    flags.isUsernameEmpty = true;
  } else if (!isValidEmail(username)) {
    flags.isUsernameInvalid = true;
  }

  return {flags: flags, result: !Object.values(flags).some(item => item)};
}

export function validatePassword(password) {
  let flags = {
    isPasswordNotPermitted: false
  }

  if (password.length < 12 || !/.*\d.*/.test(password) || !/.*[A-Z].*/.test(password) || !/.*[a-z].*/.test(password)) {
    flags.isPasswordNotPermitted = true;
  }

  return {flags: flags, result: !Object.values(flags).some(item => item)};
}

export function validateConfirmPassword(password, confirmPassword) {
  let flags = {
    isPasswordsDifference: false
  }

  if (password !== confirmPassword) {
    flags.isPasswordsDifference = true;
  }

  return {flags: flags, result: !Object.values(flags).some(item => item)};
}