import {permissionLevels, permissionLevelsRequired} from "./permissionsConfig";
import store from "../store"

export function permissionCheck(action) {
  const permissionType = permissionLevels.organization.includes(permissionLevelsRequired[action]) ? 'organization' : 'project';

  const userLevel = permissionLevels[permissionType].indexOf(store.getters[`${permissionType}/userRole`]);
  const requiredLevel = permissionLevels[permissionType].indexOf(permissionLevelsRequired[action]);

  return userLevel > -1 ? userLevel <= requiredLevel : false;
}

export function organizationInvitePositions() {
  return [
    {name: "Admin", value: "ORGANIZATION_ADMIN"},
    {name: "Member", value: "ORGANIZATION_MEMBER"}];
}

export function isUserRoleHigher(compareLevel) {
  const permissionType = permissionLevels.organization.includes(compareLevel) ? 'organization' : 'project';

  const userLevel = permissionLevels[permissionType].indexOf(store.getters[`${permissionType}/userRole`]);
  compareLevel = permissionLevels[permissionType].indexOf(compareLevel);

  return userLevel < compareLevel;
}

export function availablePositionInProject(userRoleToChange) {
  const userProjectRole = store.getters["project/userRole"];
  const userOrganizationRole = store.getters["organization/userRole"];

  if (userOrganizationRole === "ORGANIZATION_OWNER" || userOrganizationRole === "ORGANIZATION_ADMIN") {
    // organization owner can promote or add user to project with any role
    return [
      {name: "Admin", value: "PROJECT_ADMIN"},
      {name: "Editor", value: "PROJECT_EDITOR"},
      {name: "Viewer", value: "PROJECT_VIEWER"}
    ];
  } else if (userProjectRole === "PROJECT_ADMIN" && userRoleToChange !== 'Admin') {
    // project admin can't create new admins or revoke admin's role in project
    return [
      {name: "Editor", value: "PROJECT_EDITOR"},
      {name: "Viewer", value: "PROJECT_VIEWER"}
    ];
  } else {
    // in other situations role change isn't available
    return [];
  }
}

export function changePositionInOrganization(memberRole) {
  memberRole = memberRole.toUpperCase().replace(/\s/g, '_');
  if (memberRole !== "ORGANIZATION_OWNER") {
    return [
      {name: "Admin", value: "ORGANIZATION_ADMIN"},
      {name: "Member", value: "ORGANIZATION_MEMBER"}
    ];
  } else {
    return [];
  }
}