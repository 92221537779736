<template>
  <div>
    <treeselect
      v-model="localValue"
      :placeholder="`Please select value${input.type.includes('Array') ? 's' : ''}`"
      :clearable="true"
      :searchable="true"
      :close-on-select="true"
      :multiple="input.type.includes('Array')"
      :options="input.constraints ? filterValues(input.constraints.values) : []"
      :normalizer="selectableNormalizer"
    >
      <div
        v-if="input.constraints.values.length && input.type.includes('Array')"
        slot="before-list"
        class="before-list-element"
      >
        <!--TODO next button should use v-if="!areAllValuesSelected()" but for some reason value isn't updating inside component despite doing so in Array[File]-->
        <v-iseq-btn
          type="secondary"
          class="before-list-element__select-all-btn"
          @click="selectAll()"
        >
          Select All
        </v-iseq-btn>
        <!--TODO next button should use v-if="isAnyValueSelected()" but for some reason value isn't updating inside component despite doing so in Array[File]-->
        <v-iseq-btn
          type="secondary"
          class="before-list-element__select-all-btn"
          @click="deselectAll()"
        >
          Deselect All
        </v-iseq-btn>
      </div>
    </treeselect>
  </div>
</template>

<script>
  import Treeselect from "@riophae/vue-treeselect";
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import IseqButton from "@/components/ui/IseqButton.vue";

  export default {
    name: "IseqSelectableInput",
    components:{
      Treeselect,
      "v-iseq-btn": IseqButton
    },
    props: {
      value: {
        required: false,
        default: undefined
      },
      input: {
        type: Object,
        required: true
      },
      controlInputsValues: {
        required: false,
        default: undefined
      }
    },
    data: function () {
      return {
        selectableNormalizer(node) {
          if (typeof node === 'object') {
            return {
              label: node.key,
              id: node.value,
            }
          } else {
            return {
              label: node,
              id: node,
            }
          }
        }
      }
    },
    computed: {
      localValue: {
        get() {
          return this.value;
        },
        set(newValue) {
          this.$emit('input', newValue);
        }
      }
    },
    watch: {
      controlInputsValues: {
        deep: true,
        handler(newControls) {
          if (typeof newControls !== 'undefined') {
            this.updateLocalValueToControlInput(newControls);
          }
        }
      }
    },
    created() {
      if (typeof this.controlInputsValues !== 'undefined') {
        this.updateLocalValueToControlInput(this.controlInputsValues);
      }
    },
    methods: {
      updateLocalValueToControlInput(controls) {
        if (typeof this.input.default !== "object" || typeof this.input.default[0] != 'object') {
          this.localValue = this.input.default;
        } else {
          this.input.default.forEach(node => {
            let breaker = false;

            Object.keys(controls).forEach(controlInputId => {
              if (node.when[controlInputId] && !node.when[controlInputId].includes(controls[controlInputId])) {
                breaker = true;
              }
            });

            if (!breaker) {
              this.localValue = node.value;
            }
          });
        }
      },
      filterValues() {
        if (typeof this.controlInputsValues === 'undefined') {
          return this.input.constraints.values;
        } else {
          return this.input.constraints.values.filter(node => {
            if (!node.availableWhen) return true;

            let breaker = false;

            Object.keys(this.controlInputsValues).forEach(controlInputId => {
              if (node.availableWhen[controlInputId] && !node.availableWhen[controlInputId].includes(this.controlInputsValues[controlInputId])) {
                breaker = true;
              }
            })

            return !breaker;
          })
        }
      },
      selectAll() {
        let values = [];
        for (let value of this.filterValues()) {
          values.push(this.selectableNormalizer(value).id);
        }
        this.$emit('input', values);
      },
      deselectAll() {
        this.$emit('input', []);
      }
    }
  }
</script>

<style lang="scss" scoped>
.before-list-element {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  margin-bottom: 4px;
  margin-left: 5px;

  &__select-all-btn {
    width: 100px;
    height: 20px;
    margin: 0 4px 0 4px;
  }
}
</style>