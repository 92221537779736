var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{style:(_vm.cssProps)},[_c('div',{staticClass:"iseq-app"},[(_vm.windowWidth < 920)?[_c('div',{staticClass:"iseq-app__small-display"},[_c('div',{staticClass:"iseq-app__small-display__logo"},[_c('img',{attrs:{"alt":"Logo","src":_vm.pathToLogoFile.dark}})]),_c('span',{staticClass:"iseq-app__small-display__message"},[_vm._v(" This app doesn’t support mobile devices yet."),_c('br'),_vm._v(" Please, use a desktop browser to open the app. ")])])]:[(_vm.isTopBarVisible)?_c('v-iseq-top-bar',{staticClass:"iseq-app__topbar"}):_vm._e(),(
          !_vm.$route.path.includes('/login') &&
            !_vm.$route.path.includes('/sign-up') &&
            !_vm.$route.path.includes('/forgot-password')
        )?_c('div',{class:[
          'iseq-app__nav-bar',
          _vm.isTopBarVisible ? 'iseq-app__nav-bar--with-topbar' : '' ]},[_c('div',{class:[
            'iseq-app__nav-bar__logo',
            _vm.navBarUserInfo.email ? '' : 'iseq-app__nav-bar__logo--wide' ]},[_c('router-link',{attrs:{"to":_vm.navBarUserInfo.email ? '/' : '/login'}},[_c('v-img',{attrs:{"alt":"Logo","contain":"","src":_vm.pathToLogoFile.light,"transition":"scale-transition","height":"45px"}})],1)],1),(_vm.navBarUserInfo.email)?_c('div',{staticClass:"iseq-app__nav-bar__content shadow"},[_c('v-iseq-top-toolbar')],1):_vm._e()]):_vm._e(),_c('iseq-payment-modal'),_c('div',{staticClass:"iseq-app__main"},[(_vm.navBarUserInfo.email !== '')?_c('div',{staticClass:"shadow iseq-app__main__left-menu"},[_c('v-iseq-left-toolbar',{attrs:{"top-margin":_vm.mainTopMargin,"footer-intersection":_vm.footerIntersection}})],1):_vm._e(),_c('div',{staticClass:"iseq-app__main__content",class:{ 'iseq-app__main__content--wide': !_vm.navBarUserInfo.email }},[_c('router-view',{key:_vm.$route.fullPath})],1)]),_c('div',{staticClass:"iseq-app__footer"},[_c('v-iseq-footer-basic',{attrs:{"id":"footer"}})],1)]],2),(_vm.showGetResponseForm)?_c('get-response-data-collector'):_vm._e(),_c('v-iseq-snackbar-queue')],1)}
var staticRenderFns = []

export { render, staticRenderFns }