import Vue         from 'vue'
import request     from '@/customjs/request.js'
import store       from '@/store'
import router      from '@/router'
import {generatePagingData, pagingDataParse, parseFiles, timeZoned} from "@/plugins/utils";
import {handleDeletedResourceError} from "@/helpers/errorHandlers";


export default {
  namespaced: true,
  state: {
    analysis: {
      status: {},
      sample: {project: {}}
    },
    analysisProgressData: undefined,
    analysisFiles: {},
    analyses: [],
    analysisTableOptions: generatePagingData({raw: true}),
    totalElements: 0,
    content: [],
    dashboardAnalysesProgress: {
      userAnalyses: [],
      otherAnalyses: []
    },
    variantsData: {
      variants: [],
      columns: {}
    }
  },
  mutations: {
    setAnalysisTableOptions (state, payload) {
      state.analysisTableOptions = payload;
    },
    clearAnalysisTableOptions(state) {
      state.analysisTableOptions =  generatePagingData({raw: true})
    },
    setDashboardAnalysesProgress(state, payload) {
      state.dashboardAnalysesProgress = payload
    },
    setTotalElements: (state, payload) => {
      state.totalElements = payload
    },
    setContent:(state, payload) => {
      payload.map(analysis => {
        analysis.started = timeZoned(analysis.started);
        analysis.finished = timeZoned(analysis.finished);
        return analysis
      });
      for (let i=0;i<payload.length;i++){
        if (payload[i].status==="Aborted") payload[i].finished="Aborted";
        else if (!payload[i].finished) payload[i].finished="In progress"
      }
      state.content = payload
    },
    setAnalyses(state, data) {
      data.map(analysis => {
        analysis.route = "/analyses/" + analysis.uuid;
        analysis.started = timeZoned(analysis.started);
        analysis.finished = timeZoned(analysis.finished);
        return analysis
      });
      for (let i=0;i<data.length;i++){
        if (data[i].status==="Aborted" || (!data[i].finished && data[i].status==="Deleted")) data[i].finished="Aborted";
        else if (!data[i].finished) data[i].finished="In progress"
      }
      data.sort(function(a, b) {
        let keyA = new Date(a.started),
          keyB = new Date(b.started);
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
      state.analyses = data;
    },

    setAnalysis(state, data) {
      data.startedDate = timeZoned(data.startedDate);
      data.finishedDate = timeZoned(data.finishedDate);
      if (data.status.id==="ABORTED" || (!data.finishedDate && data.status.id==="DELETED")) data.finishedDate="Aborted";
      else if (!data.finishedDate) data.finishedDate = "In progress";
      state.analysis = data;
    },

    setProgressData(state, data) {
      state.analysisProgressData = data;
    },

    setAnalysisFilesTree(state, data) {
      data = parseFiles([data]);
      state.analysisFiles = data[0];
    },

    setVariantsData(state, data) {
      state.variantsData = data;
    },

    cleanAllData(state){
      state.analysis = {
        status: {},
        sample: {project: {}}
      };
      state.analysisProgressData = undefined;
      state.analyses = [];
    },

    cleanAnalysisFiles(state) {
      state.analysisFiles = {};
    },
    resetContent(state) {
      state.content = [];
      state.totalElements = 0;
    }
  },
  actions: {
    getAnalysisByProject({commit}, payload) {
      request.request({
        endpoint: `projects/${payload.projectUuid}/analyses/tab?${payload.data}`,
        callback: function (data) {
          commit("setContent", data.content);
          commit("setTotalElements", data.totalElements);
        }
      })
    },

    getAnalysisList({state, commit}) {
      const projectUuid = store.state.project.project.uuid;
      const pagingParams = pagingDataParse({
        sortDesc: state.analysisTableOptions.sortDesc,
        sortBy: state.analysisTableOptions.sortBy,
        itemsPerPage: state.analysisTableOptions.itemsPerPage,
        totalElements: state.totalElements,
        page: state.analysisTableOptions.page
      });

      request.request({
        endpoint: `projects/${projectUuid}/analyses?${pagingParams}`,
        callback: function (data) {
          commit("setContent", data.content);
          commit("setTotalElements", data.totalElements);
        }
      })
    },

    getAnalysisExamplesList({state, commit}) {
      const pagingParams = pagingDataParse({
        sortDesc: state.analysisTableOptions.sortDesc,
        sortBy: state.analysisTableOptions.sortBy,
        itemsPerPage: state.analysisTableOptions.itemsPerPage,
        totalElements: state.totalElements,
        page: state.analysisTableOptions.page
      });

      request.request({
        waitingText: "Fetching example analyses",
        endpoint: `demo/analyses?${pagingParams}`,
        callback: function (data) {
          commit("setContent", data.content);
          commit("setTotalElements", data.totalElements);
        }
      })
    },

    getDashboardAnalysesProgress({commit}, payload) {
      request.request({
        method: "get",
        endpoint: `analyses/dashboard`,
        data: payload,
        callback: function (data) {
          commit("setDashboardAnalysesProgress", data);
        }
      })
    },

    getAnalysis({commit, dispatch, rootState}, uuid) {
      Vue.$log.debug("vue.vuex.sample.getAnalysis");
      commit("cleanAnalysisFiles");
      request.request({
        endpoint: "analyses/" + uuid,
        callback: function (data) {
          if (data.status.id !== "DELETED") {
            commit("setAnalysis", data);

            // When analysis is valid checking if context is correct
            if (rootState.organization.organization.uuid !== data.sample.project.organizationUuid) {
              store.dispatch("organization/getHeaderOrganizations", {
                preferredOrganization: data.sample.project.organizationUuid,
                preferredProject: data.sample.project.uuid
              });
            } else if (rootState.project.project.uuid !== data.sample.project.uuid) {
              store.dispatch("project/getProject", {uuid: data.sample.project.uuid});
            }

            store.dispatch("sample/getSample", data.sample.uuid);

            if (data.status.id === 'SUCCEEDED') {
              dispatch("getAnalysisFilesTree", uuid);
            } else if (data.status.id === 'RUNNING') {
              dispatch("getProgressData", {analysisUuid: uuid});
            }
          } else {
            handleDeletedResourceError();
          }
        }
      })
    },

    getProgressData({commit}, payload) {
      request.request({
        method: "get",
        endpoint: `analyses/dashboard`,
        callback: function (data) {
          const progressData = data.userAnalyses.find(item => item.uuid === payload.analysisUuid);
          commit("setProgressData", progressData);
        }
      })
    },

    getAnalysisFilesTree({commit}, uuid) {
      request.request({
        endpoint: `analyses/${uuid}/tree`,
        callback: function (data) {
          commit("setAnalysisFilesTree", data);
        }
      })
    },

    getDemoAnalysis({commit, dispatch}, uuid) {
      request.request({
        waitingText: "Fetching analysis information",
        endpoint: `demo/analyses/${uuid}`,
        callback: function (data) {
          commit("setAnalysis", data);
          dispatch("getDemoAnalysisFilesTree", uuid);
        }
      })
    },

    getDemoAnalysisFilesTree({commit}, uuid) {
      request.request({
        waitingText: "Fetching analysis information",
        endpoint: `demo/analyses/${uuid}/tree`,
        callback: function (data) {
          commit("setAnalysisFilesTree", data);
        }
      })
    },

    getSampleAnalyses({commit}, uuid) {
      request.request({
        endpoint: "samples/" + uuid + "/analyses",
        callback: function (data) {
          commit("setAnalyses", data);
        }
      })
    },

    updateAnalysis({dispatch}, payload) {
      Vue.$log.debug("vue.vuex.organization.updateAnalysis");
      request.request({
        method: "patch",
        endpoint: `analyses/${payload.analysisUuid}`,
        data: payload.data,
        callback: function () {
          dispatch('getAnalysis', payload.analysisUuid);
        }
      })
    },

    abortAnalysis({dispatch}, payload){
      request.request({
        snackbarMessage: "Aborting analysis",
        endpoint: "analyses/" + payload.uuid + "/abort",
        method: 'patch',
        callback: function () {
          dispatch("getAnalysis", payload.uuid);
        }
      })
    },

    deleteAnalysis({dispatch}, payload) {
      request.request({
        snackbarMessage: "Deleting analysis",
        endpoint: "analyses/" + payload.uuid,
        method: 'delete',
        callback: function () {
          router.push({path: `/samples/${payload.sampleUuid}`});
        }
      })
    },

    createAnalysis({}, payload) {
      request.request({
        snackbarMessage: "Running new analysis",
        method: "post",
        endpoint: `samples/${payload.sampleUuid}/analyses`,
        data: payload.data,
        callback: function () {
          store.dispatch("snackbarQueue/addConfirmationMessage", {type: "analysisStarted"});
          router.push({path: `/samples/${payload.sampleUuid}`});
          store.dispatch('user/getCredits', );
        }
      });
    },

    runVariantsAnalysis({}, payload) {
      request.request({
        snackbarMessage: "Running new analysis",
        method: "post",
        endpoint: `analyses/${payload.analysisUuid}/variant`,
        data: payload.data,
        callback: function () {
          store.dispatch("snackbarQueue/addConfirmationMessage", {type: "analysisStarted"});
          router.push({path: `/samples/${payload.sampleUuid}`});
          store.dispatch('user/getCredits', );
        }
      });
    }
  },
  getters: {
    analysisTableOptions: (state) => {
      return state.analysisTableOptions;
    }
  }
}
