var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"iseq-analysis-list"},[_c('v-iseq-card',{staticClass:"iseq-analysis-list__content",attrs:{"title":"Analyses"},scopedSlots:_vm._u([{key:"title-row",fn:function(){return [_c('div',{staticClass:"iseq-analysis-list__content__title-row"},[(_vm.permissionRequired('analysisAdd'))?_c('v-iseq-btn',{attrs:{"plus-button":"","ml":""},on:{"click":function($event){$event.preventDefault();return _vm.createAnalysis($event)}}}):_vm._e(),_c('span',{staticClass:"iseq-analysis-list__content__title-row__link"},[_c('router-link',{attrs:{"to":'/analyses/demo'}},[_vm._v("See example analyses")])],1)],1)]},proxy:true}])},[(!_vm.content.length)?_c('v-iseq-banner',{attrs:{"type":"info-light","value":"You don't have any analyses right now"}}):_c('v-data-table',{staticClass:"iseq-analysis-list__content__table",attrs:{"footer-props":{
        'items-per-page-options': _vm.itemsPerPageOptions
      },"headers":_vm.analysisColumns,"items":_vm.content,"server-items-length":_vm.totalElements,"options":_vm.analysisTableOptions},on:{"update:options":function($event){_vm.analysisTableOptions=$event}},scopedSlots:_vm._u([{key:"item.sampleName",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{attrs:{"to":("/analyses/" + (item.uuid))}},[_c('div',{staticClass:"iseq-analysis-list__content__table__field"},[_vm._v(" "+_vm._s(item.sampleName)+" ")])])]}},{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{attrs:{"to":("/analyses/" + (item.uuid))}},[_c('div',{staticClass:"iseq-analysis-list__content__table__field"},[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"item.started",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{attrs:{"to":("/analyses/" + (item.uuid))}},[_c('div',{staticClass:"iseq-analysis-list__content__table__field"},[_vm._v(" "+_vm._s(item.started)+" ")])])]}},{key:"item.finished",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{attrs:{"to":("/analyses/" + (item.uuid))}},[_c('div',{staticClass:"iseq-analysis-list__content__table__field"},[_vm._v(" "+_vm._s(item.finished)+" ")])])]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{attrs:{"to":("/analyses/" + (item.uuid))}},[_c('div',{staticClass:"iseq-analysis-list__content__table__field"},[_vm._v(" "+_vm._s(item.status)+" ")])])]}},{key:"item.chargedCost",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{attrs:{"to":("/analyses/" + (item.uuid))}},[_c('div',{staticClass:"iseq-analysis-list__content__table__field"},[_vm._v(" "+_vm._s(item.chargedCost)+" credits ")])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }