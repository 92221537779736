<template>
  <div class="iseq-adding-card">
    <v-form
      id="payment-form"
      ref="form"
      v-model="valid"
      class="iseq-adding-card__form"
    >
      <div class="iseq-adding-card__form__container">
        <v-text-field
          id="email"
          v-model="user.email"
          disabled
          :rules="emailRules"
          label="E-mail"
          solo
          class="iseq-adding-card__form__container__input"
          required
        />
      </div>

      <div class="iseq-adding-card__form__container">
        <p class="iseq-adding-card__form__container__title">
          Business address
        </p>

        <v-select
          v-model="form.select"
          solo
          name="country"
          :items="items"
          item-text="label"
          class="iseq-adding-card__form__container__input"
        />

        <v-text-field
          v-model="form.name"
          name="name"
          :rules="nameRules"
          solo
          class="iseq-adding-card__form__container__input"
          placeholder="Name"
          required
        />

        <v-text-field
          v-model="form.address"
          name="address"
          :rules="addressRules"
          class="iseq-adding-card__form__container__input"
          solo
          placeholder="Address"
        />

        <v-text-field
          v-model="form.city"
          name="city"
          :rules="cityRules"
          class="iseq-adding-card__form__container__input"
          solo
          placeholder="City"
        />

        <v-text-field
          v-model="form.state"
          name="state"
          :rules="stateRules"
          class="iseq-adding-card__form__container__input"
          solo
          placeholder="State"
        />
      </div>

      <div class="form-row">
        <label
          for="card-element"
          class="iseq-adding-card__form__container__title"
        >
          Credit or debit card
        </label>

        <div id="card-element" />
        <div id="card-result" />
        <div
          id="card-errors"
          role="alert"
        />
      </div>

      <v-btn
        id="card-button"
        type="submit"
        :loading="loadingBtn"
        class="iseq-adding-card__form__container__btn"
        value="Add credit card"
      >
        Add credit card
      </v-btn>
    </v-form>

    <v-iseq-button
      type="secondary"
      class="iseq-adding-card__button"
      @click="goBack"
    >
      Go back
    </v-iseq-button>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import IseqButton from "@/components/ui/IseqButton.vue";

  // const stripe = Stripe(process.env.VUE_APP_STRIPE_KEY, {
  //   locale: 'en',
  // });

  export default {
    name: 'IseqAddingCard',
    components: {
      'v-iseq-button': IseqButton
    },
    data() {
      return {
        stripe: undefined,
        valid: false,
        loadingBtn: false,
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+/.test(v) || 'E-mail must be valid',
        ],
        nameRules: [
          v => !!v || 'Name is required',
        ],
        addressRules: [
          v => !!v || 'Address is required',
        ],
        cityRules: [
          v => !!v || 'City is required',
        ],
        stateRules: [
          v => !!v || 'State is required',
        ],
        items: [
          { value: 'AU', label: 'Australia' },
          { value: 'AT', label: 'Austria' },
          { value: 'BE', label: 'Belgium' },
          { value: 'BR', label: 'Brazil' },
          { value: 'CA', label: 'Canada' },
          { value: 'CN', label: 'China' },
          { value: 'DK', label: 'Denmark' },
          { value: 'FI', label: 'Finland' },
          { value: 'FR', label: 'France' },
          { value: 'DE', label: 'Germany' },
          { value: 'HK', label: 'Hong Kong' },
          { value: 'IE', label: 'Italy' },
          { value: 'IT', label: 'Australia' },
          { value: 'JP', label: 'Japan' },
          { value: 'LU', label: 'Luxembourg' },
          { value: 'MY', label: 'Malaysia' },
          { value: 'MX', label: 'Mexico' },
          { value: 'NL', label: 'Netherlands' },
          { value: 'NZ', label: 'New Zealand' },
          { value: 'NO', label: 'Norway' },
          { value: 'PL', label: 'Poland' },
          { value: 'PT', label: 'Portugal' },
          { value: 'SG', label: 'Singapore' },
          { value: 'ES', label: 'Spain' },
          { value: 'SE', label: 'Sweden' },
          { value: 'CH', label: 'Switzerland' },
          { value: 'GB', label: 'United Kingdom' },
          { value: 'US', label: 'United States' },
        ],
        form: {
          name: '',
          address: '',
          city: '',
          state: '',
          select: 'US',
        },
      };
    },
    computed: {
      ...mapState('payment', [ 'clientSecret' ]),
      user() {
        return this.$store.getters[ 'user/user' ];
      },
    },
    created() {
      this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY, {
        locale: 'en',
      });
    },
    mounted() {
      const elements = this.stripe.elements();
      const card = elements.create('card', {
        iconStyle: 'solid',
        style: {
          base: {
            color: '#32325D',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
              color: '#AAB7C4',
            },
          },
          invalid: {
            color: '#FA755A',
            iconColor: '#FA755A',
          },
        },
        classes: {
          focus: 'is-focused',
          empty: 'is-empty',
        },
      });
      card.mount('#card-element');

      card.on('change', function (event) {
        const displayError = document.getElementById('card-errors');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
      });

      const form = document.getElementById('payment-form');
      form.addEventListener('submit', (event) => {
        event.preventDefault();
        this.validate();

        this.stripe.createToken(card).then(result => {
          if (result.error || !this.valid) {
            const errorElement = document.getElementById('card-errors');
            errorElement.textContent = result.error.message;

          } else {
            this.loadingBtn = true;
            const hiddenInput = document.createElement('input');
            hiddenInput.setAttribute('type', 'hidden');
            hiddenInput.setAttribute('name', 'stripeToken');
            hiddenInput.setAttribute('value', result.token.id);
            form.appendChild(hiddenInput);

            this.stripe.createPaymentMethod({
              type: 'card',
              card: card,
              billing_details: {
                address: {
                  city: this.form.city,
                  country: this.form.select,
                  postal_code: this.form.postalCode,
                  state: this.form.state,
                },
                email: this.user.email,
                name: this.user.email,
              },
            }).then(result => {
              if (result.error) {
                this.loadingBtn = false;
              } else {
                const data = {
                  receipt_email: this.user.email,
                  payment_method: {
                    card: card,
                    billing_details: {
                      name: this.user.email,
                    },
                  },
                };
                this.$store.commit('payment/setStripe', this.stripe);
                this.$store.commit('payment/setCardData', data);
                this.$store.commit('payment/setPaymentSteps', 3);
                this.loadingBtn = false;
              }
            });
          }
        });
      });
    },
    methods: {
      validate() {
        this.$refs.form.validate();
      },
      goBack() {
        this.$store.commit('payment/setPaymentSteps', 1);
      },
    },
  };
</script>

<style lang="scss">
.iseq-adding-card {
  &__form {
    &__container {
      &__input {
        .v-input {
          &__control {
            min-height: 32px !important;

            .v-input__slot {
              font-size: 14px;
              box-shadow: 0 1px 3px 0 #E6EBF1 !important;
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss"
       scoped>

.iseq-adding-card {
  &__form {
    &__container {
      &__input {
      }

      &__title {
        font-weight: bold;
        display: block;
      }

      &__btn {
        margin-top: 32px;
        background: var(--primary-color) !important;
        width: 100%;
        border-radius: 5px;
        padding: 5px;
        font-size: 16px !important;
        color: var(--white);
        cursor: pointer;
        font-weight: normal;
      }
    }
  }

  &__button {
    margin-top: 16px;
    width: 100%;
    font-size: 16px !important;
  }
}

.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #E6EBF1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #CFD7DF;
}

.StripeElement--invalid {
  border-color: #FA755A;
}

.StripeElement--webkit-autofill {
  background-color: #FEFDE5 !important;
}
</style>