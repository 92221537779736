<template>
  <v-card class="iseq-faq">
    <div class="iseq-faq__title">
      <span class="iseq-faq__title__text">
        FAQ
      </span>
    </div>

    <iseq-faq-field
      id="GettingStarted"
      title="How to get started with iFlow™"
      section-name="GettingStarted"
    >
      <div class="iseq-faq__video-container">
        <iframe
          width="800"
          height="450"
          src="https://www.youtube.com/embed/1vWsJt-38tg"
          style="border-width: 0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>
    </iseq-faq-field>

    <iseq-faq-field
      id="TrialAccount"
      title="What is the difference between a trial account and a regular one?"
      section-name="TrialAccount"
    >
      <strong><u>Trial account features:</u></strong><br>
      • 300 GB upload limit; <br>
      • 30 days validity (after 30 days samples, outputs from the performed analyses, and credits will
      be deleted);<br><br>
      You can top-up your account at any time (minimum 100 USD payment) and switch to the regular
      version.<br><br>
      <strong><u>Regular account features:</u></strong><br>
      • no upload limit;<br>
      • unlimited account validity (to read about the limits for samples validity, please see below).
    </iseq-faq-field>

    <iseq-faq-field
      id="DataSecurity"
      title="How secure is my data uploaded to iFlow™?"
      section-name="DataSecurity"
    >
      iFlow™ platform is HIPAA compliant and all data is encrypted in accordance with
      applicable requirements. We do not store our customers' data or results. Everything is being
      made in the customers' cloud space that creates automatically once the customers upload
      their sample and run analyses. For more information, please see the iFlow™
      <span
        style="cursor: pointer; text-decoration: underline"
        @click="goTo('/privacy-policy')"
      >Privacy Policy</span>
      and
      <span
        style="cursor: pointer; text-decoration: underline"
        @click="goTo('/terms-of-use')"
      >Terms of Use</span>.
    </iseq-faq-field>

    <iseq-faq-field
      id="PaidServices"
      title="What exactly do I have to pay for in the app?"
      section-name="PaidServices"
    >
      You only pay for analyses that have been successfully completed or aborted. Sample storage is free of charge,
      but we store samples only for 1 month unless you run analyses (at minimum cost of 90 credits) on
      them - then the storage is extended for one additional month. You can download analysis outputs
      (reports and other files) if you want to store them outside the platform.
    </iseq-faq-field>

    <iseq-faq-field
      id="TopUp"
      title="How can I top up my account?"
      section-name="TopUp"
    >
      To top up your account click the “Account Balance” in the upper menu and choose the “Add
      credits” button. Then follow the steps on the screen. Only the owner of the organization can
      add credits.
    </iseq-faq-field>

    <iseq-faq-field
      id="SampleCreate"
      title="How do I create a sample?"
      section-name="SampleCreate"
    >
      To create a new sample simply click the “Add Sample” button on the dashboard or icon
      <v-btn
        small
        fab
        color="primary"
        height="20px"
        width="20px"
        class="iseq-faq__icon"
        depressed
      >
        <v-icon size="20px">
          mdi-plus
        </v-icon>
      </v-btn>
      in the “Samples” at the
      navigation menu. Then choose the location of files you want to upload (from the computer or URL),
      select files, and click the “Create sample” button. The sample may include all raw data from one or more
      individuals.
    </iseq-faq-field>

    <iseq-faq-field
      id="BrowsersSupport"
      title="Which browsers are supported?"
      section-name="BrowsersSupport"
    >
      All modern and up-to-date browsers are supported, including, but not limited to:<br>
      - Google Chrome<br>
      - Apple Safari<br>
      - Microsoft Edge<br>
      - Mozilla Firefox<br><br>
      It is recommended to keep your browser updated!<br>
    </iseq-faq-field>

    <iseq-faq-field
      id="FilesFormats"
      title="What file format can be uploaded to the iFlow™?"
      section-name="FilesFormats"
    >
      You can upload files with the following extensions: <i>{{ workflowInputExtensionsLong }}</i><br>
      Most workflows start from <i>fastq.gz</i> or/and <i>.fq.gz</i> file types.<br><br>
      Currently implemented workflows work only on files in GRCh38/hg38 coordinates (refer to vcf, vcf.gz, gvcf, gvcf.gz, bam, bed, bed.gz files). To liftover bed/bed.gz files from GRCh37(hg19) to GRCh38(hg38) use "Liftover tool for .bed files [GRCh37(hg19) to GRCh38(hg38)]" workflow available in "Workflows" at the navigation menu.
    </iseq-faq-field>

    <iseq-faq-field
      id="FilesUploadProgress"
      title="How can I check the progress of uploading files?"
      section-name="FilesUploadProgress"
    >
      Progress bars appear on the dashboard for files being uploaded. Once the upload is finished,
      you get a notification on the dashboard.
    </iseq-faq-field>

    <iseq-faq-field
      id="SampleStorage"
      title="Do I have to pay for storage samples?"
      section-name="SampleStorage"
    >
      Sample storage is free of charge, but we store samples only for 1 month unless you run analyses (at
      minimum cost of 90 credits) on them - then the storage is extended for one additional month. You
      can download analysis outputs (reports and other files) if you want to store them outside the
      platform.
    </iseq-faq-field>

    <iseq-faq-field
      id="AnalysisRun"
      title="How do I run an analysis?"
      section-name="AnalysisRun"
    >
      To run an analysis simply click the “Run Analysis” button on the dashboard or
      <v-btn
        small
        fab
        color="primary"
        height="20px"
        width="20px"
        class="iseq-faq__icon"
        depressed
      >
        <v-icon size="20px">
          mdi-plus
        </v-icon>
      </v-btn>
      icon in the “Analyses” at the
      navigation menu. Next, select the appropriate workflow, enter the required
      inputs (basic - obligatory and advanced – optional) and click the "Run Analysis" button at the
      bottom. Before running an analysis you need to create a sample first.
    </iseq-faq-field>

    <iseq-faq-field
      id="AnalysisCompletion"
      title="How do I know that the analysis is completed and the results are ready?"
      section-name="AnalysisCompletion"
    >
      Progress bars appear on the dashboard for analyses being performed. Once the analysis is
      finished you get a notification on the dashboard.
    </iseq-faq-field>

    <iseq-faq-field
      id="Properties"
      title="How can I enter sample/patient/physician data into the final report?"
      section-name="Properties"
    >
      You can use the “Predefined Properties” on the “Create sample” screen. All the information
      entered will be transferred into the final report (refers to the following workflows: WES/WGS
      hereditary disorders ACMG report and WGS polygenic risk scores full report).
    </iseq-faq-field>

    <iseq-faq-field
      id="Invitations"
      title="How do I invite other users to access my organization/projects?"
      section-name="Invitations"
    >
      You can invite new users by clicking “Invite new member” in the “Projects” / ”Organizations” at
      the navigation menu. Please, find below what permissions/roles you can give to the new users.
    </iseq-faq-field>

    <iseq-faq-field
      id="Roles"
      title="What permissions/roles can a user have in the iFlow™ app?"
      section-name="Roles"
    >
      <strong><u>Roles in an organization:</u></strong><br>
      <strong>“Owner”</strong> is a user who creates and owns the organization and projects; has access to all
      projects; manages payments and permissions in the organization and projects.<br>
      <strong>“Admin”</strong> is a user who creates projects within an organization; has access to all the
      existing
      projects in the organization; manages (partly) permissions in the organization and projects.<br>
      <strong>“Member”</strong> is a user who has access to the organization; sees the list of projects; can join
      the
      projects by sending join requests to the project’s admin.<br><br>
      <strong><u>Roles in a project:</u></strong><br>
      <strong>“Admin”</strong> is a user in charge of the project; can invite or remove users as well as accept
      pending
      join requests; can create samples and run analyses.<br>
      <strong>“Editor”</strong> is a user who can perform basic actions within projects such as creating samples,
      running analyses, downloading results.<br>
      <strong>“Viewer”</strong> is a user who can only see samples and results of the analyses in the project;
      cannot run analyses or create samples.
    </iseq-faq-field>
  </v-card>
</template>

<script>
  import FAQField from './components/FAQField.component.vue'
  import {mapState} from 'vuex'

  export default {
    name: "FAQ",
    components: {
      "iseq-faq-field": FAQField
    },
    computed: {
      ...mapState('workflow', ['workflowInputExtensionsLong']),
      ...mapState('organization', ['organization']),
      topMargin() {
        return this.organization.accountStatus === "TRIAL" ? 130 : 90;
      }
    },
    watch: {
      organization(newOrganization, oldOrganization) {
        if (!oldOrganization.uuid) {
          this.$vuetify.goTo(document.getElementById(this.$route.query.section), {offset: this.topMargin});
        }
      }
    },
    created: function() {
      this.$store.dispatch('workflow/getInputExtensions');
    },
    mounted() {
      if (this.$route.query.section) {
        this.$vuetify.goTo(document.getElementById(this.$route.query.section), {offset: this.topMargin});
      }
    },
    methods: {
      goTo(destination) {
        this.$router.push({path: destination});
      },
    }
  }
</script>

<style lang="scss"
       scoped>
    .iseq-faq {
        border-radius: 4px;
        padding: 32px;
        margin: 20px 40px;
        box-shadow: $card-shadow;

        &__title {
            display: flex;
            justify-content: center;

            &__text {
                font-size: 1.7em;
                color: var(--font-color);
            }
        }

        &__icon {
            cursor: text;
        }

        &__video-container {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
</style>