<template>
  <div class="iseq-status">
    <template v-if="statusPayment.error">
      <span class="iseq-status__error">
        <v-icon class="iseq-status__error__icon">mdi-close</v-icon> {{ statusPayment.error.message }}
      </span>
    </template>

    <template v-if="statusPayment.paymentIntent">
      <span class="iseq-status__succeeded">
        <v-icon class="iseq-status__succeeded__icon">mdi-check</v-icon> Topping up the account was successful!
      </span>
    </template>

    <div class="iseq-status__wrapper">
      <v-iseq-btn
        v-if="statusPayment.error"
        type="primary"
        class="iseq-status__btn"
        @click="goBack"
      >
        Update credit card details
      </v-iseq-btn>

      <v-iseq-btn
        type="secondary"
        class="iseq-status__btn"
        @click="closeDialog"
      >
        Close
      </v-iseq-btn>
    </div>
  </div>
</template>

<script>
  import {mapState} from "vuex";
  import IseqButton from "@/components/ui/IseqButton.vue";
  import {segmentTrack} from "@/helpers/analytics";

  export default {
    name: 'IseqStatus',
    components: {
      'v-iseq-btn': IseqButton
    },
    props: {
      statusPayment: {
        type: Object,
        required: true
      },
    },
    computed: {
      ...mapState('payment', ['status', 'paymentSteps']),
    },
    watch: {
      paymentSteps (activeStep) {
        if (activeStep === 4) {
          if (this.statusPayment.error) {
            segmentTrack({event: "Adding Payment Method Failed", payload: {reason: this.statusPayment.error.message}});
          } else if (this.status !== 'card_assigned') {
            segmentTrack({event: "Payment Method Added"});
          }
        }
      }
    },
    methods: {
      closeDialog() {
        this.$store.commit('payment/setPaymentDialog', false);
      },
      goBack() {
        this.$store.commit('payment/setPaymentSteps', 2);
      },
    },
  };
</script>

<style lang="scss"
       scoped>

.iseq-status {
  &__error,
  &__succeeded {
    padding: 15px;
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__icon {
      border-radius: 50%;
      padding: 2px;
      color: white;
      margin-right: 10px;
    }
  }

  &__succeeded {
    background: rgba(59, 195, 128, 0.20);
    border: 1px solid var(--success-color);

    &__icon {
      background: var(--success-color);
    }
  }

  &__error {
    background: rgba(235, 74, 97, 0.20);
    border: 1px solid var(--error-color);

    &__icon {
      background: var(--error-color);
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }

  &__btn {
    margin-top: 16px;
    width: 100%;
    font-size: 16px !important;
  }
}
</style>