<template>
  <v-card class="iseq-dashboard-files-upload">
    <div class="iseq-dashboard-files-upload__close">
      <v-icon
        class="iseq-dashboard-files-upload__close__icon"
        color="dark"
        @click="toggleFilesTab"
      >
        {{ hidden ? 'mdi-chevron-down-circle' : 'mdi-chevron-up-circle-outline' }}
      </v-icon>
    </div>

    <v-tabs v-model="activeTab">
      <v-tab
        v-show="myUploadingFilesFiltered.length"
        key="0"
        class="iseq-dashboard-files-upload__tab"
        @click="hidden = false"
      >
        My uploading files
        <v-badge
          v-show="myUploadingFilesFiltered.length"
          :content="myUploadingFilesFiltered.length"
          offset-x="-5"
          offset-y="4"
        />
      </v-tab>

      <v-tab
        v-show="othersUploadingFilesFiltered.length"
        key="1"
        class="iseq-dashboard-files-upload__tab"
        @click="hidden = false"
      >
        Others' uploading files
        <v-badge
          v-show="othersUploadingFilesFiltered.length"
          :content="othersUploadingFilesFiltered.length"
          offset-x="-5"
          offset-y="4"
        />
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-show="!hidden"
      v-model="activeTab"
      touchless
    >
      <v-tab-item key="0">
        <div
          v-show="myUploadingFilesFiltered.length"
          class="iseq-dashboard-files-upload__table"
        >
          <v-file-progressbar-list
            :items="myUploadingFilesFiltered"
            :show-actions="true"
          />
        </div>
      </v-tab-item>

      <v-tab-item key="1">
        <div
          v-show="othersUploadingFilesFiltered.length"
          class="iseq-dashboard-files-upload__table"
        >
          <v-file-progressbar-list
            :items="othersUploadingFilesFiltered"
            :show-actions="false"
          />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
  import fileProgressbarList from '@/components/ui/progressbar/fileProgressbarList.component.vue'
  import {mapState} from 'vuex';

  export default {
    name: 'FilesUploadCard',
    components: {
      'v-file-progressbar-list': fileProgressbarList
    },
    props: {
      filterSample: {
        type: String,
        default: undefined
      }
    },
    data: function () {
      return {
        isActiveTab: false,
        activeTab: 0,
        hidden: false
      }
    },
    computed: {
      ...mapState('fileUploads', ['myUploadingFiles', 'othersUploadingFiles']),
      myUploadingFilesFiltered() {
        if (this.filterSample) {
          return this.myUploadingFiles.filter(item => item.sampleUuid === this.filterSample);
        }
        else {
          return this.myUploadingFiles;
        }
      },
      othersUploadingFilesFiltered() {
        if (this.filterSample) {
          return this.othersUploadingFiles.filter(item => item.sampleUuid === this.filterSample);
        }
        else {
          return this.othersUploadingFiles;
        }
      },
      numberOfUserUploads() {
        return this.myUploadingFilesFiltered.length;
      },
      numberOfOtherUploads() {
        return this.othersUploadingFilesFiltered.length;
      }
    },
    watch: {
      numberOfUserUploads(newVal) {
        if (newVal && !this.numberOfOtherUploads) {
          this.activeTab = 0;
        }
      },
      numberOfOtherUploads(newVal) {
        if (newVal && !this.numberOfUserUploads) {
          this.activeTab = 1;
        }
      }
    },
    created() {
      if (!this.numberOfUserUploads) {
        this.activeTab = 1;
      }
    },
    methods: {
      toggleFilesTab() {
        this.hidden = !this.hidden;
      }
    }
  }
</script>
<style lang="scss"
       scoped>

.iseq-dashboard-files-upload {
    padding: 16px;
    margin: 20px 40px;
    position: relative;

    &__close {
        position: absolute;
        right: 20px;
        top: 30px;
        z-index: 10;

        &__icon {
            cursor: pointer;
            margin-right: 10px;
        }
    }
    &__header {
        flex: 1;
        display: flex;
        margin: 0 16px;
        align-items: center;
        justify-content: space-between;

        &__title {
            color: var(--dark);
            font-size: 1.7em;
            font-weight: 500;
        }
    }
    &__tab {
        padding: 0 32px;
    }
    &__table {
        margin: 0px 20px 20px 20px;
    }
}
</style>