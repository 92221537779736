<template>
  <v-menu
    bottom
    left
    :offset-y="true"
    :close-on-click="true"
  >
    <template #activator="{ on }">
      <div
        class="logout"
        v-on="on"
      >
        <v-avatar
          size="36"
          class="logout__avatar"
        >
          <img
            v-if="user.avatar"
            :src="user.avatar"
            :alt="user.name"
          >

          <img
            v-else
            src="@/assets/avatar.svg"
            :alt="user.name"
          >
        </v-avatar>

        <div class="logout__username">
          <span>{{ !user.name || user.name.length===0 ? user.email : user.name }}</span>
        </div>

        <v-icon color="active">
          mdi-menu-down
        </v-icon>
      </div>
    </template>

    <v-list>
      <v-list-item @click="goToProfile">
        <v-list-item-title>
          <v-icon
            color="active"
            class="logout__icon"
          >
            mdi-account
          </v-icon>
          Profile
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="isAdmin"
        @click="goToAdminPanel"
      >
        <v-list-item-title>
          <v-icon
            color="active"
            class="logout__icon"
          >
            mdi-shield-crown-outline
          </v-icon>
          Admin panel
        </v-list-item-title>
      </v-list-item>

      <v-list-item @click="logout">
        <v-list-item-title>
          <v-icon
            color="active"
            class="logout__icon"
          >
            mdi-logout-variant
          </v-icon>
          Logout
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import {Auth} from 'aws-amplify'
  import {mapState} from 'vuex'

  export default {
    name: "Logout",
    computed: {
      ...mapState('user', ['navBarUserInfo', 'user', 'isAdmin']),
    },
    methods: {

      logout() {
        this.$store.dispatch('cleanAllData');
        this.signOut();
      },
      goToProfile() {
        this.$router.push({path: '/profile'});
      },
      goToAdminPanel() {
        window.open(process.env.VUE_APP_ADMIN_PANEL_ADDRESS, '_blank');
      },
      async signOut() {
        try {
          await Auth.signOut();
          await this.$router.push('/login');
        } catch (e) {
          throw new Error(e)
        }
      }
    },

  }
</script>
<style lang="scss"
       scoped>
    .logout {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        &__avatar{
            width: 36px;
            margin-right: 8px;
        }

        &__username {
            color: var(--light);
            font-weight: 700;
            font-size: 1.1em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: 8px;
            padding-top: 8px;
            max-width: 75%;
        }

        &__icon {
            margin-right: 4px;
        }
    }
</style>