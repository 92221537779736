<template>
  <div class="iseq-files-upload">
    <v-tabs-items
      :value="uploadTab"
      touchless
    >
      <v-tab-item key="Drive">
        <div class="iseq-files-upload__inputs">
          <v-file-input
            outlined
            chips
            show-size
            multiple
            hide-details
            :accept="workflowInputExtensions"
            label="Press to select files from your computer"
            @change="addFiles"
          />
        </div>

        <div
          v-if="Object.keys(filesToUpload).length > 0"
          class="iseq-files-upload__files-list"
        >
          <v-list
            two-line
            subheader
            color="grey lighten-5"
          >
            <v-list-item
              v-for="(value, key) in filesToUpload"
              :key="key"
            >
              <v-icon>note</v-icon>

              <v-list-item-content>
                <v-list-item-title>{{ key }}</v-list-item-title>

                <v-list-item-subtitle>file</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  icon
                  @click="deleteFile(key)"
                >
                  <v-icon class="iseq-icon--red">
                    remove_circle
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>

        <div
          v-if="uploadErrors.disk.length>0"
          class="iseq-files-upload__error-list"
        >
          <div
            v-for="error in uploadErrors.disk"
            :key="error"
            class="iseq-files-upload__error-list__node"
          >
            {{ error }}
          </div>
        </div>
      </v-tab-item>

      <v-tab-item key="Url">
        <div class="iseq-files-upload__inputs">
          <v-text-field
            v-model="newUrl.key"
            class="input-field"
            outlined
            hide-details
            prepend-icon="mdi-web"
            label="File URL"
          />

          <div class="input-field">
            <v-text-field
              v-model="newUrl.value"
              outlined
              hide-details
              prepend-icon="mdi-label-outline"
              label="File name (optional)"
              @keyup="purifyUrlFilename"
            />

            <span
              v-if="fileExtensionFromUrl(newUrl.key)"
              :class="['input-field__extension', isExtensionAllowed(fileExtensionFromUrl(newUrl.key)) ? '' : 'input-field__extension--wrong']"
            >
              {{ fileExtensionFromUrl(newUrl.key) }}
            </span>
          </div>
        </div>
        <div class="iseq-files-upload__inputs">
          <v-text-field
            v-model="newUrl.username"
            outlined
            hide-details
            class="input-field"
            label="Login (optional)"
            prepend-icon="mdi-account"
          />

          <v-text-field
            v-model="newUrl.password"
            outlined
            hide-details
            class="input-field"
            type="password"
            label="Password (optional)"
            prepend-icon="mdi-key-variant"
            autocomplete="no-password"
          />
        </div>

        <div class="iseq-files-upload__button">
          <v-tooltip
            :disabled="isExtensionAllowed(fileExtensionFromUrl(newUrl.key)) && validateUrl(newUrl.key)"
            top
          >
            <template #activator="{ on }">
              <div v-on="on">
                <v-iseq-btn
                  width="100px"
                  :disabled="!(isExtensionAllowed(fileExtensionFromUrl(newUrl.key)) && validateUrl(newUrl.key))"
                  @click="addUrl"
                >
                  Add file
                </v-iseq-btn>
              </div>
            </template>

            <span>{{
              `${validateUrl(newUrl.key) ? `Extension not ${fileExtensionFromUrl(newUrl.key) ? "allowed" : "recognized"}` : "Invalid URL address"}`
            }}</span>
          </v-tooltip>
        </div>

        <div
          v-if="Object.keys(urlsToUpload).length>0"
          class="iseq-files-upload__files-list"
        >
          <v-list
            two-line
            subheader
            color="grey lighten-5"
          >
            <v-list-item
              v-for="node in Object.keys(urlsToUpload)"
              :key="node"
            >
              <v-icon>note</v-icon>

              <v-list-item-content>
                <v-list-item-title>{{ urlsToUpload[node].name }}</v-list-item-title>

                <v-list-item-subtitle>From: {{ urlsToUpload[node].url }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  icon
                  @click="deleteUrl(node)"
                >
                  <v-icon class="iseq-icon--red">
                    remove_circle
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>

        <div
          v-if="uploadErrors.url.length>0"
          class="iseq-files-upload__error-list"
        >
          <div
            v-for="error in uploadErrors.url"
            :key="error"
            class="iseq-files-upload__error-list__node"
          >
            {{ error }}
          </div>
        </div>
      </v-tab-item>

      <v-tab-item key="ftp">
        <!--        <div class="iseq-files-upload__inputs">-->
        <!--          <v-text-field-->
        <!--            v-model="ftp.url"-->
        <!--            outlined-->
        <!--            hide-details-->
        <!--            class="input-field"-->
        <!--            label="Link to ftp"-->
        <!--            prepend-icon="mdi-web"-->
        <!--          />-->

        <!--          <div class="input-field">-->
        <!--            <v-text-field-->
        <!--              v-model="ftp.filename"-->
        <!--              outlined-->
        <!--              hide-details-->
        <!--              label="File name (optional)"-->
        <!--              prepend-icon="mdi-label-outline"-->
        <!--              @keyup="purifyFtpFilename"-->
        <!--            />-->

        <!--            <span-->
        <!--              v-if="fileExtensionFromUrl(ftp.url)"-->
        <!--              :class="['input-field__extension', isExtensionAllowed(fileExtensionFromUrl(ftp.url)) ? '' : 'input-field__extension&#45;&#45;wrong']"-->
        <!--            >-->

        <!--              {{ fileExtensionFromUrl(ftp.url) }}-->
        <!--            </span>-->
        <!--          </div>-->

        <!--          <v-text-field-->
        <!--            v-model="ftp.username"-->
        <!--            outlined-->
        <!--            hide-details-->
        <!--            class="input-field"-->
        <!--            label="Login"-->
        <!--            prepend-icon="mdi-account"-->
        <!--          />-->

        <!--          <v-text-field-->
        <!--            v-model="ftp.password"-->
        <!--            outlined-->
        <!--            hide-details-->
        <!--            class="input-field"-->
        <!--            type="password"-->
        <!--            label="Password"-->
        <!--            prepend-icon="mdi-key-variant"-->
        <!--          />-->
        <!--        </div>-->

        <!--        <div-->
        <!--          v-if="uploadErrors.ftp.length>0"-->
        <!--          class="iseq-files-upload__error-list"-->
        <!--        >-->
        <!--          <div-->
        <!--            v-for="error in uploadErrors.ftp"-->
        <!--            :key="error"-->
        <!--            class="iseq-files-upload__error-list__node"-->
        <!--          >-->
        <!--            {{ error }}-->
        <!--          </div>-->
        <!--        </div>-->
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
  import {
    getFileExtensionFromFilename,
    getFileExtensionFromUrl,
    getFilenameFromUrl,
    recognizeExtension, validateFtp, validateUrl
  } from "@/helpers/filesUploadHelper";
  import {mapState} from "vuex";
  import IseqButton from "@/components/ui/IseqButton.vue";

  export default {
    name: "FilesUpload",
    components: {
      "v-iseq-btn": IseqButton
    },
    props: {
      uploadTab: {
        type: Number,
        default: 0
      },
      filesToUpload: {
        type: Object,
        default: () => {
        }
      },
      urlsToUpload: {
        type: Object,
        default: () => {
        }
      },
      ftp: {
        type: Object,
        default: () => {
        }
      },
      uploadErrors: {
        type: Object,
        default: () => {
        }
      },
      existingSample: {
        type: Boolean
      }
    },
    data: function () {
      return {
        newFileURL: undefined,
        newFileName: undefined,
        usedFilenames: {},
        usedUrls: {},
        newUrl: {},
        fileNameFixRegex: /[^\w|\-|\.]+/gi
      }
    },
    computed: {
      ...mapState('workflow', ['workflowInputExtensions', 'workflowInputExtensionsLong']),
      ...mapState('sample', ['sample'])
    },
    watch: {
      urlsToUpload: {
        handler() {
          this.checkIfFilesCanBeUploaded();
        },
        deep: true
      },
      ftp: {
        handler() {
          this.checkIfFilesCanBeUploaded();
        },
        deep: true
      },
      filesToUpload: {
        handler() {
          this.checkIfFilesCanBeUploaded();
        },
        deep: true
      }
    },
    methods: {
      validateUrl(url) {
        return validateUrl(url);
      },
      filenameFromUrl(url) {
        return getFilenameFromUrl(url);
      },
      fileExtensionFromUrl(url) {
        return recognizeExtension(getFileExtensionFromUrl(url), this.workflowInputExtensionsLong);
      },
      fileExtensionFromFilename(filename) {
        return recognizeExtension(getFileExtensionFromFilename(filename), this.workflowInputExtensionsLong);
      },
      addFiles(event) {
        event.forEach((file) => {
          this.$set(this.filesToUpload, file.name.replace(this.fileNameFixRegex, ""), file);
        })
        this.$emit("update:filesToUpload", this.filesToUpload);
      },
      deleteFile(key) {
        this.$delete(this.filesToUpload, key);
      },
      addUrl() {
        if (this.newUrl.value === undefined) {
          this.newUrl.value = this.filenameFromUrl(this.newUrl.key);
        } else {
          this.newUrl.value = `${this.newUrl.value.replace(this.fileNameFixRegex, "")}${this.fileExtensionFromUrl(this.newUrl.key)}`
        }
        this.$set(this.urlsToUpload, Object.keys(this.urlsToUpload).length, {
          url: this.newUrl.key,
          name: this.newUrl.value,
          username: this.newUrl.username,
          password: this.newUrl.password
        });
        this.$emit("update:urlsToUpload", this.urlsToUpload);
        this.newUrl = {};
      },
      purifyUrlFilename() {
        if (this.newUrl.value) {
          const purifiedName = this.newUrl.value.replace(/\./g, "");
          if (this.newUrl.value !== purifiedName) {
            this.$set(this.newUrl, "value", purifiedName);
          }
        }
      },
      purifyFtpFilename() {
        if (this.ftp.filename) {
          const purifiedName = this.ftp.filename.replace(/\./g, "");
          if (this.ftp.filename !== purifiedName) {
            this.$set(this.ftp, "filename", purifiedName);
          }
        }
      },
      deleteUrl(node) {
        this.$delete(this.urlsToUpload, node);
      },
      checkIfFilenameUsed(filename, type) {
        if (this.usedFilenames[filename]) {
          this.$set(this.uploadErrors, type, this.uploadErrors[type].concat(`Filename ${filename} is duplicated. File with such name is already on this uploads list or in the sample.`));
          this.$emit("update:uploadErrors", this.uploadErrors);
        } else {
          this.usedFilenames[filename] = true;
        }
      },
      checkIfUrlUsed(url, type) {
        if (this.usedUrls[url]) {
          this.$set(this.uploadErrors, type, this.uploadErrors[type].concat(`File from the address ${url} is duplicated. File with such name is already on this uploads list or in the sample.`));
          this.$emit("update:uploadErrors", this.uploadErrors);
        } else {
          this.usedUrls[url] = true;
        }
      },
      finalUploadFileName(userDefinedName, fileAddress) {
        return userDefinedName ? userDefinedName : this.filenameFromUrl(fileAddress);
      },
      checkIfFilesCanBeUploaded() {
        this.$set(this.uploadErrors, "disk", []);
        this.$set(this.uploadErrors, "url", []);
        this.$set(this.uploadErrors, "ftp", []);
        this.$emit("update:uploadErrors", this.uploadErrors);
        this.usedFilenames = {};
        this.usedUrls = {};

        // Adding names of files already uploaded to sample
        if (this.existingSample && this.sample.filesTree.children) {
          for (let i = 0; i < this.sample.filesTree.children.length; i++) {
            this.usedFilenames[this.sample.filesTree.children[i].label] = true;
          }
        }

        // Checking names of files going to be uploaded from disk
        for (let i = 0; i < Object.keys(this.filesToUpload).length; i++) {
          let filename = this.filesToUpload[Object.keys(this.filesToUpload)[i]].name;
          this.checkIfFilenameUsed(filename, 'disk');
          this.checkIfExtensionAllowed(filename, 'disk');
        }

        // Checking names and addresses of files going to be uploaded from URLs
        for (let node in this.urlsToUpload) {
          let filename = this.finalUploadFileName(this.urlsToUpload[node].name, this.urlsToUpload[node].url);
          this.checkIfFilenameUsed(filename, 'url');
          this.checkIfExtensionAllowed(filename, 'url');
          this.checkIfUrlUsed(this.urlsToUpload[node].url, 'url');
        }

        // Checking name and address of file going to be uploaded from FTP
        if (this.ftp.url) {
          if (validateFtp(this.ftp.url)) {
            let filename = this.finalUploadFileName(this.ftp.filename, this.ftp.url);
            this.checkIfFilenameUsed(filename, 'ftp');
            this.checkIfExtensionAllowed(filename, 'ftp');
            this.checkIfUrlUsed(this.ftp.url, 'ftp');
          } else {
            this.$set(this.uploadErrors, "ftp", this.uploadErrors.ftp.concat(`${this.ftp.url} isn't a valid FTP address.`));
            this.$emit("update:uploadErrors", this.uploadErrors);
          }
        }
      },
      checkIfExtensionAllowed(filename, type) {
        const allowedExtensions = this.workflowInputExtensionsLong.split(", ");
        const extension = this.fileExtensionFromFilename(filename);
        if (!allowedExtensions.includes(extension)) {
          this.$set(this.uploadErrors, type, this.uploadErrors[type].concat(`File ${filename} cannot be uploaded due to the incorrect extension.`));
          this.$emit("update:uploadErrors", this.uploadErrors);
        }
      },
      isExtensionAllowed(extension) {
        const allowedExtensions = this.workflowInputExtensionsLong.split(", ");
        return !!allowedExtensions.includes(extension);
      },
    }
  }
</script>

<style scoped lang="scss">
.iseq-files-upload {
  &__inputs {
    .input-field {
      padding-bottom: 5px;
      display: flex;
      flex-direction: row;
      align-items: baseline;

      &__extension {
        font-size: 25px;
        color: var(--font-color);
        padding-left: 5px;

        &--wrong {
          color: var(--danger-color);
        }
      }
    }

    .input-field:last-of-type {
      padding-bottom: 0px;
    }
  }

  &__button {
    display: flex;
    padding-top: 5px;
    justify-content: flex-end;
  }

  &__files-list {
    padding-top: 10px;
  }

  &__error-list {
    width: 90%;
    color: var(--danger-color);
    font-size: 15px;
    margin-bottom: 5px;

    &__node {
      margin-bottom: 5px;
    }
  }
}
</style>