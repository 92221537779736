<template>
  <div class="iseq-file-progressbar-list">
    <div class="iseq-file-progressbar-list__header">
      <span class="element--first">Name: </span>
      <span class="element">0%</span>
      <span class="element">25%</span>
      <span class="element">50%</span>
      <span class="element">75%</span>
      <span class="element--last" />
    </div>

    <div
      v-for="(item, index) in items"
      :key="index"
      class="iseq-file-progressbar-list__row"
    >
      <v-file-progressbar
        :item="item"
        :show-actions="showActions"
      />
    </div>
  </div>
</template>

<script>
  import fileProgressbar from "@/components/ui/progressbar/fileProgressbar.component.vue";

  export default {
    name: "FileProgressbarList",
    components: {
      'v-file-progressbar': fileProgressbar
    },
    props: {
      items: {
        type: Array,
        default: () => []
      },
      showActions: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss"
       scoped>
    .iseq-file-progressbar-list{
        &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 10px;
            margin-top: 15px;
            color: #000000;
            font-weight: 500;

            .element {
                text-align: left;
                width: calc(20% - 14px);
                font-size: 1em;

                &--first {
                    width: 20%;
                    padding-left: 10px;
                    margin-right: 15px;
                    font-size: 1.4em;
                }

                &--last {
                    width: 40px;
                }
            }
        }

        &__row {
            display: flex;
            align-items: center;
            height: 25px;
            margin-bottom: 10px;
            position: relative;
            width: 100%;
        }
    }
</style>