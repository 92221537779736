<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="iseq-workflow">
    <v-iseq-banner
      v-if="$route.query.version && workflow.version && $route.query.version !== workflow.version"
      type="warning"
      :value="`Link you have used to get here uses an old version of this workflow (${$route.query.version }). Workflow visible below is the current newest version (${ workflow.version }).`"
      center-content
    />

    <v-iseq-card
      :title="`Workflow: ${workflow.name}`"
      no-bottom-margin
      class="iseq-workflow__content"
    >
      <template #title-row>
        <div>
          <v-iseq-btn
            v-if="permissionRequired('analysisAdd')"
            large
            post-icon="mdi-plus-circle"
            @click.prevent="newAnalysis"
          >
            Create analysis for this workflow
          </v-iseq-btn>
        </div>
      </template>
    </v-iseq-card>

    <v-iseq-card
      title="Description"
      class="iseq-workflow__content"
    >
      <div
        class="iseq-workflow__content__field"
        v-html="compileMarkdown(workflow.description)"
      />

      <div
        v-if="isLongDescriptionOpen && workflow.longDescription"
        class="iseq-workflow__content__field"
        v-html="compileMarkdown(workflow.longDescription)"
      />

      <div
        v-if="workflow.longDescription"
        class="iseq-workflow__content__field"
        @click="isLongDescriptionOpen = !isLongDescriptionOpen"
      >
        <span class="iseq-workflow__content__field__text">
          {{ `${isLongDescriptionOpen ? 'Show less' : 'Read more...'}` }}
        </span>
      </div>
    </v-iseq-card>

    <v-iseq-card
      title="Details"
      class="iseq-workflow__content"
    >
      <v-iseq-info-field
        title="Name"
        :value="workflow.name"
        class="iseq-workflow__content__text-field"
      />

      <v-iseq-info-field
        title="Version"
        :value="workflow.version"
        class="iseq-workflow__content__text-field"
      />

      <v-iseq-info-field
        title="Author / Supervisor"
        :value="workflow.author"
        class="iseq-workflow__content__text-field"
      />

      <v-iseq-info-field
        title="Copyright"
        :value="workflow.copyright"
        class="iseq-workflow__content__text-field"
      />
    </v-iseq-card>

    <v-iseq-card
      title="Inputs"
      class="iseq-workflow__content"
    >
      <div
        v-for="input in visibleInputs"
        :key="input.id"
      >
        <v-row
          fluid
          class="iseq-workflow__content__row"
        >
          <div class="iseq-workflow__content__row__item col-3">
            Name: {{ input.name }} <br>
          </div>

          <div class="iseq-workflow__content__row__item col-8">
            Description: <br>

            <div v-html="compileMarkdown(input.description)" />
          </div>
        </v-row>
      </div>
    </v-iseq-card>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import {permissionCheck} from "@/plugins/permissions";
  import {markdownCompile} from "@/plugins/utils";
  import IseqInfoField from '@/components/ui/IseqInfoField.component.vue'
  import IseqCard from "@/components/ui/IseqCard.vue";
  import IseqButton from "@/components/ui/IseqButton.vue";
  import IseqBanner from "@/components/ui/IseqBanner.vue";
  import {segmentTrack} from "@/helpers/analytics";

  export default {
    name: "WorkflowDetails",
    components: {
      'v-iseq-info-field': IseqInfoField,
      "v-iseq-card": IseqCard,
      "v-iseq-btn": IseqButton,
      "v-iseq-banner": IseqBanner
    },
    data: function () {
      return {
        isLongDescriptionOpen: false
      }
    },
    computed: {
      ...mapState('workflow', ['workflow']),
      visibleInputs() {
        return this.workflow.inputs ? this.workflow.inputs : [];
      }
    },
    created: function () {
      this.$store.dispatch('workflow/getWorkflow', this.$route.params.workflowId);
    },
    methods: {
      permissionRequired(action) {
        return permissionCheck(action)
      },
      compileMarkdown(data) {
        return markdownCompile(data);
      },
      newAnalysis() {
        segmentTrack({event: "Create Analysis For This Workflow Clicked", payload: {workflow_uuid: this.$route.params.workflowId}});
        this.$router.push({path: `/analyses/new/${this.$route.params.workflowId}`});
      }
    }
  }
</script>

<style scoped
       lang="scss">
.iseq-workflow {
  &__warning {
    display: flex;
    padding: 10px 20px;
    margin: 20px 40px 20px 40px;
    background-color: var(--warning-color) !important;
    justify-content: center;
    align-content: center;

    &__text {
      margin-left: 10px;
      color: white;
      font-size: 1.3em;
    }
  }

  &__content {
    &__field {
      margin: 0px 10px 20px 16px;
      font-size: 14px;
      color: var(--font-inverted-color);

      &__text {
        color: var(--font-color);
        text-decoration: underline;
        cursor: pointer;
        font-size: 14px;
      }
    }

    &__text-field {
      margin: 20px 32px;
    }

    &__row {
      margin: 0 8px 0;
      justify-content: center;

      &__item {
        margin: 8px;
        padding: 8px;
        background-color: var(--tertiary-color);
      }
    }
  }
}
</style>