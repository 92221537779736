<template>
  <div>
    <treeselect
      v-model="localValue"
      :multiple="false"
      :options="allowedExtensionsFilter(filesTree.children, allowedExtensions)"
      :clearable="true"
      :searchable="true"
      :flatten-search-results="true"
      :close-on-select="true"
      :disable-branch-nodes="true"
      placeholder="Please select file"
      search-nested
    >
      <label
        slot="option-label"
        slot-scope="{node}"
      >
        <template v-if="node.raw.runDate">
          <v-tooltip right>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >
                {{ node.label }}
              </span>
            </template>

            <span>{{ node.raw.runDate }}</span>
          </v-tooltip>
        </template>
        <template v-else>
          {{ node.label }}
        </template>
      </label>
    </treeselect>
  </div>
</template>

<script>
  import Treeselect from '@riophae/vue-treeselect'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'

  export default {
    name: "IseqFileInput",
    components: {Treeselect},
    props: {
      value: {
        required: false,
        default: undefined
      },
      input: {
        type: Object,
        required: true
      },
      filesTree: {
        type: Object,
        required: false,
        default: () => {}
      },
      allowedExtensionsFilter: {
        type: Function,
        required: true
      }
    },
    computed: {
      localValue: {
        get() {
          return this.value;
        },
        set(newValue) {
          this.$emit('input', newValue);
        }
      },
      allowedExtensions() {
        if (this.input.constraints) {
          return this.input.constraints.extensions;
        } else {
          return undefined;
        }
      }
    }
  }
</script>