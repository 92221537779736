import Vue        from 'vue'
import request    from '@/customjs/request.js'
import store      from '@/store'
import router     from '@/router'

export default {
  namespaced: true,
  state: {
    user: {userSettings: {}},
    userCredits: undefined,
    navBarUserInfo: {
      email: ''
    },
    isAdmin: false,
  },
  getters: {
    user(state) {
      return state.user
    },
    userCredits(state) {
      return state.userCredits;
    }
  },
  mutations: {
    setUserData(state, data) {
      state.user = data;
      Vue.$log.debug("Set user data for " + state.user.email);
      const path = router.currentRoute.path;
      // Following views will force their own context organization and project to load
      if(
        !/organizations\/[a-zA-Z0-9\-]+$/.test(path) &&
                !/projects\/[a-zA-Z0-9\-]+$/.test(path) &&
                !/samples\/[a-zA-Z0-9\-]+$/.test(path) &&
                !/analyses\/[a-zA-Z0-9\-]+$/.test(path)
      ) {
        store.dispatch('organization/getHeaderOrganizations', {});
      }
      store.dispatch('event/getUserEvents')
    },
    setNavBarUserInfo(state, payload) {
      state.navBarUserInfo = {
        email: payload.email
      };
    },
    setUserAdminStatus(state, payload) {
      state.isAdmin = payload;
    },
    cleanAllData(state) {
      state.user = {userSettings: {}};
      state.navBarUserInfo = {
        email: ''
      };
      state.isAdmin = false;
    },
    setUserCredits(state, payload) {
      state.userCredits = payload;
    },
    setDashboardGettingStartedVisibility(state, payload) {
      state.user.userSettings.dashboardGettingStartedVisible = payload;
    },
    setGetResponseUserData(state, payload) {
      state.user.userSettings.marketing.getResponseAccountStatus = payload
    }
  },
  actions: {
    getCredits({state, commit}) {
      const organizationUuid = store.getters["organization/currentOrganizationUuid"];

      if (organizationUuid) {
        request.request({
          endpoint: `organizations/${organizationUuid}/credits`,
          method: "get",
          callback: function (data) {
            if (data.credits) {
              commit('setUserCredits', data.credits);
            } else {
              commit('setUserCredits', 0);
            }
          }
        });
      } else {
        commit('setUserCredits', undefined);
      }
    },
    getUser({commit, dispatch}) {
      request.request({
        endpoint: "user",
        method: "get",
        callback: function (data) {
          commit('setUserData', data);
          commit('setNavBarUserInfo', data);
          dispatch('getUserAdminStatus');
        }
      })
    },
    getUserAdminStatus({commit, dispatch}) {
      request.request({
        endpoint: "admin",
        method: "get",
        callback: function (data) {
          commit('setUserAdminStatus', data);
        }
      })
    },
    setData({dispatch}, payload){
      request.request({
        endpoint: "user",
        method: "put",
        data: {userName: payload},
        callback: function () {
          store.dispatch('snackbarQueue/addConfirmationMessage', {type: 'userNameChanged'});
          dispatch('getUser')
        }
      });
    },
    hideDashboardGettingStartedSection({commit}) {
      request.request({
        endpoint: "user/settings",
        method: "patch",
        data: {
          dashboardGettingStartedVisible: false
        },
        callback: function() {
          commit('setDashboardGettingStartedVisibility', false);
        }
      });
    },
    updateGetResponseData({commit}, payload) {
      request.request({
        endpoint: "user/get-response",
        method: "put",
        data: {
          getResponseAccountStatus: payload
        },
        callback: function() {
          commit('setGetResponseUserData', payload);
        }
      });
    },
    reportBug({}, payload) {
      if (payload.sendMe) {
        request.request({
          endpoint: "bug-report",
          method: "post",
          data: {
            bugDescription: payload.content,
            sendEmail: payload.sendEmail
          },
          callback: function () {
            if (payload.showNotification) {
              store.dispatch("snackbarQueue/addConfirmationMessage", {type: "bugReported"});
            }
          }
        });
      }
    },
  }
}
