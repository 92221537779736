<template>
  <div class="iseq-project-create">
    <v-iseq-card
      title="Create new project"
      class="iseq-project-create__content"
    >
      <div class="iseq-project-create__content__wrapper">
        <v-form
          ref="nameForm"
          v-model="isNameFormValid"
          class="iseq-project-create__content__wrapper__item"
        >
          <v-text-field
            v-model="project.name"
            class="text-field"
            outlined
            :rules="nameRules"
            counter="50"
            label="Name"
            @change="validateName"
            @keyup="validateName"
          />
        </v-form>

        <v-text-field
          v-model="organization.name"
          class="iseq-project-create__content__wrapper__item"
          outlined
          readonly
          background-color="#eee"
          label="Organization"
        />
      </div>

      <div class="iseq-project-create__content__wrapper">
        <v-textarea
          v-model="project.description"
          name="iseq-project-create__content__wrapper__textarea"
          :rows="5"
          outlined
          label="Description"
          auto-grow
        />
      </div>
    </v-iseq-card>

    <v-iseq-card
      no-title
      no-bottom-margin
      class="iseq-project-create__create-section"
    >
      <template #title-row>
        <div class="iseq-project-create__create-section__button">
          <v-tooltip
            :disabled="!createProjectDisabledReasons.length"
            top
          >
            <template #activator="{on}">
              <div v-on="on">
                <v-iseq-btn
                  large
                  width="wide"
                  :disabled="!isNameFormValid"
                  @click.prevent="createProject"
                >
                  Create project
                </v-iseq-btn>
              </div>
            </template>
            <span> {{ createProjectDisabledReasons }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-iseq-card>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import {permissionCheck} from "@/plugins/permissions";
  import IseqCard from "@/components/ui/IseqCard.vue";
  import IseqButton from "@/components/ui/IseqButton.vue";
  import {segmentTrack} from "@/helpers/analytics";

  export default {
    name: "ProjectCreate",
    components: {
      "v-iseq-card": IseqCard,
      "v-iseq-btn": IseqButton
    },
    beforeRouteLeave(to, from, next) {
      if (!to.name.includes('project')) {
        this.$store.commit('project/clearProjectsTableOptions');
        next();
      } else {
        next();
      }
    },
    data: function () {
      return {
        project: {
          name: "",
          description: ""
        },
        isNameFormValid: false,
        nameRules: [
          v => (!!v && v.length > 0) || "Name cannot be empty",
          v => v.length <= 50 || "Name cannot be longer than 50 characters",
          v => !this.headerProjects.some(element => element.name === v) || "Project named " + v + "already exists in this organization"
        ]
      }
    },
    computed: {
      ...mapState('organization', ['organizations', 'organization']),
      ...mapState('project', ['headerProjects']),
      createProjectDisabledReasons() {
        if (!this.isNameFormValid) {
          return "Project name is invalid";
        } else {
          return "";
        }
      }
    },
    watch: {
      organization() {
        if (!permissionCheck('projectAdd')) {
          this.$router.push('/projects');
        }
      }
    },
    methods: {
      validateName() {
        return this.$refs.nameForm.validate();
      },
      createProject() {
        if (this.validateName()) {
          segmentTrack({event: "Create Project Clicked"});
          this.$store.dispatch('project/createProject', {
            organizationUuid: this.organization.uuid,
            data: this.project
          });
        }
      },
    }
  }
</script>

<style scoped
       lang="scss">

.iseq-project-create {
  &__content {
    &__wrapper {
      margin: 10px 20px;
      display: flex;
      flex-direction: row;

      &__item {
        width: calc(50% - 5px);

        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }

  &__create-section {
    &__button {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
  }
}


</style>