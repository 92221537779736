import VueCookies  from 'vue-cookies'
import request     from '@/customjs/request.js'
import store       from '@/store'
import {generatePagingData, timeZoned} from "@/plugins/utils";
import {permissionCheck} from "@/plugins/permissions";
import {handleDeletedResourceError} from "@/helpers/errorHandlers";

export default {
  namespaced: true,
  state: {
    organization: {},
    organizations: [],
    content: [],
    totalElements: 0,
    members: [],
    permissionLevels: [
      "ORGANIZATION_OWNER",
      "ORGANIZATION_ADMIN",
      "ORGANIZATION_MEMBER"
    ],
    transactionsHistory: [],
    invitations: []
  },
  mutations: {
    setOrganizations(state, payload) {
      let data = payload.data;

      data.map(org => {
        org.route = "/organizations/" + org.uuid;
        org.creationDate = timeZoned(org.creationDate);
        return org
      });

      data.sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      });

      state.organizations = data;

      let organizationUuid = undefined;

      // Checking if preferred organization given and present on the list
      if (payload.preferredOrganization) {
        organizationUuid = data.find(node => node.uuid === payload.preferredOrganization);
        if (organizationUuid) {
          organizationUuid = organizationUuid.uuid;
        }
      }

      // If organization still not chosen checking if we have cookie saved and it's value present on the list
      if (!organizationUuid && VueCookies.get('organization')) {
        const cookie = VueCookies.get('organization');
        organizationUuid = data.find(node => node.uuid === cookie);
        if (organizationUuid) {
          organizationUuid = organizationUuid.uuid;
        }
      }

      // If organization still not chosen selecting first value from the list
      if (!organizationUuid) {
        organizationUuid = data[0].uuid;
      }

      store.dispatch('organization/getOrganization', {organizationUuid: organizationUuid, preferredProject: payload.preferredProject});
    },
    setOrganization(state, payload) {
      payload.data.creationDate = timeZoned(payload.data.creationDate);
      state.organization = payload.data;
      if (payload.data.status.id !== "DELETED") {
        VueCookies.set('organization', payload.data.uuid);
        store.dispatch('project/getHeaderProjects', {
          organizationUuid: payload.data.uuid,
          preferredProject: payload.preferredProject
        })
      }
    },
    cleanAllData(state) {
      state.organization = {};
      state.organizations = [];
    },
    setContent(state, payload) {
      state.content = payload
    },
    setTotalElements(state, payload) {
      state.totalElements = payload
    },
    clearConent(state) {
      state.content = [];
      state.totalElements = 0;
    },
    setMembers(state, payload) {
      state.members = payload;
    },
    clearMembers(state) {
      state.members = [];
    },
    setTransactionsHistory(state, payload) {
      state.transactionsHistory = payload;
    },
    setInvitations(state, payload) {
      state.invitations = payload;
    },
  },
  getters: {
    userRole: (state) => {
      return state.organization.role;
    },
    userOrganizations: (state) => {
      return state.organizations.map(organization => {
        return {name: organization.name, uuid: organization.uuid}
      });
    },
    currentOrganizationUuid: (state) => {
      return state.organization.uuid;
    }
  },
  actions: {
    // GET

    getHeaderOrganizations({commit}, payload) {
      request.request({
        endpoint: "organizations/minimal",
        callback: function (data) {
          commit('setOrganizations', {
            data: data,
            preferredOrganization: payload.preferredOrganization,
            preferredProject: payload.preferredProject
          })
        }
      })
    },

    getOrganizationsList({commit, dispatch}, payload) {
      const pagingParams = payload.data ? payload.data : generatePagingData({})

      request.request({
        endpoint: `organizations?${pagingParams}`,
        callback: function (data) {
          commit('setContent', data.content);
          commit('setTotalElements', data.totalElements);
        }
      })
    },

    getOrganization({commit, dispatch}, payload) {
      request.request({
        endpoint: `organizations/${payload.organizationUuid}`,
        callback: function (data) {
          if (data.status.id !== "DELETED") {
            commit('setOrganization', {data: data, preferredProject: payload.preferredProject});
            if (payload.getAllData) {
              store.dispatch('project/getUserProjects', {organizationUuid: payload.organizationUuid});
              store.dispatch('project/getJoinableProjects', {organizationUuid: payload.organizationUuid});
              dispatch('getOrganizationMembers', {uuid: data.uuid});
              dispatch('getOrganizationsList', {});
              if (permissionCheck('organizationTransactionHistoryView')) {
                dispatch('getTransactionHistory', {uuid: data.uuid});
              }
              if (permissionCheck('organizationUserInvite')) {
                dispatch('getInvitations', {uuid: data.uuid});
              }
            }
          } else {
            handleDeletedResourceError();
          }
        }
      })
    },

    getOrganizationMembers({commit, dispatch}, payload) {
      request.request({
        endpoint: `organizations/${payload.uuid}/members`,
        callback: function (data) {
          commit('setMembers', data);
        }
      })
    },

    getTransactionHistory({commit}, payload) {
      request.request({
        endpoint: `organizations/${payload.uuid}/transactions`,
        method: "get",
        callback: function (data) {
          commit('setTransactionsHistory', data);
        }
      })
    },

    getInvitations({commit}, payload) {
      request.request({
        endpoint: `organizations/${payload.uuid}/invitations`,
        method: "get",
        callback: function (data) {
          commit('setInvitations', data);
        }
      })
    },

    updateOrganization({dispatch, state}, payload) {
      request.request({
        method: "patch",
        endpoint: `organizations/${payload.uuid}`,
        data: payload.formData,
        callback: function (data) {
          dispatch('getHeaderOrganizations', {});
        }
      })
    },

    updateMemberRole({dispatch, commit, state}, payload) {
      request.request({
        method: "patch",
        endpoint: `organizations/${payload.uuid}/role`,
        data: payload.data,
        callback: function () {
          commit('clearMembers');
          dispatch('getOrganizationMembers', {uuid: payload.uuid});
        }
      })
    },

    inviteMemberToOrganization({}, payload) {
      request.request({
        endpoint: `organizations/${payload.uuid}/invitations`,
        method: "post",
        data: {
          email: payload.email,
          role: payload.position
        },
        callback: function() {
          store.dispatch("snackbarQueue/addConfirmationMessage", {type: "invitationSentSuccessfully"});
        }
      })
    },

    // DELETE

    removeMember({state, dispatch}, payload){
      request.request({
        snackbarMessage: "Removing user from organization",
        endpoint: `organizations/${payload.organizationUuid}/users/${payload.uuid}`,
        method: "delete",
        callback: function () {
          dispatch('getOrganizationMembers', {uuid: payload.organizationUuid});
        }
      })
    }
  }
}