<template>
  <div class="iseq-analysis-examples-list">
    <v-iseq-card
      title="Example analyses"
      class="iseq-analysis-examples-list__content"
    >
      <v-data-table
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions
        }"
        :headers="analysisColumns"
        :items="content"
        :server-items-length="totalElements"
        :options.sync="analysisTableOptions"
        class="iseq-analysis-example-list__content__table"
        @click:row="goToAnalysis"
      />
    </v-iseq-card>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import IseqCard from "@/components/ui/IseqCard.vue";

  export default {
    name: "AnalysisDemoList",
    components: {
      "v-iseq-card": IseqCard
    },
    data: function () {
      return {
        itemsPerPageOptions: [10, 20, 30, -1],
        analysisColumns: [
          {text: 'Name', value: 'name'},
          {text: 'Workflow', value: 'workflowName'}
        ]
      }
    },
    computed: {
      ...mapState('analysis', ['analyses', 'content', 'totalElements']),
      analysisTableOptions: {
        get() {
          return this.$store.getters['analysis/analysisTableOptions'];
        },
        set(newValue) {
          this.$store.commit('analysis/setAnalysisTableOptions', newValue);
          this.$store.dispatch('analysis/getAnalysisExamplesList');
        }
      }
    },
    created: function () {
      this.$store.dispatch('analysis/getAnalysisExamplesList');
    },
    methods: {
      goToAnalysis(event) {
        this.$router.push({path: `/analyses/demo/${event.uuid}`})
      }
    },
  }
</script>

<style scoped
       lang="scss">

.iseq-analysis-examples-list {
  &__content {
    &__table {
      padding: 16px;
      max-width: 100%;
    }
  }
}
</style>