import {parsePhenotypesInputs, getGenesForDiseases, getGenesFromPanels, validateUserGenes} from "@/services/explorareService"

export async function parseHPOInputsToGenes(HPOInputs, HPOInputsIds) {
  let foundGenes = [];
  let errors = [];
  let promises = [];

  if (HPOInputs.phenotypes_description || HPOInputs.hpo_terms) {
    promises.push(parsePhenotypesInputs({
      phenotypes_description: HPOInputs.phenotypes_description,
      hpo_terms: HPOInputs.hpo_terms,
      HPOInputsIds: HPOInputsIds
    }));
  }
  if (HPOInputs.diseases) {
    promises.push(getGenesForDiseases(HPOInputs.diseases, HPOInputsIds.diseases));
  }
  if (HPOInputs.panel_names) {
    promises.push(getGenesFromPanels(HPOInputs.panel_names, HPOInputsIds.panel_names));
  }
  if (HPOInputs.genes) {
    promises.push(validateUserGenes(HPOInputs.genes, HPOInputsIds.genes));
  }

  await Promise.allSettled(promises).then((results) => {
    results.forEach((result) => {
      if (result.status === 'fulfilled') {
        foundGenes = [...foundGenes, ...result.value];
      } else {
        if (Array.isArray(result.reason)) {
          // Errors from combined multiple endpoints will return as array
          errors = [...errors, ...result.reason];
        } else {
          // Errors from single endpoints will return as single value objects
          errors = [...errors, {reason: result.reason.reason, id: result.reason.inputID}];
        }
      }
    })
  })

  if (!errors.length && !foundGenes.length) {
    errors.push({reason: "No genes with score above 25.0 were found.", id: undefined});
  }

  return {
    genes: removeDuplicatedGenes(foundGenes),
    errors: errors
  }
}

function removeDuplicatedGenes(genes) {
  // order genes by name (asc) and score (desc) for removing duplicates purpose
  genes.sort((a, b) => a.score > b.score ? -1 : 1).sort((a, b) => a.name < b.name ? -1 : 1);
  
  // among the same genes keep the one with the highest score
  for (let i=1; i<genes.length; i++) {
    if (genes[i].name === genes[i-1].name) {
      if (!genes[i-1].type.includes(genes[i].type)) {
        genes[i-1].type += `, ${genes[i].type}`;
      }
      genes.splice(i, 1);
      i--;
    }
  }
  
  // order genes by score (desc) and name (asc)
  genes.sort((a, b) => a.name < b.name ? -1 : 1).sort((a, b) => a.score > b.score ? -1 : 1);
  
  return genes;
}

export function parseDiseasesSuggestions(diseases) {
  return markDiseasesWithNoGenes(sortDiseasesByGenesCount(cleanDoubledDiseases(diseases)));
}

function cleanDoubledDiseases(diseases) {
  let cleanDiseases = [];
  diseases.forEach(disease => {
    let foundCopy = cleanDiseases.find( node => node.name.toLowerCase() === disease.name.toLowerCase());
    if (!foundCopy) {
      cleanDiseases.push(disease);
    } else if (foundCopy.genesCount < disease.genesCount) {
      cleanDiseases.splice(cleanDiseases.indexOf(foundCopy, 1));
      cleanDiseases.push(disease);
    }
  });
  return cleanDiseases;
}

function sortDiseasesByGenesCount(diseases) {
  return diseases.sort(function(a, b) {
    if (a.genesCount === 0 && b.genesCount === 0) {
      return a.name > b.name ? 1 : -1;
    } else if (a.genesCount !==0 && b.genesCount !==0) {
      return a.name > b.name ? 1 : -1;
    } else {
      return a.genesCount === 0 ? 1 : -1;
    }
  });
}

function markDiseasesWithNoGenes(diseases) {
  return diseases.map(disease => {
    disease.isDisabled = disease.genesCount === 0;
    return disease;
  });
}