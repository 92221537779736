import Vue         from 'vue'
import request     from '@/customjs/request.js'
import router      from '@/router'
import store       from '@/store'
import {timeZoned} from "../../plugins/utils";
import organization from "./organization";

export default{
  namespaced: true,

  state: {
    invitations: [],
    invitation: {sender: {}},
    sentInvitations: {}
  },

  mutations: {
    setInvitations(state, data) {
      data.map(invitation => {
        invitation.route = "/invitations/" + invitation.uuid;
        invitation.expirationDate = timeZoned(invitation.expirationDate);
        invitation.sendDate = timeZoned(invitation.sendDate);
        return invitation
      });
      data.sort(function(a, b) {
        let keyA = new Date(a.expirationDate),
          keyB = new Date(b.expirationDate);
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
      state.invitations = data;
    },
    setSentInvitations(state, data) {
      Vue.$log.debug("|===|===|> SENT INVITATIONS");
      data.map(invitation => {
        invitation.route = "/invitations/" + invitation.uuid;
        invitation.expirationDate = timeZoned(invitation.expirationDate);
        invitation.sendDate = timeZoned(invitation.sendDate);
        return invitation
      });
      data.sort(function(a, b) {
        let keyA = new Date(a.expirationDate),
          keyB = new Date(b.expirationDate);
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
      state.sentInvitations = data;
    },
    setInvitation(state, data){
      data.expirationDate = timeZoned(data.expirationDate);
      data.sendDate = timeZoned(data.sendDate);
      state.invitation = data;
    },
    cleanAllData(state){
      state.invitations = [];
      state.invitation = {sender: {}};
      state.sentInvitations = {};
    }
  },

  getters: {
    invitationsNumber: (state) => {
      return state.invitations.length;
    },
  },

  actions: {

    // GET

    getInvitations({commit}) {
      Vue.$log.debug("vue.vuex.organization.getInvitations");
      request.request({
        endpoint: "invitations/pending",
        callback: function (data) {
          commit('setInvitations', data)
        }
      })
    },

    getSentInvitations({commit}) {
      Vue.$log.debug("vue.vuex.organization.getSentInvitations");
      request.request({
        endpoint: "invitations/sent",
        callback: function (data) {
          commit('setSentInvitations', data)
        }
      })
    },

    getInvitation({commit}, uuid) {
      Vue.$log.debug("vue.vuex.organization.getInvitation");
      request.request({
        endpoint: `invitations/${uuid}`,
        callback: function (data) {
          commit('setInvitation', data)
        }
      })
    },

    acceptInvitation({dispatch}, payload) {
      Vue.$log.debug("vue.vuex.organization.acceptInvitation");
      request.request({
        method: "put",
        endpoint: `invitations/${payload.uuid}`,
        callback: function (data) {
          if (payload.objectType === 'organization') {
            store.dispatch('organization/getHeaderOrganizations', {});
          } else if (payload.objectType === 'project' && data.uuid === store.getters["organization/currentOrganizationUuid"]) {
            store.dispatch('project/getHeaderProjects', {organizationUuid: data.uuid});
          }
          dispatch('getInvitations');
        }
      })
    },

    // DELETE

    rejectInvitation({dispatch}, payload) {
      Vue.$log.debug("vue.vuex.organization.rejectInvitation");
      request.request({
        snackbarMessage: "Rejecting invitation",
        method: "delete",
        endpoint: `invitations/${payload.uuid}`,
        callback: function () {
          dispatch('getInvitations');
          if (payload.redirect) {
            router.push({path: `/`});
          }
        }
      })
    }
  }
}
