<template>
  <div class="iseq-properties">
    <div class="input">
      <span class="input__name">Add property</span>

      <div class="input__tab-selector">
        <v-tabs
          v-model="propertiesTab"
          color="primary"
          centered
        >
          <v-tab
            key="Predefined"
            class="input__tab-selector__tab"
            :disabled="editPropertyMode || overwritePropertyMode"
            @click="resetNewPropertySwap('predefined')"
          >
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="input__tab-selector__tab__icon">
                    mdi-account-multiple
                  </v-icon>
                  Predefined
                </div>
              </template>

              <span>Predefined properties will be included in<br>
                final report of "WES/WGS hereditary<br>
                disorders ACMG report" workflows</span>
            </v-tooltip>
          </v-tab>

          <v-tab
            key="Personalized"
            class="input__tab-selector__tab"
            :disabled="editPropertyMode || overwritePropertyMode"
            @click="resetNewPropertySwap('personalized')"
          >
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="input__tab-selector__tab__icon">
                    mdi-folder
                  </v-icon>
                  Personalized
                </div>
              </template>

              <span>Add your own (unique) properties that are <br>
                not included in the predefined list.</span>
            </v-tooltip>
          </v-tab>
        </v-tabs>
      </div>

      <div class="input__input-fields">
        <v-tabs-items
          v-model="propertiesTab"
          touchless
        >
          <v-tab-item key="Predefined">
            <v-select
              v-model="newProperty.key"
              :items="predefinedProperties"
              item-value="value"
              item-text="text"
              outlined
              :hide-details="true"
              :disabled="editPropertyMode || overwritePropertyMode"
              class="input__input-fields__field"
            />

            <template v-if="keyIsDate(newProperty.key)">
              <v-date-picker
                v-model="newProperty.value"
                color="dark"
                header-color="primary"
                :disabled="overwritePropertyMode"
                class="input__input-fields__field"
              />
            </template>

            <template v-else-if="selectedProperty && selectedProperty.type==='select'">
              <v-select
                v-model="newProperty.value"
                item-text="text"
                item-value="value"
                outlined
                :hide-details="true"
                :disabled="overwritePropertyMode"
                class="input__input-fields__field"
                :items="selectPropertyValues"
              />
            </template>

            <template v-else-if="selectedProperty && selectedProperty.type==='longString'">
              <v-textarea
                v-model="newProperty.value"
                :rows="5"
                auto-grow
                outlined
                :hide-details="typeof propertyLengthCounter() === 'boolean'"
                :counter="propertyLengthCounter()"
                :disabled="overwritePropertyMode"
                placeholder="patient description"
                class="input__input-fields__field"
                @input="trimNewPropertyValue()"
              />
            </template>

            <template v-else>
              <v-text-field
                v-model="newProperty.value"
                class="input__input-fields__field"
                outlined
                :hide-details="typeof propertyLengthCounter() === 'boolean'"
                :counter="propertyLengthCounter()"
                :disabled="overwritePropertyMode"
                label="Property value"
                @input="trimNewPropertyValue()"
                @keyup.enter="addProperty"
              />
            </template>
          </v-tab-item>

          <v-tab-item key="Personalized">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="newProperty.key"
                  class="input__input-fields__field"
                  outlined
                  v-bind="attrs"
                  :hide-details="true"
                  :disabled="editPropertyMode || overwritePropertyMode"
                  label="Property key"
                  v-on="on"
                  @keyup.enter="addProperty"
                />
              </template>

              <span>Property keys ending with "_date" ("_Date", "_DATE" etc.) <br>
                will trigger calendar input for Property value.</span>
            </v-tooltip>

            <template v-if="keyIsDate(newProperty.key)">
              <v-date-picker
                v-model="newProperty.value"
                color="dark"
                header-color="primary"
                :disabled="overwritePropertyMode"
                class="input__input-fields__field"
              />
            </template>

            <template v-else>
              <v-textarea
                v-model="newProperty.value"
                class="input__input-fields__field"
                :rows="5"
                outlined
                :counter="5000"
                :disabled="overwritePropertyMode"
                label="Property value"
                @keyup.enter="addProperty"
              />
            </template>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <div class="input__actions">
        <template v-if="emptyPropertyNotifier && (newProperty.key.trim() === '' || newProperty.value.trim() === '')">
          <span class="input__actions__text input__actions__text--red">
            Both <span class="bold">key</span> and <span class="bold">value</span> of property cannot be empty!
          </span>
        </template>

        <template v-if="invalidKeyNotifier && !newProperty.key.match(propertyKeyRegex)">
          <span class="input__actions__text input__actions__text--red">
            Property key contains illegal characters.
          </span>
        </template>

        <template v-if="overwritePropertyMode">
          <span class="input__actions__text">
            Property with key <span class="bold">{{ translatePropertyKey(newProperty.key) }}</span> {{ newPropertyKey === overwritePropertyKey ? '' : ` (${overwritePropertyKey})` }} already exists. Do you want to overwrite it?
          </span>

          <div class="properties__element">
            <div class="properties__element__text">
              <span class="name">{{ translatePropertyKey(newProperty.key) }}:</span>

              <span class="value"><s>{{ overwritePropertyValue }}</s> <v-icon color="dark">mdi-arrow-right</v-icon> {{ overwriteDatePropertyParser(newProperty.value) }}</span>
            </div>
          </div>

          <div class="input__actions__buttons">
            <v-iseq-btn
              type="red"
              mr
              @click="overwritePropertyMode = false"
            >
              Cancel
            </v-iseq-btn>

            <v-iseq-btn
              @click="forceOverwriteProperty=true; addProperty()"
            >
              Overwrite
            </v-iseq-btn>
          </div>
        </template>

        <template v-if="editPropertyMode">
          <div class="input__actions__buttons">
            <v-iseq-btn
              type="red"
              mr
              @click="cancelEditProperty"
            >
              Cancel
            </v-iseq-btn>

            <v-iseq-btn
              @click="addProperty()"
            >
              Save
            </v-iseq-btn>
          </div>
        </template>

        <template v-if="!editPropertyMode && !overwritePropertyMode">
          <div class="input__actions__buttons">
            <v-iseq-btn
              :disabled="typeof propertyLengthCounter() !== 'boolean' && propertyLengthCounter() < newProperty.value.length"
              @click="addProperty"
            >
              Add
            </v-iseq-btn>
          </div>
        </template>
      </div>
    </div>

    <v-divider vertical />

    <div class="properties">
      <div
        v-for="(property, key) in properties"
        :key="key"
      >
        <div
          class="properties__element"
          @mouseenter="mouseOverProperty = property.key"
          @mouseleave="mouseOverProperty = ''"
        >
          <div class="properties__element__text">
            <span class="name">{{ translatePropertyKey(property.key) }}:</span>

            <span class="value">{{ property.value }}</span>
          </div>

          <div v-if="property.key === mouseOverProperty"
              class="properties__element__icons properties__element__icons--icon-content-version"
          >
              <v-icon
                class="icon icon--blue"
                @click="editProperty(property.key, property.value)"
              >
                mdi-pencil
              </v-icon>

              <v-icon
                class="icon icon--red"
                @click="deleteProperty(property.key)"
              >
                remove_circle
              </v-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import iseqButton from "@/components/ui/IseqButton.vue";

  export default {
    name: "Properties",
    components: {
      "v-iseq-btn": iseqButton
    },
    props: {
      properties: {
        type: Array,
        default: () => []
      }
    },
    data: function() {
      return {
        newProperty: {
          key: "surname",
          value: ""
        },
        mouseOverProperty: "",
        propertiesTab: 0,
        editPropertyMode: false,
        emptyPropertyNotifier: false,
        invalidKeyNotifier: false,
        propertyKeyRegex: /^(?!\s)[\wÀ-ž\sΑ-Ωα-ω-]+(?<!\s)$/g,
        overwritePropertyMode: false,
        overwritePropertyValue: "",
        overwritePropertyKey: "",
        forceOverwriteProperty: false,
        predefinedProperties: [
          {divider: true},
          {header: "Patient"},
          {divider: true},
          {text: "Surname", value: "surname", maxLength: 30, type: "string"},
          {text: "Name", value: "name", maxLength: 30, type: "string"},
          {text: "Sex", value: "sex", type: "select", values: [
              {text: "Male", value: "male"},
              {text: "Female", value: "female"}
            ]},
          {text: "Birth date", value: "birth_date", type: "date"},
          {text: "Patient ID (e.g. PESEL or Insurance ID)", value: "patient_id", maxLength: 25, type: "string"},
          {text: "Description", value: "patient_description", maxLength: 250, type: "longString"},
          {divider: true},
          {header: "Sample"},
          {divider: true},
          {text: "Sample type", value: "sample_type", maxLength: 25, type: "string"},
          {text: "Source", value: "source", maxLength: 25, type: "string"},
          {text: "Sample collection date", value: "sample_collection_date", type: "date"},
          {text: "Received date", value: "received_date", type: "date"},
          {text: "Specimen type", value: "specimen_type", maxLength: 25, type: "string"},
          {text: "Sequencing data ID", value: "sequencing_data_id", maxLength: 25, type: "string"},
          {text: "Sequencing date", value: "sequencing_date", type: "date"},
          {text: "Sequencing type", value: "sequencing_type", maxLength: 25, type: "string"},
          {text: "Sequencing platform", value: "sequencing_platform", maxLength: 25, type: "string"},
          {divider: true},
          {header: "Doctor"},
          {divider: true},
          {text: "Ordering physician", value: "ordering_physician", maxLength: 25, type: "string"},
          {divider: true},
          {header: "Other"},
          {divider: true},
          {text: "Current medications", value: "current_medications", maxLength: 5000, type: "longString"},
          {text: "Sponsor name", value: "sponsor_name", maxLength: 25, type: "string"}
        ],
      }
    },
    computed: {
      selectPropertyValues() {
        if (this.selectedProperty && this.selectedProperty.type === 'select') {
          return this.selectedProperty.values;
        } else {
          return [];
        }
      },
      selectedProperty() {
        return this.predefinedProperties.find(element => element.value === this.newProperty.key);
      },
      newPropertyKey() {
        return this.newProperty.key;
      }
    },
    watch: {
      newPropertyKey(newKey, oldKey) {
        if (this.keyIsSelect(newKey)) {
          this.newProperty.value = this.predefinedProperties.find(node => node.value === newKey).values[0].value;
        } else if (!this.isKeySameType(newKey, oldKey)) {
          this.newProperty.value = "";
        }
      }
    },
    methods: {
      addProperty() {
        this.newProperty.key = this.newProperty.key.trim();
        if (this.newProperty.key.trim() === '' || this.newProperty.value.trim() === '') {
          this.emptyPropertyNotifier = true;
        } else if (!this.newProperty.key.match(this.propertyKeyRegex)) {
          this.invalidKeyNotifier = true;
        } else {
          if (this.editPropertyMode || this.forceOverwriteProperty || !this.checkPropertyExists()) {
            if (this.keyIsDate(this.newProperty.key)) {
              const date = new Date(this.newProperty.value);
              this.newProperty.value = ('0' + (date.getMonth() + 1)).slice(-2) + "/" + ('0' + date.getDate()).slice(-2) + "/" + date.getFullYear();
            }
            this.$emit("addProperty", {
              key: this.newProperty.key.trim(),
              value: this.newProperty.value.trim(),
              overwrite: !!(this.editPropertyMode || this.forceOverwriteProperty)
            });
            this.resetNewProperty();
            this.overwritePropertyMode = false;
            this.forceOverwriteProperty = false;
            this.editPropertyMode = false;
            this.emptyPropertyNotifier = false;
            this.invalidKeyNotifier = false;
          }
        }
      },
      deleteProperty(key) {
        this.$emit("deleteProperty", key);
      },
      editProperty(key, value) {
        this.editPropertyMode = true;
        this.overwritePropertyMode = false;
        this.propertiesTab=1;
        for (let i=0; i<this.predefinedProperties.length; i++) {
          if (key === this.predefinedProperties[i].value) {
            this.propertiesTab=0;
          }
        }
        this.newProperty.key = key;
        if (this.keyIsDate(this.newProperty.key)) {
          this.newProperty.value = value.slice(6) + '-' +
            value.slice(0, 2) + '-' +
            value.slice(3, 5)
        } else {
          this.newProperty.value = value
        }
      },
      checkPropertyExists() {
        for (let i=0; i<this.properties.length; i++) {
          if (this.properties[i].key.toLowerCase() === this.newProperty.key.toLowerCase().trim()){
            this.overwritePropertyMode = true;
            this.overwritePropertyValue = this.properties[i].value;
            this.overwritePropertyKey = this.properties[i].key;
            return true;
          }
        }
        return false;
      },
      propertyLengthCounter() {
        if (this.selectedProperty && this.selectedProperty.maxLength) {
          return this.selectedProperty.maxLength;
        } else {
          return false;
        }
      },
      translatePropertyKey(key) {
        for (let i=0; i<this.predefinedProperties.length; i++) {
          if (key === this.predefinedProperties[i].value) {
            return this.predefinedProperties[i].text
          }
        }
        return key
      },
      trimNewPropertyValue() {
        if (this.selectedProperty && this.selectedProperty.maxLength && this.newProperty.value.length > this.selectedProperty.maxLength) {
          this.$nextTick(() => {
            this.$set(this.newProperty, 'value', this.newProperty.value.slice(0, this.selectedProperty.maxLength));
          });
          this.$forceUpdate();
        }
      },
      overwriteDatePropertyParser(value) {
        if (this.keyIsDate(this.newProperty.key)) {
          const date = new Date(value);
          return ('0' + (date.getMonth() + 1)).slice(-2) + "/" + ('0' + date.getDate()).slice(-2) + "/" + date.getFullYear()
        } else {
          return value
        }
      },
      cancelEditProperty() {
        this.editPropertyMode = false;
        this.resetNewProperty()
      },
      resetNewProperty() {
        if (this.propertiesTab === 0) {
          // If last added property was predefined switch to the next property from list
          let index = this.predefinedProperties.findIndex( item => item.value === this.newProperty.key) + 1;

          // Checking if next position on the list is property and we don't go over the array length
          if (index > 0) {
            while (index < this.predefinedProperties.length && this.predefinedProperties[index].value === undefined) {
              index++;
            }
          }

          if (index < this.predefinedProperties.length) {
            this.newProperty.key = this.predefinedProperties[index].value;
          } else {
            // if last added property wasn't predefined or it was the last one in the array we come back to the first position
            this.newProperty.key = this.predefinedProperties[3].value;
          }
        } else {
          this.newProperty.key = "";
        }
        this.newProperty.value = ""
      },
      resetNewPropertySwap(type) {
        if (type === 'personalized') {
          this.newProperty.key = ""
        } else {
          this.newProperty.key = this.predefinedProperties[3].value
        }
        this.newProperty.value = ""
      },
      keyIsDate(key) {
        return key.toLowerCase().match(/.*_date/g) || (this.predefinedProperties.find(node => node.value === key) && this.predefinedProperties.find(node => node.value === key).type === 'date');
      },
      keyIsSelect(key) {
        const foundNode = this.predefinedProperties.find(node => node.value === key);
        if (foundNode) {
          return (foundNode.type === "select");
        } else {
          return false;
        }
      },
      isKeySameType(key1, key2) {
        if (this.keyIsDate(key1)) {
          return this.keyIsDate(key2);
        } else if (this.keyIsSelect(key1)) {
          return this.keyIsSelect(key2);
        } else {
          return (!this.keyIsSelect(key2) && !this.keyIsDate(key2));
        }
      }
    }
  }
</script>

<style scoped lang="scss">
    .iseq-properties {
        display: flex;
        flex-direction: row;

        .properties {
            display: flex;
            flex-direction: column;
            margin: 0 4px 24px 12px;
            width: calc(50% - 16px);

            &__element{
              position: relative;
              display: flex;
              justify-content: space-between;
              width: 100%;
              margin: 5px 0;
              padding: 12px;
              border-radius: 5px;
              background: var(--light-2);
              font-size: 14px;
              border-width: 1px;
              border-style: solid;
              border-color: var(--light);
              white-space: nowrap;
              overflow: hidden;
              height: 48px;
              text-overflow: ellipsis;

                &__text {
                    .name {
                        color: var(--font-inverted-color);
                    }

                    .value {
                        color: var(--font-color);
                        font-weight: bold;
                        margin-left: 10px;
                    }
                }

                &__icons {
                  &--icon-content-version{
                    height: 100%;
                    width: 90px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;
                    background: rgb(0,0,0);
                    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(245,246,250,1) 20%);
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding-left: 4px;
                    padding-right: 12px;
                    -webkit-box-shadow: -60px 0px 24px -30px rgba(66, 68, 90, 1);
                    -moz-box-shadow: -60px 0px 24px -30px rgba(66, 68, 90, 1);
                    box-shadow: -60px 0px 24px -30px rgba(66, 68, 90, 1);
                  }


                    .icon {
                      width: min-content;
                      margin-right: 4px;

                      &--blue {
                        color: var(--primary-color) !important;
                      }

                      &--red {
                        color: var(--danger-color) !important;
                      }
                    }
                }
            }
        }

        .input {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin: 0 12px 20px 4px;
            width: calc(50% - 16px);

            &__name {
                color: var(--font-color);
                font-weight: bold;
                font-size: 16px;
            }

            &__tab-selector {
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 64px;

                &__tab {
                    height: 64px;
                    width: 50%;
                    padding: 4px;

                    &__icon {
                        padding: 4px;

                    }
                }
            }

            &__input-fields {
                display: flex;
                flex-direction: column;
                justify-items: center;
                width: 100%;

                &__field {
                    width: 100%;
                    margin: 4px 0 4px;
                }
            }

            &__actions {
                display: flex;
                flex-direction: column;
                margin-top: 4px;

                &__text {
                    color: var(--font-color);
                    font-size: 13px;

                    .bold {
                        text-decoration: underline;
                        font-weight: 700;
                    }
                }

                &__buttons {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    margin: 4px;

                    .button {
                        margin: 4px;
                        max-width: 150px;
                    }
                }
            }
        }
    }
</style>
