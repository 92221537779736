var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"iseq-report-variants-columns"},[_c('div',{staticClass:"iseq-report-variants-columns__content"},[_c('div',{staticClass:"iseq-report-variants-columns__content__table"},[_c('div',{staticClass:"iseq-report-variants-columns__content__table__selection"},_vm._l((_vm.headersSelectionSearch),function(header){return _c('div',{key:header.value,staticClass:"iseq-report-variants-columns__content__table__selection__box"},[_c('v-checkbox',{attrs:{"value":header.value,"hide-details":"","color":"primary"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"iseq-report-variants-columns__content__table__selection__box__label"},[_vm._v(_vm._s(header.name ? header.name : header.text))])]},proxy:true}],null,true),model:{value:(_vm.selectedHeaders),callback:function ($$v) {_vm.selectedHeaders=$$v},expression:"selectedHeaders"}}),_c('div',{staticClass:"iseq-report-variants-columns__content__table__selection__box__separator"}),_c('span',{staticClass:"iseq-report-variants-columns__content__table__selection__box__description"},[_vm._v(" "+_vm._s(header.description)+" ")]),(header.display && (header.display === 'pill' || header.display === 'icon'))?[_c('div',{staticClass:"iseq-report-variants-columns__content__table__selection__box__separator"}),_c('span',{staticClass:"iseq-report-variants-columns__content__table__selection__box__description"},[_vm._v(" When downloaded data from this column will have following values:"),_c('br'),_vm._l((Object.entries(header.icons)),function(ref){
var key = ref[0];
var value = ref[1];
return _c('span',{key:key},[(header.display === 'pill')?_c('span',{staticClass:"pill pill--inline",style:({'background-color':header.colors[key] ? header.colors[key] : 'black'})},[_vm._v(" "+_vm._s(value)+" ")]):_c('span',[_c('v-icon',{attrs:{"color":"text"}},[_vm._v(" "+_vm._s(value)+" ")])],1),_vm._v(" - "+_vm._s(key)),_c('br')])})],2)]:_vm._e()],2)}),0)]),_c('div',{staticClass:"iseq-report-variants-columns__content__tools"},[_c('div',{staticClass:"iseq-report-variants-columns__content__tools__title"},[_vm._v(" Columns' settings ")]),_c('div',{staticClass:"iseq-report-variants-columns__content__tools__searchbar"},[_c('v-text-field',{attrs:{"label":"Search","outlined":"","hide-details":""},model:{value:(_vm.headerSearch),callback:function ($$v) {_vm.headerSearch=$$v},expression:"headerSearch"}})],1),_c('div',{staticClass:"iseq-report-variants-columns__content__tools__button-row"},[_c('v-iseq-btn',{staticClass:"iseq-button",on:{"click":function($event){return _vm.selectAllColumns()}}},[_vm._v(" Select all columns ")]),_c('v-iseq-btn',{staticClass:"iseq-button",on:{"click":function($event){return _vm.deselectAllColumns()}}},[_vm._v(" Deselect all columns ")])],1),_c('div',{staticClass:"iseq-report-variants-columns__content__tools__button-row"},[_c('v-iseq-btn',{staticClass:"iseq-button iseq-button--wide",on:{"click":function($event){return _vm.selectRecommendedColumns()}}},[_vm._v(" Select recommended columns only ")])],1),_c('div',{staticClass:"iseq-report-variants-columns__content__tools__separator"}),_c('div',{staticClass:"iseq-report-variants-columns__content__tools__title"},[_vm._v(" Download data ")]),_c('div',{staticClass:"iseq-report-variants-columns__content__tools__button-row"},[_c('v-tooltip',{attrs:{"top":"","disabled":!!_vm.selectedHeaders.length},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-iseq-btn',{staticClass:"iseq-button iseq-button--wide",attrs:{"width":"310px","disabled":_vm.selectedHeaders.length < 1},on:{"click":function($event){(_vm.downloadColumnsData({all: false}))}}},[_vm._v(" Download selected columns data to CSV file ")])],1)]}}])},[_c('span',[_vm._v("At least 1 column must remain selected")])])],1),_c('div',{staticClass:"iseq-report-variants-columns__content__tools__button-row"},[_c('v-tooltip',{attrs:{"top":"","disabled":!!_vm.selectedHeaders.length},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-iseq-btn',{staticClass:"iseq-button iseq-button--wide",attrs:{"width":"310px","disabled":_vm.selectedHeaders.length < 1},on:{"click":function($event){(_vm.downloadColumnsData({all: true}))}}},[_vm._v(" Download all columns data to CSV file ")])],1)]}}])},[_c('span',[_vm._v("At least 1 column must remain selected")])])],1)])]),_c('div',{staticClass:"iseq-report-variants-columns__action-button"},[_c('v-tooltip',{attrs:{"top":"","disabled":!!_vm.selectedHeaders.length},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-iseq-btn',{staticClass:"iseq-button",attrs:{"disabled":_vm.selectedHeaders.length < 1},on:{"click":function($event){return _vm.$emit('closed')}}},[_vm._v(" Confirm ")])],1)]}}])},[_c('span',[_vm._v("At least 1 column must remain selected")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }