import Vue from 'vue'
import request from '@/customjs/request.js'
import store from '@/store'

export default{
  namespaced: true,

  state: {
    events: []
  },

  mutations: {
    setEvents(state, data) {
      state.events = data.sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime())
    },
    cleanAllData(state){
      state.events = [];
    }
  },

  actions: {
    getUserEvents({commit}) {
      Vue.$log.debug("vue.vuex.event.getUserEvents");
      request.request({
        endpoint: "user/events",
        callback: function (data) {
          commit('setEvents', data)
        }
      })
    }
  }
}
