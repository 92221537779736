<template>
  <v-dialog
    v-model="isOpen"
    persistent
    :max-width="width"
  >
    <div class="iseq-confirmation-modal">
      <div
        v-if="header"
        class="iseq-confirmation-modal__header"
      >
        <span class="iseq-confirmation-modal__header__title">{{ header }}</span>
      </div>

      <div
        v-if="message"
        class="iseq-confirmation-modal__message"
      >
        {{ message }}
      </div>

      <div
        v-else
        class="iseq-confirmation-modal__message"
      >
        <slot />
      </div>

      <div class="iseq-confirmation-modal__buttons">
        <v-iseq-btn
          :type="rejectButtonType === 'danger' ? 'red' : rejectButtonType === 'secondary' ? 'secondary' : 'primary'"
          @click="rejectAction()"
        >
          {{ rejectButtonText }}
        </v-iseq-btn>

        <v-iseq-btn
          :type="confirmButtonType === 'danger' ? 'red' : confirmButtonType === 'secondary' ? 'secondary' : 'primary'"
          :disabled="confirmButtonDisabled"
          @click="confirmAction()"
        >
          {{ confirmButtonText }}
        </v-iseq-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
  import IseqButton from "@/components/ui/IseqButton.vue";

  export default {
    name: "IseqConfirmationModal",
    components: {
      "v-iseq-btn": IseqButton
    },
    props: {
      header: {
        type: String,
        default: undefined
      },
      message: {
        type: String,
        default: undefined
      },
      rejectButtonText: {
        type: String,
        default: "reject"
      },
      rejectButtonType: {
        type: String,
        default: "primary"
      },
      confirmButtonText: {
        type: String,
        default: "confirm"
      },
      confirmButtonType: {
        type: String,
        default: "primary"
      },
      confirmButtonDisabled: {
        type: Boolean,
        default: false
      },
      width: {
        type: String,
        default: "600px"
      }
    },
    data: function () {
      return {
        isOpen: true
      }
    },
    methods: {
      rejectAction() {
        this.$emit('actionRejected');
      },
      confirmAction() {
        this.$emit('actionConfirmed');
      }
    }
  }
</script>

<style lang="scss"
       scoped>
.iseq-confirmation-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background-color: white;

  &__header {
    margin-bottom: 20px;

    &__title {
      color: var(--dark);
      font-size: 1.7em;
      font-weight: 500;
    }
  }

  &__message {
    margin-bottom: 20px;
    width: 100%;
    color: var(--dark);
    font-size: 1.4em;
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .iseq-btn {
      margin-left: 4px;
    }
  }
}
</style>