<template>
  <div class="iseq-project-list">
    <v-iseq-card
      title="Projects"
      tabs-only
      class="iseq-project-list__relationship"
    >
      <div class="iseq-project-list__relationship__wrapper">
        <v-tabs
          v-model="tab"
          height="64px"
          color="primary"
        >
          <v-tab
            v-if="permissionRequired('projectView')"
            class="px-12"
            href="#ownProjects"
          >
            <v-icon class="mr-4 title">
              mdi-folder-account
            </v-icon>
            My projects
          </v-tab>

          <v-tab
            class="px-12"
            href="#otherProjects"
          >
            <v-icon class="mr-4 title">
              mdi-folder-multiple-plus
            </v-icon>

            {{ joinableProjectsTotalElements ? `Other projects (${joinableProjectsTotalElements})` : 'Other projects' }}
          </v-tab>
        </v-tabs>
      </div>
    </v-iseq-card>

    <v-iseq-card
      class="iseq-project-list__details"
    >
      <project-list-tabs :tab="tab" />
    </v-iseq-card>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import {permissionCheck} from "@/plugins/permissions";
  import ProjectListTabs from "@/views/Project/components/ProjectListTabs";
  import IseqCard from "@/components/ui/IseqCard.vue";

  export default {
    name: 'ProjectList',
    components: {
      ProjectListTabs,
      "v-iseq-card": IseqCard
    },
    beforeRouteLeave(to, from, next) {
      if (!to.name.includes('project')) {
        this.$store.commit('project/clearProjectsTableOptions');
        this.$store.commit('project/clearContent');
        next();
      } else {
        next();
      }
    },
    data: function () {
      return {
        tab: "ownProjects",
      }
    },
    computed: {
      ...mapState('project', ['joinableProjectsTotalElements'])
    },
    created() {
      if (this.$route.query.tab === "join") {
        this.tab = "otherProjects";
      }
    },
    methods: {
      permissionRequired(action) {
        return permissionCheck(action)
      },
    },
  }
</script>
<style lang="scss"
       scoped>
    .iseq-project-list {
        &__relationship {
            display: flex;
            justify-content: space-between;
        }
    }
</style>