<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    :id="input.id"
    class="iseq-analysis-create-input"
  >
    <template v-if="input.inputs">
      <div class="iseq-analysis-create-input__container">
        <div class="iseq-analysis-create-input__container__wrapper">
          <div class="iseq-analysis-create-input__container__data">
            <b>Name:</b> {{ input.name }} <br>
          </div>

          <div
            class="iseq-analysis-create-input__container__data"
            :class="[input.rules ? 'iseq-analysis-create-input__container__data--wide' : 'iseq-analysis-create-input__container__data--superwide']"
          >
            <b>Description:</b><br>
            <div v-html="compileMarkdown(input.description)" />
          </div>

          <div
            v-if="input.rules"
            class="iseq-analysis-create-input__container__data"
          >
            <span :class="{'iseq-analysis-create-input__container__data--input-error': isInputErroneous}">
              <b>Rules:</b><br>
              {{ input.rules }} <br>
            </span>
          </div>
        </div>

        <div
          v-for="subInput in input.inputs"
          :key="subInput.name"
        >
          <analysis-create-input
            :input="subInput"
            :subinput="true"
            :parent-value.sync="parentValue"
            :erroneous-inputs="erroneousInputs"
            :workflow-inputs="workflowInputs"
            @inputChange="updateSubinput"
          />
        </div>
      </div>
    </template>

    <template v-else>
      <div :class="{'iseq-analysis-create-input__container': true, 'subinput': subinput}">
        <div class="iseq-analysis-create-input__container__wrapper">
          <div class="iseq-analysis-create-input__container__data">
            <b>Name:</b> {{ input.name }} <br>

            <template v-if="input.constraints && input.constraints.paired">
              <b>Paired input</b>
              <v-tooltip
                class="tooltip--right"
                right
              >
                <template #activator="{ on }">
                  <v-icon
                    class="tooltip__icon mb-3 ml-1"
                    color="primary"
                    size="15"
                    v-on="on"
                  >
                    mdi-help-circle
                  </v-icon>
                </template>

                <span>This input requires even number of selected values</span>
              </v-tooltip>
              <br>
            </template>

            <template v-if="input.controls && controlledInputNames.length">
              <b>Control input</b>
              <v-tooltip
                class="tooltip--right"
                right
              >
                <template #activator="{ on }">
                  <v-icon
                    class="tooltip__icon mb-3 ml-1"
                    color="primary"
                    size="15"
                    v-on="on"
                  >
                    mdi-help-circle
                  </v-icon>
                </template>

                <span>Change of value in this input may affect chosen and available values in {{ input.controls.length > 1 ? 'inputs' : 'input' }}: {{ controlledInputNames }}</span>
              </v-tooltip>
              <br>
            </template>

            <template v-if="controlInputNames.length && controlInputNames.length">
              <b>Dependent input</b>
              <v-tooltip
                class="tooltip--right"
                right
              >
                <template #activator="{ on }">
                  <v-icon
                    class="tooltip__icon mb-3 ml-1"
                    color="primary"
                    size="15"
                    v-on="on"
                  >
                    mdi-help-circle
                  </v-icon>
                </template>

                <span>Values available in this input depend on value chosen in {{ controlInputNames.includes(',') ? 'inputs' : 'input' }}: {{ controlInputNames }}</span>
              </v-tooltip>
              <br>
            </template>

            <template v-if="input.type === 'String' && input.constraints && input.constraints.notAllowedCharacters">
              <b>Restricted characters: </b> {{ JSON.stringify(input.constraints.notAllowedCharacters) }} <br>
            </template>

            <template v-if="input.constraints && input.constraints.extensions">
              <b>Extensions: </b>
              <template v-if="Array.isArray(input.constraints.extensions)">
                <template v-for="extension in input.constraints.extensions">
                  {{ extension + " " }}
                </template>
              </template>
              <template v-else>
                {{ input.constraints.extensions }}
              </template>
              <br>
            </template>

            <template v-if="input.constraints && typeof input.constraints.min !== 'undefined'">
              <b>Min value:</b> {{ input.constraints.min }} <br>
            </template>

            <template v-if="input.constraints && typeof input.constraints.max !== 'undefined'">
              <b>Max value:</b> {{ input.constraints.max }} <br>
            </template>

            <template v-if="input.constraints && typeof input.constraints.maxLength !== 'undefined'">
              <b>Max length:</b> {{ input.constraints.maxLength }} <br>
            </template>

            <template v-if="input.constraints && input.constraints.maxFiles">
              <b>Max:</b> {{ `${input.constraints.maxFiles} files` }} <br>
            </template>

            <template v-if="input.required && input.type !== 'Boolean'">
              <b>Required</b>
            </template>
          </div>

          <v-divider vertical />

          <div :class="['iseq-analysis-create-input__container__wrapper', 'iseq-analysis-create-input__container__wrapper--column']">
            <!-- File -->

            <iseq-file-input
              v-if="input.type === 'File'"
              v-model="value"
              :input="input"
              :files-tree="sample.filesTree"
              :allowed-extensions-filter="allowedExtensionsFiles"
              class="input"
              @input="updateInputValue($event)"
            />

            <!-- Array[File] -->

            <iseq-array-file-input
              v-if="input.type === 'Array[File]'"
              v-model="value"
              :input="input"
              :files-tree="sample.filesTree"
              :sample-uuid="sample.details.uuid"
              :allowed-extensions-filter="allowedExtensionsFiles"
              :are-all-values-selected="areAllValuesSelected()"
              :is-any-value-selected="isAnyValueSelected()"
              class="input"
              @input="updateInputValue($event)"
            />

            <!-- input with selection (String, Float, Integer) -->

            <iseq-selectable-input
              v-if="(input.constraints && input.constraints.values)"
              v-model="value"
              :input="input"
              :control-inputs-values.sync="controlInputsValues"
              class="input"
              @input="updateInputValue($event)"
            />

            <!-- String, Float, Int -->

            <iseq-text-field-input
              v-if="(input.type === 'String' || input.type === 'Float' || input.type === 'Int') && (!(input.constraints && input.constraints.values)) && input.explorare !== 'Phenotypes'"
              v-model="value"
              :input-name="input.name"
              class="input"
              @input="updateInputValue($event)"
            />

            <iseq-text-field-input
              v-if="input.explorare === 'Genes' || input.explorare === 'HPOTerms'"
              v-model="value"
              :input-name="input.name"
              class="input"
              @input="updateInputValue($event)"
            />

            <!-- Boolean -->

            <iseq-boolean-input
              v-if="input.type === 'Boolean'"
              v-model="value"
              :input-name="input.name"
              class="input"
              @input="updateInputValue($event)"
            />

            <iseq-diseases-input
              v-if="input.explorare === 'Diseases'"
              v-model="value"
              class="input"
              @input="updateInputValue($event)"
            />

            <iseq-text-area-input
              v-if="input.explorare === 'Phenotypes'"
              v-model="value"
              :input-name="input.name"
              :max-length="phenotypesDescriptionMaxLength"
              class="input"
              @input="updateInputValue($event)"
            />

            <span
              v-if="isInputErroneous"
              class="input-error"
            >
              {{ inputError }}
            </span>

            <v-divider />

            <div class="description">
              <b>Description: </b>

              <div v-html="compileMarkdown(input.description)" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import {markdownCompile} from "../../../plugins/utils";
  import {mapState} from "vuex";
  import IseqFileInput from "@/views/Analysis/components/IseqFileInput.component";
  import IseqArrayFileInput from "@/views/Analysis/components/IseqArrayFileInput.component";
  import IseqSelectableInput from "@/views/Analysis/components/IseqSelectableInput.component";
  import IseqTextFieldInput from "@/views/Analysis/components/IseqTextFieldInput.component";
  import IseqBooleanInput from "@/views/Analysis/components/IseqBooleanInput.component";
  import IseqDiseasesInput from "@/views/Analysis/components/IseqDiseasesInput.component";
  import IseqTextAreaInput from "@/views/Analysis/components/IseqTextAreaInput.component";

  export default {
    name: "AnalysisCreateInput",
    components: {
      IseqTextAreaInput,
      IseqDiseasesInput,
      IseqBooleanInput,
      IseqTextFieldInput,
      IseqSelectableInput,
      IseqArrayFileInput,
      IseqFileInput
    },
    props: {
      input: {
        required: true,
        type: Object
      },
      subinput: {
        required: false,
        type: Boolean,
        default: false
      },
      parentValue: {
        required: true,
        type: Object
      },
      workflowInputs: {
        required: true,
        type: Array
      },
      erroneousInputs: {
        required: false,
        type: Array,
        default: () => []
      }
    },
    data: function () {
      return {
        value: undefined,
        phenotypesDescriptionMaxLength: 600
      }
    },
    computed: {
      ...mapState('sample', ['sample', 'content']),
      isInputErroneous() {
        return this.erroneousInputs.find(item => this.input.id === item.id) !== undefined;
      },
      inputError() {
        return this.erroneousInputs.find(item => this.input.id === item.id).reason;
      },
      controlInputsValues() {
        if (this.input.constraints && this.input.constraints.values && typeof this.input.constraints.values[0] === "object") {
          const controlInputsIds = new Set();

          this.input.constraints.values.forEach(node => {
            if (node.availableWhen) {
              Object.keys(node.availableWhen).forEach(key => {
                controlInputsIds.add(key);
              });
            }
          });

          let controlInputsValues = {};

          controlInputsIds.forEach(inputId => {
            controlInputsValues[inputId] = this.parentValue[inputId];
          });

          return controlInputsValues;
        } else return undefined;
      },
      controlInputNames() {
        if (this.controlInputsValues) {
          const HRNames = [];
          Object.keys(this.controlInputsValues).forEach(id => {
            const controllingInput = this.workflowInputs.find(node => node.id === id)
            if (controllingInput !== undefined) {
              HRNames.push(controllingInput.name);
            }
          });
          return HRNames.join(', ');
        } else {
          return '';
        }
      },
      controlledInputNames() {
        if (this.input.controls) {
          const HRNames = [];
          this.input.controls.forEach(id => {
            const controlledInput = this.workflowInputs.find(node => node.id === id)
            if (controlledInput !== undefined) {
              HRNames.push(controlledInput.name);
            }
          })
          return HRNames.join(', ');
        } else {
          return '';
        }
      }
    },
    watch: {
      parentValue: {
        deep: true,
        handler (newParentValue) {
          this.$nextTick(() => {
            this.value = newParentValue[this.input.id];
          });
          this.$forceUpdate();
        }
      }
    },
    created: function () {
      if (this.parentValue) {
        this.$nextTick(() => {
          this.value = this.parentValue[this.input.id];
        });
        this.$forceUpdate();
      }
    },
    methods: {
      compileMarkdown(data) {
        return markdownCompile(data);
      },
      allowedExtensionsFiles(files, extensions) {
        if (!files) return [];
        if (!extensions) return files;

        function copy(o) {
          return Object.assign({}, o)
        }

        return files.map(copy).filter(function extensionsFilter(file) {
          if (file.children) {
            if (file.children.map(copy).filter(extensionsFilter).length) {
              file.children = file.children.map(copy).filter(extensionsFilter);
              return true
            } else return false
          } else {
            if (Array.isArray(extensions)) {
              return extensions.some(extension => file.label.endsWith(extension))
            } else return file.label.endsWith(extensions)
          }
        })
      },
      areAllValuesSelected() {
        return this.areValuesSelected(true);
      },
      isAnyValueSelected() {
        return this.areValuesSelected();
      },
      areValuesSelected(all = false) {
        let breaker = false;

        function checkLeaves(payload) {
          if (payload.node.children) {
            for (let child of payload.node.children) {
              if (!breaker) {
                checkLeaves({inputValue: payload.inputValue, node: child});
              }
            }
          } else {
            if ((all && !payload.inputValue.includes(payload.node.id)) || (!all && payload.inputValue.includes(payload.node.id))) {
              breaker = true;
            }
          }
        }

        if (!this.value || (Array.isArray(this.value) && this.value.length === 0)) return false;

        if (this.input.type === 'Array[File]') {
          if (this.input.constraints) {
            for (let node of this.allowedExtensionsFiles(this.sample.filesTree.children, this.input.constraints.extensions)) {
              checkLeaves({inputValue: this.value, node: node});
            }
          }
        } else {
          return all ? this.value.length === this.input.constraints.values.length : true;
        }

        return all ? !breaker : breaker;
      },
      updateInputValue(inputValue) {
        let visibleValue, realValue;

        // Apply constraints
        if (this.input.type === 'Float') {
          const values = this.sanitizeInputValue(inputValue);

          if (values) {
            visibleValue = values.visibleValue;
            realValue = values.realValue;
          } else {
            visibleValue = undefined;
            realValue = undefined;
          }
        } else {
          visibleValue = this.sanitizeInputValue(inputValue);
          realValue = visibleValue;
        }

        // Update value in input field
        this.$nextTick(() => {
          this.$set(this, 'value', visibleValue)
        });
        this.$forceUpdate();

        // Emit new value to parent component
        this.$emit('inputChange', {
          value: realValue,
          id: this.input.id,
          groupId: this.input.groupId
        });
      },
      sanitizeInputValue(inputValue) {

        if (typeof inputValue === "undefined" || (Array.isArray(inputValue) && inputValue.length === 0) || inputValue === '') {
          return inputValue;
        }

        if(this.input.explorare === "Phenotypes" && inputValue.length>this.phenotypesDescriptionMaxLength) {
          // Preventing phenotypes description too long to compute
          inputValue = inputValue.slice(0, this.phenotypesDescriptionMaxLength);
        }

        if (this.input.type === "String" && inputValue) {
          // Removing forbidden characters
          if (this.input.constraints && this.input.constraints.notAllowedCharacters) {
            for (const character of this.input.constraints.notAllowedCharacters) {
              while (inputValue.includes(character)) {
                inputValue = inputValue.replace(character, '');
              }
            }
          }

          // Preventing value to go outside limits
          if (this.input.constraints && this.input.constraints.maxLength && inputValue.length > this.input.constraints.maxLength) {
            inputValue = inputValue.slice(0, this.input.constraints.maxLength);
          }
        } else if (this.input.type === 'Float') {
          // Removing forbidden characters
          inputValue = inputValue.toString().replace(/[^0-9.]/g, '');

          // Removal of leading zeros
          if(inputValue.length > 1 && inputValue[0] === '0' && inputValue[1] !== '.') {
            inputValue = inputValue.slice(1, inputValue.length);
          }

          // Preventing multiple '.' in float
          if (inputValue.indexOf('.') !== inputValue.lastIndexOf('.')) {
            inputValue = inputValue.slice(0, inputValue.lastIndexOf('.'));
          }

          // Sanitized value set for display
          let visibleValue = inputValue;

          // Adding '0' after '.' if omitted by user
          if (inputValue.endsWith('.')) {
            inputValue += '0';
          }

          // Parsing result to float
          inputValue = parseFloat(inputValue);

          // Preventing value to go outside limits
          if (this.input.constraints && typeof inputValue === 'number') {
            if (this.input.constraints.max && inputValue > this.input.constraints.max) {
              inputValue = this.input.constraints.max;
              visibleValue = inputValue;
            } else if (this.input.constraints.min && inputValue < this.input.constraints.min) {
              inputValue = this.input.constraints.min;
              visibleValue = inputValue;
            }
          }

          return {visibleValue: visibleValue, realValue: inputValue};
        } else if (this.input.type === 'Int') {
          // Removing forbidden characters
          inputValue = inputValue.toString().replace(/[^0-9]/g, '');

          // Parsing to integer
          inputValue = !isNaN(parseInt(inputValue)) ? parseInt(inputValue) : undefined;

          // Preventing value to go outside limits
          if (this.input.constraints && typeof inputValue === 'number') {
            if (this.input.constraints.max && inputValue > this.input.constraints.max) {
              inputValue = this.input.constraints.max;
            } else if (this.input.constraints.min && inputValue < this.input.constraints.min) {
              inputValue = this.input.constraints.min;
            }
          }
        }

        return inputValue;
      },
      updateSubinput(payload) {
        this.$emit('inputChange', payload);
      }
    }
  }
</script>

<style lang="scss"
       scoped>
.iseq-analysis-create-input {
  &__container {
    display: flex;
    flex-direction: column;
    background: $gray-200;
    border: 1px solid $gray-400;
    margin-bottom: 8px;

    &__data {
      padding: 10px 24px 10px 24px;
      font-size: 1rem;
      font-weight: 400;
      letter-spacing: .15px;
      line-height: 1.75rem;
      width: 25%;

      &--wide {
        width: 50%;
      }

      &--superwide {
        width: 75%
      }

      &--input-error {
        color: var(--danger-color);
      }
    }

    &__wrapper {
      display: flex;
      font-size: 1rem;
      font-weight: 400;
      letter-spacing: .15px;
      line-height: 1.75rem;
      width: 100%;

      &--column {
        flex-direction: column;
      }

      .description {
        padding: 10px 24px 10px 24px;
        width: 100%
      }

      .input {
        padding: 10px 24px 10px 24px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-self: center;
        justify-content: center;
      }

      .input-error {
        margin: -5px 0 10px 24px;
        font-size: 15px;
        color: var(--danger-color);
      }
    }
  }
}

.warning-text {
  color: var(--warning-color);
  font-size: 14px;
}

.subinput {
  background: var(--light);
  border: 1px solid $gray-400;
  margin: 5px 5px 5px 45px;
}
</style>