import VueCookies from "vue-cookies";

export function analyticsIdentify(payload) {
  window.analytics.identify(payload.userUuid, {
    email: payload.userEmail,
    organization_uuid: payload.activeOrganization,
    project_uuid: payload.activeProject
  });
}

export function segmentTrack(payload) {
  if (payload.payload) {
    window.analytics.track(payload.event, payload.payload);
  } else {
    window.analytics.track(payload.event);
  }
}