<template>
  <footer class="iseq-footer-basic">
    <div class="iseq-footer-basic__left">
      <a
        href="https://www.intelliseq.com"
        class="iseq-footer-basic__left__link"
      >
        <img
          :src="logoPath"
          alt="logo"
          class="iseq-footer-basic__left__link__logo"
        >
      </a>
    </div>

    <div class="iseq-footer-basic__middle">
      <router-link
        to="/cookies-consents"
        class="iseq-footer-basic__middle__link"
      >
        GDPR
      </router-link>

      <router-link
        to="/privacy-policy"
        class="iseq-footer-basic__middle__link"
      >
        Privacy Policy
      </router-link>

      <router-link
        to="/terms-of-use"
        class="iseq-footer-basic__middle__link"
      >
        Terms of Use
      </router-link>

      <a
        :href="`mailto:${contactEmail}`"
        class="iseq-footer-basic__middle__link"
      >{{ contactEmail }}</a>

      <router-link
          v-show="!!user.email"
          to="/report-problem"
          class="iseq-footer-basic__middle__link"
      >Report problem
      </router-link>
    </div>

    <div class="iseq-footer-basic__right">
      <v-tooltip
        v-if="isClinical"
        top
      >
        <template #activator="{ on }">
          <v-img
            alt="IntelliseqFlow Medical Device-CE"
            src="@/assets/logo-CE.png"
            class="iseq-footer-basic__right__logo"
            transition="scale-transition"
            max-width="70px"
            v-on="on"
          />
        </template>

        <span>IntelliseqFlow Medical Device-CE</span>
      </v-tooltip>

      <v-tooltip top>
        <template #activator="{ on }">
          <v-img
            v-if="!isTgenome"
            alt="ISO 13485 – Quality Management System"
            src="@/assets/logo-ISO-13485.png"
            class="iseq-footer-basic__right__logo"
            transition="scale-transition"
            max-width="70px"
            v-on="on"
          />
        </template>

        <span>ISO 13485 – Quality Management System</span>
      </v-tooltip>
    </div>
  </footer>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    name: 'IseqFooterBasic',
    data () {
      return {
        logoPath: require(process.env.VUE_APP_LOGO_LIGHT_PATH),
        contactEmail: process.env.VUE_APP_CONTACT_EMAIL
      }
    },
    computed: {
      ...mapState('user', ['user']),
      isClinical() {
        return process.env.VUE_APP_CLINICAL_VERSION === "true";
      },
      isTgenome() {
        return process.env.VUE_APP_MODE === "tgenome";
      }
    },
  }
</script>
<style lang="scss"
       scoped>

.iseq-footer-basic {
    background: var(--navbar-background-color);
    width: 100%;
    margin-bottom: 0;
    margin-top: auto;
    height: 90px;
    display: flex;
    color: var(--white);
    align-items: center;
    justify-content: space-between;

    &__left {
        height: 100%;
        width: 280px;
        display: flex;
        justify-content: center;
        align-items: center;

        &__link {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

            &__logo {
                height: 45px;
            }
        }
    }

    &__middle {
        &__link {
            font-size: 14px;

            &:hover {
                text-decoration: underline;
            }
        }

        :nth-child(1n) {
            margin-right: 30px;
        }

        :last-child {
            margin-right: 0;
        }
    }

    &__right {
        width: 280px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        &__logo {
            opacity: 0.5;
            margin-right: 73px;
        }
    }
}
</style>