<template>
  <div class="get-response-data-collector">
    <div class="get-response-data-collector__response-form">
      <getresponse-form
        form-id="fa929f4c-526b-4281-b408-f9ebbca98dd0"
        e="0"
      />
    </div>
    
    <iseq-button
      type="secondary"
      class="refuse-button"
      @click="refuseProvidingData()"
    >
      I don't want to provide Intelliseq with this information
    </iseq-button>
  </div>
</template>

<script>
  import IseqButton from "@/components/ui/IseqButton.vue";
  import {mapState} from "vuex";

  export default {
    name: "GetResponseDataCollector",
    components: {IseqButton},
    computed: {
      ...mapState('user', ['user']),
    },
    methods: {
      refuseProvidingData() {
        this.$store.dispatch("user/updateGetResponseData", "OPT_OUT");
      },
    }
  }
</script>

<style scoped lang="scss">
.get-response-data-collector {
  z-index: 10000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__response-form {
    max-width: 800px;
  }

  .refuse-button {
    margin-top: 20px;
    height: 50px;
  }
}
</style>