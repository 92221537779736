<template>
  <v-dialog
    v-model="isOpen"
    persistent
    max-width="600px"
  >
    <div class="iseq-genes-verification-modal">
      <div class="iseq-genes-verification-modal__header">
        <span class="iseq-genes-verification-modal__header__title">Genes selected for the analysis</span>
      </div>

      <div class="iseq-genes-verification-modal__message">
        <p>Below is the list of genes with phenotype match (score, %) generated from the Scope of analysis inputs.</p>
        <p>To add more genes go back to the "Create new analysis" view by clicking "Cancel" button.</p>
      </div>

      <div class="iseq-genes-verification-modal__filter">
        <v-text-field
          v-model="nameFilter"
          outlined
          hide-details
          label="Search"
          placeholder="Provide gene name"
          class="iseq-genes-verification-modal__filter__text-field"
        />

        <v-icon
          color="primary"
          size="32px"
          class="iseq-genes-verification-modal__filter__icon"
        >
          mdi-magnify
        </v-icon>
      </div>

      <!-- Height in v-data-table component must be set to value of height of genes-box div minus the constant height of v-data-footer which is 59px -->
      <div class="iseq-genes-verification-modal__genes-box">
        <v-data-table
          v-model="selected"
          :footer-props="{
            'items-per-page-options': itemsPerPageOptions
          }"
          :items-per-page="100"
          :headers="headers"
          :items="filteredGenes"
          show-select
          fixed-header
          height="291px"
          checkbox-color="primary"
          item-key="name"
          @click:row="toggleGeneValue"
        >
          <template #[`header.data-table-select`]="{}" />

          <template #[`header.score`]="{}">
            Score
            <v-tooltip
              top
              max-width="600px"
            >
              <template #activator="{on}">
                <v-icon
                  color="primary"
                  class="mb-1"
                  v-on="on"
                >
                  mdi-help-circle
                </v-icon>
              </template>

              <span>
                Phenotype match (score) - percent of a maximum possible score that is attributed to a gene. For each
                HPO term and/or phenotype description provided by a user, the algorithm traverses the phenotype ontology
                tree searching for a match with each gene and assigning a score based on the distance between the original
                term and the term that matches the gene. For specific genes provided by a user, the phenotype match is
                assigned to be 75%. For specific diseases provided by a user, the phenotype match is assigned to be 50%.
                For genes from the gene panel, phenotype match is assigned to be 30%.
              </span>
            </v-tooltip>
          </template>

          <template #[`item.name`]="{ item }">
            <span
              :class="['iseq-genes-verification-modal__genes-box__entry__text', selected.includes(item) ? '' : 'iseq-genes-verification-modal__genes-box__entry__text--excluded']"
            >
              {{ item.name }}
            </span>
          </template>

          <template #[`item.score`]="{ item }">
            <div
              :class="[selected.includes(item) ? 'iseq-genes-verification-modal__genes-box__entry__score' : 'iseq-genes-verification-modal__genes-box__entry__score-excluded']"
            >
              <span class="score-text">{{ parseFloat(item.score).toFixed(2) }}%</span>
            </div>
          </template>

          <template #[`item.type`]="{ item }">
            <span
              :class="['iseq-genes-verification-modal__genes-box__entry__source', selected.includes(item) ? '' : 'iseq-genes-verification-modal__genes-box__entry__source--excluded']"
            >
              {{ item.type }}
            </span>
          </template>
        </v-data-table>
      </div>

      <div class="iseq-genes-verification-modal__buttons">
        <v-iseq-btn
          type="secondary"
          @click="close()"
        >
          Cancel
        </v-iseq-btn>

        <v-tooltip
          top
          :disabled="selected.length > 0"
        >
          <template #activator="{ on }">
            <div v-on="on">
              <v-iseq-btn
                ml
                :disabled="selected.length === 0"
                @click="accept"
              >
                Accept
              </v-iseq-btn>
            </div>
          </template>
          <span>At least one gene from the list must be selected</span>
        </v-tooltip>
      </div>
    </div>
  </v-dialog>
</template>

<script>
  import IseqButton from "@/components/ui/IseqButton.vue";

  export default {
    name: "IseqGenesVerificationModal",
    components: {
      "v-iseq-btn": IseqButton
    },
    props: {
      genes: {
        type: Array,
        default: () => []
      },
    },
    data: function () {
      return {
        isOpen: true,
        nameFilter: "",
        headers: [
          {text: 'Name', value: 'name', sortable: false},
          {text: 'Score', value: 'score', sortable: false},
          {text: 'Source', value: 'type', sortable: false}
        ],
        itemsPerPageOptions: [100, 200, 300, 400, 500],
        selected: [...this.genes]
      }
    },
    computed: {
      filteredGenes() {
        if (!this.nameFilter || this.nameFilter.length === 0) {
          return this.genes;
        } else {
          return this.genes.filter(gene => gene.name.toLowerCase().includes(this.nameFilter.toLowerCase()));
        }
      }
    },
    methods: {
      close() {
        this.$emit('closed');
      },
      accept() {
        this.$emit('accepted', this.selected);
      },
      toggleGeneValue(gene) {
        if (this.selected.includes(gene)) {
          this.selected.splice(this.selected.indexOf(gene),1);
        } else {
          this.selected.push(gene);
        }
      }
    }
  }
</script>

<style lang="scss"
       scoped>
.iseq-genes-verification-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: white;

  &__header {
    margin-bottom: 10px;

    &__title {
      color: var(--dark);
      font-size: 1.7em;
      font-weight: 500;
    }
  }

  &__message {
    width: 100%;
    align-items: flex-start;
    margin: 10px 0;
    color: var(--dark);
    font-size: 1.2em;
  }

  &__filter {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;

    &__text-field {
      width: 90%;
    }

    &__icon {
      padding: 10px;
    }
  }

  &__genes-box {
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    border-color: var(--secondary-color);
    width: 100%;
    margin-bottom: 15px;
    height: 350px;
    overflow-y: hidden;
    overflow-x: hidden;

    &__entry {
      display: flex;
      flex-direction: row;
      align-items: center;

      cursor: pointer;
      margin-bottom: 15px;

      &__checkbox {
        margin-top: -4px;
      }

      &__text {
        font-size: 1.2em;
        color: var(--font-color);

        &--excluded {
          color: var(--font-inverted-color);
          text-decoration: line-through;
        }
      }

      &__score {
        border: 2px solid var(--primary-color);
        padding: 3px 7px;
        border-radius: 5px;
        width: min-content;
        color: var(--primary-color);

        .score-text {
          color: var(--primary-color);
          font-weight: bold;
        }
      }

      &__source {
        font-size: 0.9em;
        color: var(--font-color);

        &--excluded {
          color: var(--font-inverted-color);
          text-decoration: line-through;
        }
      }

      &__score-excluded {
        border: 1px solid var(--font-inverted-color);
        padding: 3px 7px;
        border-radius: 5px;
        width: min-content;
        color: var(--primary-color);

        .score-text {
          color: var(--font-inverted-color);
        }
      }
    }
  }

  &__buttons {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
  }

  ::v-deep .v-data-table .v-data-footer {
    margin-top: 0px !important;
  }
}
</style>