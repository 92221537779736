<template>
  <div class="iseq-drop-down">
    <v-menu
      v-model="showMenu"
      offset-y
    >
      <template #activator="{ on }">
        <div
          class="iseq-drop-down__content"
          v-on="items.length>1 || caption === 'Account Balance' ? on : {}"
          @click="items.length === 1 ? goToDetail() : ''"
          @mouseenter="cursorOverField = true"
          @mouseleave="cursorOverField = false"
        >
          <v-icon
            class="iseq-drop-down__content__pre-icon"
            color="inactive"
          >
            {{ icon }}
          </v-icon>

          <div class="iseq-drop-down__content__text">
            <span class="label"> {{ caption }} </span>
            <span class="value"> {{ title }} </span>
          </div>

          <v-icon
            v-if="items.length>1 || caption === 'Account Balance'"
            class="iseq-drop-down__content__post-icon"
            color="active"
          >
            mdi-menu-down
          </v-icon>

          <v-icon
            v-else-if="cursorOverField"
            class="iseq-drop-down__content__post-icon"
            color="active"
          >
            mdi-menu-right
          </v-icon>
        </div>
      </template>

      <v-list class="iseq-drop-down__list">
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          :class="['iseq-drop-down__list__item', title === item.name ? 'iseq-drop-down__list__item--active' : '']"
          @mouseenter="selectedItemName = item.name"
          @mouseleave="selectedItemName = undefined"
        >
          <v-list-item-title
            v-if="item.type"
            class="dropdown__label"
            @click="addCredits"
          >
            {{ item.name }}
          </v-list-item-title>

          <v-list-item-title
            v-else
            class="dropdown__label"
            @click="changeNavbarItem(item)"
          >
            {{ item.name }}
          </v-list-item-title>

          <template v-if="item.name === selectedItemName && !item.type">
            <div
              @mouseenter="cursorOverArrow = true"
              @mouseleave="cursorOverArrow = false"
            >
              <template v-if="cursorOverArrow">
                <v-icon
                  class="iseq-icon"
                  @click="goToDetail(item)"
                >
                  mdi-arrow-right-circle
                </v-icon>
              </template>

              <template v-else>
                <v-icon
                  class="iseq-icon"
                  @click="goToDetail(item)"
                >
                  mdi-arrow-right
                </v-icon>
              </template>
            </div>
          </template>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  import {segmentTrack} from "@/helpers/analytics";

  export default {
    name: 'IseqDropDown',
    props: ['caption', 'title','icon','items'],
    data: function () {
      return {
        showMenu: false,
        selectedItemName: undefined,
        cursorOverArrow: false,
        cursorOverField: false
      }
    },
    methods: {
      changeNavbarItem(item) {
        if(this.caption === 'Organization') {
          segmentTrack({event: "Organization Switched"});
          this.$store.dispatch('organization/getOrganization', {organizationUuid: item.uuid});
        } else if (this.caption === 'Project') {
          segmentTrack({event: "Project Switched"});
          this.$store.dispatch('project/getProject', {uuid: item.uuid});
        }
      },
      goToDetail(item) {
        if (this.caption === 'Organization') {
          segmentTrack({event: "Organization Switched"});
        } else if (this.caption === 'Project') {
          segmentTrack({event: "Project Switched"});
        }
        if (!item) {
          item = this.items.find( node => node.name === this.title);
        }
        if (item && item.route) {
          this.$router.push({ path: item.route }).catch(()=>{});
        }
      },
      addCredits() {
        segmentTrack({event: "Add Credits Clicked", payload: {view: "top bar"}});
        this.$store.commit('payment/setPaymentDialog', true)
      }
    }
  }
</script>

<style lang="scss" scoped>
    .iseq-drop-down {
        display: flex;
        justify-content: flex-start;

        &__content {
            display: flex;
            align-content: center;
            width: 100%;

            &__pre-icon {
                width: 24px;
                margin-right: 8px;
            }

            &__text {
                display: flex;
                flex-direction: column;
                max-width: calc(100% - 56px);

                .label {
                    color: var(--inactive);
                    font-size: 0.8em;
                    line-height: 1em;
                }

                .value {
                    color: var(--light);
                    width: calc(100%);
                    font-weight: 700;
                    font-size: 1.1em;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &__post-icon {
                width: 24px;
            }
        }

        &__list {
            max-height: 50vh;
            overflow-y: auto;

            &__item {
                cursor: pointer;

                .v-icon {
                    margin-right: 6px;
                }

                .dropdown__label {
                    line-height: 2;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &:hover {
                    background: $hover-background;
                }

                &--active {
                    background: $hover-background;
                }
            }
        }
    }

    .iseq-icon {
        margin-left: 0;
        margin-right: 10px;
    }

    .v-menu__content {
        max-width: 100px;
    }

    @media screen and (max-width: 1270px) {

        .iseq-drop-down {
            &__content {
                &__card {
                    &__text {
                        width: 150px;
                    }
                }
            }
        }
    }
</style>
