<template>
  <v-card class="iseq-privacy-policy">
    <div class="iseq-privacy-policy__title">
      <span class="iseq-privacy-policy__title__text">
        PRIVACY POLICY
      </span>
    </div>

    <div class="iseq-privacy-policy__last-updated-time">
      Last updated: January 29, 2021
    </div>

    <div class="iseq-privacy-policy__section">
      <div class="iseq-privacy-policy__section__text">
        This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.<br><br>
        We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
      </div>
    </div>

    <div class="iseq-privacy-policy__section">
      <div class="iseq-privacy-policy__section__subtitle">
        Interpretation and Definitions
      </div>

      <div class="iseq-privacy-policy__section__2ndsubtitle">
        Interpretation
      </div>

      <div class="iseq-privacy-policy__section__text">
        The words of which the initial letter is capitalized have meanings defined under the following conditions.<br><br>
        The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
      </div>

      <div class="iseq-privacy-policy__section__2ndsubtitle">
        Definitions
      </div>

      <div class="iseq-privacy-policy__section__text">
        For the purposes of this Privacy Policy: <br><br>
        <span class="bold">You</span> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.<br><br>
        Under GDPR (General Data Protection Regulation), You can be referred to as the Data Subject or as the User as you are the individual using the Service.<br><br>
        <span class="bold">Company</span> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Intelliseq sp. z o. o., Wilczyńskiego 19, 31-358 Kraków.<br><br>
        For the purpose of the GDPR, the Company is the Data Controller. For the purposes of processing genetic sequence data the Company is the Data Processor.<br><br>
        <span class="bold">Affiliate</span> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.<br><br>
        <span class="bold">Account</span> means a unique account created for You to access our Service or parts of our Service.<br><br>
        <span class="bold">Website</span> refers to Intelliseq and iFlow™, accessible from <span class="link"><a href="http://www.intelliseq.com">http://www.intelliseq.com</a></span> and <span class="link"><router-link to="/">https://flow.intelliseq.com/</router-link></span><br><br>
        <span class="bold">Service</span> refers to the Website.<br><br>
        <span class="bold">Country</span> refers to: Poland<br><br>
        <span class="bold">Service Provider</span> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.<br><br>
        For the purpose of the GDPR, Service Providers are considered Data Processors.<br><br>
        <span class="bold">Third-party Social Media Service</span> refers to any website or any social network website through which a User can log in or create an account to use the Service.<br><br>
        <span class="bold">Facebook Fan Page</span> is a public profile named Intelliseq specifically created by the Company on the Facebook social network, accessible from https://www.facebook.com/Intelliseq/<br><br>
        <span class="bold">Personal Data</span> is any information that relates to an identified or identifiable individual.<br><br>
        For the purposes for GDPR, Personal Data means any information relating to You such as a name, an identification number, location data, online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity.<br><br>
        For the purposes of the CCPA, Personal Data means any information that identifies, relates to, describes or is capable of being associated with, or could reasonably be linked, directly or indirectly, with You.<br><br>
        <span class="bold">Cookies</span> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.<br><br>
        <span class="bold">Usage Data</span> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).<br><br>
        <span class="bold">Data Controller</span>, for the purposes of the GDPR (General Data Protection Regulation), refers to the Company as the legal person which alone or jointly with others determines the purposes and means of the processing of Personal Data.<br><br>
        <span class="bold">Do Not Track</span> (DNT) is a concept that has been promoted by US regulatory authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for allowing internet users to control the tracking of their online activities across websites.<br><br>
        <span class="bold">Business</span>, for the purpose of the CCPA (California Consumer Privacy Act), refers to the Company as the legal entity that collects Consumers' personal information and determines the purposes and means of the processing of Consumers' personal information, or on behalf of which such information is collected and that alone, or jointly with others, determines the purposes and means of the processing of consumers' personal information, that does business in the State of California.<br><br>
        <span class="bold">Consumer</span>, for the purpose of the CCPA (California Consumer Privacy Act), means a natural person who is a California resident. A resident, as defined in the law, includes (1) every individual who is in the USA for other than a temporary or transitory purpose, and (2) every individual who is domiciled in the USA who is outside the USA for a temporary or transitory purpose.<br><br>
        <span class="bold">Sale</span>, for the purpose of the CCPA (California Consumer Privacy Act), means selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a Consumer's Personal information to another business or a third party for monetary or other valuable consideration.
      </div>
    </div>

    <div class="iseq-privacy-policy__section">
      <div class="iseq-privacy-policy__section__subtitle">
        Collecting and Using Your Personal Data and Genetic Data
      </div>

      <div class="iseq-privacy-policy__section__2ndsubtitle">
        Types of Data Collected
      </div>

      <div class="iseq-privacy-policy__section__3rdsubtitle">
        Personal Data
      </div>

      <div class="iseq-privacy-policy__section__text">
        While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:<br>
        ·        Email address<br>
        ·        First name and last name<br>
        ·        Bank account information in order to pay for products and/or services within the Service<br>
        ·        Usage Data<br><br>
        When You pay for a product and/or a service via bank transfer, We may ask You to provide information to facilitate this transaction and to verify Your identity. Such information may include, without limitation:<br>
        ·        Date of birth<br>
        ·        Passport or National ID card<br>
        ·        Bank card statement<br>
        ·        Other information linking You to an address
      </div>

      <div class="iseq-privacy-policy__section__3rdsubtitle">
        Genetic Information That You Voluntarily Provide
      </div>

      <div class="iseq-privacy-policy__section__text">
        We store and process the genetic sequence data (DNA, RNA, etc), that you submit through along with metadata and other information related to such sequence data, derived from humans or other organisms. You agree to and accept full responsibility for obtaining all necessary permissions and informed consents from the donors of all samples from which your submitted sequence data is derived. We will be a processor for the purposes of processing genetic sequence data.
      </div>

      <div class="iseq-privacy-policy__section__3rdsubtitle">
        Usage Data
      </div>

      <div class="iseq-privacy-policy__section__text">
        Usage Data is collected automatically when using the Service.<br><br>
        Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.<br><br>
        When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.<br><br>
        We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
      </div>

      <div class="iseq-privacy-policy__section__3rdsubtitle">
        Tracking Technologies and Cookies
      </div>

      <div class="iseq-privacy-policy__section__text">
        We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service.<br><br>
        You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service.<br><br>
        Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser. Learn more about cookies: <span class="link"><a href="https://www.termsfeed.com/blog/cookies/">All About Cookies</a></span>.<br><br>
        We use both session and persistent Cookies for the purposes set out below:<br><br>
        <span class="bold">Necessary / Essential Cookies</span><br><br>
        Type: Session Cookies<br>
        Administered by: Us<br>
        Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.<br><br>
        <span class="bold">Cookies Policy / Notice Acceptance Cookies</span><br><br>
        Type: Persistent Cookies<br>
        Administered by: Us<br>
        Purpose: These Cookies identify if users have accepted the use of cookies on the Website.<br><br>
        <span class="bold">Functionality Cookies</span><br><br>
        Type: Persistent Cookies<br>
        Administered by: Us<br>
        Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.<br><br>
        <span class="bold">Tracking and Performance Cookies</span><br><br>
        Type: Persistent Cookies<br>
        Administered by: Third-Parties<br>
        Purpose: These Cookies are used to track information about traffic to the Website and how users use the Website. The information gathered via these Cookies may directly or indirectly identify you as an individual visitor. This is because the information collected is typically linked to a pseudonymous identifier associated with the device you use to access the Website. We may also use these Cookies to test new advertisements, pages, features or new functionality of the Website to see how our users react to them.<br><br>
        For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy.
      </div>
    </div>

    <div class="iseq-privacy-policy__section">
      <div class="iseq-privacy-policy__section__subtitle">
        Use of Your Personal Data
      </div>

      <div class="iseq-privacy-policy__section__text">
        The Company may use Personal Data for the following purposes:<br>
        ·        <span class="bold">To provide and maintain our Service</span>, including to monitor the usage of our Service.<br>
        ·        <span class="bold">To manage Your Account:</span> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.<br>
        ·        <span class="bold">For the performance of a contract:</span> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.<br>
        ·        <span class="bold">To contact You:</span> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.<br>
        ·        <span class="bold">To provide You</span> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.<br>
        ·        <span class="bold">To manage Your requests:</span> To attend and manage Your requests to Us.<br><br>
        We may share your personal information in the following situations:<br>
        ·        <span class="bold">With Service Providers:</span> We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to show advertisements to You to help support and maintain Our Service, to contact You, to advertise on third party websites to You after You visited our Service or for payment processing.<br>
        ·        <span class="bold">For Business transfers:</span> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our business to another company.<br>
        ·        <span class="bold">With Affiliates:</span> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.<br>
        ·        <span class="bold">With Business partners:</span> We may share Your information with Our business partners to offer You certain products, services or promotions.<br>
        ·        <span class="bold">With other users:</span> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.
      </div>
    </div>

    <div class="iseq-privacy-policy__section">
      <div class="iseq-privacy-policy__section__subtitle">
        Retention of Your Personal Data
      </div>

      <div class="iseq-privacy-policy__section__text">
        The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.<br><br>
        The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
      </div>
    </div>

    <div class="iseq-privacy-policy__section">
      <div class="iseq-privacy-policy__section__subtitle">
        Transfer of Your Personal Data
      </div>

      <div class="iseq-privacy-policy__section__text">
        Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.<br><br>
        Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.<br><br>
        The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.
      </div>
    </div>

    <div class="iseq-privacy-policy__section">
      <div class="iseq-privacy-policy__section__subtitle">
        Disclosure of Your Personal Data
      </div>

      <div class="iseq-privacy-policy__section__2ndsubtitle">
        Business Transactions
      </div>

      <div class="iseq-privacy-policy__section__text">
        If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
      </div>

      <div class="iseq-privacy-policy__section__2ndsubtitle">
        Law enforcement
      </div>

      <div class="iseq-privacy-policy__section__text">
        Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
      </div>

      <div class="iseq-privacy-policy__section__2ndsubtitle">
        Other legal requirements
      </div>
      <div class="iseq-privacy-policy__section__text">
        The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:<br>
        ·        Comply with a legal obligation<br>
        ·        Protect and defend the rights or property of the Company<br>
        ·        Prevent or investigate possible wrongdoing in connection with the Service<br>
        ·        Protect the personal safety of Users of the Service or the public<br>
        ·        Protect against legal liability<br>
      </div>
    </div>

    <div class="iseq-privacy-policy__section">
      <div class="iseq-privacy-policy__section__subtitle">
        Security of Your Personal Data and Genetic Information
      </div>

      <div class="iseq-privacy-policy__section__text">
        The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.<br><br>
        We store the software and sequence data you submit and your account, profile, and member information (including Personal Information) on "cloud" servers owned and operated by third party providers. Our platform encrypts all sequence data at rest and in transit. Our current providers are Google Cloud Platform (GCP) and Amazon Web Services (AWS). For information about Google Cloud Platform and Amazon Web Service's privacy protection and data security practices, please visit https://azure.microsoft.com/en-us/services/security-center and https://cloud.google.com/security.
      </div>

      <div class="iseq-privacy-policy__section__2ndsubtitle">
        Detailed Information on the Processing of Your Personal Data
      </div>

      <div class="iseq-privacy-policy__section__text">
        Service Providers have access to Your Personal Data only to perform their tasks on Our behalf and are obligated not to disclose or use it for any other purpose.
      </div>
    </div>

    <div class="iseq-privacy-policy__section">
      <div class="iseq-privacy-policy__section__subtitle">
        Analytics
      </div>

      <div class="iseq-privacy-policy__section__text">
        We may use third-party Service providers to monitor and analyze the use of our Service.
      </div>

      <div class="iseq-privacy-policy__section__2ndsubtitle">
        Google Analytics
      </div>

      <div class="iseq-privacy-policy__section__text">
        Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network.<br><br>
        You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing information with Google Analytics about visits activity.<br><br>
        For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: <span class="link"><a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></span>
      </div>
    </div>

    <div class="iseq-privacy-policy__section">
      <div class="iseq-privacy-policy__section__subtitle">
        Email Marketing
      </div>

      <div class="iseq-privacy-policy__section__text">
        We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and other information that may be of interest to You. You may opt-out of receiving any, or all, of these communications from Us by following the unsubscribe link or instructions provided in any email We send or by contacting Us.<br><br>
        We may use Email Marketing Service Providers to manage and send emails to You.
      </div>

      <div class="iseq-privacy-policy__section__2ndsubtitle">
        Mailchimp
      </div>

      <div class="iseq-privacy-policy__section__text">
        Mailchimp is an email marketing sending service provided by The Rocket Science Group LLC.<br><br>
        For more information on the privacy practices of Mailchimp, please visit their Privacy policy:  <span class="link"><a href="https://mailchimp.com/legal/privacy/">https://mailchimp.com/legal/privacy/</a></span>
      </div>
    </div>

    <div class="iseq-privacy-policy__section">
      <div class="iseq-privacy-policy__section__subtitle">
        Behavioral Remarketing
      </div>

      <div class="iseq-privacy-policy__section__text">
        The Company uses remarketing services to advertise on third party websites to You after You visited our Service. We and Our third-party vendors use cookies to inform, optimize and serve ads based on Your past visits to our Service.
      </div>

      <div class="iseq-privacy-policy__section__2ndsubtitle">
        Google Ads (AdWords)
      </div>

      <div class="iseq-privacy-policy__section__text">
        Google Ads (AdWords) remarketing service is provided by Google Inc.
        You can opt-out of Google Analytics for Display Advertising and customise the Google Display Network ads by visiting the Google Ads Settings page: <span class="link"><a href="http://www.google.com/settings/ads">http://www.google.com/settings/ads</a></span><br><br>
        Google also recommends installing the Google Analytics Opt-out Browser Add-on - <span class="link"><a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a></span> - for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics.<br><br>
        For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: <span class="link"><a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></span>
      </div>
    </div>

    <div class="iseq-privacy-policy__section">
      <div class="iseq-privacy-policy__section__subtitle">
        Payments
      </div>

      <div class="iseq-privacy-policy__section__text">
        We may provide paid products and/or services within the Service. In that case, we may use third-party services for payment processing (e.g. payment processors).<br><br>
        We will not store or collect Your payment card details. That information is provided directly to Our third-party payment processors whose use of Your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
      </div>

      <div class="iseq-privacy-policy__section__2ndsubtitle">
        Stripe
      </div>

      <div class="iseq-privacy-policy__section__text">
        Their Privacy Policy can be viewed at <span class="link"><a href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a></span>
      </div>

      <div class="iseq-privacy-policy__section__2ndsubtitle">
        PayPal
      </div>

      <div class="iseq-privacy-policy__section__text">
        Their Privacy Policy can be viewed at <span class="link"><a href="https://www.paypal.com/webapps/mpp/ua/privacy-full">https://www.paypal.com/webapps/mpp/ua/privacy-full</a></span><br><br>
        When You use Our Service to pay a product and/or service via bank transfer, We may ask You to provide information to facilitate this transaction and to verify Your identity.
      </div>
    </div>

    <div class="iseq-privacy-policy__section">
      <div class="iseq-privacy-policy__section__subtitle">
        GDPR Privacy
      </div>

      <div class="iseq-privacy-policy__section__2ndsubtitle">
        Legal Basis for Processing Personal Data under GDPR
      </div>

      <div class="iseq-privacy-policy__section__text">
        We may process Personal Data under the following conditions:<br>
        ·        <span class="bold">Consent:</span> You have given Your consent for processing Personal Data for one or more specific purposes.<br>
        ·        <span class="bold">Performance of a contract:</span> Provision of Personal Data is necessary for the performance of an agreement with You and/or for any pre-contractual obligations thereof.<br>
        ·        <span class="bold">Legal obligations:</span> Processing Personal Data is necessary for compliance with a legal obligation to which the Company is subject.<br>
        ·        <span class="bold">Vital interests:</span> Processing Personal Data is necessary in order to protect Your vital interests or of another natural person.<br>
        ·        <span class="bold">Public interests:</span> Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Company.<br>
        ·        <span class="bold">Legitimate interests:</span> Processing Personal Data is necessary for the purposes of the legitimate interests pursued by the Company.<br><br>
        In any case, the Company will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.
      </div>

      <div class="iseq-privacy-policy__section__2ndsubtitle">
        Your Rights under the GDPR
      </div>

      <div class="iseq-privacy-policy__section__text">
        The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can exercise Your rights.<br><br>
        You have the right under this Privacy Policy, and by law, to:<br>
        ·        <span class="bold">Request access to Your Personal Data.</span> The right to access, update or delete the information We have on You. Whenever made possible, you can access, update or request deletion of Your Personal Data directly within Your account settings section. If you are unable to perform these actions yourself, please contact Us to assist You. This also enables You to receive a copy of the Personal Data We hold about You.<br>
        ·        <span class="bold">Request correction of the Personal Data that We hold about You.</span> You have the right to to have any incomplete or inaccurate information We hold about You corrected.<br>
        ·        <span class="bold">Object to processing of Your Personal Data.</span> This right exists where We are relying on a legitimate interest as the legal basis for Our processing and there is something about Your particular situation, which makes You want to object to our processing of Your Personal Data on this ground. You also have the right to object where We are processing Your Personal Data for direct marketing purposes.<br>
        ·        <span class="bold">Request erasure of Your Personal Data.</span> You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it.<br>
        ·        <span class="bold">Request the transfer of Your Personal Data.</span> We will provide to You, or to a third-party You have chosen, Your Personal Data in a structured, commonly used, machine-readable format. Please note that this right only applies to automated information which You initially provided consent for Us to use or where We used the information to perform a contract with You.<br>
        ·        <span class="bold">Withdraw Your consent.</span> You have the right to withdraw Your consent on using your Personal Data. If You withdraw Your consent, We may not be able to provide You with access to certain specific functionalities of the Service.
      </div>

      <div class="iseq-privacy-policy__section__2ndsubtitle">
        Exercising of Your GDPR Data Protection Rights
      </div>

      <div class="iseq-privacy-policy__section__text">
        You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us. Please note that we may ask You to verify Your identity before responding to such requests. If You make a request, We will try our best to respond to You as soon as possible.<br><br>
        You have the right to complain to a Data Protection Authority about Our collection and use of Your Personal Data. For more information, if You are in the European Economic Area (EEA), please contact Your local data protection authority in the EEA.
      </div>
    </div>

    <div class="iseq-privacy-policy__section">
      <div class="iseq-privacy-policy__section__subtitle">
        Facebook Fan Page
      </div>

      <div class="iseq-privacy-policy__section__2ndsubtitle">
        Data Controller for the Facebook Fan Page
      </div>

      <div class="iseq-privacy-policy__section__text">
        The Company is the Data Controller of Your Personal Data collected while using the Service. As operator of the Facebook Fan Page (https://www.facebook.com/Intelliseq/), the Company and the operator of the social network Facebook are Joint Controllers.<br><br>
        The Company has entered into agreements with Facebook that define the terms for use of the Facebook Fan Page, among other things. These terms are mostly based on the Facebook Terms of Service: <span class="link"><a href="https://www.facebook.com/terms.php">https://www.facebook.com/terms.php</a></span><br><br>
        Visit the Facebook Privacy Policy <span class="link"><a href="https://www.facebook.com/policy.php">https://www.facebook.com/policy.php</a></span> for more information about how Facebook manages Personal data or contact Facebook online, or by mail: Facebook, Inc. ATTN, Privacy Operations, 1601 Willow Road, Menlo Park, CA 94025, United States.
      </div>

      <div class="iseq-privacy-policy__section__2ndsubtitle">
        Facebook Insights
      </div>

      <div class="iseq-privacy-policy__section__text">
        We use the Facebook Insights function in connection with the operation of the Facebook Fan Page and on the basis of the GDPR, in order to obtain anonymized statistical data about Our users.<br><br>
        For this purpose, Facebook places a Cookie on the device of the user visiting Our Facebook Fan Page. Each Cookie contains a unique identifier code and remains active for a period of two years, except when it is deleted before the end of this period.<br><br>
        Facebook receives, records and processes the information stored in the Cookie, especially when the user visits the Facebook services, services that are provided by other members of the Facebook Fan Page and services by other companies that use Facebook services.<br><br>
        For more information on the privacy practices of Facebook, please visit Facebook Privacy Policy here: <span class="link"><a href="https://www.facebook.com/full_data_use_policy">https://www.facebook.com/full_data_use_policy</a></span>
      </div>
    </div>

    <div class="iseq-privacy-policy__section">
      <div class="iseq-privacy-policy__section__subtitle">
        CCPA Privacy
      </div>

      <div class="iseq-privacy-policy__section__2ndsubtitle">
        Your Rights under the CCPA
      </div>

      <div class="iseq-privacy-policy__section__text">
        Under this Privacy Policy, and by law if You are a resident of California, You have the following rights:<br>
        ·        <span class="bold">The right to notice.</span> You must be properly notified which categories of Personal Data are being collected and the purposes for which the Personal Data is being used.<br>
        ·        <span class="bold">The right to access / the right to request.</span> The CCPA permits You to request and obtain from the Company information regarding the disclosure of Your Personal Data that has been collected in the past 12 months by the Company or its subsidiaries to a third-party for the third party's direct marketing purposes.<br>
        ·        <span class="bold">The right to say no to the sale of Personal Data.</span> You also have the right to ask the Company not to sell Your Personal Data to third parties. You can submit such a request by visiting our "Do Not Sell My Personal Information" section or web page.<br><br>
        <span class="bold">The right to know about Your Personal Data.</span> You have the right to request and obtain from the Company information regarding the disclosure of the following:<br>
        ·        The categories of Personal Data collected<br>
        ·        The sources from which the Personal Data was collected<br>
        ·        The business or commercial purpose for collecting or selling the Personal Data<br>
        ·        Categories of third parties with whom We share Personal Data<br>
        ·        The specific pieces of Personal Data we collected about You<br>
        ·        <span class="bold">The right to delete Personal Data.</span> You also have the right to request the deletion of Your Personal Data that have been collected in the past 12 months.<br><br>
        <span class="bold">The right not to be discriminated against.</span> You have the right not to be discriminated against for exercising any of Your Consumer's rights, including by:<br>
        ·        Denying goods or services to You<br>
        ·        Charging different prices or rates for goods or services, including the use of discounts or other benefits or imposing penalties<br>
        ·        Providing a different level or quality of goods or services to You<br>
        ·        Suggesting that You will receive a different price or rate for goods or services or a different level or quality of goods or services.
      </div>

      <div class="iseq-privacy-policy__section__2ndsubtitle">
        Exercising Your CCPA Data Protection Rights
      </div>

      <div class="iseq-privacy-policy__section__text">
        In order to exercise any of Your rights under the CCPA, and if you are a California resident, You can email or call us or visit our "Do Not Sell My Personal Information" section or web page.<br><br>
        The Company will disclose and deliver the required information free of charge within 45 days of receiving Your verifiable request. The time period to provide the required information may be extended once by an additional 45 days when reasonable necessary and with prior notice.
      </div>

      <div class="iseq-privacy-policy__section__2ndsubtitle">
        Do Not Sell My Personal Information
      </div>

      <div class="iseq-privacy-policy__section__text">
        We do not sell personal information. However, the Service Providers we partner with (for example, our advertising partners) may use technology on the Service that "sells" personal information as defined by the CCPA law.<br><br>
        If you wish to opt out of the use of your personal information for interest-based advertising purposes and these potential sales as defined under CCPA law, you may do so by following the instructions below.<br><br>
        Please note that any opt out is specific to the browser You use. You may need to opt out on every browser that you use.
      </div>

      <div class="iseq-privacy-policy__section__3rdsubtitle">
        Website
      </div>

      <div class="iseq-privacy-policy__section__text">
        You can opt out of receiving ads that are personalized as served by our Service Providers by following our instructions presented on the Service:<br>
        ·        From Our "Cookie Consent" notice banner<br>
        ·        Or from Our "CCPA Opt-out" notice banner<br>
        ·        Or from Our "Do Not Sell My Personal Information" notice banner<br>
        ·        Or from Our "Do Not Sell My Personal Information" link<br><br>
        The opt out will place a cookie on Your computer that is unique to the browser You use to opt out. If you change browsers or delete the cookies saved by your browser, you will need to opt out again.
      </div>

      <div class="iseq-privacy-policy__section__3rdsubtitle">
        Mobile Devices
      </div>

      <div class="iseq-privacy-policy__section__text">
        Your mobile device may give you the ability to opt out of the use of information about the apps you use in order to serve you ads that are targeted to your interests:<br>
        ·        "Opt out of Interest-Based Ads" or "Opt out of Ads Personalization" on Android devices<br>
        ·        "Limit Ad Tracking" on iOS devices<br>
        You can also stop the collection of location information from Your mobile device by changing the preferences on your mobile device.<br><br>
      </div>
    </div>

    <div class="iseq-privacy-policy__section">
      <div class="iseq-privacy-policy__section__subtitle">
        "Do Not Track" Policy as Required by California Online Privacy Protection Act (CalOPPA)
      </div>

      <div class="iseq-privacy-policy__section__text">
        Our Service does not respond to Do Not Track signals.<br><br>
        However, some third party websites do keep track of Your browsing activities. If You are visiting such websites, You can set Your preferences in Your web browser to inform websites that You do not want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of Your web browser.
      </div>

      <div class="iseq-privacy-policy__section__2ndsubtitle">
        Children's Privacy
      </div>

      <div class="iseq-privacy-policy__section__text">
        Our Service does not address anyone under the age of 18. We do not knowingly collect personally identifiable information from anyone under the age of 18. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 18 without verification of parental consent, We take steps to remove that information from Our servers.<br><br>
        If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.
      </div>

      <div class="iseq-privacy-policy__section__2ndsubtitle">
        Links to Other Websites
      </div>

      <div class="iseq-privacy-policy__section__text">
        Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.<br><br>
        We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
      </div>

      <div class="iseq-privacy-policy__section__2ndsubtitle">
        Changes to this Privacy Policy
      </div>

      <div class="iseq-privacy-policy__section__text">
        We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.<br><br>
        We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.<br><br>
        You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
      </div>

      <div class="iseq-privacy-policy__section__2ndsubtitle">
        Contact Us
      </div>

      <div class="iseq-privacy-policy__section__text">
        If you have any questions about this Privacy Policy, You can contact us:<br>
        ·        By email: <span class="link"><a href="mailto:contact@intelliseq.com">contact@intelliseq.com</a></span>
      </div>
    </div>
  </v-card>
</template>

<script>
  export default {
    name: "PrivacyPolicy"
  }
</script>

<style lang="scss"
       scoped>
    .iseq-privacy-policy {
        border-radius: 4px;
        padding: 32px;
        margin: 20px 40px;
        box-shadow: $card-shadow;

        &__title {
            display: flex;
            justify-content: center;

            &__text {
                font-size: 1.7em;
                color: var(--font-color);
                font-weight: bold;
            }
        }

        &__last-updated-time {
            display: flex;
            justify-content: flex-end;
            margin: 10px 10px 10px 0px;
        }

        &__section {
            margin: 0px 15px 0px 15px;

            &__subtitle {
                display: flex;
                justify-content: center;
                margin-bottom: 15px;
                font-size: 1.5em;
                color: var(--font-color);
                font-weight: bold;
            }

            &__2ndsubtitle {
                margin-bottom: 15px;
                font-size: 1.3em;
                color: var(--font-color);
                font-weight: bold;
            }

            &__3rdsubtitle {
                margin-bottom: 10px;
                font-size: 1.1em;
                color: var(--font-color);
                font-weight: bold;
            }

            &__text {
                margin-bottom: 20px;
                margin-left: 5px;

                .underlined {
                    text-decoration: underline;
                }

                .bold {
                    font-weight: bold;
                }
            }
        }

        .link {
            color: var(--primary-color);
        }
    }
</style>