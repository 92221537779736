<template>
  <div class="iseq-organization-details">
    <v-iseq-card
      :title="`Organization: ${organization.name }`"
      class="iseq-organization-details__base-info"
    >
      <template #title-row>
        <div class="iseq-organization-details__base-info__title-row">
          <template
            v-if="permissionRequired('organizationEdit')"
            class="iseq-organization-details__base-info__title-row__button"
          >
            <v-iseq-btn
              type="secondary"
              @click="updateOrganization"
            >
              Edit
            </v-iseq-btn>
          </template>
        </div>
      </template>

      <v-divider :inset="false" />

      <div
        v-if="organization.description"
        class="iseq-organization-details__base-info__description"
      >
        {{ organization.description }}
      </div>

      <v-iseqfield :values="descriptionFields" />
    </v-iseq-card>

    <v-iseq-card
      no-title
      no-bottom-margin
      class="iseq-organization-details__actions"
    >
      <template #title-row>
        <div class="iseq-organization-details__actions__wrapper">
          <v-iseq-btn
            v-if="permissionRequired('organizationCreditsAdd')"
            width="wide"
            mr
            @click="addCredits"
          >
            Add credits
          </v-iseq-btn>

          <v-iseq-btn
            v-if="permissionRequired('projectAdd')"
            width="wide"
            mr
            @click.prevent="newProject"
          >
            New project
          </v-iseq-btn>

          <v-iseq-btn
            v-if="permissionRequired('organizationUserInvite')"
            width="wide"
            @click="openInviteNewMemberModal()"
          >
            Invite new member
          </v-iseq-btn>
        </div>
      </template>
    </v-iseq-card>

    <v-iseq-card
      title="Organization details"
      tabs-only
      class="iseq-organization-details__relationship"
    >
      <template #title-row>
        <div class="iseq-organization-details__relationship__wrapper">
          <v-tabs
            v-model="tab"
            height="64px"
            color="primary"
          >
            <v-menu
              bottom
              offset-y
              open-on-hover
            >
              <template #activator="{ on }">
                <v-tab
                  v-if="permissionRequired('projectView')"
                  class="iseq-organization-details__relationship__wrapper__tab"
                  href="#projects"
                  v-on="on"
                >
                  <v-icon class="mr-4 title">
                    mdi-folder
                  </v-icon>
                  Projects
                  <v-icon class="ml-2 title">
                    mdi-menu-down
                  </v-icon>
                </v-tab>
              </template>

              <v-list>
                <v-list-item @click="projectTab = 'ownProjects'; tab = 'projects'">
                  <v-list-item-title>
                    <v-icon color="primary">
                      mdi-account-group-outline
                    </v-icon>
                    My projects
                  </v-list-item-title>
                </v-list-item>

                <v-list-item @click="projectTab = 'otherProjects'; tab = 'projects'">
                  <v-list-item-title>
                    <v-icon color="primary">
                      mdi-account-multiple
                    </v-icon>
                    {{ 'Other projects' + (joinableProjectsTotalElements ? ` (${joinableProjectsTotalElements})` : '') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-tab
              class="iseq-organization-details__relationship__wrapper__tab"
              href="#employees"
            >
              <v-icon class="mr-4 title">
                mdi-account-multiple
              </v-icon>
              Members
            </v-tab>

            <v-tab
              v-if="permissionRequired('organizationTransactionHistoryView')"
              class="iseq-organization-details__relationship__wrapper__tab"
              href="#transactionsHistory"
            >
              <v-icon class="mr-4 title">
                mdi-calendar-clock
              </v-icon>
              Transactions history
            </v-tab>

            <v-tab
              v-if="permissionRequired('organizationUserInvite')"
              class="iseq-organization-details__relationship__wrapper__tab"
              href="#sentInvitations"
            >
              <v-icon class="mr-4 title">
                mdi-account-plus
              </v-icon>
              Invitations
            </v-tab>
          </v-tabs>
        </div>
      </template>
    </v-iseq-card>

    <v-iseq-card class="iseq-organization-details__table">
      <v-tabs-items
        touchless
        :value="tab"
      >
        <v-tab-item value="projects">
          <project-list-tabs :tab="projectTab" />
        </v-tab-item>

        <v-tab-item value="employees">
          <v-data-table
            :headers="memberHeaders"
            :footer-props="{
              'items-per-page-options': itemsPerPageOptionsMembers
            }"
            :items="members"
          >
            <template #[`item.actions`]="{ item }">
              <v-icon
                v-if="permissionRequired('organizationUserRoleUpdate') && changePositionInOrganization(item.role).length"
                small
                class="iseq-organization-details__table__icon"
                @click="updateRole(item)"
              >
                mdi-pencil
              </v-icon>

              <v-icon
                v-if="permissionRequired('organizationUserRemove') && memberRoleCompare(item)"
                small
                class="iseq-organization-details__table__icon"
                @click="removeMember(item)"
              >
                mdi-account-off
              </v-icon>
            </template>
          </v-data-table>
        </v-tab-item>

        <v-tab-item value="transactionsHistory">
          <v-data-table
            :headers="transactionsHeader"
            :items="transactionsHistory"
          >
            <template #[`item.type.id`]="{item}">
              {{ parseType(item.type.id) }}
            </template>

            <template #[`item.value`]="{item}">
              {{ item.value }} credits
            </template>
          </v-data-table>
        </v-tab-item>

        <v-tab-item value="sentInvitations">
          <v-data-table
            :headers="invitationsHeader"
            :items="invitations"
          >
            <template #[`item.expirationDate`]="{item}">
              <v-tooltip top>
                <template #activator="{ on }">
                  <span v-on="on">
                    {{ calculateDifference(item.expirationDate) }}
                  </span>
                </template>

                {{ item.expirationDate }}
              </v-tooltip>
            </template>

            <template #[`item.role`]="{item}">
              {{ roleToHR(item.role) }}
            </template>

            <template #[`item.status`]="{item}">
              {{ item.status[0] + item.status.toLowerCase().slice(1) }}
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-iseq-card>

    <iseq-confirmation-modal
      v-if="updateMemberDialog"
      header="Update role"
      confirm-button-type="primary"
      confirm-button-text="Save changes"
      reject-button-type="secondary"
      reject-button-text="Close"
      @actionRejected="closeDialog"
      @actionConfirmed="updateMemberRole(member)"
    >
      <p class="iseq-organization-details__dialog__info">
        Change user's role for:
        <span class="iseq-organization-details__dialog__info__email">{{ member.email }}</span>
      </p>

      <v-select
        v-model="member.newRole"
        item-text="name"
        item-value="value"
        :items="changePositions(member.role)"
      />

      <p
        v-if="showWarningText"
        class="iseq-organization-details__dialog__info--warning"
      >
        <v-icon class="icon-warning-sign">
          warning
        </v-icon>
        WARNING: the user will lose access to the projects he has access to as an organization's owner/admin.
      </p>
    </iseq-confirmation-modal>

    <iseq-confirmation-modal
      v-if="removeMemberDialog"
      header="Remove member"
      confirm-button-type="danger"
      confirm-button-text="Remove"
      reject-button-type="secondary"
      reject-button-text="close"
      @actionRejected="removeMemberDialog = false"
      @actionConfirmed="removeMemberConfirmed()"
    >
      <p class="iseq-organization-details__dialog__info">
        Are you sure that you want to remove member with email:
        <span class="iseq-organization-details__dialog__info__email">{{ member.email }}</span>
      </p>
    </iseq-confirmation-modal>

    <iseq-confirmation-modal
      v-if="updateOrganizationDialog"
      width="800px"
      header="Update organization data"
      confirm-button-type="primary"
      confirm-button-text="Save changes"
      reject-button-type="secondary"
      reject-button-text="Close"
      :confirm-button-disabled="!isNameFormValid"
      @actionRejected="closeOrganizationDialog"
      @actionConfirmed="saveChanges"
    >
      <div class="iseq-organization-details__settings__inputs">
        <div>
          <v-form
            ref="nameForm"
            v-model="isNameFormValid"
          >
            <v-text-field
              v-model="organizationUpdateForm.formData.name"
              label="Name"
              :rules="nameRules"
              counter="50"
              outlined
              class=""
            />
          </v-form>
        </div>

        <v-text-field
          v-model="organizationUpdateForm.formData.website"
          label="Website"
          outlined
          class=""
        />

        <v-select
          v-model="organizationUpdateForm.formData.type"
          class=""
          label="Type"
          outlined
          :items="types"
        />

        <v-text-field
          v-model="organizationUpdateForm.formData.country"
          label="Country"
          outlined
          class=""
        />

        <v-text-field
          v-model="organizationUpdateForm.formData.city"
          label="Location"
          outlined
          class=""
        />

        <v-textarea
          v-model="organizationUpdateForm.formData.description"
          auto-grow
          label="Description"
          outlined
          class=""
        />
      </div>
    </iseq-confirmation-modal>

    <invite-member-modal
      v-if="inviteMemberModal"
      invitation-type="organization"
      @closed="inviteMemberModal=false"
    />
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import IseqField from "@/components/ui/IseqField.vue";
  import InviteMemberModal from '@/components/modals/InviteMemberModal.component.vue';
  import {
    permissionCheck,
    changePositionInOrganization,
    isUserRoleHigher
  } from "@/plugins/permissions";
  import {dateToRelativeTime, pagingDataParse, roleToHR} from "@/plugins/utils";
  import IseqConfirmationModal from "@/components/modals/IseqConfirmationModal.vue";
  import ProjectListTabs from "@/views/Project/components/ProjectListTabs";
  import IseqCard from "@/components/ui/IseqCard.vue";
  import IseqButton from "@/components/ui/IseqButton.vue";
  import {segmentTrack} from "@/helpers/analytics";

  export default {
    name: "OrganizationDetails",
    components: {
      ProjectListTabs,
      IseqConfirmationModal,
      'v-iseqfield': IseqField,
      InviteMemberModal,
      "v-iseq-card": IseqCard,
      "v-iseq-btn": IseqButton
    },
    data: function () {
      return {
        showWarningText: false,
        itemsPerPageOptionsProjects: [10, 20, 30, -1],
        itemsPerPageOptionsMembers: [10, 20, 30, -1],
        optionsProjects: {},
        inviteMemberModal: false,
        updateMemberDialog: false,
        updateOrganizationDialog: false,
        removeMemberDialog: false,
        nameError: "",
        nameCorrection: false,
        newMemberAddress: '',
        organizationUpdateForm: {
          uuid: '',
          formData: {
            name: '',
            type: '',
            country: '',
            city: '',
            website: '',
            description: ''
          }
        },
        position: "ORGANIZATION_MEMBER",
        member: {
          uuid: '',
          email: '',
          newRole: '',
          role: ''
        },
        descriptions: {
          ORGANIZATION_OWNER: "“Owner” is a user who creates and owns the organization and projects; has access to all projects; manages payments and permissions in the organization and projects.",
          ORGANIZATION_MEMBER: "“Member” is a user who has access to the organization; sees the list of projects; can join the projects by sending join requests to the project’s admin.",
          ORGANIZATION_ADMIN: "“Admin” is a user who creates projects within an organization; has access to all the existing projects in the organization; manages (partly) permissions in the organization and projects."
        },
        uuid: this.$route.params.uuid,
        tab: "employees",
        projectTab: "ownProjects",
        memberHeaders: [
          {text: 'Name', value: 'name'},
          {text: 'Email', value: 'email'},
          {text: 'Role', value: 'role'},
          {text: 'Actions', value: 'actions', sortable: false,}
        ],
        projectHeaders: [
          {text: 'Name', value: 'name'},
          {text: 'Role', value: 'role'},
          {text: 'Actions', value: 'joinable'}
        ],
        transactionsHeader: [
          {text: 'Type', value: 'type.id'},
          {text: 'Project name', value: 'projectName'},
          {text: 'Analysis name', value: 'targetName'},
          {text: 'Created at', value: 'date'},
          {text: 'Cost', value: 'value'},
        ],
        invitationsHeader: [
          {text: 'Email', value: 'recipientEmail'},
          {text: 'Expires in', value: 'expirationDate'},
          {text: 'Role', value: 'role'},
          {text: 'Issued by', value: 'senderEmail'},
          {text: 'Status', value: 'status'},
        ],
        types: [
          "Business",
          "Research Institute",
          "University",
          "Laboratory",
          "Non - profit organization",
          "Individual",
          "Other"
        ],
        isNameFormValid: false,
        nameRules: [
          v => (!!v && v.length > 0) || "Name cannot be empty",
          v => v.length <= 50 || "Name cannot be longer than 50 characters",
          v => !this.organizations.some(element => (element.name === v && v !== this.organization.name)) || "Organization named " + v + "already exists in our system"
        ]
      }
    },
    computed: {
      ...mapState('organization', [
        'organization',
        'organizations',
        'contentProjects',
        'totalElementsProjects',
        'members',
        'permissionLevels',
        'content',
        'totalElements',
        'transactionsHistory',
        'invitations'
      ]),
      ...mapState('project', ['joinableProjectsTotalElements']),
      ...mapState('user', ['user']),
      descriptionFields() {
        if (!this.organization) return [];
        else return [
          {
            icon: "mdi-tooltip-text",
            name: "Website: ",
            value: this.organization.website
          },
          {
            icon: "mdi-domain",
            name: "Type: ",
            value: this.organization.type
          },
          {
            icon: "mdi-longitude",
            name: "Country: ",
            value: this.organization.country
          },
          {
            icon: "mdi-map-marker",
            name: "Location: ",
            value: this.organization.city
          },
          {
            icon: "mdi-account-multiple",
            name: "Number of members: ",
            value: this.organization.numberOfMembers
          }
        ]
      },
    },
    watch: {
      optionsProjects: {
        handler() {
          this.$store.dispatch('organization/getOrganizationProjects', {
            uuid: this.uuid,
            data: pagingDataParse({
              sortBy: this.optionsProjects.sortBy,
              sortDesc: this.optionsProjects.sortDesc,
              itemsPerPage: this.optionsProjects.itemsPerPage,
              page: this.optionsProjects.page,
              totalElements: this.totalElementsProjects
            })
          });
        },
        deep: true,
      },
      optionsTransactionHistory: {
        handler() {
          this.$store.dispatch('organization/getTransactionHistory', {uuid: this.uuid});
        }
      },
      organization(current) {
        if (current.uuid !== this.$route.params.uuid) {
          this.$router.push(`/organizations/${current.uuid}`);
        }
        if (this.permissionRequired('projectView')) {
          this.tab = "projects";
        } else {
          this.tab = "employees";
        }
      }
    },
    created() {
      this.uuid = this.$route.params.uuid;
      this.$store.dispatch('organization/getOrganization', {organizationUuid: this.uuid, getAllData: true});
    },
    methods: {
      roleToHR(role) {
        return roleToHR(role);
      },
      calculateDifference(date) {
        let relativeTime = dateToRelativeTime(date);
        return relativeTime.slice(0, relativeTime.lastIndexOf(' '));
      },
      openInviteNewMemberModal() {
        segmentTrack({event: "Invite New Member Clicked"});
        this.inviteMemberModal = true;
      },
      parseType(item) {
        const firstLetter = item.charAt(0).toLocaleUpperCase();
        return firstLetter + item.slice(1).toLowerCase().replace('_', ' ');
      },
      permissionRequired(action) {
        return permissionCheck(action);
      },
      memberRoleCompare(item) {
        return isUserRoleHigher(item.role.toUpperCase().replace(" ", "_"));
      },
      updateOrganization() {
        this.organizationUpdateForm = {
          uuid: this.uuid,
          formData: {
            city: this.organization.city,
            country: this.organization.country,
            description: this.organization.description,
            type: this.organization.type,
            website: this.organization.website,
            name: this.organization.name
          }
        };
        this.updateOrganizationDialog = true;
      },
      closeOrganizationDialog() {
        this.nameCorrection = false;
        this.updateOrganizationDialog = false;
      },
      saveChanges() {
        this.updateOrganizationDialog = false;
        this.$store.dispatch('organization/updateOrganization', this.organizationUpdateForm);
      },
      correctName() {
        this.nameError = "";
        this.nameCorrection = false;
        const isNameTaken = this.content.find(element => element.name === this.organizationUpdateForm.formData.name);
        if (this.organizationUpdateForm.formData.name !== this.organizationNameRegex(this.organizationUpdateForm.formData.name)) {
          this.nameError = "Name contains illegal characters, our suggestion: " + this.organizationNameRegex(this.organizationUpdateForm.formData.name);
          this.nameCorrection = true;
          return false;
        } else if (this.organizationUpdateForm.formData.name === "") {
          this.nameError = "Name cannot be empty";
          this.nameCorrection = true;
          return false;
        } else if (isNameTaken && this.organizationUpdateForm.formData.name !== this.organization.name) {
          this.nameError = "This name has already been used, please try another one";
          this.nameCorrection = true;
          return false;
        }
        return true;
      },
      acceptNameChange() {
        this.organizationUpdateForm.formData.name = this.organizationNameRegex(this.organizationUpdateForm.formData.name);
        this.nameCorrection = false;
        this.nameError = ""
      },
      organizationNameRegex(name) {
        return name.replace(/[^A-Za-z0-9_\s\-\']+/g, '');
      },
      closeDialog() {
        this.updateMemberDialog = false;

        this.member = {
          uuid: '',
          email: '',
          newRole: '',
          role: ''
        }
      },
      addCredits() {
        segmentTrack({event: "Add Credits Clicked"});
        this.$store.commit('payment/setPaymentDialog', true)
      },
      newProject() {
        segmentTrack({event: "New Project Clicked"});
        this.$router.push('/projects/new');
      },
      joinProject(project) {
        this.$store.dispatch('project/joinProject', {projectUuid: project.uuid});
        this.contentProjects[this.contentProjects.indexOf(project)].requestSent = true;
      },
      updateRole(data) {
        const newRole = data.role.replace(' ', '_').toUpperCase();
        this.member = {
          uuid: data.uuid,
          email: data.email,
          role: data.role,
          newRole: newRole
        };
        this.updateMemberDialog = true;
      },
      updateMemberRole() {
        const organizationUud = this.$route.params.uuid;
        const data = {
          role: this.member.newRole,
          userUuid: this.member.uuid
        };
        this.$store.dispatch('organization/updateMemberRole', {
          uuid: organizationUud,
          data: data
        });
        this.updateMemberDialog = false;
      },
      removeMember(data) {
        this.member = {
          uuid: data.uuid,
          email: data.email,
          role: '',
          newRole: ''
        };
        this.removeMemberDialog = true;
      },
      removeMemberConfirmed() {
        this.removeMemberDialog = false;
        this.$store.dispatch('organization/removeMember', {
          uuid: this.member.uuid,
          organizationUuid: this.uuid
        });
      },
      changePositions(memberRole) {
        const oldRole = this.member.role.slice(this.member.role.indexOf(' ') + 1).toUpperCase();
        const newRole = this.member.newRole.slice(this.member.newRole.indexOf('_') + 1);

        this.showWarningText = ((oldRole === 'ADMIN' || oldRole === 'OWNER') && newRole === 'MEMBER');

        return changePositionInOrganization(memberRole);
      },
      changePositionInOrganization(memberRole) {
        return changePositionInOrganization(memberRole);
      },
      checkDirection(direction) {
        switch (direction) {
        case true:
          return 'DESC';
        case false:
          return 'ASC';
        default:
          return ''
        }
      },
    }
  }
</script>
<style lang="scss"
       scoped>

.iseq-organization-details {
  padding: 0;
  margin: 0;

  &__base-info,
  &__actions,
  &__relationship,
  &__table {
    &__wrapper {
      display: flex;
    }

    &__icon {
      margin-left: 20px;
    }
  }

  &__table {
    &__field {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  &__base-info {
    &__title-row {
      width: min-content;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    &__description {
      padding: 20px 0px 15px;
      margin-left: 2px;
      font-size: 14px;
      color: var(--font-inverted-color);
    }
  }

  &__actions {
    &__wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &__relationship {
    &__wrapper {
      &__tab {
        max-width: 200px;
        padding: 0 24px;

        &__icon {
          margin-right: 16px;
        }
      }
    }
  }

  &__dialog {
    &__info {
      font-size: 18px;

      &__email {
        display: block;
        color: var(--primary-color);
      }

      &--warning {
        font-size: 12px;
        border-radius: 2px;
        background: rgba(251, 140, 0, 0.1);
        border: 1px solid var(--warning-light-color);
        color: #000;
        padding: 4px !important;

        .icon-warning-sign {
          color: var(--warning-light-color);
          font-size: 20px;
        }
      }
    }
  }

  &__settings {
    &__inputs {
      margin: 10px 20px 0px;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
