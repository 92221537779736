<template>
  <div>
    <v-text-field
      v-model="localValue"
      outlined
      hide-details
      :label="inputName"
      @change="emitChangeEvent"
    />
  </div>
</template>

<script>
  export default {
    name: "IseqTextFieldInput",
    props: {
      value: {
        required: false,
        default: undefined
      },
      inputName: {
        type: String,
        required: false,
        default: ""
      },
    },
    computed: {
      localValue: {
        get() {
          return this.value;
        },
        set(newValue) {
          this.$emit('input', newValue);
        }
      }
    },
    methods: {
      emitChangeEvent() {
        this.$emit('change', this.localValue);
      }
    }
  }
</script>
