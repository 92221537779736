<template>
  <div class="iseq-left-toolbar">
    <div
      id="top"
      :style="topMarginStyle"
    >
      <p class="iseq-left-toolbar__header">
        Navigation
      </p>

      <v-list class="iseq-left-toolbar__list">
        <v-list-item
          v-for="item in toolbarItems('Top')"
          :key="item.title"
          :to="item.route"
          class="iseq-left-toolbar__list__wrapper"
          @click="executeAction(item)"
        >
          <div class="iseq-left-toolbar__list__wrapper__link">
            <v-icon
              class="icon"
              :style="style(item, false)"
            >
              {{ item.icon }}
            </v-icon>

            <div
              class="item text-wrap"
              :style="style(item, true)"
              v-text="item.title"
            />

            <v-badge
              v-if="item.title==='Dashboard' && unreadNotificationsLength()+invitations.length>0"
              :content="unreadNotificationsLength()+invitations.length"
              color="danger"
              offset-x="-4"
              offset-y="12"
            />
          </div>
        </v-list-item>
      </v-list>
    </div>

    <div
      id="bottom"
      :style="bottomMarginStyle"
    >
      <v-list class="iseq-left-toolbar__list">
        <v-list-item
          v-for="item in filteredBottomToolbarItems"
          :key="item.title"
          :to="item.route"
          class="iseq-left-toolbar__list__wrapper iseq-left-toolbar__list__wrapper--bottom"
          @click="executeAction(item)"
        >
          <div class="iseq-left-toolbar__list__wrapper__link">
            <v-icon
              class="icon"
              :style="style(item, false)"
            >
              {{ item.icon }}
            </v-icon>

            <div
              class="item text-wrap"
              :style="style(item, true)"
              v-text="item.title"
            />
          </div>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapState} from 'vuex';
  import {getDocumentTitle} from "@/plugins/utils";

  export default {
    name: 'IseqLeftToolbar',
    props: {
      topMargin: {
        type: String,
        default: "80px"
      },
      footerIntersection: {
        type: Number,
        default: 0
      }
    },
    data: function () {
      return {
        websiteHeight: document.body.clientHeight,
        screenHeight: window.innerHeight,
        topMenuHeight: "0px",
        bottomMenuHeight: "0px",
        resizeObserver: undefined,
        itemsTop: [
          {title: 'Dashboard', icon: 'mdi-view-dashboard', route: '/', group: 'Dashboard', projectRequired: false},
          {title: 'Projects', icon: 'mdi-folder', route: '/projects', group: 'Projects', projectRequired: false},
          {title: 'Samples', icon: 'mdi-clipboard-file', route: '/samples', group: 'Samples', projectRequired: true},
          {title: 'Analyses', icon: 'mdi-poll', route: '/analyses', group: 'Analyses', projectRequired: true},
          {title: 'Workflows', icon: 'mdi-sitemap', route: '/workflows', group: 'Workflows', projectRequired: false}
        ],
        itemsBottom: [
          {title: 'Organizations', icon: 'mdi-account-group', route: '/organizations', group: 'Organizations', projectRequired: false},
          {title: 'FAQ', icon: 'mdi-frequently-asked-questions', route: '/FAQ', group: 'FAQ', projectRequired: false}
        ],
        clinicalItemsTop: [],
        clinicalItemsBottom: [
          {title: "User manual (Instrukcja użytkowania)", icon: "mdi-file-document", group: 'none', fileAddress: "@/../clinical/IntelliseqFlowManualPL.pdf", projectRequired: false},
          {title: "Medical Device Label (Etykieta wyrobu medycznego)", icon: "mdi-label", group: 'none', fileAddress: "@/../clinical/label.pdf", projectRequired: false}
        ],
      }
    },
    computed: {
      ...mapState('user', ['user']),
      ...mapState('notification', ['notifications']),
      ...mapState('invitation', ['invitations']),
      ...mapState('project', ['project']),
      ...mapState('organization', ['organizations']),
      ...mapGetters('notification', ['unreadNotificationsNumber']),
      ...mapGetters('invitation', ['invitationsNumber']),
      topMarginStyle() {
        return 'position: fixed; top: ' + (parseInt(this.topMargin.slice(0, -2)) + 24) +'px';
      },
      bottomMarginStyle() {
        if (this.isCollision) {
          return 'position: fixed; top: ' + ((parseInt(this.topMargin.slice(0, -2)) + 24) + this.topMenuHeight) + 'px';
        } else {
          return 'position: fixed; top: ' + (this.screenHeight - this.bottomMenuHeight - this.footerIntersection - 1) + 'px';
        }
      },
      isCollision() {
        return (parseInt(this.topMargin.slice(0, -2)) + 24) + this.topMenuHeight > this.screenHeight - this.bottomMenuHeight - this.footerIntersection;
      },
      isClinical() {
        return process.env.VUE_APP_CLINICAL_VERSION === "true";
      },
      filteredBottomToolbarItems() {
        return this.toolbarItems('Bottom').filter(item => !(item.title==='Organizations' && this.organizations.length === 1));
      }
    },
    watch:{
      $route (to, from){
        this.$forceUpdate()
      },

      notifications () {
        this.refreshDocumentTitle();
      },

      invitations () {
        this.refreshDocumentTitle();
      }
    },
    created() {
      this.resizeObserver = new ResizeObserver(entries =>
        this.onResize()
      );
      window.addEventListener('resize', this.onResize);
      this.resizeObserver.observe(document.body);
    },

    mounted() {
      this.resizeBottomMenu();
      this.resizeTopMenu();
      new ResizeObserver(this.resizeBottomMenu).observe(document.getElementById("bottom"));
      new ResizeObserver(this.resizeBottomMenu).observe(document.getElementById("top"));
    },

    methods: {
      refreshDocumentTitle() {
        document.title = getDocumentTitle(this.$route.meta.title, this.unreadNotificationsNumber + this.invitationsNumber);
      },
      executeAction (item) {
        if (item.route) {
          this.route(item.route);
        } else if (item.fileAddress) {
          this.openFile(item.fileAddress);
        }
      },
      toolbarItems(part) {
        let items = this['items' + part];
        if (this.isClinical) {
          items = [...items, ...this['clinicalItems' + part]];
        }
        if (this.project.uuid) {
          return items;
        } else {
          return items.filter(item => item.projectRequired === false)
        }
      },
      openFile (fileAddress) {
        window.open(fileAddress, '_blank');
      },
      route (destination) {
        this.$router.push({path: destination}).catch(err=>{});
      },
      style: function (item, istitle) {
        let color;
        if (istitle) {
          color = this.$vuetify.theme.currentTheme.active;
        } else {
          if (this.$router.currentRoute.meta.group === item.group) {
            color = this.$vuetify.theme.currentTheme.primary;
          } else {
            color = this.$vuetify.theme.currentTheme.inactive;
          }
        }
        return {
          'color': color
        }
      },
      unreadNotificationsLength(){
        return this.notifications.filter(element => element.read === false).length;
      },
      onResize() {
        this.websiteHeight = document.body.clientHeight;
        this.screenHeight = window.innerHeight;
      },
      resizeBottomMenu() {
        let bottom = document.getElementById("bottom");
        this.bottomMenuHeight = bottom ? bottom.clientHeight : undefined;
      },
      resizeTopMenu() {
        let top = document.getElementById("top");
        this.topMenuHeight = top ? top.clientHeight : undefined;
      }
    }
  }
</script>
<style lang="scss"
       scoped>

    .iseq-left-toolbar {
        padding: 0 32px;
        margin-top: 24px;
        height: 100%;
        position: fixed;

        &__header {
            color: var(--font-color);
            font-size: 1.7em ;
            padding: 0 16px;
        }

        &__list {
            width: 210px;

            &__wrapper {
                padding-bottom: 12px;
                padding-top: 12px;
                min-height: 24px;

                &__link {
                    display: flex;
                    flex-direction: row;

                    .icon {
                        margin-right: 24px;
                        margin-top: -4px;
                    }

                    .item {
                        font-size: .75rem;
                        letter-spacing: .0333333333em;
                        line-height: 1.25rem;
                        word-wrap: break-word;
                    }

                    .iseq-badge {
                        background: var(--danger-color) !important;
                        height: 20px;
                        width: 20px;
                        border-radius: 50%;
                        text-align: center;
                        padding-top: 4px;
                        color: #fff;
                    }
                }

                &--bottom {
                    &:first-child {
                        border-top: 1px solid rgba(0,0,0,.12);
                    }
                }
            }
        }

        &__certification-logo {
            display: flex;
            justify-content: center;
            margin: 20px;
        }
    }

    @media screen and (max-width: 1270px) and (min-width: 1141px) {
        .iseq-left-toolbar {
            padding: 0 24px;

            &__list {
                width: 190px;
            }
        }
    }

    @media screen and (max-width: 1140px) and (min-width: 768px) {
        .iseq-left-toolbar {
            padding: 0 8px;

            &__list {
                width: 170px;
            }
        }
    }
</style>