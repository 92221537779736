<template>
  <v-card class="cookies-consents">
    <div class="cookies-consents__content">
      <script
        id="CookieDeclaration"
        src="https://consent.cookiebot.com/30af8f8f-0616-43a4-beda-3c69b309ee5e/cd.js"
        type="application/javascript"
        async
        defer
      />
    </div>
  </v-card>
</template>

<script>
  export default {
    name: "CookiesConsents"
  }
</script>

<style lang="scss" scoped>
.cookies-consents {
  border-radius: 4px;
  padding: 32px;
  margin: 20px 40px;
  box-shadow: $card-shadow;
}
</style>