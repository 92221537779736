<template>
  <div class="iseq-confirm-card">
    <div>
      <span class="iseq-confirm-card__header">Current credit card data:</span>
    </div>

    <div>
      <span class="iseq-confirm-card__content">
        <b>{{ currentCardData.brand }}</b> ending with <b>{{ currentCardData.last4 }}</b><br>
        Expires: <b>{{ currentCardData.expMonth }}/{{ currentCardData.expYear }}</b>
      </span>
    </div>

    <div>
      <span class="iseq-confirm-card__subinfo">
        Only one card per account is allowed - in order to add a different card you have to remove the current one.
      </span>
    </div>

    <v-iseq-button
      type="primary"
      class="iseq-confirm-card__button"
      @click="confirmCard"
    >
      Confirm card
    </v-iseq-button>

    <v-iseq-button
      type="red"
      class="iseq-confirm-card__button"
      @click="removeCardData"
    >
      Remove credit card
    </v-iseq-button>

    <v-iseq-button
      type="secondary"
      class="iseq-confirm-card__button"
      @click="goBack"
    >
      Go back
    </v-iseq-button>
  </div>
</template>

<script>
  import IseqButton from "@/components/ui/IseqButton.vue";
  import {mapState} from "vuex";

  export default {
    name: 'IseqConfirmCardData',
    components: {
      'v-iseq-button': IseqButton
    },
    computed: {
      ...mapState('payment', [ 'currentCardData' ]),
    },
    methods: {
      goBack() {
        this.$store.commit('payment/setPaymentSteps', 1);
      },
      removeCardData() {
        this.$store.dispatch('payment/removeCurrentCardData');
      },
      confirmCard() {
        this.$store.commit('payment/setPaymentSteps', 3);
      }
    },
  }
</script>

<style scoped lang="scss">
.iseq-confirm-card {
  display: flex;
  flex-direction: column;

  &__header {
    text-align: center;
    font-weight: 500;
    font-size: 24px;
  }

  &__content {
    display: block;
    margin-left: 15px;
    margin-bottom: 15px;
    font-size: 18px;
  }

  &__subinfo {
    font-size: 12px;
  }
  &__button {
    margin-top: 16px;
    width: 100%;
    font-size: 16px !important;
  }
}
</style>