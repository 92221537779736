<template>
  <div class="wrapper">
    <iseq-credential-views-frame>
      <template v-if="form==='remindPassword'">
        <div class="wrapper__input-fields">
          <div class="wrapper__input-fields__title">
            <span class="wrapper__input-fields__title__text">
              Reset your password
            </span>
          </div>

          <div class="wrapper__input-fields__field-name">
            <span class="wrapper__input-fields__field-name__text">
              Email
            </span>
          </div>

          <v-text-field
            v-model="username"
            type="email"
            filled
            class="iseq-text-field wrapper__input-fields__field"
            @keyup.enter="retrievePassword"
          />

          <div
            v-if="flags.isUsernameEmpty"
            class="wrapper__input-fields__error"
          >
            <span
              class="wrapper__input-fields__error__text"
            >Email address cannot be empty</span>
          </div>

          <div
            v-if="flags.isUsernameInvalid"
            class="wrapper__input-fields__error"
          >
            <span
              class="wrapper__input-fields__error__text"
            >It is not a valid email address</span>
          </div>

          <div class="wrapper__button-field">
            <v-iseq-button
              class="wrapper__button-field__button"
              @click="retrievePassword"
            >
              Reset my password
            </v-iseq-button>
          </div>
        </div>
      </template>

      <template v-if="form==='newPassword'">
        <div class="wrapper__input-fields">
          <div class="wrapper__input-fields__title">
            <span class="wrapper__input-fields__title__text">
              Set new password
            </span>
          </div>

          <div class="wrapper__input-fields__field-name">
            <span class="wrapper__input-fields__field-name__text">
              Code
            </span>
          </div>

          <v-text-field
            v-model="code"
            filled
            class="iseq-text-field wrapper__input-fields__field"
            @input="resetCodeFlags()"
            @keyup.enter="setNewPassword"
          />

          <div
            v-if="flags.isCodeInvalid"
            class="wrapper__input-fields__error"
          >
            <span class="wrapper__input-fields__error__text">Code invalid or account doesn't exist. Try again</span>
          </div>

          <div class="wrapper__input-fields__field-name">
            <span class="wrapper__input-fields__field-name__text">
              New password

              <v-tooltip
                class="tooltip--right"
                right
              >
                <template #activator="{ on }">
                  <v-icon
                    class="tooltip__icon"
                    size="20px"
                    color="primary"
                    v-on="on"
                  >
                    mdi-help-circle
                  </v-icon>
                </template>

                <span>Password must:<br>
                  -be at least 12 characters long,<br>
                  -contain at least 1 digit,<br>
                  -contain at least 1 capital letter,<br>
                  -contain at least 1 small letter
                </span>
              </v-tooltip>
            </span>
          </div>

          <v-text-field
            v-model="password"
            type="password"
            filled
            class="iseq-text-field wrapper__input-fields__field"
            @keyup.enter="setNewPassword"
            @focusout="validatePassword"
          />

          <div
            v-if="flags.isPasswordNotPermitted && passwordStructureError"
            class="wrapper__input-fields__error"
          >
            <span class="wrapper__input-fields__error__text">{{
              passwordStructureError
            }}</span>
          </div>

          <div class="wrapper__input-fields__field-name">
            <span class="wrapper__input-fields__field-name__text">
              Confirm new password
            </span>
          </div>

          <v-text-field
            v-model="confirmPassword"
            type="password"
            filled
            class="iseq-text-field wrapper__input-fields__field"
            @keyup.enter="setNewPassword"
            @focusout="validateConfirmPassword(password, confirmPassword)"
          />

          <div
            v-if="flags.isPasswordsDifference"
            class="wrapper__input-fields__error"
          >
            <span class="wrapper__input-fields__error__text">Passwords don't match</span>
          </div>

          <div
            v-if="flags.attemptsExceeded"
            class="wrapper__input-fields__error"
          >
            <span class="wrapper__input-fields__error__text">Attempt limit exceeded. Try again later.</span>
          </div>

          <div class="wrapper__button-field">
            <v-iseq-button
              :disabled="signingInInProgress"
              class="wrapper__button-field__button"
              @click="setNewPassword"
            >
              {{ signingInInProgress ? `Signing in...` : `Set new password` }}
            </v-iseq-button>
          </div>
        </div>
      </template>
    </iseq-credential-views-frame>
  </div>
</template>

<script>
  import {AmplifyEventBus} from 'aws-amplify-vue'
  import {Auth} from 'aws-amplify'
  import store from '@/store'
  import {
    passwordStructureError,
    validateConfirmPassword,
    validatePassword,
    validateUsername
  } from "@/helpers/credentialsHelper";
  import IseqCredentialViewsFrame from "@/views/Login/components/IseqCredentialViewsFrame";
  import IseqButton from "@/components/ui/IseqButton.vue";

  async function getJWT() {
    const session = await Auth.currentSession();
    const jwt = session.idToken.jwtToken;
    store.commit('security/updateToken', jwt);
  }

  export default {
    name: "PasswordRetrieval",
    components: {
      IseqCredentialViewsFrame,
      "v-iseq-button": IseqButton
    },
    data: function () {
      return {
        username: "",
        code: "",
        password: "",
        confirmPassword: "",
        flags: {
          isPasswordNotPermitted: false,
          isCodeInvalid: false,
          isPasswordsDifference: false,
          isUsernameEmpty: false,
          isUsernameInvalid: false,
          attemptsExceeded: false
        },
        form: "remindPassword",
        signingInInProgress: false
      }
    },
    computed: {
      passwordStructureError() {
        return passwordStructureError(this.password);
      }
    },
    mounted() {
      Auth.currentAuthenticatedUser().catch(err => console.log(err));

      AmplifyEventBus.$on("authState", eventInfo => {
        if (eventInfo === "signedIn") {
          getJWT();
          this.$router.push({path: "/"});
        }
      });
    },
    created() {
      if (this.$route.params.username) {
        this.username = this.$route.params.username;
      }
    },
    methods: {
      async retrievePassword() {
        if (this.validateUsername(this.username)) {
          try {
            await Auth.forgotPassword(this.username);
            this.form = "newPassword"
          } catch (error) {
            console.log(error)
          }
        }
      },
      async setNewPassword() {
        if (this.validatePassword(this.password) && this.validateConfirmPassword(this.password, this.confirmPassword)) {
          this.flags['attemptsExceeded'] = false;
          try {
            await Auth.forgotPasswordSubmit(this.username, this.code, this.password);
            await this.signIn()
          } catch (error) {
            if (error.code === "CodeMismatchException") {
              this.flags.isCodeInvalid = true
            } else if (error.code === "InvalidParameterException") {
              this.flags.isPasswordNotPermitted = true;
            } else if (error.code === "ExpiredCodeException") {
              this.flags.isCodeInvalid = true
            } else if (error.code === "LimitExceededException") {
              this.flags.attemptsExceeded = true
            } else {
              console.log(error)
            }
          }
        }
      },
      async signIn() {
        try {
          this.signingInInProgress = true;
          const user = await Auth.signIn(this.username, this.password);
          this.signingInInProgress = false;
          await this.$router.push("/");
        } catch (error) {
          this.signingInInProgress = false;
          console.log(error)
        }
      },
      resetCodeFlags() {
        this.flags['isCodeInvalid'] = false;
      },
      validateUsername(username) {
        const validation = validateUsername(username);
        this.flags = {...this.flags, ...validation.flags};
        return validation.result;
      },
      validatePassword(password) {
        const validation = validatePassword(password);
        this.flags = {...this.flags, ...validation.flags};
        return validation.result;
      },
      validateConfirmPassword(password, confirmPassword) {
        const validation = validateConfirmPassword(password, confirmPassword);
        this.flags = {...this.flags, ...validation.flags};
        return validation.result;
      }
    }
  }
</script>

<style scoped lang="scss">

@import "@/scss/CredentialViewsStyles.scss";
</style>