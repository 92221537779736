import Vue         from 'vue'
import request     from '@/customjs/request.js'
import {timeZoned} from "../../plugins/utils";

export default{
  namespaced: true,

  state: {
    notifications: [],
    notification: {}
  },

  mutations: {
    setNotifications(state, data) {
      data.map(notification => notification.creationDate = timeZoned(notification.creationDate));
      state.notifications = data;
    },
    setNotification(state, data){
      data.creationDate = timeZoned(data.creationDate);
      state.notification = data;
    },
    cleanAllData(state){
      state.notifications = [];
      state.notification = {};
    }
  },

  getters: {
    unreadNotificationsNumber: (state) => {
      return state.notifications.filter(element => element.read === false).length;
    },
  },

  actions: {

    // GET

    getNotifications({commit}) {
      Vue.$log.debug("vue.vuex.organization.getNotifications");
      request.request({
        endpoint: "notifications",
        callback: function (data) {
          commit('setNotifications', data)
        }
      })
    },

    // POST

    readAllNotifications({dispatch}) {
      request.request({
        method: "post",
        endpoint: "notifications/readall",
        callback: function () {
          dispatch('getNotifications')
        }
      })
    },

    deleteAllNotifications({dispatch}) {
      request.request({
        method: "delete",
        snackbarMessage: "Deleteing all notifications",
        endpoint: "notifications",
        callback: function () {
          dispatch('getNotifications');
        }
      })
    },

    readNotification({dispatch}, uuid) {
      Vue.$log.debug("vue.vuex.organization.readNotification");
      request.request({
        method: "post",
        endpoint: "notifications/" + uuid + "/read",
        callback: function () {
          dispatch('getNotifications')
        }
      })
    }
  }
}
