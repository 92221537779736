<template>
  <div
    class="iseq-card"
    :style="cssProps"
  >
    <div
      v-if="title || noTitle"
      class="iseq-card__title-row"
    >
      <div
        v-if="title"
        class="iseq-card__title-row__title"
      >
        <span class="iseq-card__title-row__title__text">
          {{ title }}
          <slot name="post-title" />
        </span>
      </div>

      <slot
        class="iseq-card__title-row__slot"
        name="title-row"
      />
    </div>

    <slot />
  </div>
</template>

<script>5
  export default {
    name: "IseqCard",
    props: {
      title: {
        type: String,
        default: undefined
      },
      noBottomMargin: {
        type: Boolean,
        default: false
      },
      noTitle: {
        type: Boolean,
        default: false
      },
      tabsOnly: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      cssProps() {
        return {
          '--title-row-bottom-margin': this.noBottomMargin ? '0px' : this.tabsOnly ? '0px' : '20px',
          '--title-top-padding': this.tabsOnly ? '16px' : '5px',
          '--card-padding': this.tabsOnly ? '0px 0px 0px 16px': '16px'
        }
      }
    },
  }
</script>

<style lang="scss"
       scoped>
.iseq-card {
  padding: var(--card-padding);
  margin: 20px 40px;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
  0px 2px 2px 0px rgba(0, 0, 0, 0.14),
  0px 1px 5px 0px rgba(0, 0, 0, 0.12);

  &__title-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;
    margin-bottom: var(--title-row-bottom-margin);

    &__title {
      padding-top: var(--title-top-padding);

      &__text {
        color: var(--font-color);
        font-weight: bold;
        font-size: 20px;
      }
    }

    &__slot {

    }
  }
}
</style>