var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('treeselect',{attrs:{"multiple":true,"load-options":_vm.loadOptions,"async":true,"clearable":true,"close-on-select":true,"placeholder":"Please select diseases"},scopedSlots:_vm._u([{key:"value-label",fn:function(ref){
var node = ref.node;
return _c('div',{},[_vm._v(" "+_vm._s(node.raw.name)+" ")])}},{key:"option-label",fn:function(ref){
var node = ref.node;
var labelClassName = ref.labelClassName;
return _c('label',{class:labelClassName},[_c('span',{staticClass:"diseases-list-node"},[_c('span',{class:{'diseases-list-node__genes-count-box': true, 'diseases-list-node__genes-count-box--empty': !node.raw.genesCount}},[_c('span',{class:{'diseases-list-node__genes-count-box__value': true, 'diseases-list-node__genes-count-box__value--empty': !node.raw.genesCount}},[_vm._v(" "+_vm._s(((node.raw.genesCount) + " gene" + (node.raw.genesCount === 1 ? '' : 's')))+" ")])]),_c('v-tooltip',{attrs:{"top":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"diseases-list-node__name"},on),[_vm._v(" "+_vm._s(((node.raw.name) + " (" + (node.raw.id) + ")"))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(((node.raw.name) + " (" + (node.raw.id) + ")"))+" ")])])],1)])}}]),model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }