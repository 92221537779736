<template>
  <div class="tree-menu">
    <div
      :style="indent"
      @click="toggleChildren"
    >
      <v-hover v-slot="{}">
        <v-list
          two-line
          subheader
          class="tree-menu__element"
        >
          <v-list-item>
            <v-icon>{{ typeof tree.children === 'undefined' ? 'note' : 'folder' }}</v-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ tree[label] }}
                <span class="tree-menu__element__size">
                  {{ `(${tree.size})` }}
                </span>
              </v-list-item-title>
            </v-list-item-content>

            <template v-if="typeof tree.children === 'undefined'">
              <template v-if="tree[label] && filenameBlobValidate(tree[label])">
                <v-list-item-action>
                  <v-btn
                    icon
                    @click="getFile(true)"
                  >
                    <v-icon class="iseq-tree-menu-icon iseq-tree-menu-icon--blue">
                      mdi-table-search
                    </v-icon>
                    <!--https://jsfiddle.net/pdfjs/wagvs9Lf/-->
                  </v-btn>
                </v-list-item-action>
              </template>

              <v-list-item-action>
                <v-btn
                  icon
                  @click="getFile()"
                >
                  <v-icon class="iseq-tree-menu-icon iseq-tree-menu-icon--blue">
                    mdi-download
                  </v-icon>
                </v-btn>
              </v-list-item-action>

              <template v-if="isDeletable && permissionRequired('fileDelete')">
                <v-list-item-action>
                  <v-btn
                    icon
                    @click="deleteFileDialog=true"
                  >
                    <v-icon class="iseq-tree-menu-icon iseq-tree-menu-icon--red">
                      remove_circle
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </template>
            </template>

            <template v-else>
              <v-list-item-action>
                <template v-if="mutableShowChildren">
                  <v-icon class="iseq-tree-menu-icon iseq-tree-menu-icon--blue">
                    expand_less
                  </v-icon>
                </template>

                <template v-else>
                  <v-icon class="iseq-tree-menu-icon iseq-tree-menu-icon--blue">
                    expand_more
                  </v-icon>
                </template>
              </v-list-item-action>


              <template v-if="isDeletable && depth!==0 && permissionRequired('fileDelete')">
                <v-list-item-action>
                  <v-btn
                    icon
                    @click="deleteFileDialog=true"
                  >
                    <v-icon class="iseq-tree-menu-icon iseq-tree-menu-icon--red">
                      remove_circle
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </template>
            </template>
          </v-list-item>
        </v-list>
      </v-hover>
    </div>

    <template v-if="mutableShowChildren">
      <tree-menu
        v-for="child in tree.children"
        :key="child.relPath"
        :tree="child"
        :rel-path="relPath"
        :analysis-uuid="analysisUuid"
        :sample-uuid="sampleUuid"
        :project-uuid="projectUuid"
        :organization-uuid="organizationUuid"
        :type="type"
        :label="label"
        :depth="depth + 1"
        :deletable="deletable"
      />
    </template>

    <v-dialog
      v-model="fileBlobDialog"
      width="calc(100vw - 170px)"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ file.name }}</span>
        </v-card-title>
        <div style="text-align: center;">
          <iframe
            :src="file.url"
            style="width: 98%; height: calc(100vh - 221px);"
          />
        </div>
        <v-card-actions>
          <v-spacer />
          <v-iseq-btn
            @click="fileBlobDialog = false"
          >
            Close
          </v-iseq-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <iseq-confirmation-modal
      v-if="deleteFileDialog"
      :header="`Delete ${ typeof tree.children === 'undefined' ? `file` : `folder`}`"
      :message="`Are you sure that you want to delete ${ typeof tree.children === 'undefined' ? `file` : `folder`} ${tree[label]}?`"
      confirm-button-type="primary"
      confirm-button-text="Delete"
      reject-button-type="secondary"
      reject-button-text="Close"
      @actionRejected="deleteFileDialog=false"
      @actionConfirmed="deleteFile"
    />

    <div
      v-for="address of downloadFrames"
      :key="address"
    >
      <iframe
        style="position: absolute; width:0; height:0; border:0;"
        :src="address"
      />
    </div>
  </div>
</template>

<script>
  import {permissionCheck} from "@/plugins/permissions";
  import IseqConfirmationModal from "@/components/modals/IseqConfirmationModal.vue";
  import IseqButton from "@/components/ui/IseqButton.vue";

  export default {
    name: 'TreeMenu',
    components: {
      IseqConfirmationModal,
      "v-iseq-btn": IseqButton
    },
    props: {
      tree: {
        type: Object,
        default: () => {}
      },
      label: {
        type: String,
        default: "label"
      },
      relPath: {
        type: String,
        default: "id"
      },
      analysisUuid: {
        type: String,
        default: undefined
      },
      sampleUuid: {
        type: String,
        default: undefined
      },
      projectUuid: {
        type: String,
        default: undefined
      },
      organizationUuid: {
        type: String,
        default: undefined
      },
      type: {
        type: String,
        default: undefined
      },
      depth: {
        type: Number,
        default: 0
      },
      deletable: {
        type: Boolean,
        default: true
      },
      showChildren: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        mutableShowChildren: this.showChildren,
        fileBlobDialog: false,
        file: {},
        deleteFileDialog: false,
        downloadFrames: []
      }
    },
    computed: {
      indent() {
        return {transform: `translate(${this.depth * 50}px)`, width: `calc(100% - ${this.depth * 50}px)`}
      },
      isDeletable() {
        if (this.tree[this.label] === "sample-properties.json") return false;
        return !(this.deletable === undefined || this.deletable === false);
      }
    },
    methods: {
      permissionRequired(action) {
        return permissionCheck(action)
      },
      toggleChildren() {
        this.mutableShowChildren = !this.mutableShowChildren;
      },
      deleteFile() {
        this.deleteFileDialog = false;
        this.$store.dispatch('sample/deleteFile', {
          type: this.type,
          key: this.tree.relPath,
          analysisUuid: this.analysisUuid,
          sampleUuid: this.sampleUuid
        });
      },
      filenameBlobValidate(filename) {
        const extensions = [".pdf", ".html", ".json"];
        return extensions.some(extension => filename.includes(extension))
      },
      getFile(blob) {
        console.log(this.tree.relPath)
        this.$store.dispatch('sample/downloadFile', {
          type: this.type,
          key: this.tree.relPath,
          analysisUuid: this.analysisUuid,
          sampleUuid: this.sampleUuid
        }).then(response => {
          if (blob) {
            this.file.name = this.tree[this.label];
            this.file.url = response.url;
            this.fileBlobDialog = true;
          } else {
            this.downloadFrames.push(response.url + "&response-content-disposition=attachment;filename=" + this.tree[this.label]);
          }
        })
      },
    }
  }
</script>
<style lang="scss" scoped>
.iseq-tree-menu-icon {
  margin-left: 10px;
  cursor: pointer;

  &--blue {
    color: var(--primary-color) !important;
  }

  &--red {
    color: var(--danger-color) !important;
  }
}

.tree-menu {

  &__element {
    background-color: var(--light);

    &__size {
      color: var(--font-inverted-color);
      font-size: 0.8rem
    }
  }

  .v-list-item__action {
    margin: 0 10px !important;
  }
}
</style>
