<template>
  <div>
    <treeselect
      v-model="localValue"
      :multiple="true"
      :load-options="loadOptions"
      :async="true"
      :clearable="true"
      :close-on-select="true"
      placeholder="Please select diseases"
    >
      <div
        slot="value-label"
        slot-scope="{ node }"
      >
        {{ node.raw.name }}
      </div>

      <label
        slot="option-label"
        slot-scope="{ node, labelClassName }"
        :class="labelClassName"
      >
        <span class="diseases-list-node">
          <span :class="{'diseases-list-node__genes-count-box': true, 'diseases-list-node__genes-count-box--empty': !node.raw.genesCount}">
            <span :class="{'diseases-list-node__genes-count-box__value': true, 'diseases-list-node__genes-count-box__value--empty': !node.raw.genesCount}">
              {{ `${node.raw.genesCount} gene${node.raw.genesCount === 1 ? '' : 's'}` }}
            </span>
          </span>

          <v-tooltip
            top
            open-delay="500"
          >
            <template #activator="{ on }">
              <span
                class="diseases-list-node__name"
                v-on="on"
              >
                {{ `${node.raw.name} (${node.raw.id})` }}
              </span>
            </template>

            <span>
              {{ `${node.raw.name} (${node.raw.id})` }}
            </span>
          </v-tooltip>
        </span>
      </label>
    </treeselect>
  </div>
</template>

<script>
  import Treeselect, {ASYNC_SEARCH} from "@riophae/vue-treeselect";
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import {getDiseasesSuggestion} from "@/services/explorareService";
  import {parseDiseasesSuggestions} from "@/helpers/explorareHelper";

  export default {
    name: "IseqDiseasesInput",
    components: {Treeselect},
    props: {
      value: {
        required: false,
        default: undefined
      }
    },
    computed: {
      localValue: {
        get() {
          return this.value;
        },
        set(newValue) {
          this.$emit('input', newValue);
        }
      }
    },
    methods: {
      async loadOptions({action, searchQuery, callback}) {
        if (action === ASYNC_SEARCH) {
          let options = await getDiseasesSuggestion(searchQuery);
          callback(null, parseDiseasesSuggestions(options));
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
.diseases-list-node {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__genes-count-box {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--primary-color);
    height: 25px;
    min-width: 65px;
    margin-right: 10px;
    border-radius:5px;

    &__value {
      color: var(--primary-color);
      font-size: 12px;

      &--empty {
        color: grey;
      }
    }

    &--empty{
      border: 1px solid var(--border-inactive);
    }
  }
}
</style>