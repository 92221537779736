<template>
  <div class="iseq-problem-report">
    <v-iseq-card title="Report a problem" class="content">
      <v-textarea
          v-model="content"
          outlined
          :rows="10"
          counter="5000"
          label="Please, describe your problem so we can find a solution for you"
      />

      <div class="btn">
        <v-iseq-btn @click="sendBugReport" width="150px">
          Send
        </v-iseq-btn>
      </div>
    </v-iseq-card>
  </div>
</template>

<script>
import IseqCard from "@/components/ui/IseqCard.vue";
import IseqButton from "@/components/ui/IseqButton.vue";

export default {
  name: "CookiesConsents",
  components: {
    "v-iseq-card": IseqCard,
    "v-iseq-btn": IseqButton
  },
  data: function () {
    return {
      content: "",
    }
  },
  methods: {
    sendBugReport() {
      this.$store.dispatch('user/reportBug', {content: this.content, sendEmail: true});
      this.content = "";
      this.$router.push({path: '/'});
    }
  }
}
</script>

<style lang="scss" scoped>
.iseq-problem-report {
  padding: 0;
  margin: 0;

  .content {
  }

  .btn {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
  }
}
</style>