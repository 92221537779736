<template>
  <v-iseq-card
    title="Getting started"
    class="iseq-dashboard-getting-started"
  >
    <template #title-row>
      <span
        class="iseq-btn__label"
        @click="hideVideo"
      >Hide</span>
    </template>

    <div class="iseq-dashboard-getting-started__container">
      <iframe
        class="iseq-dashboard-getting-started__container__media"
        width="800"
        height="450"
        src="https://www.youtube.com/embed/1vWsJt-38tg"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </div>
  </v-iseq-card>
</template>

<script>
  import IseqCard from "@/components/ui/IseqCard.vue";

  export default {
    name: 'IseqDashboardGettingStarted',
    components: {
      "v-iseq-card": IseqCard
    },
    methods: {
      hideVideo() {
        this.$store.dispatch('user/hideDashboardGettingStartedSection');
      }
    }
  };
</script>

<style lang="scss"
       scoped>

.iseq-dashboard-getting-started {
  .iseq-btn {
    min-width: 155px;
    margin: 4px 4px 0 auto;

    &__label {
      font-size: 16px;
      font-weight: 500;
      color: var(--border-text);
      cursor: pointer;
      padding: 10px;
      border-radius: 4px;

      &:hover {
        background: var(--light);
      }
    }
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;

    &__media {
      border-radius: 4px;
    }
  }
}
</style>