<template>
  <div class="iseq-summary">
    <h1 class="iseq-summary__title">
      Summary
    </h1>

    <div class="iseq-summary__container">
      <span class="iseq-summary__container__credits">Number of credits to top up:
        <span class="iseq-summary__container__credits__value">{{ credits }}</span>
      </span>

      <span class="iseq-summary__container__cost">Total cost:
        <span class="iseq-summary__container__cost__value">${{ credits }}</span>
      </span>
    </div>

    <v-iseq-btn
      type="primary"
      class="iseq-summary__btn"
      :loading="loadingBtn"
      @click="completeTransaction"
    >
      Pay
    </v-iseq-btn>

    <v-iseq-btn
      type="secondary"
      class="iseq-summary__btn"
      @click="goBack"
    >
      Go back
    </v-iseq-btn>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import IseqButton from "@/components/ui/IseqButton.vue";

  export default {
    name: 'IseqSummary',
    components: {
      'v-iseq-btn': IseqButton
    },
    props: {
      credits: {
        type: [String, Number],
        required: true,
        default: '100',
      },
    },
    computed: {
      ...mapState('payment', ['status', 'loadingBtn'])
    },
    methods: {
      goBack() {
        if (this.status === 'card_assigned') {
          this.$store.commit('payment/setPaymentSteps', 1);
        } else {
          this.$store.commit('payment/setPaymentSteps', 2);
        }
      },
      completeTransaction() {
        this.$store.dispatch('payment/completeTransaction');
      },
    },
  };
</script>

<style lang="scss"
       scoped>
.iseq-summary {
    &__title {
        text-align: center;
        font-weight: 500;
        font-size: 24px;
    }

    &__container {
        margin: 32px 32px 16px 32px;

        &__credits,
        &__cost {
            display: flex;
            font-size: 18px;
            justify-content: space-between;
            color: #7A7A7A;
            margin: 8px 0;
            border: 1px solid #F1F1F1;
            padding: 8px;
            border-radius: 2px;

            &__value {
                color: var(--primary-color);
                font-weight: 600;
            }
        }
    }

    &__btn {
        margin-top: 16px;
        width: 100%;
        font-size: 16px !important;
    }
}
</style>