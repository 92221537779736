<template>
  <div class="iseq-dashboard-header">
    <v-iseq-card
      title="Dashboard"
      no-bottom-margin
    >
      <template #title-row>
        <div class="iseq-dashboard-header__buttons">
          <v-iseq-btn
            v-if="!headerProjects.length && permissionRequired('projectAdd')"
            large
            width="wide"
            ml
            @click.prevent="createProject"
          >
            Create new project
          </v-iseq-btn>

          <v-iseq-btn
            v-if="!permissionRequired('projectAdd')"
            large
            width="wide"
            ml
            @click.prevent="joinProject"
          >
            Join project
          </v-iseq-btn>

          <v-iseq-btn
            v-if="permissionRequired('sampleAdd')"
            large
            width="wide"
            ml
            @click.prevent="createSample"
          >
            Add Sample
          </v-iseq-btn>

          <v-iseq-btn
            v-if="permissionRequired('analysisAdd')"
            large
            width="wide"
            ml
            @click.prevent="createAnalysis"
          >
            Run Analysis
          </v-iseq-btn>
        </div>
      </template>
    </v-iseq-card>
  </div>
</template>

<script>
  import {permissionCheck} from "@/plugins/permissions";
  import {mapState} from "vuex";
  import IseqCard from "@/components/ui/IseqCard.vue";
  import IseqButton from "@/components/ui/IseqButton.vue";
  import {segmentTrack} from "@/helpers/analytics";

  export default {
    name: 'DashboardHeader',
    components: {
      "v-iseq-card": IseqCard,
      "v-iseq-btn": IseqButton
    },
    computed: {
      ...mapState('project', ['headerProjects'])
    },
    methods: {
      permissionRequired(action) {
        return permissionCheck(action)
      },
      createProject() {
        this.$router.push('/projects/new');
      },
      joinProject() {
        this.$router.push({path: '/projects', query: {tab: "join"}});
      },
      createSample() {
        segmentTrack({event: "Add Sample Clicked"});
        this.$router.push('/samples/new');
      },
      createAnalysis() {
        segmentTrack({event: "Run Analysis Clicked"});
        this.$router.push('/analyses/new');
      }
    },
  }
</script>
<style lang="scss"
       scoped>

.iseq-dashboard-header {
  &__buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
</style>