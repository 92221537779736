<template>
  <div class="iseq-dashboard">
    <dashboard-header />

    <template v-if="myUploadingFiles.length || othersUploadingFiles.length">
      <v-files-upload-card />
    </template>

    <template v-if="(dashboardAnalysesProgress.userAnalyses.length || dashboardAnalysesProgress.otherAnalyses.length) && typeof project.uuid!=='undefined'">
      <dashboard-analysis-progress />
    </template>

    <template v-if="invitations.length">
      <dashboard-invitations />
    </template>

    <template>
      <dashboard-notification />
    </template>

    <dashboard-analysis v-if="typeof project.uuid!=='undefined'" />

    <dashboard-getting-started v-if="user.userSettings.dashboardGettingStartedVisible && !isTgenome" />
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import dashboardHeader from './components/Dashboard-header.component.vue'
  import dashboardNotification from './components/Dashboard-notification.component.vue'
  import dashboardInvitations from './components/Dashboard-invitations.component.vue'
  import dashboardAnalysis from './components/Dashboard-analysis.component.vue'
  import filesUploadCard from '../../components/ui/filesUploadCard.component.vue'
  import dashboardAnalysisProgress from './components/Dashboard-analysis-progress.vue'
  import dashboardGettingStarted from './components/Dashboard-getting-started.component.vue'
  import {permissionCheck} from "../../plugins/permissions";

  export default {
    name: 'Dashboard',
    components: {
      dashboardHeader,
      dashboardGettingStarted,
      dashboardAnalysis,
      dashboardNotification,
      dashboardInvitations,
      'v-files-upload-card': filesUploadCard,
      dashboardAnalysisProgress
    },
    computed: {
      ...mapState('invitation', ['invitations']),
      ...mapState('notification', ["notifications"]),
      ...mapState('fileUploads', ['myUploadingFiles', 'othersUploadingFiles']),
      ...mapState('analysis', ['dashboardAnalysesProgress']),
      ...mapState('user', ['user']),
      ...mapState('project', ['project']),
      isTgenome() {
        return process.env.VUE_APP_MODE === "tgenome";
      }
    },
    watch: {
      project(newProject) {
        if (newProject.uuid && this.permissionRequired('fileUploadView')) {
          this.$store.dispatch('fileUploads/getIncompleteFiles', {projectUuid: newProject.uuid});
        }
      }
    },
    created() {
      if (this.user !== undefined) {
        this.$store.dispatch('event/getUserEvents');
        this.$store.dispatch('notification/getNotifications');
        this.$store.dispatch('invitation/getInvitations');
        this.$store.dispatch('analysis/getDashboardAnalysesProgress');
      }
    },
    methods: {
      permissionRequired(action) {
        return permissionCheck(action)
      },
    },
  }
</script>
<style lang="scss"
       scoped>

    .iseq-dashboard {
        &__card {
            padding: 16px;
            margin: 0 40px 20px 40px;
            box-shadow: $card-shadow;

            &__header {
                flex: 1;
                display: flex;
                margin: 0 16px;
                align-items: center;
                justify-content: space-between;

                &__title {
                    color: var(--dark);
                    font-size: 1.7em;
                    font-weight: 500;
                }
            }

            &__table {
                padding: 16px;
            }

            &__btn {
                display: block;
            }

            &--sample {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .iseq-btn {
                min-width: 155px;
                margin: 4px 4px 0 auto;

                &__label,
                &__icon {
                    margin: 0 4px;
                }
            }
        }
    }
</style>
