var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('treeselect',{attrs:{"multiple":true,"options":_vm.allowedExtensionsFilter(_vm.filesTree.children, _vm.input.constraints.extensions),"clearable":true,"searchable":true,"flatten-search-results":false,"close-on-select":true,"disable-branch-nodes":true,"placeholder":"Please select files","search-nested":""},scopedSlots:_vm._u([{key:"option-label",fn:function(ref){
var node = ref.node;
return _c('label',{},[(node.raw.runDate)?[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(node.label)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(node.raw.runDate))])])]:[_vm._v(" "+_vm._s(node.label)+" ")]],2)}},{key:"value-label",fn:function(ref){
var node = ref.node;
return _c('div',{},[_vm._v(" "+_vm._s(_vm.fileNodeLabel(node.raw.id, node.raw.label))+" ")])}}]),model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}},[(_vm.allowedExtensionsFilter(_vm.filesTree.children, _vm.input.constraints.extensions).length)?_c('div',{staticClass:"before-list-element",attrs:{"slot":"before-list"},slot:"before-list"},[(!_vm.areAllValuesSelected)?_c('v-iseq-btn',{staticClass:"before-list-element__select-all-btn",attrs:{"type":"secondary"},on:{"click":function($event){return _vm.selectAll()}}},[_vm._v(" Select All ")]):_vm._e(),(_vm.isAnyValueSelected)?_c('v-iseq-btn',{staticClass:"before-list-element__select-all-btn",attrs:{"type":"secondary"},on:{"click":function($event){return _vm.deselectAll()}}},[_vm._v(" Deselect All ")]):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }