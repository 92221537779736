<template>
  <div
    class="iseq-banner"
    :style="cssVars"
  >
    <div class="iseq-banner__body">
      <v-icon class="iseq-banner__body__icon">
        {{ iconLocal }}
      </v-icon>

      <span class="iseq-banner__body__text">
        <template v-if="Array.isArray(value)">
          <span>
            {{ value[0] }}
            <span
              v-if="link && link.address && link.text"
              class="iseq-banner__body__text__link"
              @click="goToAddress()"
            >
              {{ link.text }}
            </span>
            {{ value[1] }}
          </span>
        </template>

        <template v-else>
          {{ value }}
        </template>
      </span>
    </div>

    <slot />
  </div>
</template>

<script>
  export default {
    name: "IseqBanner",
    props: {
      value: {
        required: true
      },
      icon: {
        type: String,
        required: false,
        default: ''
      },
      type: {
        type: String,
        required: false,
        default: 'success'
      },
      link: {
        type: Object,
        required: false,
        default: undefined
      },
      centerContent: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      iconLocal() {
        if (this.icon) {
          return this.icon
        } else {
          let icon;

          switch (this.type) {
          case 'success':
            icon = 'mdi-check-circle-outline';
            break;
          case 'info':
            icon = '';
            break;
          case 'info-light':
            icon = 'mdi-information'
            break;
          default:
            icon = 'mdi-alert'
            break;
          }

          return icon;
        }
      },
      cssVars() {
        let bannerColor = 'white';
        let fontColor = 'white';
        let iconColor = 'white';

        switch (this.type) {
        case 'success':
          bannerColor = 'var(--success-color)';
          break;
        case 'warning':
          bannerColor = 'var(--warning-color)';
          break;
        case 'danger':
          bannerColor = 'var(--danger-color)';
          break;
        case 'error':
          bannerColor = 'var(--error-color)';
          break;
        case 'info':
          bannerColor = 'var(--primary-color)';
          break;
        case 'info-light':
          bannerColor = 'var(--info-light-color)';
          fontColor = 'var(--font-color)';
          iconColor = `var(--active)`;
          break;
        default:
          break;
        }

        return {
          '--content-aligning': this.centerContent ? 'center' : 'flex-start',
          '--banner-color': bannerColor,
          '--banner-font-color': fontColor,
          '--icon-color': iconColor
        }
      }
    },
    methods: {
      goToAddress() {
        if (this.link.address.startsWith('/')) {
          this.$router.push(this.link.address);
        } else {
          window.open(this.link.address, '_blank');
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.iseq-banner {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  margin: 20px 40px 20px 40px;
  border-radius: 4px;
  background-color: var(--banner-color) !important;
  align-items: var(--content-aligning);
  justify-content: center;
  color: var(--banner-font-color);

  &__body {
    display: flex;
    flex-direction: row;

    &__icon {
      color: var(--icon-color) !important;
    }

    &__text {
      margin-left: 10px;
      font-size: 1.3em;
      text-align: center;

      &__link {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
</style>