<template>
  <div style="position: fixed; right: 120px; bottom: 50px;">
    <v-card
      v-for="item in visibleItems"
      :key="item.id"
      class="ma-2 d-flex align-center"
      width="20vw"
      dark
      :color="item.type"
    >
      <v-progress-circular
        v-if="item.type === 'request'"
        class="px-4"
        width="2"
        size="20"
        indeterminate
      />
      <v-icon
        v-if="item.icon"
        class="ml-4"
      >
        {{ item.icon }}
      </v-icon>
      <p class="px-2 mr-auto">
        {{ item.message }}
      </p>

      <v-btn
        v-if="item.id !== missingConnectionSnackbarId"
        icon
        text
        @click="removeItem(item.id)"
      >
        <v-icon>close</v-icon>
      </v-btn>
    </v-card>
  </div>
</template>

<script>
  import {mapState} from 'vuex';

  export default {
    name: 'IseqSnackbarQueue',
    computed: {
      ...mapState('snackbarQueue', ['items', 'missingConnectionSnackbarId']),
      visibleItems () {
        let items = this.items.filter(item => item.message && !item.message.includes('Uploading'));
        const itemUploading = this.items.find(item => item.message.includes('Uploading'));
        if (itemUploading) {
          items.push(itemUploading);
        }
        return items;
      }
    },
    methods: {
      removeItem(id) {
        this.$store.commit('snackbarQueue/removeItem', {id: id, force: true});
      }
    },
  }
</script>
