<template>
  <v-dialog
    v-model="isOpen"
    persistent
    max-width="400px"
  >
    <div class="iseq-two-factor-authentication-modal">
      <div class="iseq-two-factor-authentication-modal__header">
        <span class="iseq-two-factor-authentication-modal__header__title">Two-Factor Authentication</span>
      </div>

      <div class="iseq-two-factor-authentication-modal__step">
        <div
          v-show="step===0"
          class="iseq-two-factor-authentication-modal__step__wrapper"
        >
          <span class="activity-text">Set up your phone number to receive authentication codes via SMS.</span>

          <vue-tel-input-vuetify
            v-model="phoneNumber"
            class="tel-input input-field"
            outlined
            background-color="light"
            mode="international"
            label="Phone number"
            placeholder="Enter your phone number"
            :preferred-countries="['US', 'PL']"
            @validate="changeNumberValidity()"
          />
        </div>

        <div
          v-show="step===1"
          class="iseq-two-factor-authentication-modal__step__wrapper"
        >
          <span class="activity-text">We have just sent you a confirmation code via SMS. Please, enter the code below.</span>

          <v-text-field
            v-model="code"
            class="input-field"
            outlined
            hide-details
            label="Code from SMS"
            @keyup.enter="continueProcedure()"
          />
        </div>

        <div
          v-show="step===2"
          class="iseq-two-factor-authentication-modal__step__wrapper"
        >
          <span class="activity-text">
            Your 2FA is inactive. Do you want to activate it? <br>
            Further sign-ins will have to be confirmed by code received through SMS on number ending with <span class="heavy">-{{ phoneNumberAnonymized }}</span>.
          </span>
        </div>

        <div
          v-show="step===3"
          class="iseq-two-factor-authentication-modal__step__wrapper"
        >
          <span class="activity-text">
            Your 2FA is active. Do you want to deactivate it? Future sign-ins will no longer require using SMS codes. Your current phone number is the one ending with <span class="heavy">-{{ phoneNumberAnonymized }}</span>.
          </span>
        </div>

        <div
          v-show="step===4"
          class="iseq-two-factor-authentication-modal__step__wrapper"
        >
          <span class="activity-text">Your 2FA has been activated.</span>
        </div>

        <div
          v-show="step===5"
          class="iseq-two-factor-authentication-modal__step__wrapper"
        >
          <span class="activity-text">Your 2FA has been deactivated.</span>
        </div>
      </div>

      <div class="iseq-two-factor-authentication-modal__progress-button">
        <v-iseq-btn
          :type="step === 3 ? 'red': 'primary'"
          width="100%"
          :disabled="!!(step === 0 && !numberValid)"
          @click="continueProcedure()"
        >
          {{ continueButtonText[step] }}
        </v-iseq-btn>
      </div>

      <div class="iseq-two-factor-authentication-modal__footer">
        <span
          class="change-number-text"
          @click="changePhoneNumber()"
        >
          <span v-show="step>0">Change your phone number</span>
        </span>

        <span
          class="change-number-text"
          @click="close()"
        >
          <span v-show="step<4">Close</span>
        </span>
      </div>
    </div>
  </v-dialog>
</template>

<script>
  import {Auth} from 'aws-amplify'
  import VueTelInputVuetify from 'vue-tel-input-vuetify/lib/vue-tel-input-vuetify'
  import IseqButton from "@/components/ui/IseqButton.vue";

  export default {
    name: "TwoFactorAuthenticationModal",
    components: {
      VueTelInputVuetify,
      "v-iseq-btn": IseqButton
    },
    props: {
      forceChangePhoneNumber: {
        type: Boolean,
        default: false
      }
    },
    data: function () {
      return {
        isOpen: true,
        user: undefined,
        attributes: undefined,
        preferredMFA: undefined,
        step: -1,
        code: undefined,
        phoneNumber: null,
        numberValid: true,
        continueButtonText: [
          "Set phone number",
          "Activate Two-Factor Authentication",
          "Activate Two-Factor Authentication",
          "Deactivate Two-Factor Authentication",
          "Close",
          "Close"
        ]
      }
    },
    computed: {
      phoneNumberAnonymized() {
        if (this.attributes && this.attributes.find(attribute => attribute.Name==="phone_number")) {
          const phoneNumber = this.attributes.find(attribute => attribute.Name === "phone_number").Value;
          return phoneNumber.slice(-3);
        } else {
          return undefined;
        }
      }
    },
    created() {
      this.getUser().then(response => {
        this.getUserAttributes().then(response => {
          this.getPreferredMFA().then(response => {
            let phoneNumber = this.attributes.find(attribute => attribute.Name==="phone_number");
            phoneNumber = phoneNumber ? phoneNumber.Value : undefined;
            if (!phoneNumber || this.forceChangePhoneNumber) {
              this.step = 0;
            } else if (this.attributes.find(attribute => attribute.Name==="phone_number_verified").Value === "false") {
              this.sendVerificationCode();
              this.step = 1;
            } else if (this.preferredMFA === "NOMFA") {
              this.step = 2;
            } else {
              this.step = 3;
            }
          });
        })
      })
    },
    methods: {
      close() {
        this.$emit('closed');
      },
      continueProcedure() {
        switch (this.step) {
        case 0:
          this.setPhoneNumber();
          break;
        case 1:
          this.verifyPhoneNumber();
          break;
        case 2:
          this.setSMSAuthentication("SMS");
          break;
        case 3:
          this.setSMSAuthentication("NOMFA");
          break;
        case 4:
          this.close();
          break;
        case 5:
          this.close();
          break;
        }
      },
      async getUser() {
        try {
          this.user = await Auth.currentAuthenticatedUser();
        } catch (err) {
          throw new Error(err)
        }
      },
      async getUserAttributes() {
        this.attributes = await Auth.userAttributes(this.user);
      },
      async getPreferredMFA() {
        this.preferredMFA = await Auth.getPreferredMFA(this.user);
      },
      async sendVerificationCode() {
        try {
          await Auth.verifyCurrentUserAttribute("phone_number");
        } catch (err) {
          throw new Error(err);
        }
      },
      async setPhoneNumber() {
        const clearedPhoneNumber = this.phoneNumber.replace(/[^0-9+]/ig, '');
        try {
          await Auth.updateUserAttributes(this.user, {
            phone_number: clearedPhoneNumber
          });
          await this.sendVerificationCode();
          this.step++;
        } catch (error) {
          throw new Error(error);
        }
      },
      async verifyPhoneNumber(){
        try {
          await Auth.verifyCurrentUserAttributeSubmit("phone_number", this.code).then(response =>
            this.getUserAttributes().then(response => {
              if (!this.forceChangePhoneNumber) {
                this.setSMSAuthentication("SMS").then(response =>
                  this.step = 4
                )
              } else {
                this.close();
              }
            })
          );
        } catch (err) {
          throw new Error(err);
        }
      },
      async setSMSAuthentication(method) {
        try {
          await Auth.setPreferredMFA(this.user, method);
        } catch (err) {
          throw new Error(err);
        }
        this.step += 2;
      },
      anonymizePhoneNumber(phone) {
        const prefix = phone.split(' ')[0];
        const core = phone.slice(prefix.length, -3).replace(/[0-9]/g, '*');
        const suffix = phone.slice(-3);
        return prefix + core + suffix;
      },
      changeNumberValidity() {
        this.numberValid = !this.numberValid;
      },
      changePhoneNumber() {
        this.step = 0;
      }
    }
  }
</script>

<style lang="scss"
       scoped>

.iseq-two-factor-authentication-modal {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: white;

    &__header {
        margin-bottom: 20px;

        &__title {
            color: var(--dark);
            font-size: 1.7em;
            font-weight: 500;
        }
    }

    &__step {
        .iseq-text-field {
            margin-bottom: 15px;
        }

        .vue-tel-input-vuetify .country-code {
            width: 120px !important;
        }

        .tel-input {
            margin-bottom: -25px;
        }

        &__wrapper {
            margin: 0px 15px 0px 15px;

            .activity-text {
                color: var(--font-color);

                .heavy {
                    font-weight: bold;
                }
            }

            .input-field {
                margin-top: 15px;
            }
        }
    }

    &__progress-button{
        margin: 20px 0px 20px 0px;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        .change-number-text {
            color: var(--font-inverted-color);
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
</style>