<template>
  <div class="iseq-dashboard-notification">
    <v-iseq-card
      title="Notifications"
      no-bottom-margin
      class="iseq-dashboard-notification__card"
    >
      <template #post-title>
        <v-badge
          v-if="unreadNotificationsLength()"
          :content="unreadNotificationsLength()"
          color="danger"
          offset-x="0"
          offset-y="-4"
        />
      </template>

      <template #title-row>
        <span
          v-if="unreadNotificationsLength()!==0"
          class="iseq-btn__label"
          @click.prevent="readAllNotifications"
        >Mark all as read</span>

        <span
          v-if="unreadNotificationsLength()===0 && notifications.length"
          class="iseq-btn__label iseq-btn__label--delete"
          @click.prevent="deleteAllNotification"
        >Delete All</span>
      </template>

      <v-iseq-banner
        v-if="!notifications.length"
        type="info-light"
        value="You don't have any awaiting notifications"
      />

      <v-data-table
        v-else
        :items-per-page="5"
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions
        }"
        :headers="notificationColumns"
        :items="notifications"
        :custom-sort="customSort"
        class="iseq-dashboard-notification__card__table"
        @click:row="readNotification"
      >
        <template #[`item.customMessage`]="{ item }">
          <div
            :class="{unread: !item.read}"
            class="iseq-dashboard-notification__card__table__notification"
          >
            <span v-if="item.target.type !== 'USER'">
              <span class="value">{{ `${item.target.type[0]}${item.target.type.toLowerCase().slice(1, item.target.type.length)}` }}</span>

              <span
                v-if="messageHasPhrase(item, 'has been deleted')"
                class="value"
              >{{ item.target.name }}</span>

              <span
                v-else
                class="value value--link"
                @click="goToTarget(item)"
              >{{ item.target.name }}</span>
            </span>

            <span class="value">{{ item.message }}</span>

            <span v-if="item.target.type === 'USER'">
              <span
                v-if="messageHasPhrase(item, 'removed')"
                class="value"
              >{{ item.target.name }}</span>

              <span
                v-else
                class="value value--link"
                @click="goToTarget(item)"
              >{{ item.target.name }}</span>
            </span>

            <span v-if="messageHasPhrase(item, 'will be deleted in')">
              <span class="value value--link">
                <router-link to="/FAQ?section=SampleStorage">Read the FAQ</router-link>
              </span>
              <span class="value">to find out how to extend its validity.</span>
            </span>
          </div>
        </template>
        <template #[`item.creationDate`]="{ item }">
          <v-tooltip top>
            <template #activator="{ on }">
              <span v-on="on">

                {{ calculateDifference(item.creationDate) }}
              </span>
            </template>

            {{ item.creationDate }}
          </v-tooltip>
        </template>
      </v-data-table>
    </v-iseq-card>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import {dateToRelativeTime} from '../../../plugins/utils';
  import IseqCard from "@/components/ui/IseqCard.vue";
  import IseqBanner from "@/components/ui/IseqBanner.vue";

  export default {
    name: 'DashboardNotification',
    components: {
      "v-iseq-card": IseqCard,
      "v-iseq-banner": IseqBanner
    },
    data: function () {
      return {
        itemsPerPageOptions: [5, 10, 20, 30, -1],
        notificationColumns: [
          {text: 'Message', value: 'customMessage', sortable: false},
          {text: 'Time', value: 'creationDate', width: '150px'}
        ],
      }
    },
    computed: {
      ...mapState('user', ['user']),
      ...mapState('notification', ['notifications'])
    },
    methods: {
      unreadNotificationsLength() {
        return this.notifications.filter(element => element.read === false).length;
      },
      readAllNotifications() {
        this.$store.dispatch('notification/readAllNotifications')
      },
      deleteAllNotification() {
        this.$store.dispatch('notification/deleteAllNotifications')
      },
      readNotification(event) {
        this.$store.dispatch('notification/readNotification', event.uuid)
      },
      messageHasPhrase(notification, phrase) {
        if (notification.message) {
          return notification.message.includes(phrase);
        } else {
          return false;
        }
      },
      customSort(items, index, isDesc) {
        items.sort((a, b) => {
          if (index[0] === "creationDate" || index[0] === undefined) {
            const keyA = new Date(a.creationDate);
            const keyB = new Date(b.creationDate);
            if (isDesc[0]) {
              return keyB < keyA ? 1 : -1;
            } else {
              return keyA < keyB ? 1 : -1;
            }
          } else {
            if (!isDesc[0]) {
              return a[index] < b[index] ? -1 : 1;
            } else {
              return b[index] < a[index] ? -1 : 1;
            }
          }
        });
        return items;
      },
      calculateDifference(date) {
        return dateToRelativeTime(date)
      },
      goToTarget(notification) {
        let destinationType;
        let params = {};
        switch (notification.target.type) {
        case "ORGANIZATION":
          destinationType = 'organizations';
          break;
        case "PROJECT":
          destinationType = 'projects';
          if (notification.message.includes("join requests")) {
            params.tab = "requests";
          }
          break;
        case "SAMPLE":
        case "FILE":
          destinationType = 'samples';
          break;
        case "ANALYSIS":
          destinationType = 'analyses';
          break;
        }
        this.$router.push({path: `/${destinationType}/${notification.target.uuid}`, query: params});
      },
    }
  }
</script>
<style lang="scss"
       scoped>
.iseq-dashboard-notification {
  &__card {
    &__table {
      max-width: 100%;
      padding: 0px 16px;

      &__notification {
        margin: 10px;
        font-size: 14px;

        .value {
          padding-right: 4px;
          white-space: normal;

          &--link {
            cursor: pointer;
            color: var(--primary-color);

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .unread {
        font-weight: bold;
      }
    }

    &__btn {
      display: block;
    }

    .iseq-btn {
      min-width: 155px;
      margin: 4px 4px 0 auto;

      &__label,
      &__icon {
        margin: 0 4px;
      }

      &__label {
        font-size: 16px;
        font-weight: 500;
        color: var(--border-text);
        cursor: pointer;
        padding: 10px;
        border-radius: 4px;

        &:hover {
          background: var(--light);
        }
      }
    }
  }
}
</style>