var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('div',{staticClass:"iseq-report-variants"},[_c('div',{staticClass:"iseq-report-variants__header"},[_c('span',{staticClass:"iseq-report-variants__header__title"},[_vm._v(" Report variants"+_vm._s(_vm.selectHeaders ? ' columns' : '')+" selection ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.selectHeaders),expression:"!selectHeaders"}]},[_c('div',{staticClass:"iseq-report-variants__content"},[_c('div',{staticClass:"iseq-report-variants__content__table"},[(_vm.showLoader)?_c('iseq-loading-spinner',{attrs:{"text":"Loading variants..."}}):_c('v-data-table',{attrs:{"headers":_vm.visibleHeaders,"items":_vm.filteredItems,"show-select":"","select-strategy":"all","checkbox-color":"primary","item-key":"ISEQ_GNOMAD_ID","search":_vm.search,"height":"100%","fixed-header":""},on:{"click:row":_vm.selectValue},scopedSlots:_vm._u([_vm._l((_vm.visibleHeaders),function(header){return {key:("header." + (header.value)),fn:function(ref){return [_vm._v(" "+_vm._s(header.name ? header.name : header.text)+" "),_c('v-tooltip',{key:header.value,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mb-5",attrs:{"color":"primary","size":"15px"}},on),[_vm._v(" mdi-help-circle ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(header.description))])])]}}}),_vm._l((_vm.visibleHeaders),function(header){return {key:("item." + (header.value)),fn:function(ref){
var item = ref.item;
return [(header.type === 'link')?[(item[header.value])?_c('span',{key:header.value,staticClass:"link",on:{"click":function($event){return _vm.getReportFile(item[header.value])}}},[_vm._v("Click to view")]):_vm._e()]:(header.display && header.display === 'icon')?[(header.icons[item[header.value]])?[_c('v-icon',{key:header.value,attrs:{"color":"text"}},[_vm._v(" "+_vm._s(header.icons[item[header.value]])+" ")])]:[_vm._v(" "+_vm._s(item[header.value])+" ")]]:(String(item[header.value]).length > 25)?[_c('v-tooltip',{key:header.value,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(((String(item[header.value]).slice(0, 25)) + "..."))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item[header.value])+" ")])]:[_c('span',{key:header.value,class:{'pill': (header.display && header.display === 'pill')},style:({'background-color': (header.display && header.display === 'pill') ? (header.colors[item[header.value]] ? header.colors[item[header.value]] : 'black') : 'transparent' })},[(header.display && header.display === 'pill')?[_vm._v(" "+_vm._s(header.icons[item[header.value]] ? header.icons[item[header.value]] : item[header.value])+" ")]:[_vm._v(" "+_vm._s(item[header.value])+" ")]],2)]]}}})],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('div',{staticClass:"iseq-report-variants__content__tools"},[_c('div',{staticClass:"iseq-report-variants__content__tools__title"},[_vm._v(" Data filtering ")]),_c('div',{staticClass:"iseq-report-variants__content__tools__searchbar"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-text-field',{attrs:{"label":"Search","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]}}])},[_vm._v(" Searches through all visible data without affecting filters ")])],1),_c('div',{staticClass:"iseq-report-variants__content__tools__button-row"},[_c('v-iseq-btn',{staticClass:"iseq-button",attrs:{"type":_vm.filterRulesActive ? 'primary' : 'secondary'},on:{"click":function($event){_vm.filterDataModal = true}}},[_vm._v(" "+_vm._s(("Filter data: " + (_vm.filterRulesActive ? 'ON' : 'OFF')))+" ")]),_c('v-iseq-btn',{staticClass:"iseq-button",attrs:{"ml":""},on:{"click":function($event){_vm.selectHeaders=true}}},[_vm._v(" Columns' settings ")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.filterRulesActive),expression:"filterRulesActive"}],staticClass:"iseq-report-variants__content__tools__button-row"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-iseq-btn',{staticClass:"iseq-button",attrs:{"type":"primary","disabled":_vm.deselectUnmatchedActive},on:{"click":function($event){return _vm.deselectUnmatched()}}},[_vm._v(" Deselect unmatched ")])],1)]}}])},[_vm._v(" Deselects previously selected values that don't match currently set filters"),_c('br'),_vm._v(" You have "+_vm._s(_vm.deselectUnmatchedActive ? 'no' : _vm.unmatchedVariantNumber)+" unmatched variant"+_vm._s(_vm.unmatchedVariantNumber === 1 ? '' : 's')+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-iseq-btn',{staticClass:"iseq-button",attrs:{"ml":""},on:{"click":_vm.handleAllFiltered}},[_vm._v(" "+_vm._s(_vm.areAllFilteredSelected ? 'Deselect' : 'Select')+" all filtered ")])],1)]}}])},[_vm._v(" Selects all filtered items if none or only some are selected. Deselects all filtered items if all are already selected. ")])],1),_c('div',{staticClass:"iseq-report-variants__content__tools__button-row"},[_c('v-iseq-btn',{staticClass:"iseq-button iseq-button--wide",attrs:{"width":"310px"},on:{"click":function($event){return _vm.downloadToFile()}}},[_vm._v(" Download selected to CSV file ")])],1),(!!_vm.variantAnalysisId)?[_c('div',{staticClass:"iseq-report-variants__content__tools__separator"}),_c('div',{staticClass:"iseq-report-variants__content__tools__title"},[_vm._v(" Variants analysis ")]),_c('v-form',{staticClass:"iseq-report-variants__content__tools__analysis-data",model:{value:(_vm.dataFormValidity),callback:function ($$v) {_vm.dataFormValidity=$$v},expression:"dataFormValidity"}},[_c('v-text-field',{staticClass:"iseq-report-variants__content__tools__analysis-data__name",attrs:{"outlined":"","hide-details":!_vm.nameRulesBroken,"rules":_vm.analysisNameRules,"label":"Variant analysis name"},model:{value:(_vm.analysisName),callback:function ($$v) {_vm.analysisName=$$v},expression:"analysisName"}}),_c('v-textarea',{staticClass:"iseq-report-variants__content__tools__analysis-data__description",attrs:{"outlined":"","hide-details":"","rows":"3","label":"Variant analysis description"},model:{value:(_vm.analysisDescription),callback:function ($$v) {_vm.analysisDescription=$$v},expression:"analysisDescription"}})],1),_c('div',{staticClass:"iseq-report-variants__content__tools__button-row"},[_c('v-tooltip',{attrs:{"top":"","disabled":!!_vm.analysisName.length && !!_vm.selected.length},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-iseq-btn',{attrs:{"width":"310px","disabled":!_vm.dataFormValidity || !_vm.analysisName || _vm.analysisName.length === 0 || _vm.selected.length === 0},on:{"click":function($event){return _vm.confirmReportVariants()}}},[_vm._v(" Run analysis ")])],1)]}}],null,false,3117414532)},[_vm._v(" "+_vm._s(_vm.selected.length ? 'Analysis name cannot be empty' : 'At least one variant must be selected')+" ")])],1)]:_vm._e()],2)]),_c('div',{staticClass:"iseq-report-variants__close-button"},[_c('v-iseq-btn',{staticClass:"iseq-report-variants__close-button__button",attrs:{"type":"secondary"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" Close ")])],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.selectHeaders),expression:"!!selectHeaders"}]},[_c('iseq-report-variants-columns',{attrs:{"parent-selected-headers":_vm.selectedHeaders,"all-headers":_vm.headers,"previous-analysis-name":_vm.previousAnalysisName},on:{"closed":function($event){_vm.selectHeaders = false},"updateSelectedHeaders":_vm.updateSelectedHeaders}})],1)]),_c('iseq-report-variants-filtering',{attrs:{"visibility":_vm.filterDataModal,"items":_vm.items,"headers":_vm.visibleHeaders},on:{"closed":function($event){_vm.filterDataModal = false},"updateFilteredValues":_vm.updateFilteredValues,"updateFiltersActive":_vm.updateFiltersActive}}),(_vm.fileBlobDialog)?_c('iseq-simple-message-modal',{attrs:{"header":_vm.file.name,"button-text":"Close","width":"calc(100vw - 170px)"},on:{"closed":function($event){_vm.fileBlobDialog = false}}},[_c('div',{staticStyle:{"text-align":"center"}},[_c('iframe',{staticStyle:{"width":"98%","height":"calc(100vh - 251px)"},attrs:{"src":_vm.file.url}})])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }