<template>
  <v-card
    v-show="typeof userCredits !== 'undefined' && price>userCredits"
    class="iseq-insufficient-credits"
  >
    <div class="iseq-insufficient-credits__text">
      <v-icon color="white">
        mdi-alert
      </v-icon>

      Your account balance is insufficient to {{ message[type] }}.

      <span v-if="organization.accountStatus==='TRIAL'">
        Please, <span
          class="link"
          @click="openChat"
        >contact our customer support</span> to receive free credits.
      </span>

      <span v-else-if="permissionRequired('organizationCreditsAdd')">
        Please, <span
          class="link"
          @click="addCredits"
        >top up</span> your account.
      </span>

      <span v-else>
        Please, contact your organization owner.
      </span>
    </div>
  </v-card>
</template>

<script>
  import {permissionCheck} from "../../plugins/permissions";
  import { mapState } from 'vuex';

  export default {
    name: "IseqInsufficientCreditsCard",
    props: {
      type: {
        type: String,
        default: "sampleCreate"
      },
      price: {
        type: Number,
        default: 0
      }
    },
    data: function() {
      return {
        message: {
          sampleCreate: "create a new sample",
          analysisRun: "run this workflow"
        }
      }
    },
    computed: {
      ...mapState('organization', ['organization']),
      ...mapState('user', [ 'userCredits' ]),
    },
    methods: {
      permissionRequired(action) {
        return permissionCheck(action)
      },
      openChat() {
        window.drift.api.openChat();
      },
      addCredits() {
        this.$store.commit('payment/setPaymentDialog', true)
      }
    }
  }
</script>

<style lang="scss"
       scoped>
    .iseq-insufficient-credits {
        display: flex;
        padding: 10px 20px;
        margin: 20px 40px 20px 40px;
        box-shadow: $card-shadow;
        background-color: var(--warning-color) !important;
        justify-content: center;
        align-content: center;

        &__text {
            color: white;
            font-size: 1.3em;

            .link {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }

</style>