<template>
  <div
    class="iseq-analysis-details"
    :style="cssProps"
  >
    <v-iseq-card
      :title="`Analysis: ${analysis.name}`"
      class="iseq-analysis-details__base-info"
    >
      <template #post-title>
        <span class="iseq-analysis-details__base-info__title-row__status">{{ analysis.status.name }}</span>
      </template>

      <template #title-row>
        <div class="iseq-analysis-details__base-info__title-row">
          <div class="iseq-analysis-details__base-info__title-row__buttons">
            <template>
              <v-iseq-btn
                v-if="permissionRequired('analysisAdd')"
                v-show="analysis.status.id === 'SUCCEEDED' && analysis.variantSelectors.length"
                @click="reportVariantsModal = true"
              >
                Manual filtering
              </v-iseq-btn>
            </template>

            <v-iseq-btn
              v-if="permissionRequired('analysisEdit')"
              type="secondary"
              @click="updateAnalysis"
            >
              Edit
            </v-iseq-btn>

            <template v-if="analysis.finishedDate === 'In progress'">
              <v-iseq-btn
                v-if="permissionRequired('analysisCancel')"
                type="red"
                ml
                @click="abortAnalysisModal = true"
              >
                Abort analysis
              </v-iseq-btn>
            </template>

            <template v-else-if="permissionRequired('analysisCancel')">
              <v-iseq-btn
                type="red"
                ml
                @click="deleteAnalysisModal = true"
              >
                Delete analysis
              </v-iseq-btn>
            </template>

            <template v-if="isAdmin">
              <v-iseq-btn
                type="warning"
                ml
                @click="goToAdminPanel()"
              >
                Debug
              </v-iseq-btn>
            </template>
          </div>
        </div>
      </template>

      <div
        v-if="analysis.initiator"
        class="iseq-analysis-details__base-info__dates-row"
      >
        <div class="">
          <v-icon class="icon">
            mdi-account
          </v-icon>
          <span class="thin">Initiator: </span>
          <span class="thick">{{ analysis.initiator.email }}</span>
        </div>

        <div class="">
          <v-icon class="icon">
            mdi-calendar-month
          </v-icon>
          <span class="thin">Started: </span>
          <span class="thick">{{ analysis.startedDate }}</span>
          <span class="thin"> - Finished: </span>
          <span class="thick">{{ analysis.finishedDate }}</span>
        </div>
      </div>

      <v-divider :inset="false" />

      <div class="iseq-analysis-details__base-info__description">
        {{ analysis.description }}
      </div>

      <v-iseq-field :values="descriptionFields" />
    </v-iseq-card>

    <v-iseq-card
      v-if="analysisProgressData"
      title="Progress"
      class="iseq-analysis-details__progress"
    >
      <v-progress-linear
        :rounded="true"
        :value="calculateProgressState(analysisProgressData.doneTask, analysisProgressData.totalTask)"
        height="16"
        :color="checkColorProgressBar(analysisProgressData.doneTask, analysisProgressData.totalTask)"
      >
        <template #default="{}">
          <strong class="progressbar"> Processing... ({{ analysisProgressData.doneTask }} /
            {{ analysisProgressData.totalTask }} tasks) </strong>
        </template>
      </v-progress-linear>
    </v-iseq-card>

    <v-iseq-card
      title="Results"
      class="iseq-analysis-details__results"
    >
      <template v-if="analysis.status.id === 'RUNNING'">
        <v-alert
          border="left"
          colored-border
          type="info"
          color="primary"
          icon="alarm"
          elevation="2"
          class="iseq-analysis-details__results__message text--text"
        >
          The results of the analysis will appear here after its completion.
        </v-alert>
      </template>

      <template v-else-if="analysis.status.id === 'SUCCEEDED'">
        <div
          v-if="filesLoadingSpinner"
          class="iseq-analysis-details__results__spinner"
        >
          <v-iseq-loading-spinner text="Loading outputs..." />
        </div>

        <template v-if="analysis.name && analysisFiles.id && permissionRequired('analysisFilesView')">
          <v-tree-menu
            :tree="analysisFiles"
            type="analysis"
            :analysis-uuid="analysis.uuid"
            :sample-uuid="analysis.sample.uuid"
            :project-uuid="analysis.sample.project.uuid"
            :organization-uuid="analysis.sample.project.organizationUuid"
            :show-children="true"
          />
        </template>
      </template>

      <template v-else-if="analysis.status.id === 'FAILED' && analysis.cromwellData.returnCode === '86'">
        <v-alert
          border="left"
          colored-border
          type="error"
          elevation="2"
          class="iseq-analysis-details__results__message text--text"
        >
          Error: <strong>{{ analysis.cromwellData.errorMessage }} </strong>For more information contact our <span
            class="link"
            @click="openChat"
          >support team</span>.
        </v-alert>
      </template>

      <template v-else-if="analysis.status.id === 'FAILED'">
        <v-alert
          border="left"
          colored-border
          type="error"
          elevation="2"
          class="iseq-analysis-details__results__message text--text"
        >
          This analysis has failed. For more information contact our <span
            class="link"
            @click="openChat"
          >support team</span>.
        </v-alert>
      </template>

      <template v-else-if="analysis.status.id">
        <v-alert
          border="left"
          colored-border
          type="info"
          elevation="2"
          class="iseq-analysis-details__results__message text--text"
        >
          This analysis doesn't have any result files.
        </v-alert>
      </template>
    </v-iseq-card>

    <iseq-confirmation-modal
      v-if="updateAnalysisDialog"
      header="Update analysis data"
      confirm-button-type="primary"
      confirm-button-text="Save changes"
      reject-button-type="secondary"
      reject-button-text="Close"
      @actionRejected="closeAnalysisDialog"
      @actionConfirmed="saveChanges"
    >
      <v-textarea
        v-model="analysisUpdateForm.description"
        class="iseq-text-field iseq-text-field--active"
        label="Description"
        auto-grow
      />
    </iseq-confirmation-modal>

    <v-iseq-confirmation-modal
      v-if="abortAnalysisModal"
      confirm-button-text="Abort"
      confirm-button-type="danger"
      reject-button-text="Cancel"
      reject-button-type="secondary"
      header="Aborting analysis"
      message="Are you sure that you want to abort this analysis? Credits for aborted analysis won't be refunded."
      @actionRejected="abortAnalysisModal=false"
      @actionConfirmed="abortAnalysis()"
    />

    <v-iseq-confirmation-modal
      v-if="deleteAnalysisModal"
      confirm-button-text="Delete"
      confirm-button-type="danger"
      reject-button-text="Cancel"
      reject-button-type="secondary"
      header="Deleting analysis"
      message="Are you sure that you want to delete this analysis?"
      @actionRejected="deleteAnalysisModal=false"
      @actionConfirmed="deleteAnalysis()"
    />

    <v-iseq-report-variants-modal
      v-if="reportVariantsModal"
      :analysis-uuid="uuid"
      :previous-analysis-name="analysis.name"
      :sample-uuid="sample.details.uuid"
      :files="analysisFiles"
      :used-analysis-names="sample.analysesNames"
      :variant-selectors="analysis.variantSelectors"
      :variant-analysis-id="analysis.variantSelectors[0].wdlId"
      @closed="reportVariantsModal = false"
      @confirmed="reportVariantsConfirmed"
    />
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import TreeMenu from '@/components/ui/TreeMenu.vue';
  import IseqField from "@/components/ui/IseqField.vue";
  import {permissionCheck} from "@/plugins/permissions";
  import LoadingSpinner from "@/components/ui/LoadingSpinner.vue";
  import IseqConfirmationModal from "@/components/modals/IseqConfirmationModal.vue";
  import IseqReportVariants from "./components/IseqReportVariants.modal"
  import IseqCard from "@/components/ui/IseqCard.vue";
  import IseqButton from "@/components/ui/IseqButton.vue";

  export default {
    name: "AnalysisDetails",
    components: {
      IseqConfirmationModal,
      "v-tree-menu": TreeMenu,
      "v-iseq-field": IseqField,
      "v-iseq-loading-spinner": LoadingSpinner,
      "v-iseq-confirmation-modal": IseqConfirmationModal,
      "v-iseq-report-variants-modal": IseqReportVariants,
      "v-iseq-card": IseqCard,
      "v-iseq-btn": IseqButton
    },
    beforeRouteLeave(to, from, next) {
      if (!to.name.includes('analys')) {
        this.$store.commit('analysis/clearAnalysisTableOptions');
        this.$store.commit('analysis/resetContent');
        next();
      } else {
        next();
      }
    },
    data: function () {
      return {
        uuid: '',
        updateAnalysisDialog: false,
        analysisUpdateForm: {
          name: '',
          description: ''
        },
        abortAnalysisModal: false,
        deleteAnalysisModal: false,
        filesLoadingSpinner: true,
        reportVariantsModal: false,
      }
    },
    computed: {
      ...mapState('analysis', ['analysis', 'analysisFiles', 'analysisProgressData']),
      ...mapState('project', ['project']),
      ...mapState('organization', ['organization']),
      ...mapState('sample', ['sample']),
      ...mapState('user', ['user', 'isAdmin']),
      cssProps() {
        return {
          '--status-color': this.$vuetify.theme.defaults.light[this.checkColorBadge(this.analysis.status.id)]
        }
      },
      descriptionFields() {
        return [
          {
            icon: "mdi-microscope",
            name: "Workflow: ",
            value: this.analysis.workflowName,
            route: "/workflows/" + this.analysis.workflowId + "?version=" + this.analysis.workflowVersion
          },
          {
            icon: "mdi-cash",
            name: "Cost: ",
            value: `${this.analysis.chargedCost} credits`,
          },
          {
            icon: "mdi-folder-multiple",
            name: "Project: ",
            value: this.analysis.sample.project.name,
            route: "/projects/" + this.analysis.sample.project.uuid
          },
          {
            icon: "mdi-folder-open",
            name: "Sample: ",
            value: this.analysis.sample.name,
            route: "/samples/" + this.analysis.sample.uuid
          }
        ]
      }
    },
    watch: {
      analysisFiles(newFilesTree) {
        this.filesLoadingSpinner = !newFilesTree.id;
      },
      project(newProject, oldProject) {
        if (oldProject.uuid) {
          if (!newProject.uuid) {
            this.$router.push('/');
          } else if (newProject.uuid !== this.analysis.sample.project.uuid) {
            this.$router.push('/analyses');
          }
        }
      }
    },
    created: function () {
      this.uuid = this.$route.params.uuid;
      this.$store.commit('analysis/cleanAllData');
      this.$store.dispatch('analysis/getAnalysis', this.uuid);
    },
    methods: {
      permissionRequired(action) {
        return permissionCheck(action)
      },
      updateAnalysis() {
        this.analysisUpdateForm = {
          name: this.analysis.name,
          description: this.analysis.description
        };
        this.updateAnalysisDialog = true;
      },
      checkColorBadge(status) {
        switch (status) {
        case 'SUCCEEDED':
          return 'success';
        case 'FAILED':
          return 'error';
        case 'RUNNING':
          return 'primary';
        case 'ABORTED':
          return 'bordertext';
        case 'DELETED':
          return 'bordertext';
        }
      },
      calculateProgressState(done, total) {
        return (done / total) * 100;
      },
      checkColorProgressBar(done, total) {
        return ((done / total) === 1) ? 'success' : 'dark';
      },
      closeAnalysisDialog() {
        this.updateAnalysisDialog = false
      },
      saveChanges() {
        this.updateAnalysisDialog = false;
        this.$store.dispatch('analysis/updateAnalysis', {
          analysisUuid: this.analysis.uuid,
          data: this.analysisUpdateForm
        })
      },
      abortAnalysis() {
        this.abortAnalysisModal = false;
        this.$store.dispatch('analysis/abortAnalysis', {uuid: this.analysis.uuid})
      },
      deleteAnalysis() {
        this.$store.dispatch('analysis/deleteAnalysis', {
          uuid: this.analysis.uuid,
          sampleUuid: this.analysis.sample.uuid
        })
      },
      goToAdminPanel() {
        window.open(`${process.env.VUE_APP_ADMIN_PANEL_ADDRESS}/analyses/${this.analysis.uuid}`, '_blank');
      },
      openChat() {
        window.drift.api.openChat();
      },
      reportVariantsConfirmed(data) {
        let inputs = {};
        inputs[this.analysis.variantSelectors[0].variants[0].inputName] = data.variants
        this.reportVariantsModal = false;
        this.$store.dispatch('analysis/runVariantsAnalysis', {
          data: {
            inputs: JSON.stringify(inputs),
            workflowId: this.analysis.variantSelectors[0].wdlId,
            name: data.name,
            description: data.description
          },
          analysisUuid: this.uuid,
          sampleUuid: this.analysis.sample.uuid
        });
      }
    }
  }
</script>
<style lang="scss"
       scoped>

.iseq-analysis-details {
  &__base-info,
  &__progress,
  &__results {
    &__title-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__status {
        margin-left: 10px;
        padding: 5px 10px 5px 10px;
        display: inline-block;
        font-size: 12px;
        background-color: var(--status-color);
        color: white;
        border-radius: 18px;
        font-weight: bold;
      }

      &__buttons {
        width: min-content;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .iseq-btn {
          margin-left: 8px;
        }
      }
    }

    &__dates-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .icon {
        color: var(--primary-color);
      }

      .thick {
        color: var(--font-color);
        font-weight: bold;
        font-size: 13px;
      }

      .thin {
        font-size: 14px;
        color: var(--font-inverted-color);
      }
    }

    &__description {
      padding: 20px 0px 15px;
      margin-left: 2px;
      font-size: 14px;
      color: var(--font-inverted-color);
    }

    &__message {
      display: flex;
      justify-content: center;
      font-size: 16px;
      margin-bottom: 10px;

      .link {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  &__progress {
    .progressbar {
      color: white;
    }
  }

  &__results {
    &__spinner {
      padding-bottom: 25px;
    }
  }
}
</style>
