import Vue         from 'vue'
import request     from '@/customjs/request.js'
import store       from '@/store'
import router      from '@/router'
import VueCookies  from 'vue-cookies'
import {generatePagingData, timeZoned} from "../../plugins/utils";
import {permissionCheck} from "../../plugins/permissions";
import {handleDeletedResourceError} from "../../helpers/errorHandlers";
import {pagingDataParse} from "../../plugins/utils";

export default{
  namespaced: true,
  state: {
    project: {},
    joinRequests: [],
    members: [],
    pendingInvitations: [],
    runningAnalyses: [],

    headerProjects: [],

    userProjects: [],
    userProjectsTotalElements: 0,
    userProjectsTableOptions: generatePagingData({raw: true}),

    joinableProjects: [],
    joinableProjectsTotalElements: 0,
    joinableProjectsTableOptions: generatePagingData({raw: true}),

    content: [],
    totalElements: 0,
    projectsTableOptions: generatePagingData({raw: true}),
  },
  mutations: {
    setMembers(state, payload) {
      state.members = payload
    },
    clearMembers (state) {
      state.members = [];
    },
    setJoinRequests (state, data) {
      state.joinRequests = data;
    },
    setHeaderProjects(state, payload) {
      let data = payload.data;

      data.map(project => {
        project.route = "/projects/" + project.uuid;
        return project
      });
            
      data.sort(function(a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      });

      state.headerProjects = data;

      let projectUuid = undefined;

      // Checking if preferred project given and present on the list
      if (payload.preferredProject) {
        projectUuid = data.find(node => node.uuid === payload.preferredProject);
        if (projectUuid) {
          projectUuid = projectUuid.uuid;
        }
      }

      // If project still not chosen checking if we have cookie saved and it's value present on the list
      if (!projectUuid && VueCookies.get('project')) {
        const cookie = VueCookies.get('project');
        projectUuid = data.find(node => node.uuid === cookie);
        if (projectUuid) {
          projectUuid = projectUuid.uuid;
        }
      }

      // If project still not chosen selecting first value from the list
      if (!projectUuid && data[0]) {
        projectUuid = data[0].uuid;
      }

      // Get project, if none was chosen clean project data
      if (projectUuid) {
        store.dispatch('project/getProject', {uuid: projectUuid});
      } else {
        store.commit('project/cleanAllData');
      }
    },

    setProject(state, data) {
      data.creationDate = timeZoned(data.creationDate);
      if (data.status.id !== "DELETED") {
        VueCookies.set('project', data.uuid);
      }
      state.project = data
    },
        
    setUserProjects(state, data){
      data.map(project => {
        project.route = "/projects/" + project.uuid;
        project.created = timeZoned(project.created);
        return project;
      });
      state.userProjects = data
    },

    setUserProjectsTotalElements(state, payload) {
      state.userProjectsTotalElements= payload
    },

    setUserProjectsTableOptions (state, payload) {
      state.userProjectsTableOptions = payload;
    },

    setJoinableProjects(state, data){
      data.map(project => {
        project.created = timeZoned(project.created);
        return project;
      });
      state.joinableProjects = data
    },

    setJoinableProjectsTotalElements(state, payload) {
      state.joinableProjectsTotalElements= payload
    },

    setJoinableProjectsTableOptions (state, payload) {
      state.joinableProjectsTableOptions = payload;
    },

    setPendingInvitations(state, payload) {
      state.pendingInvitations = payload;
    },

    setRunningAnalyses(state, payload) {
      state.runningAnalyses = payload;
    },

    cleanProjectData(state) {
      state.project = {};
    },

    cleanAllData(state){
      state.project = {};
      state.headerProjects = [];
      state.userProjects = [];
      state.joinableProjects =[];
      state.userProjects = [];
      state.joinRequests = [];
      state.pendingInvitations = [];
    }
  },
  actions: {
    // GET

    getMembers({commit}, payload) {
      request.request({
        endpoint: `projects/${payload.uuid}/members`,
        callback: function (data) {
          commit('setMembers', data);
        }
      })
    },
    getUserProjects({commit, state}, payload) {
      const pagingParams = pagingDataParse({...state.userProjectsTableOptions, totalElements: state.totalElements});

      request.request({
        endpoint: `organizations/${payload.organizationUuid}/projects?membership=JOINED&${pagingParams}`,
        callback: function (data) {
          commit('setUserProjects', data.content);
          commit('setUserProjectsTotalElements', data.totalElements);
        }
      })
    },

    getJoinableProjects({commit, state}) {
      const organizationUuid = store.getters["organization/currentOrganizationUuid"];
      const pagingParams = pagingDataParse({...state.joinableProjectsTableOptions, totalElements: state.joinableProjectsTotalElements});

      request.request({
        endpoint: `organizations/${organizationUuid}/projects?membership=JOINABLE&${pagingParams}`,
        callback: function (data) {
          commit('setJoinableProjects', data.content);
          commit('setJoinableProjectsTotalElements', data.totalElements);
        }
      })
    },

    getHeaderProjects({commit}, payload) {
      request.request({
        endpoint: `organizations/${payload.organizationUuid}/projects/minimal`,
        callback: function (data) {
          commit('setHeaderProjects', {data: data, preferredProject: payload.preferredProject})
        }
      })
    },

    getProject({rootState, commit, dispatch}, payload) {
      request.request({
        endpoint: `projects/${payload.uuid}`,
        callback: function (data) {
          if (data.status.id !== "DELETED") {
            commit('setProject', data);

            // When project is valid checking if context is correct
            if (rootState.organization.organization.uuid !== data.organizationUuid) {
              store.dispatch("organization/getHeaderOrganizations", {
                preferredOrganization: data.organizationUuid,
                preferredProject: data.uuid
              });
            }

            if (payload.getAllData) {
              store.dispatch("sample/getSampleList");
              store.dispatch('analysis/getAnalysisByProject', {
                data: generatePagingData({}),
                projectUuid: payload.uuid
              });
              dispatch('getMembers', {uuid: payload.uuid});
              if (permissionCheck('projectUserInvite')) {
                dispatch('getPendingInvitations', {uuid: payload.uuid});
              }
              store.dispatch('organization/getOrganizationMembers', {uuid: data.organizationUuid});
              if (permissionCheck('projectUserAdd')) {
                dispatch('getJoinRequests', {projectUuid: data.uuid});
              }
              dispatch('getRunningAnalyses', {uuid: data.uuid});
            }
          } else {
            handleDeletedResourceError();
          }
        }
      })
    },

    getRunningAnalyses({commit}, payload){
      request.request({
        endpoint: `projects/${payload.uuid}/analyses/running`,
        callback: function (data) {
          commit('setRunningAnalyses', data);
        }
      })
    },

    getJoinRequests({commit}, payload){
      request.request({
        endpoint: `projects/${payload.projectUuid}/requests`,
        callback: function (data) {
          commit('setJoinRequests', data)
        }
      })
    },

    getPendingInvitations({commit}, payload){
      request.request({
        endpoint: `projects/${payload.uuid}/invitations`,
        callback: function (data) {
          commit('setPendingInvitations', data)
        }
      })
    },

    // CREATE

    createProject({dispatch}, payload) {
      request.request({
        endpoint: `organizations/${payload.organizationUuid}/projects`,
        method: "post",
        data: payload.data,
        callback: function (data) {
          router.push({path: `/projects/${data.uuid}`});
          dispatch('getHeaderProjects', {organizationUuid: store.state.organization.organization.uuid});
        }
      })
    },

    updateProject({dispatch}, payload) {
      request.request({
        method: "patch",
        endpoint: `projects/${payload.uuid}`,
        data: payload.formData,
        callback: function() {
          dispatch('getProject', {uuid: payload.uuid, getAllData: true})
        }
      })
    },

    updateMemberRole({dispatch, state}, payload) {
      request.request({
        method: "patch",
        endpoint: `projects/${payload.projectUuid}/role`,
        data: payload.data,
        callback: function () {
          dispatch('getProject', {uuid: payload.projectUuid});
          dispatch('getMembers', {uuid: payload.projectUuid});
        }
      })
    },

    joinProject({}, payload){
      request.request({
        endpoint: `projects/${payload.projectUuid}/join`,
        method: "post",
        callback: function() {
          store.dispatch("snackbarQueue/addConfirmationMessage", {type: "joinRequestSent"});
        }
      })
    },

    acceptRequest({state}, payload) {
      request.request({
        endpoint: `projects/requests/${payload.invitationUuid}`,
        method: "post",
        data: {
          role: payload.role,
          userUuid: payload.userUuid
        },
      })
    },


    inviteMemberToProject({state, dispatch}, payload) {
      request.request({
        endpoint: `projects/${payload.projectUuid}/invitations`,
        method: "post",
        data: {
          email: payload.email,
          role: payload.role
        },
        callback: function() {
          dispatch('getPendingInvitations', {uuid: payload.projectUuid});
          store.dispatch("snackbarQueue/addConfirmationMessage", {type: "invitationSentSuccessfully"});
        }
      })
    },

    inviteMemberToProjectAndOrganization({state, dispatch}, payload) {
      request.request({
        endpoint: `projects/${payload.projectUuid}/invitations/external`,
        method: "post",
        data: {
          email: payload.email,
          organizationRole: payload.organizationRole,
          projectRole: payload.projectRole
        },
        callback: function() {
          dispatch('getPendingInvitations', {uuid: payload.projectUuid});
          store.dispatch("snackbarQueue/addConfirmationMessage", {type: "invitationSentSuccessfully"});
        }
      })
    },

    // DELETE

    rejectRequest({state}, payload) {
      request.request({
        snackbarMessage: "Rejecting join request",
        endpoint: `projects/requests/${payload.requestUuid}`,
        method: "delete"
      })
    },

    removeMember({dispatch}, payload){
      request.request({
        snackbarMessage: "Removing user from project",
        endpoint: `projects/${payload.projectUuid}/users/${payload.uuid}`,
        method: "delete",
        callback: function () {
          dispatch('getMembers', {uuid: payload.projectUuid});
        }
      })
    },


    deleteProject({commit, dispatch}, payload) {
      VueCookies.set('project', "null");
      request.request({
        snackbarMessage: "Deleting project",
        endpoint: `projects/${payload.uuid}`,
        method: "delete",
        callback: function () {
          dispatch('getHeaderProjects', {organizationUuid: payload.organizationUuid});
          router.push('/');
        }
      })
    }
  },
  getters: {
    projectsTableOptions: (state) => {
      return state.projectsTableOptions;
    },
    userRole: state => {
      return state.project.role;
    }
  }
}
