<template>
  <!-- eslint-disable vue/no-v-html -->
  <section class="iseq-info-field">
    <span class="iseq-info-field__title">{{ title }}</span>

    <template v-if="markdown">
      <div v-html="compileMarkdown(value)" />
    </template>

    <template v-else-if="value">
      {{ value }}
    </template>

    <slot />
  </section>
</template>

<script>
  import {markdownCompile} from "@/plugins/utils";

  export default {
    name: "IseqInfoField",
    props: {
      value: {
        type: String,
        required: false,
        default: undefined
      },
      title: {
        type: String,
        required: false,
        default: ''
      },
      markdown: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      compileMarkdown(data) {
        return markdownCompile(data);
      }
    }
  }
</script>

<style lang="scss"
       scoped>
    .iseq-info-field {
        position: relative;
        padding: 15px 12px;
        border-style: solid;
        border-radius: 4px;
        border-width: 1px;
        border-color: var(--border-inactive);
        font-size: 16px;
        color: var(--v-secondary-darken3);

        &__title {
            position: absolute;
            top: 0;
            margin: -6px 0 0 -2px; /* first value is a half of font-size */
            padding: 0 3px;
            font-size: 12px;
            font-weight: 500;
            line-height: 1;
            color: var(--border-text);
            background: #fff;
        }
    }
</style>