<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="iseq-analysis-create">
    <v-iseq-insufficient-credits
      type="analysisRun"
      :price="parseInt(workflowPrice)"
    />

    <v-iseq-banner
      v-if="!samplesMinimal.length"
      type="warning"
      :value="['There are no samples in this project. In order to run new analysis ', ' first.']"
      :link="{address: '/samples/new', text: 'create a sample'}"
      :center-content="true"
    />

    <v-iseq-card
      title="Create new analysis"
      class="iseq-analysis-create__base-info"
    >
      <div class="iseq-analysis-create__base-info__wrapper">
        <v-text-field
          readonly
          outlined
          hide-details
          background-color="#eee"
          label="Organization"
          class="text-field"
          :value="organization.name"
        />

        <v-text-field
          readonly
          outlined
          hide-details
          background-color="#eee"
          label="Project"
          class="text-field"
          :value="project.name"
        />

        <v-select
          v-if="samplesMinimal.length"
          v-model="selectedSample"
          outlined
          class="iseq-select"
          return-object
          label="Sample"
          item-text="name"
          :items="samplesMinimal"
          placeholder="Select sample"
          @change="selectSample"
        />

        <v-iseq-btn
          v-else
          :disabled="!permissionRequired('sampleAdd')"
          class="new-sample-button"
          depressed
          pre-icon="mdi-plus-circle"
          @click="createSample"
        >
          Create new sample
        </v-iseq-btn>
      </div>

      <div v-if="permissionRequired('analysisAdd')">
        <div class="iseq-analysis-create__base-info__wrapper">
          <v-select
            v-if="!showWorkflowFromAddressInput"
            v-model="selectedWorkflowModel"
            outlined
            return-object
            label="Workflow"
            item-text="name"
            item-value="workflowId"
            :items="parsedGroupedWorkflows"
            class="iseq-select  iseq-select--max-width"
            placeholder="Select workflow"
            @change="selectWorkflow"
          >
            <template #item="{ item }">
              <v-tooltip
                top
                :disabled="item.available"
              >
                <template #activator="{ on, attrs }">
                  <div
                    class="iseq-select__label"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <div class="iseq-select__label__name">
                      <span
                        :class="[item.available ? 'iseq-select__label__name__value' : 'iseq-select__label__name__disabled-value']"
                      >{{
                        item.name
                      }}</span>
                    </div>
                    <div :class="[item.available ? 'iseq-select__label__price' : 'iseq-select__label__disabled-price']">
                      <span class="value">{{ `${item.price ? item.price : '-'} credits` }}</span>
                    </div>
                  </div>
                </template>
                <span>There are missing files in the sample required to run this workflow.</span>
              </v-tooltip>
            </template>
            <template #selection="{ item }">
              <div class="iseq-select__label">
                <div class="iseq-select__label__name iseq-select__label__name--selected">
                  <span
                    :class="[item.available ? 'iseq-select__label__name__value' : 'iseq-select__label__name__disabled-value']"
                  >{{
                    item.name
                  }}</span>
                </div>
                <div :class="[item.available ? 'iseq-select__label__price' : 'iseq-select__label__disabled-price']">
                  <span class="value">{{ `${item.price ? item.price : '-'} credits` }}</span>
                </div>
              </div>
            </template>
          </v-select>

          <div
            v-else
            class="text-workflow-input"
          >
            <v-text-field
              v-model="workflowAddress"
              outlined
              hide-details
              label="Workflow address"
              class="text-workflow-input__field"
            />

            <v-text-field
              v-model="workflowVariant"
              outlined
              hide-details
              label="Variant"
              class="text-workflow-input__field-short"
            />

            <v-iseq-btn
              width="wide"
              large
              @click="loadWorkflowFromAddress()"
            >
              Load workflow
            </v-iseq-btn>
          </div>
        </div>

        <v-tooltip top>
          <template #activator="{ on }">
            <div
              style="width: 230px;"
              v-on="on"
            >
              <v-switch
                v-if="isAdmin"
                v-model="showWorkflowFromAddressInput"
                outlined
                hide-details
                label="Run workflow from URL"
                color="primary"
                class="iseq-analysis-create__base-info__switch"
              />
            </div>
          </template>
          <span>Admin users only</span>
        </v-tooltip>

        <v-iseq-info-field
          v-if="selectedWorkflow.description && workflowPrice <= userCredits"
          class="iseq-analysis-create__base-info__workflow-description"
          title="Description"
        >
          <div
            class="iseq-analysis-create__base-info__workflow-description__markdown-field"
            v-html="compileMarkdown(selectedWorkflow.description)"
          />

          <div
            v-if="isLongDescriptionOpen"
            class="iseq-analysis-create__base-info__workflow-description__markdown-field"
            v-html="compileMarkdown(selectedWorkflow.longDescription)"
          />

          <span
            v-if="selectedWorkflow.longDescription"
            class="iseq-analysis-create__base-info__workflow-description__long-description-toggle-button"
            @click="toggleWorkflowDescription"
          >
            {{ `${isLongDescriptionOpen ? 'Show less' : 'Read more...'}` }}
          </span>
        </v-iseq-info-field>
      </div>

      <template v-if="!showInputs && !filesLoading">
        <div
          v-if="!permissionRequired('analysisAdd')"
          class="iseq-analysis-create__base-info__info-text"
        >
          <span class="text-value">You are not allowed to run analyses in this project.</span>

          <span class="text-value">You need to be at least Project Editor in order to start one. </span>
        </div>
      </template>

      <div
        v-if="selectedWorkflowModel && !parsedGroupedWorkflows.find(item => item.workflowId===selectedWorkflowModel).available && !filesLoading"
        class="iseq-analysis-create__base-info__missing-files-alert"
      >
        <v-alert
          icon="mdi-alert"
          dense
          type="warning"
        >
          There are missing files in the sample required to run this workflow.
        </v-alert>
      </div>
    </v-iseq-card>

    <v-iseq-card
      v-if="showInputs && advancedInputsExist"
      title="Inputs"
      tabs-only
      no-bottom-margin
      class="iseq-analysis-create__actions"
    >
      <template
        #title-row
        class="iseq-analysis-create__actions__title-row"
      >
        <div class="iseq-analysis-create__actions__tabs-wrapper">
          <v-tabs
            v-model="tab"
            height="64px"
            color="primary"
          >
            <v-tab
              key="Basic"
              class="iseq-analysis-create__actions__tabs-wrapper__tab"
            >
              <v-icon class="icon">
                mdi-vector-combine
              </v-icon>
              Basic
            </v-tab>

            <v-tab
              id="advancedInputs"
              key="Advanced"
              class="iseq-analysis-create__actions__tabs-wrapper__tab"
            >
              <v-icon class="icon">
                mdi-vector-selection
              </v-icon>
              Advanced
            </v-tab>
          </v-tabs>
        </div>
      </template>
    </v-iseq-card>

    <v-iseq-card
      v-if="showInputs"
      :title="!advancedInputsExist ? 'Inputs' : tab === 0 ? 'Basic inputs' : 'Advanced inputs'"
      class="iseq-analysis-create__details"
    >
      <div
        v-if="selectedWorkflow"
        class="iseq-analysis-create__details__wrapper"
      >
        <div
          v-if="tab === 0"
          class="iseq-analysis-create__details__wrapper__name-input"
        >
          <v-text-field
            v-model="analysisName"
            label="Analysis name"
            outlined
            hide-details
            background-color="white"
            class="text-field"
          />
        </div>

        <template v-if="isAnalysisNameTaken && tab===0">
          <span class="iseq-analysis-create__details__wrapper__name-input-error-text">This analysis name is already taken within the chosen sample. Please provide different name.</span>
        </template>

        <div
          v-if="tab===0"
          class="iseq-analysis-create__details__wrapper__description-input"
        >
          <v-textarea
            v-model="description"
            :rows="2"
            auto-grow
            outlined
            label="Analysis description"
            placeholder="Your analysis' description"
            class="iseq-text-area"
          />

          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon
                color="primary"
                v-on="on"
              >
                mdi-help-circle
              </v-icon>
            </template>

            This description will appear in variant report.
          </v-tooltip>
        </div>

        <div v-show="tab===0">
          <div
            v-for="input in visibleBasicInputs"
            :key="input.id"
          >
            <analysis-create-input
              :input="input"
              :subinput="false"
              :parent-value.sync="inputs"
              :erroneous-inputs="inputsWithErrors"
              :workflow-inputs="selectedWorkflow.inputs"
              @inputChange="updateInput"
            />
          </div>
        </div>

        <div v-show="tab===1">
          <div
            v-for="input in visibleAdvancedInputs"
            :key="input.id"
          >
            <analysis-create-input
              :input="input"
              :subinput="false"
              :parent-value.sync="inputs"
              :erroneous-inputs="inputsWithErrors"
              :workflow-inputs="selectedWorkflow.inputs"
              @inputChange="updateInput"
            />
          </div>
        </div>
      </div>
    </v-iseq-card>

    <v-iseq-card
      v-if="showInputs"
      no-title
      no-bottom-margin
      class="iseq-analysis-create__run-section"
    >
      <template #title-row>
        <div class="iseq-analysis-create__run-section__button">
          <v-tooltip
            :disabled="!(runAnalysisLock || createAnalysisDisabledReasons.length)"
            top
          >
            <template #activator="{on}">
              <div v-on="on">
                <v-iseq-btn
                  large
                  width="wide"
                  :disabled="!!(runAnalysisLock || createAnalysisDisabledReasons.length)"
                  @click.prevent="startValidation"
                >
                  <span>Run Analysis</span>
                </v-iseq-btn>
              </div>
            </template>

            <span>{{ createAnalysisDisabledReasons }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-iseq-card>

    <v-iseq-card
      v-if="filesLoading"
      class="iseq-analysis-create__details"
    >
      <v-iseq-loading-spinner text="Loading sample data..." />
    </v-iseq-card>

    <v-dialog
      v-model="parsingGenePanelSpinnerModal"
      persistent
      max-width="500"
      class="iseq-analysis-create__dialog"
    >
      <div class="iseq-analysis-create__dialog__content">
        <v-iseq-loading-spinner text="Preparing your analysis..." />
        <span v-if="isPhenotypesDescriptionProvided">It might take up to 30s</span>
      </div>
    </v-dialog>

    <iseq-simple-message-modal
      v-if="errorsWithoutSpecificInput.length > 0"
      header="We have encountered following problems while parsing your inputs:"
      width="600px"
      @closed="errorsWithoutSpecificInput = []"
    >
      <div
        v-for="error in errorsWithoutSpecificInput"
        :key="error.reason"
      >
        {{ error.reason }}
      </div>
    </iseq-simple-message-modal>

    <v-iseq-genes-confirmation
      v-if="HPOParsingResults.genes.length > 0 && HPOParsingResults.errors.length === 0 && inputsWithErrors.length === 0"
      :genes="HPOParsingResults.genes"
      @closed="HPOParsingResults={genes: [], errors: []}"
      @accepted="genesPanelAccepted"
    />
  </div>
</template>

<script>
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import {mapState} from 'vuex'
  import AnalysisCreateInput from "./components/AnalysisCreateInput.component";
  import {permissionCheck} from "@/plugins/permissions";
  import {markdownCompile} from "@/plugins/utils";
  import IseqInsufficientCreditsCard from "../../components/ui/IseqInsufficientCreditsCard.vue";
  import LoadingSpinner from "@/components/ui/LoadingSpinner.vue";
  import IseqSimpleMessageModal from "@/components/modals/IseqSimpleMessageModal.component.vue"
  import {getFileExtensionFromFilename} from "@/helpers/filesUploadHelper";
  import IseqInfoField from "@/components/ui/IseqInfoField.component.vue";
  import {parseHPOInputsToGenes} from "@/helpers/explorareHelper";
  import IseqGenesConfirmationModal from "./components/IseqGenesVerificationModal.component"
  import IseqCard from "@/components/ui/IseqCard.vue";
  import IseqButton from "@/components/ui/IseqButton.vue";
  import IseqBanner from "@/components/ui/IseqBanner.vue"

  export default {
    name: "WorkflowCreate",
    components: {
      IseqSimpleMessageModal,
      "analysis-create-input": AnalysisCreateInput,
      "v-iseq-insufficient-credits": IseqInsufficientCreditsCard,
      "v-iseq-loading-spinner": LoadingSpinner,
      'v-iseq-info-field': IseqInfoField,
      "v-iseq-genes-confirmation": IseqGenesConfirmationModal,
      "v-iseq-card": IseqCard,
      "v-iseq-btn": IseqButton,
      "v-iseq-banner": IseqBanner
    },
    beforeRouteLeave(to, from, next) {
      if (!to.name.includes('analys')) {
        this.$store.commit('analysis/clearAnalysisTableOptions');
        this.$store.commit('analysis/resetContent');
        next();
      } else {
        next();
      }
    },
    data: function () {
      return {
        selectedSample: undefined,
        filesLoading: false,
        analysisName: "",
        inputs: {},
        description: "",
        selectedWorkflowModel: undefined,
        parsingGenePanelSpinnerModal: false,
        isLongDescriptionOpen: false,
        sampleFilesExtensions: [],
        parsedGroupedWorkflows: [],
        HPOParsingResults: {genes: [], errors: []},
        unpairedFilesDialogVisibility: false,
        unpairedInputName: "",
        missingGroupInputsDialogVisibility: false,
        missingGroupInputsData: {},
        missingRequiredInputsDialogVisibility: false,
        missingRequiredInputsData: [],
        tab: 0,
        runAnalysisLock: false,
        inputsWithErrors: [],
        errorsWithoutSpecificInput: [],
        showWorkflowFromAddressInput: false,
        workflowAddress: "",
        workflowVariant: "1",
        errorGettingWdlFromUrl: undefined
      }
    },
    computed: {
      ...mapState('user', ['userCredits', 'isAdmin']),
      ...mapState('organization', ['organization']),
      ...mapState('project', ['headerProjects', 'project']),
      ...mapState('sample', ['sample', 'samplesMinimal']),
      ...mapState('workflow', {
        selectedWorkflow: 'workflow',
        workflows: "workflows",
        workflowsGrouped: "workflowsGrouped"
      }),

      advancedInputsExist() {
        if (this.selectedWorkflow && this.selectedWorkflow.inputs) {
          return this.selectedWorkflow.inputs.some(input => input.advanced)
        } else return false
      },
      showInputs() {
        return (
          this.selectedWorkflow &&
          this.selectedWorkflow.inputs &&
          !this.filesLoading &&
          this.permissionRequired('analysisAdd')
        )
      },
      visibleBasicInputs() {
        return this.selectedWorkflow.inputs.filter(input =>
          !input.groupId && !input.advanced);
      },
      visibleAdvancedInputs() {
        return this.selectedWorkflow.inputs.filter(input =>
          !input.groupId && input.advanced);
      },
      workflowPrice() {
        return (this.selectedWorkflow.price ? this.selectedWorkflow.price : 0)
      },
      sampleFilesTree() {
        return this.sample.filesTree;
      },
      isSelectedWorkflowAffordable() {
        return this.workflowPrice <= this.userCredits;
      },
      isAnalysisNameTaken() {
        return this.sample.analysesNames && this.sample.analysesNames.includes(this.purifyAnalysisName(this.analysisName));
      },
      isPhenotypesDescriptionProvided() {
        if (!this.selectedWorkflow.inputs) return;

        const input = this.selectedWorkflow.inputs.find(node => node.explorare === "Phenotypes");

        return input && this.inputs[input.id] && this.inputs[input.id].length > 0;
      },
      createAnalysisDisabledReasons() {
        if (!this.analysisName.length) {
          return "Analysis name cannot be empty";
        } else if (this.selectedSample && this.isAnalysisNameTaken) {
          return "There is already existing analysis under this name in this sample";
        } else if (!this.selectedSample) {
          return "You must select any sample to run the analysis";
        } else if (!!this.workflowAddress && !!this.selectedWorkflowModel) {
          return "You have to select workflow for your analysis";
        } else if (!this.isSelectedWorkflowAffordable) {
          return "You don't have enough credits to run this workflow";
        } else {
          return ""
        }
      }
    },
    watch: {
      selectedWorkflow(newWorkflow) {
        this.analysisName = newWorkflow.name;
        this.inputs = {};
        this.description = "";
        this.tab = 0;

        if (newWorkflow.inputs) {
          for (const input of newWorkflow.inputs) {
            this.setDefaultForInput(input);
          }
        }
      },
      showWorkflowFromAddressInput() {
        this.$store.commit("workflow/cleanWorkflow");
        this.analysisName = "";
        this.inputs = {};
        this.description = "";
        this.selectedWorkflowModel = undefined;
        this.workflowAddress = "";
        this.workflowVariant = "1";
        this.errorGettingWdlFromUrl = undefined;
      },
      workflowsGrouped: {
        deep: true,
        handler: function () {
          this.parsedGroupedWorkflows = this.workflowsGrouped.map(item => Object.assign(item, {available: this.analysisRequiredFilesPresentInSample(item.requiredInputs)}));
          if (typeof this.$route.params.name !== "undefined") {
            const foundWorkflow = this.workflowsGrouped.find(workflow => workflow.workflowId === this.$route.params.name);

            if (foundWorkflow) {
              this.$store.dispatch('workflow/getWorkflow', foundWorkflow.workflowId);
              this.analysisName = foundWorkflow.name;
              this.selectedWorkflowModel = foundWorkflow.workflowId;
            }
          }
        }
      },
      sampleFilesExtensions: {
        deep: true,
        handler: function () {
          this.parsedGroupedWorkflows = this.workflowsGrouped.map(item => Object.assign(item, {available: this.analysisRequiredFilesPresentInSample(item.requiredInputs)}));
        }
      },
      sampleFilesTree: {
        deep: true,
        handler: function (newTree) {
          this.sampleFilesExtensions = this.collectSampleExtensions(newTree);
          if (Object.keys(newTree).length !== 0) {
            this.filesLoading = false;
          }
          if (this.selectedWorkflow.inputs) {
            this.selectedWorkflow.inputs.forEach(input => this.updateFileInputs(input));
          }
        }
      },
      samplesMinimal() {
        const foundSample = this.samplesMinimal.find(sample => sample.uuid === this.$route.params.name);
        if (foundSample) {
          this.selectedSample = foundSample;
          this.selectSample(foundSample);
        }
      },
      project(newProject) {
        if (!newProject.uuid) {
          this.$router.push('/');
        } else if (!permissionCheck('analysisAdd')) {
          this.$router.push('/analyses');
        } else {
          this.$store.dispatch('sample/getSampleListMinimal');
          this.inputs = {};
          this.selectedSample = undefined
        }
      },
    },
    created: function () {
      if (this.project) {
        this.$store.dispatch("sample/getSampleListMinimal");
      }
      this.$log.debug("vue.workflow.created");
      if (typeof this.$route.params.name !== "undefined") {
        const foundWorkflow = this.workflows.find(workflow => workflow.workflowId === this.$route.params.name);

        if (foundWorkflow) {
          this.$store.dispatch('workflow/getWorkflow', foundWorkflow.workflowId);
          this.analysisName = foundWorkflow.name;
          this.selectedWorkflowModel = foundWorkflow.workflowId;
        } else {
          this.$store.commit('workflow/cleanWorkflow');
        }
      } else {
        this.$store.commit('workflow/cleanWorkflow');
      }
      if (!this.workflows.length) this.$store.dispatch('workflow/getProdWorkflows');
      this.parsedGroupedWorkflows = this.workflowsGrouped.map(item => Object.assign(item, {available: this.analysisRequiredFilesPresentInSample(item.requiredInputs)}));
    },
    beforeCreate() {
      this.$store.commit('sample/cleanSample');
    },
    methods: {
      permissionRequired(action) {
        return permissionCheck(action)
      },
      compileMarkdown(data) {
        return markdownCompile(data);
      },
      toggleWorkflowDescription() {
        this.isLongDescriptionOpen = !this.isLongDescriptionOpen;
      },
      async loadWorkflowFromAddress() {
        this.errorGettingWdlFromUrl = undefined;
        let promise = await this.$store
          .dispatch('workflow/getWorkflowFromURL', {url: this.workflowAddress, variant: this.workflowVariant})
          .then()
          .catch(rejectionError => {
            this.errorGettingWdlFromUrl = rejectionError;
            console.log(rejectionError)
          });
      },
      collectSampleExtensions(node) {
        let extensions = [];
        if (node.children) {
          for (let subnode of node.children) {
            extensions = [...extensions, ...this.collectSampleExtensions(subnode)];
          }
          return extensions;
        } else {
          return [getFileExtensionFromFilename(node.label)];
        }
      },
      // TODO
      // Following code comes from functionality which was problematic at the time of its creation. It's possible that
      // it will be added back in the future as functionality isn't required for app to work properly so the code itself
      // shouldn't be deleted yet.
      analysisRequiredFilesPresentInSample(requiredInputs) {
        return true;
      // if (!this.selectedSample || !requiredInputs) {
      //     // If sample wasn't selected workflow isn't blocked yet
      //     return true;
      // } else {
      //     // Checking if there is a required input missing potential file matches
      //     for (let requiredInput of requiredInputs) {
      //         let filesFound = 0;
      //         // Searching for match for any of available extensions
      //         for (let extension of requiredInput.constraints?.extensions) {
      //             // Checking how many appearances of of such extension exists in selected sample
      //             if (this.sampleFilesExtensions.includes(extension)) {
      //                 filesFound += this.sampleFilesExtensions.filter(item => item === extension).length;
      //             }
      //         }
      //         // Checking if enough appearances have been found - 1 for unpaired input and 2 for paired input
      //         if (filesFound < (requiredInput.paired ? 2 : 1)) {
      //             // If required input doesn't have matching files in selected sample workflow can't be ran on it
      //             return false;
      //         }
      //     }
      //     // If no missing files were detected workflow cn be ran on selected sample
      //     return true;
      // }
      },
      selectSample(sample) {
        this.filesLoading = true;
        this.$store.dispatch('sample/getSample', sample.uuid);
      },
      createSample() {
        this.$router.push('/samples/new');
      },
      selectWorkflow(workflow) {
        this.selectedWorkflowModel = workflow.workflowId;
        this.$store.dispatch('workflow/getWorkflow', workflow.workflowId)
      },
      purifyAnalysisName(name) {
        return name.replace(/[^A-Za-z0-9\s\-._]+/g, '_');
      },
      setDefaultForInput(input) {
        if (input.inputs) {
          for (const subinput of input.inputs) {
            this.setDefaultForInput(subinput);
          }
        } else if (typeof input.default !== "undefined") {
          if (input.type === 'File' || input.type === "Array[File]") {
            if (this.sampleFilesTree.children) {
              if (input.type === 'File') {
                this.setDefaultForFileInput(input);
              } else {
                this.setDefaultForFilesArrayInput(input);
              }
            }
          } else if (!Array.isArray(input.default) || typeof input.default[0] !== 'object') {
            this.updateInput({
              value: input.default,
              id: input.id,
              groupId: input.groupId
            });
          }
        } else if (input.id === "sample_info_json" && this.sampleFilesTree.children) {
          const samplePropertiesFile = this.sample.filesTree.children.find(element => element.label === "sample-properties.json");
          this.updateInput({
            value: samplePropertiesFile.id,
            id: input.id,
            groupId: input.groupId
          });
        } else if (input.id === "sample_id") {
          if (typeof this.sample.details.name !== 'undefined') {
            this.updateInput({
              value: this.sample.details.name.replace(/\s/g, '_'),
              id: input.id,
              groupId: input.groupId
            });
          }
        }
      },
      setDefaultForFileInput(input) {
        const file = this.sampleFilesTree.children.find(element => element.label === input.default);
        if (file) {
          this.updateInput({
            value: file.id,
            id: input.id,
            groupId: input.groupId
          });
        }
      },
      setDefaultForFilesArrayInput(input) {
        let filesIds = [];
        input.default.forEach(defaultFileName => {
          const foundFile = this.sample.filesTree.children.find(element => element.label === defaultFileName);
          if (foundFile) {
            filesIds.push(foundFile.id);
          }
        });
        this.updateInput({
          value: filesIds,
          id: input.id,
          groupId: input.groupId
        });
      },
      updateFileInputs(input) {
        if (input.inputs) {
          for (const subinput of input.inputs) {
            this.updateFileInputs(subinput);
          }
        } else if (input.type === 'File' || input.type === "Array[File]") {
          if (typeof input.default !== 'undefined' && this.sampleFilesTree.children) {
            if (input.type === 'File') {
              this.setDefaultForFileInput(input);
            } else {
              this.setDefaultForFilesArrayInput(input);
            }
          } else if (input.id === "sample_info_json" && this.sampleFilesTree.children) {
            const samplePropertiesFile = this.sampleFilesTree.children.find(element => element.label === "sample-properties.json");
            this.updateInput({
              value: samplePropertiesFile.id,
              id: input.id,
              groupId: input.groupId
            });
          } else {
            this.updateInput({
              value: undefined,
              id: input.id,
              groupId: input.groupId
            });
          }
        } else if (input.id === "sample_id") {
          if (typeof this.sample.details.name !== 'undefined') {
            this.updateInput({
              value: this.sample.details.name.replace(/\s/g, '_'),
              id: input.id,
              groupId: input.groupId
            });
          }
        }
      },
      genesPanelAccepted(value) {
        this.inputs["panel_json"] = JSON.stringify(value);
        this.runAnalysis();
      },
      async validateInputs() {
        let errors = [];
        this.inputsWithErrors = [];
        this.errorsWithoutSpecificInput = [];
        let shouldAnalysisBeStoppedForExplorareConfirmation = false;

        for (const input of this.selectedWorkflow.inputs) {
          const value = this.inputs[input.id];

          // Checking if each input marked with 'paired' flag has even number of values
          if (input.constraints && input.constraints.paired) {
            if (value && value.length % 2 === 1) {
              errors.push({id: input.id, reason: "Paired input requires even number of values"});
            }
          }

          // Correcting empty arrays to undefined
          if (input.type === 'Array[String]' || input.type === 'Array[File]') {
            if(Array.isArray(value) && value.length === 0) {
              this.updateInput({
                value: undefined,
                id: input.id,
                groupId: input.groupId
              });
            }
          }

          // Checking if required inputs have been filled
          if (input.required && (typeof value === "undefined" || (Array.isArray(value) && value.length === 0) || value === "")) {
            errors.push({id: input.id, reason: "This input is required"});
          }


          if (input.genePanelExplorare) {
            let HPOInputs = {};
            let HPOInputsIds = {};
            this.HPOParsingResults = {genes: [], errors: []};

            for (let explorareInput of input.inputs) {
              const explorareInputValue = this.inputs[explorareInput.id];
              if (explorareInput.explorare === "Phenotypes" && explorareInputValue && explorareInputValue.length > 0) {
                HPOInputs.phenotypes_description = explorareInputValue;
                HPOInputsIds.phenotypes_description = explorareInput.id;
              }
              if (explorareInput.explorare === "Diseases" && explorareInputValue && explorareInputValue.length > 0) {
                HPOInputs.diseases = explorareInputValue;
                HPOInputsIds.diseases = explorareInput.id;
              }
              if (explorareInput.explorare === "HPOTerms" && explorareInputValue && explorareInputValue.length > 0) {
                HPOInputs.hpo_terms = explorareInputValue;
                HPOInputsIds.hpo_terms = explorareInput.id;
              }
              if (explorareInput.explorare === "Genes" && explorareInputValue && explorareInputValue.length > 0) {
                HPOInputs.genes = explorareInputValue;
                HPOInputsIds.genes = explorareInput.id;
              }
              if (explorareInput.explorare === "PanelNames" && explorareInputValue && explorareInputValue.length > 0) {
                HPOInputs.panel_names = explorareInputValue;
                HPOInputsIds.panel_names = explorareInput.id;
              }
            }

            // If HPO inputs were present additional confirmation will be needed. User may also need to fix faulty HPO inputs
            if (Object.keys(HPOInputs).length > 0) {
              this.parsingGenePanelSpinnerModal = true;
              this.HPOParsingResults = await parseHPOInputsToGenes(HPOInputs, HPOInputsIds);
              errors = [...errors, ...this.HPOParsingResults.errors];
              this.parsingGenePanelSpinnerModal = false;
              if (!this.HPOParsingResults.errors || this.HPOParsingResults.errors.length === 0) {
                this.inputs[input.genePanelExplorare.outputName] = JSON.stringify(this.HPOParsingResults.genes);
              }

              // If there were visible used HPO inputs additional confirmation is needed.
              shouldAnalysisBeStoppedForExplorareConfirmation = true;
            } else {
              // If there were no HPO inputs analysis may be started without additional confirmations
              shouldAnalysisBeStoppedForExplorareConfirmation = false;
            }
          }
        }

        // Checking if minimal number of inputs have been provided in each input group
        if (this.selectedWorkflow.groupInputs) {
          for (let index in Object.keys(this.selectedWorkflow.groupInputs)) {
            let filled = 0;
            let group = this.selectedWorkflow.groupInputs[Object.keys(this.selectedWorkflow.groupInputs)[index]];
            for (let j = 0; j < group.inputs.length; j++) {
              let value = this.inputs[group.inputs[j].id];

              // Checking if input has been filled
              if (typeof value !== "undefined" && value !== "" && (Array.isArray(value) ? value.length > 0 : true)) {
                filled++;
              }
            }
            if (group.constraints && filled < group.constraints.minInputs) {
              errors.push({
                id: group.id,
                reason: `Minimal number of inputs in this group is ${group.constraints.minInputs}`
              });
            }
          }
        }

        // If any errors occurred analysis cannot be run
        if (errors.length > 0 || shouldAnalysisBeStoppedForExplorareConfirmation) {
          this.inputsWithErrors = errors.filter(node => !!node.id);
          this.errorsWithoutSpecificInput = errors.filter(node => !node.id);
          if (this.inputsWithErrors.length > 0) {
            const highestElementId = await this.getHighestElementId(this.inputsWithErrors);
            document.getElementById(highestElementId).scrollIntoView({behavior: "smooth", block: "center"});
          }
          return false;
        } else {
          return true;
        }
      },
      async getHighestElementId(elements) {
        // Changing tab for 'Basic' - if there are no advanced inputs nothing happens
        this.tab = 0;

        // View needs time to rerender new tab
        await this.$nextTick();

        // Separate errors from basic and advanced inputs
        const errorsFromAdvancedInputs = elements.filter(node => {
          const input = this.selectedWorkflow.inputs.find(input => {
            return input.id === node.id;
          });
          return !!input.advanced;
        })
        const errorsFromBasicInputs = elements.filter(node => !errorsFromAdvancedInputs.includes(node));

        let documentElements;

        // If there are errors in basic inputs then showing them will get priority. If all of them are in advanced
        // inputs tab will be changed to advanced inputs
        if (errorsFromBasicInputs.length > 0) {
          documentElements = errorsFromBasicInputs.map(node => document.getElementById(node.id));
        }
        else {
          // Swap visible section to 'Advanced' inputs so they will appear in the Document
          this.tab = 1;

          // View needs time to rerender new tab
          await this.$nextTick();

          // Getting elements from now visible 'Advanced' section
          documentElements = errorsFromAdvancedInputs.map(node => document.getElementById(node.id)).filter(node => node !== null);
        }

        // Sorting inputs with errors from selected Basic/Advanced input section
        documentElements.sort((a, b) =>
          a.compareDocumentPosition(b) & Node.DOCUMENT_POSITION_FOLLOWING ? -1 : 1
        );
        return documentElements[0].id;
      },
      async startValidation() {
        // Lock on button to prevent multiple run
        this.runAnalysisLock = true;

        // Validating inputs
        let inputsValid = await this.validateInputs();

        if (inputsValid) {
          this.runAnalysis();
        } else {
          // Unlocking run button if inputs didn't pass validation
          this.runAnalysisLock = false;
        }
      },
      runAnalysis() {
        for (let input of this.selectedWorkflow.inputs) {

          // Removing inputs which shouldn't be passed
          if (input.excluded) {
            this.$delete(this.inputs, input.id);
          }

          // Setting sample id if not set by user
          if (input.id === "sample_id" && !this.inputs[input.id]) {
            this.inputs[input.id] = this.sample.details.name;
          }
          if (input.id === "sample_id") {
            this.inputs[input.id] = this.inputs[input.id].replace(/\s/g, '_');
          }

          // Parsing genes and HPO terms provided as a string to an Array
          if ((input.explorare === 'Genes' || input.explorare === 'HPOTerms') && !!this.inputs[input.id]) {
            this.inputs[input.id] = this.inputs[input.id].split(',').map(node => node.trim());
          }

          // Adjusting array format depending on multiselect constraint value
          if ((input.type === 'Array[String]' || input.type === 'Array[File]')) {
            if (Array.isArray(this.inputs[input.id])
              && this.inputs[input.id].length === 1
              && !input.type.includes('Array')) {

              this.inputs[input.id] = this.inputs[input.id][0];
            } else if (!Array.isArray(this.inputs[input.id])
              && this.inputs[input.id] != null
              && input.type.includes('Array')) {

              this.inputs[input.id] = [this.inputs[input.id]];
            }
          }
        }

        // Adding additional inputs - user's timezone difference and analysis metadata
        this.inputs["timezoneDifference"] = (new Date().getTimezoneOffset()) * 60 * 1000;
        this.inputs["analysisEnvironment"] = process.env.VUE_APP_MODE === 'tgenome' ? 'T-Systems' : 'IntelliseqFlow';

        // Running analysis
        let analysisData = {
          inputs: JSON.stringify(this.inputs),
          name: this.purifyAnalysisName(this.analysisName),
          description: this.description
        }

        if (this.showWorkflowFromAddressInput) {
          analysisData.workflowUrl = `${this.workflowAddress}${this.workflowVariant ? "?variant=" + this.workflowVariant : ''}`;
        } else {
          analysisData.workflowId = this.selectedWorkflow.workflowId;
        }

        this.$store.dispatch("analysis/createAnalysis", {
          sampleUuid: this.sample.details.uuid,
          data: analysisData
        });
      },
      removeErrorForInputId(id) {
        const errorToRemove = this.inputsWithErrors.find(item => item.id === id);
        if (errorToRemove) {
          this.inputsWithErrors.splice(this.inputsWithErrors.indexOf(errorToRemove), 1);
        }
      },
      updateInput(newInput) {
        this.removeErrorForInputId(newInput.id);
        if (newInput.groupId) {
          this.removeErrorForInputId(newInput.groupId);
        }

        if (typeof newInput.value !== 'undefined') {
          this.$set(this.inputs, newInput.id, newInput.value);
        } else {
          this.$delete(this.inputs, newInput.id);
        }
      }
    }
  }
</script>
<style lang="scss"
       scoped>
.iseq-analysis-create {
  &__base-info,
  &__actions,
  &__details {
    &__title-row {
      padding: 20px 0 16px 16px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__switch {
      margin-bottom: 20px;
    }

    &__wrapper {
      justify-content: space-between;
      display: flex;
      margin-bottom: 20px;

      .text-workflow-input {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &__field {
          max-width: calc(100% - 250px);
        }

        &__field-short {
          max-width: 70px;
        }

        &__button {
          margin-right: 15px;
        }
      }

      .text-field {
        margin: 0px 4px 0px 0px;
        max-width: 33%;
      }

      .new-sample-button {
        height: 56px;
        min-width: 33%;
      }

      &__name-input {
        .text-field {
          min-width: 525px;
        }
      }

      &__name-input-error-text {
        color: var(--danger-color)
      }

      &__description-input {
        display: flex;
        flex-direction: row;
      }

      &__inputs {
        background: $gray-200;
        border: 1px solid $gray-400;
        margin-bottom: 8px;
      }
    }

    &__workflow-description {
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      width: 100%;

      &__markdown-field {
        color: grey;
        font-size: 16px;

        p {
          margin-left: -10px;
        }
      }

      &__long-description-toggle-button {
        color: var(--font-color);
        text-decoration: underline;
        cursor: pointer;
        font-size: 14px;
      }
    }

    &__info-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 30px 0 30px 0;

      .text-value {
        font-size: 20px;
        color: var(--font-color);
      }
    }

    &__missing-files-alert {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: -16px;
    }
  }

  &__actions {
    padding-bottom: 0px;

    &__title-row {
      justify-content: end;
    }

    &__tabs-wrapper {
      justify-content: space-between;
      display: flex;

      &__tab {
        .icon {
          margin-right: 16px;
          font-size: 1.25rem;
          font-weight: 500;
          line-height: 2rem;
          letter-spacing: 0.0125em;
        }
      }
    }
  }

  &__details {
    &__wrapper {
      flex-direction: column;

      .iseq-text-area {
        margin: 4px;
        margin-top: 25px;
        background: transparent;
      }
    }
  }

  &__dialog {
    &__content {
      padding: 20px 20px;
      font-size: 16px;
      text-align: center;
      background: #fff;

      &__text {
        display: block;
        margin-bottom: 40px;

        &__link {
          color: var(--primary-color);
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .input {
        margin: 10px 0 10px 0;
      }
    }
  }

  &__run-section {
    &__button {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }
}

.iseq-select {
  height: 55px;
  max-width: 33%;

  &--max-width {
    min-width: 100%;
  }

  &__label {
    display: flex;
    flex-direction: row;

    &__name {
      &__value {
        color: var(--font-color);
        font-size: 15px;
      }

      &__disabled-value {
        color: grey;
        font-size: 15px;
      }

      &--selected {
        margin-top: 4px;
      }
    }

    &__price {
      border: 1px solid var(--primary-color);
      padding: 3px 7px;
      margin-left: 10px;
      border-radius: 5px;

      .value {
        color: var(--primary-color);
        font-size: 12px;
      }
    }

    &__disabled-price {
      border: 1px solid var(--border-inactive);
      padding: 3px 7px;
      margin-left: 10px;
      border-radius: 5px;

      .value {
        color: grey;
        font-size: 12px;
      }
    }
  }
}
</style>
