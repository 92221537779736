<template>
  <v-toolbar
    flat
    dense
    height="80px"
    color="navbar_bg"
    class="iseq-top-toolbar"
  >
    <v-row
      fluid
      align="center"
      class="iseq-top-toolbar__container"
    >
      <div class="iseq-top-toolbar__container__wrapper-organization">
        <template v-if="typeof organization.uuid!=='undefined'">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
              >
                <v-iseq-dropdown
                  caption="Organization"
                  :title="organization.name"
                  icon="mdi-home-city"
                  :items="organizations"
                />
              </div>
            </template>
            <span>{{ `${organizations.length > 1 ? 'Select Organization' : 'Go to Organization'}` }}</span>
          </v-tooltip>
        </template>
      </div>

      <div :class="['iseq-top-toolbar__container__wrapper-project', typeof project.uuid === 'undefined' ? 'centered' : '' ]">
        <template v-if="typeof project.uuid!=='undefined'">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
              >
                <v-iseq-dropdown
                  caption="Project"
                  :title="project.name"
                  icon="mdi-folder"
                  :items="headerProjects"
                />
              </div>
            </template>
            <span>{{ `${headerProjects.length > 1 ? 'Select Project' : 'Go to Project'}` }}</span>
          </v-tooltip>
        </template>
      </div>


      <div class="iseq-top-toolbar__container__wrapper-balance">
        <template v-if="typeof organization.uuid!=='undefined'">
          <template v-if="permissionRequired('organizationCreditsAdd')">
            <v-iseq-dropdown
              caption="Account Balance"
              :title="userCredits"
              icon="mdi-cash-multiple"
              :items="credits"
            />
          </template>

          <template v-else>
            <v-icon color="inactive">
              mdi-cash-multiple
            </v-icon>
            <v-card
              flat
              class="iseq-top-toolbar__container__wrapper-balance__card"
              color="transparent"
            >
              <v-card-text class="iseq-top-toolbar__container__wrapper-balance__card__text">
                <p class="label">
                  Account Balance
                </p>
                <p class="value">
                  {{ userCredits }}
                </p>
              </v-card-text>
            </v-card>
          </template>
        </template>
      </div>

      <div class="iseq-top-toolbar__container__wrapper-profile">
        <template v-if="navBarUserInfo.email">
          <v-iseq-logout />
        </template>
      </div>
    </v-row>
  </v-toolbar>
</template>

<script>
  import Logout from './Logout.vue';
  import IseqDropDown from '@/components/IseqDropDown.vue';
  import {mapState} from 'vuex';
  import {permissionCheck} from "../../plugins/permissions";

  export default {
    name: 'IseqTopToolbar',
    components: {
      'v-iseq-logout': Logout,
      'v-iseq-dropdown': IseqDropDown,
    },
    data: function () {
      return {
        credits: [{name: 'Add credits', route: '/credits', type: 'credits'}],
      };
    },
    computed: {
      ...mapState('user', ['navBarUserInfo', 'userCredits']),
      ...mapState('organization', ['organization', 'organizations']),
      ...mapState('project', ['project', 'headerProjects']),
    },
    watch: {
      organization() {
        this.$store.dispatch('user/getCredits');
      },
      organizations(newOrganizationsList, oldOrganizationsList) {
        if (Array.isArray(oldOrganizationsList) && oldOrganizationsList.length) {
          if (!newOrganizationsList || (Array.isArray(newOrganizationsList) && !newOrganizationsList.length)) {
            this.$store.dispatch('organization/getHeaderOrganizations', {});
          }
        }
      },
      headerProjects(newHeaderProjects, oldHeaderProjects) {
        if (Array.isArray(oldHeaderProjects) && oldHeaderProjects.length) {
          if (!newHeaderProjects || (Array.isArray(newHeaderProjects) && !newHeaderProjects.length)) {
            this.$store.dispatch('project/getHeaderProjects', {organizationUuid: this.organization.uuid});
          }
        }
      }
    },
    created() {
      this.$store.dispatch('user/getCredits');
    },
    methods: {
      permissionRequired(action) {
        return permissionCheck(action)
      },
      route: function (path) {
        this.$router.push({path: path});
      },
    }
  };
</script>

<style lang="scss"
       scoped>
    .iseq-top-toolbar {

        &__container {

            &__wrapper-organization {
                width: calc(35% - 95px);
                margin-left: 40px;
                margin-right: 20px;
                order: 0;
            }

            &__wrapper-project {
                width: calc(35% - 95px);
                margin-right: 20px;
                order: 1;
            }

            &__wrapper-balance {
                width: 145px;
                margin-right: 20px;
                order: 2;
                display: flex;
                flex-direction: row;

                &__card {
                    margin-left: 12px;
                    padding: 0;

                    &__text {
                        margin-left: 0px;
                        padding: 0;

                        .label {
                            color: var(--inactive);
                            font-size: 0.7em;
                            line-height: 1em;
                        }

                        .value {
                            color: var(--light);
                            font-weight: 700;
                            font-size: 1em;
                        }
                    }
                }
            }

            &__wrapper-profile {
                width: calc(30% - 95px);
                margin-right: 40px;
                order: 3;
            }
        }
    }
</style>