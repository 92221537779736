<template>
  <v-dialog
    v-model="active"
    persistent
  >
    <div class="iseq-report-variants">
      <div class="iseq-report-variants__header">
        <span class="iseq-report-variants__header__title">
          Report variants{{ selectHeaders ? ' columns' : '' }} selection
        </span>
      </div>

      <div v-show="!selectHeaders">
        <div class="iseq-report-variants__content">
          <div class="iseq-report-variants__content__table">
            <iseq-loading-spinner
              v-if="showLoader"
              text="Loading variants..."
            />
            <v-data-table
              v-else
              v-model="selected"
              :headers="visibleHeaders"
              :items="filteredItems"
              show-select
              select-strategy="all"
              checkbox-color="primary"
              item-key="ISEQ_GNOMAD_ID"
              :search="search"
              height="100%"
              fixed-header
              @click:row="selectValue"
            >
              <template
                v-for="header in visibleHeaders"
                #[`header.${header.value}`]="{}"
              >
                {{ header.name ? header.name : header.text }}
                <v-tooltip
                  :key="header.value"
                  top
                >
                  <template #activator="{ on }">
                    <v-icon
                      color="primary"
                      size="15px"
                      class="mb-5"
                      v-on="on"
                    >
                      mdi-help-circle
                    </v-icon>
                  </template>
                  <span>{{ header.description }}</span>
                </v-tooltip>
              </template>

              <template
                v-for="header in visibleHeaders"
                #[`item.${header.value}`]="{item}"
              >
                <template v-if="header.type === 'link'">
                  <span
                    v-if="item[header.value]"
                    :key="header.value"
                    class="link"
                    @click="getReportFile(item[header.value])"
                  >Click to view</span>
                </template>

                <template v-else-if="header.display && header.display === 'icon'">
                  <template v-if="header.icons[item[header.value]]">
                    <v-icon
                      :key="header.value"
                      color="text"
                    >
                      {{ header.icons[item[header.value]] }}
                    </v-icon>
                  </template>

                  <template v-else>
                    {{ item[header.value] }}
                  </template>
                </template>

                <template v-else-if="String(item[header.value]).length > 25">
                  <v-tooltip
                    :key="header.value"
                    top
                  >
                    <template #activator="{ on }">
                      <span v-on="on">
                        {{ `${String(item[header.value]).slice(0, 25)}...` }}
                      </span>
                    </template>
                    {{ item[header.value] }}
                  </v-tooltip>
                </template>

                <template v-else>
                  <span
                    :key="header.value"
                    :class="{'pill': (header.display && header.display === 'pill')}"
                    :style="{'background-color': (header.display && header.display === 'pill') ? (header.colors[item[header.value]] ? header.colors[item[header.value]] : 'black') : 'transparent' }"
                  >
                    <template v-if="header.display && header.display === 'pill'">
                      {{ header.icons[item[header.value]] ? header.icons[item[header.value]] : item[header.value] }}
                    </template>

                    <template v-else>
                      {{ item[header.value] }}
                    </template>
                  </span>
                </template>
              </template>
            </v-data-table>
          </div>

          <div class="iseq-report-variants__content__tools">
            <div class="iseq-report-variants__content__tools__title">
              Data filtering
            </div>

            <div class="iseq-report-variants__content__tools__searchbar">
              <v-tooltip top>
                <template #activator="{ on }">
                  <div v-on="on">
                    <v-text-field
                      v-model="search"
                      label="Search"
                      outlined
                      hide-details
                    />
                  </div>
                </template>
                Searches through all visible data without affecting filters
              </v-tooltip>
            </div>

            <div class="iseq-report-variants__content__tools__button-row">
              <v-iseq-btn
                :type="filterRulesActive ? 'primary' : 'secondary'"
                class="iseq-button"
                @click="filterDataModal = true"
              >
                {{ `Filter data: ${filterRulesActive ? 'ON' : 'OFF'}` }}
              </v-iseq-btn>

              <v-iseq-btn
                ml
                class="iseq-button"
                @click="selectHeaders=true"
              >
                Columns' settings
              </v-iseq-btn>
            </div>

            <div
              v-show="filterRulesActive"
              class="iseq-report-variants__content__tools__button-row"
            >
              <v-tooltip top>
                <template #activator="{ on }">
                  <div v-on="on">
                    <v-iseq-btn
                      type="primary"
                      class="iseq-button"
                      :disabled="deselectUnmatchedActive"
                      @click="deselectUnmatched()"
                    >
                      Deselect unmatched
                    </v-iseq-btn>
                  </div>
                </template>
                Deselects previously selected values that don't match currently set filters<br>
                You have {{ deselectUnmatchedActive ? 'no' : unmatchedVariantNumber }} unmatched variant{{ unmatchedVariantNumber === 1 ? '' : 's' }}
              </v-tooltip>

              <v-tooltip top>
                <template #activator="{ on }">
                  <div v-on="on">
                    <v-iseq-btn
                      ml
                      class="iseq-button"
                      @click="handleAllFiltered"
                    >
                      {{ areAllFilteredSelected ? 'Deselect' : 'Select' }} all filtered
                    </v-iseq-btn>
                  </div>
                </template>
                Selects all filtered items if none or only some are selected. Deselects all filtered items if all are already selected.
              </v-tooltip>
            </div>

            <div class="iseq-report-variants__content__tools__button-row">
              <v-iseq-btn
                width="310px"
                class="iseq-button iseq-button--wide"
                @click="downloadToFile()"
              >
                Download selected to CSV file
              </v-iseq-btn>
            </div>

            <template v-if="!!variantAnalysisId">
              <div class="iseq-report-variants__content__tools__separator" />

              <div class="iseq-report-variants__content__tools__title">
                Variants analysis
              </div>

              <v-form
                v-model="dataFormValidity"
                class="iseq-report-variants__content__tools__analysis-data"
              >
                <v-text-field
                  v-model="analysisName"
                  outlined
                  :hide-details="!nameRulesBroken"
                  :rules="analysisNameRules"
                  label="Variant analysis name"
                  class="iseq-report-variants__content__tools__analysis-data__name"
                />

                <v-textarea
                  v-model="analysisDescription"
                  outlined
                  hide-details
                  rows="3"
                  label="Variant analysis description"
                  class="iseq-report-variants__content__tools__analysis-data__description"
                />
              </v-form>

              <div class="iseq-report-variants__content__tools__button-row">
                <v-tooltip
                  top
                  :disabled="!!analysisName.length && !!selected.length"
                >
                  <template #activator="{ on }">
                    <div v-on="on">
                      <v-iseq-btn
                        width="310px"
                        :disabled="!dataFormValidity || !analysisName || analysisName.length === 0 || selected.length === 0"
                        @click="confirmReportVariants()"
                      >
                        Run analysis
                      </v-iseq-btn>
                    </div>
                  </template>
                  {{ selected.length ? 'Analysis name cannot be empty' : 'At least one variant must be selected' }}
                </v-tooltip>
              </div>
            </template>
          </div>
        </div>

        <div class="iseq-report-variants__close-button">
          <v-iseq-btn
            type="secondary"
            class="iseq-report-variants__close-button__button"
            @click="closeModal()"
          >
            Close
          </v-iseq-btn>
        </div>
      </div>

      <div v-show="!!selectHeaders">
        <iseq-report-variants-columns
          :parent-selected-headers="selectedHeaders"
          :all-headers="headers"
          :previous-analysis-name="previousAnalysisName"
          @closed="selectHeaders = false"
          @updateSelectedHeaders="updateSelectedHeaders"
        />
      </div>
    </div>

    <iseq-report-variants-filtering
      :visibility="filterDataModal"
      :items="items"
      :headers="visibleHeaders"
      @closed="filterDataModal = false"
      @updateFilteredValues="updateFilteredValues"
      @updateFiltersActive="updateFiltersActive"
    />

    <iseq-simple-message-modal
      v-if="fileBlobDialog"
      :header="file.name"
      button-text="Close"
      width="calc(100vw - 170px)"
      @closed="fileBlobDialog = false"
    >
      <div style="text-align: center;">
        <iframe
          :src="file.url"
          style="width: 98%; height: calc(100vh - 251px);"
        />
      </div>
    </iseq-simple-message-modal>
  </v-dialog>
</template>

<script>
  import {mapState} from "vuex";
  import IseqButton from "@/components/ui/IseqButton.vue";
  import IseqReportVariantsFiltering from "@/views/Analysis/components/IseqReportVariantsFiltering.modal.vue";
  import IseqReportVariantsColumns from "@/views/Analysis/components/IseqReportVariantsColumns.component.vue";
  import IseqSimpleMessageModal from "@/components/modals/IseqSimpleMessageModal.component.vue";
  import IseqLoadingSpinner from "@/components/ui/LoadingSpinner.vue";

  export default {
    name: "IseqReportVariants",
    components: {
      IseqLoadingSpinner,
      IseqSimpleMessageModal,
      IseqReportVariantsColumns,
      IseqReportVariantsFiltering,
      "v-iseq-btn": IseqButton
    },
    props: {
      analysisUuid: {
        type: String,
        required: true
      },
      previousAnalysisName: {
        type: String,
        required: true
      },
      sampleUuid: {
        type: String,
        required: true
      },
      files: {
        type: Object,
        required: true
      },
      usedAnalysisNames: {
        type: Array,
        required: true
      },
      variantSelectors: {
        type: Array,
        required: true
      },
      variantAnalysisId: {
        type: String,
        required: false,
        default: ""
      }
    },
    data: function () {
      return {
        active: true,
        items: [],
        selected: [],
        headers: [],
        selectHeaders: false,
        selectedHeaders: [],
        search: "",
        analysisName: "",
        analysisDescription: undefined,
        analysisNameRules: [
          v => (!!v ? v.length <= 50 : true) || "Name cannot be longer than 50 characters",
          v => !this.usedAnalysisNames.includes(v) || "Analysis named " + v + " already exists in this organization"
        ],
        dataFormValidity: false,
        filterDataModal: false,
        filteredItems: [],
        filterRulesActive: false,
        fileBlobDialog: false,
        file: {},
        showLoader: false,
      }
    },
    computed: {
      ...mapState('analysis', ['variantsData']),
      visibleHeaders() {
        return this.headers.filter(header => this.selectedHeaders.includes(header.value) || header.required);
      },
      nameRulesBroken() {
        return this.analysisNameRules.some(item => item(this.analysisName) !== true);
      },
      deselectUnmatchedActive() {
        if (!this.filterRulesActive) {
          return true;
        } else {
          return !this.selected.some(item => !this.filteredItems.includes(item));
        }
      },
      unmatchedVariantNumber() {
        return this.selected.filter(item => !this.filteredItems.includes(item)).length;
      },
      areAllFilteredSelected() {
        return !this.filteredItems.some(item => !this.selected.includes(item));
      }
    },
    created() {
      this.getFile();
    },
    beforeDestroy() {
      this.$store.commit('analysis/setVariantsData', {variants: [], columns: {}});
    },
    methods: {
      closeModal() {
        this.$emit('closed');
      },
      updateSelectedHeaders(payload) {
        this.selectedHeaders = payload;
      },
      confirmReportVariants() {
        this.$emit('confirmed', {
          variants: this.selected.map(variant => variant.ISEQ_GNOMAD_ID).join(';'),
          name: this.analysisName.toString(),
          description: this.analysisDescription ? this.analysisDescription : ""
        });
      },
      selectValue(item) {
        if (this.selected.includes(item)) {
          this.selected.splice(this.selected.indexOf(item), 1);
        } else {
          this.selected.push(item);
        }
      },
      deselectUnmatched() {
        this.$nextTick(() => {
          this.selected = this.selected.filter(item => this.filteredItems.includes(item));
        });
        this.$forceUpdate();
      },
      handleAllFiltered() {
        if (this.filteredItems.every(item => this.selected.includes(item))) {
          this.deselectAllFiltered();
        } else {
          this.selectAllFiltered()
        }
      },
      selectAllFiltered() {
        this.selected = this.selected.filter(item => !this.filteredItems.includes(item));
        this.selected = [...this.selected, ...this.filteredItems];
      },
      deselectAllFiltered() {
        this.selected = this.selected.filter(item => !this.filteredItems.includes(item));
      },
      updateFilteredValues(values) {
        this.filteredItems = values;
      },
      updateFiltersActive(value) {
        this.filterRulesActive = value;
      },
      downloadToFile() {
        const headers = this.headers.map(header => header.value).join(',') + '\r\n';
        const content = this.selected.map(selectedNode => {
          const line = [];
          for (const header of this.headers) {
            line.push(`"${selectedNode[header.value]}"`);
          }
          return line.join(',');
        }).join('\r\n');

        this.download(`${this.previousAnalysisName.replace(' ', '_')}_viariants.csv`, headers+content)
      },
      download(filename, text) {
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      },
      getFile() {
        const outputAddress = this.variantSelectors[0].variants[0].outputAddress;
        const fileAddress = outputAddress.slice(outputAddress.split('/', 2).join('/').length + 1);
        this.showLoader = true;
        this.$store.dispatch('sample/downloadFile', {
          type: "analysis",
          key: fileAddress,
          analysisUuid: this.analysisUuid
        }).then(response => {
          axios({
            url: response.url,
            responseType: 'json',
            credentials: "include",
            mode: "no-cors",
            headers: {
              "Accept": "application/json; odata=verbose"
            }
          }).then((response) => {
            this.items = response.data.variants;
            this.filteredItems = response.data.variants;
            for (const [key, value] of Object.entries(response.data.columns)) {
              this.headers.push({text: key, value: key, ...value});
            }
            this.selectedHeaders = this.headers.filter(node => node.default).map(node => node.text);
            this.showLoader = false;
          })
        })
      },
      getReportFile(path) {
        this.$store.dispatch('sample/downloadFile', {
          type: 'analysis',
          key: `${this.analysisUuid}/${path.slice(0, path.indexOf('#'))}`,
          analysisUuid: this.analysisUuid,
          sampleUuid: this.sampleUuid
        }).then(response => {
          this.file.name = 'Variant';
          this.file.url = `${response.url}${path.slice(path.indexOf('#'))}`;
          this.fileBlobDialog = true;
        })
      },
    }
  }
</script>

<style scoped
       lang="scss">
.iseq-report-variants {
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: white;
  max-height: calc(100vh - 100px);

  &__header {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__title {
      color: var(--dark);
      font-size: 1.7em;
      font-weight: 700;
    }

    &__topbar {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 5px;
      margin-bottom: -20px;

      &__search {
        width: 310px;
        margin-bottom: 10px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: row;

    &__table {
      width: calc(100% - 330px);
      max-height: calc(100vh - 250px);
      border-right: 1px solid var(--light);

      ::v-deep .v-data-table {
        height: calc(100% - 58px);
      }

      .pill {
        width: min-content;
        display: block;
        padding: 3px 10px;
        color: white;
        border-radius: 15px;
        font-weight: bold;
      }
    }

    &__tools {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 330px;
      padding: 20px 0 0 20px;
      margin-top: -14px;
      margin-bottom: 55px;

      &__title {
        color: var(--dark);
        font-size: 1.7em;
        font-weight: 700;
      }

      &__button-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 10px;
        width: 310px;
      }

      &__searchbar {
        width: 310px;
        margin-top: 10px;
      }

      &__separator {
        margin-top: 15px;
        margin-bottom: 10px;
        width: 100%;
        height: 2px;
        background-color: var(--light);
      }

      &__analysis-data {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        &__name {
          width: 310px;
        }

        &__description {
          margin-top: 10px;
          width: 310px;
        }
      }
    }
  }

  &__close-button {
    margin-top: -45px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    &__button {
      width: 150px;
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  ::v-deep .v-data-table .v-data-footer {
    margin-top: 0px !important;
  }

  ::v-deep tbody tr {
    cursor: default;
  }

  .iseq-button {
    width: 150px;

    &--wide {
      width: 310px;
    }
  }

  .link {
    font-weight: bold;
    text-decoration: underline;
    color: var(--primary-color);
    cursor: pointer;
  }
}
</style>